import { Col, Divider, Form, Input, Row, Select, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import parkService from '../../../services/config/park.service';
import { CheckCircleFilled, CloseCircleFilled, LoadingOutlined } from '@ant-design/icons';
import promotionService from '../../../services/config/promotion.service';
import { useDispatch } from 'react-redux';
import { set_valid_promo_code } from '../../../actions/config.action';

const NewPromoModal = ({ form, }) => {
    const dispatch = useDispatch();
    const [state, setState] = useState({
        parks : [],

        // check promo
        validating : false,
        is_valid_promo : null,

        selected_park : null,
    })
    const [timer, setTimer] = useState(null);

    const initialize = async () => {
        const parkResponse = await parkService?.getParkByAuth();
        const { parks } = parkResponse;

        setState(state => ({...state, parks : parks,}))
    }

    const changePark = async () => {
        const { park, } = form.getFieldsValue();
        const selected_park = state?.parks?.find(x => x.park_id === park);
        setState(state => ({...state, selected_park : selected_park, }));

        await checkPromoExisiting();
    }

    const checkPromoExisiting = async () => {
        const { promo_code, park, } = form.getFieldsValue();
        if(promo_code && park){
            setState(state => ({...state, validating : true,}));
            const validatePromoResponse = await promotionService?.getPromoCode({
                park_ids : [park],
                promo_code,
            })


            // results
            const { count, rows } = validatePromoResponse?.promotion_codes;
            const is_validate = (count <= 0);
            dispatch(set_valid_promo_code(is_validate));
            setTimeout(() => {
                setState(state => ({...state, validating : false, is_valid_promo : is_validate, }));
            }, 500);
        }
    }

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            checkPromoExisiting();
        }, 300);
        setTimer(newTimer);
    }

    useEffect(() => {
        initialize();
    }, [])

    return (
        <>
            <div>
                {/* general detail */}
                <div style={{marginTop:12,}}>
                    <div>
                        <Form.Item name={'park'} label="Park">
                            <Select
                            onChange={changePark}
                            options={state?.parks?.map((park, index) => {
                                const { park_id, name } = park;
                                return {
                                    label : name,
                                    value : park_id,
                                }
                            })}
                            placeholder="Select Park"
                            />
                        </Form.Item>
                    </div>
                    <div>
                        <Row gutter={[12, 12]}>
                            <Col span={12}>
                                <div>
                                    <Form.Item name="name" label="Name">
                                        <Input placeholder='Promotion Name' />
                                    </Form.Item>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div>
                                    <Form.Item name="promo_code" label={<><Tag color='orange' style={{border:'none',}}>Promo Code</Tag></>}>
                                        <Input
                                        placeholder='PROMO CODE'
                                        // status='error'
                                        suffix={
                                            state?.validating ?
                                            (
                                                <>
                                                    <LoadingOutlined />
                                                </>
                                            ) :
                                            (
                                                <>
                                                    {
                                                        (state?.is_valid_promo != null) &&
                                                        (
                                                            <>
                                                                {
                                                                    state?.is_valid_promo ?
                                                                    (
                                                                        <>
                                                                            <CheckCircleFilled style={{color:'#95de64'}} />
                                                                        </>
                                                                    )
                                                                    :
                                                                    (
                                                                        <>
                                                                            <CloseCircleFilled style={{color:'#ff7875'}} />
                                                                        </>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                    
                                                </>
                                            )
                                        }
                                        onChange={(e) => doneTyping(e?.target?.value)}
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                <Divider style={{ margin : 0, marginBottom:12, }} />
                {/* percentage amount */}
                <div>
                    <div style={{textAlign:'start',}}>
                        <span style={{fontWeight:500,}}>Promotion Amount</span>
                    </div>
                    <div style={{marginTop:8,}}>
                        <Row gutter={[12, 12]}>
                            <Col span={8}>
                                <div>
                                    <Form.Item name="percentage" label="Percentage">
                                        <Input placeholder='0.00' allowClear type='number' style={{width:'100%'}} suffix={"%"} />
                                    </Form.Item>
                                </div>
                            </Col>

                            <Col span={8}>
                                <div>
                                    <Form.Item name="value" label="Value">
                                        <Input placeholder='0.00' allowClear type='number' style={{width:'100%'}} prefix={state?.selected_park?.currency?.code} />
                                    </Form.Item>
                                </div>
                            </Col>

                            <Col span={8}>
                                <div>
                                    <Form.Item label="Cap" name='max_cap'>
                                        <Input placeholder='0.00' allowClear type='number' style={{width:'100%'}} prefix={state?.selected_park?.currency?.code} />
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewPromoModal;