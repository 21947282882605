import { Badge, Button, Card, Col, Form, Image, Input, Modal, Popconfirm, Row, Space, Upload, notification } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { CheckCircleFilled, CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import StepNoItem from '../../../helpers/components/step_no_item';
import parkService from '../../../services/config/park.service';
import globalHelper from '../../../helpers/functions/global.helper';
import EditTicketTypeModal from './edit_ticket_type_modal';
import AddTicketTypeModal from './add_ticket_type_modal';

const { TextArea } = Input;
const TicketType = ({ park_id, ticket_types, refreshPage = () => {}, }) => {
    const [add_form] = Form.useForm();
    const [edit_form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        edit_mode : false,

        // modal
        edit_modal : null,
        add_new_modal : false,

        // initialize
        all_ticket_types : [],

        // action
        save_loading : false,
        update_loading : false,
    })

    const openEditModal = (park_applicable_ticket_type_id) => {
        setState(state => ({...state, edit_modal : park_applicable_ticket_type_id,}));
    }

    const saveParkApplicableTicketType = async () => {
        setState(state => ({...state, save_loading : true, }));
        const { image, advance_day, change_date_advance_day, date_range, descriptions, min_purchase, max_purchase, min_paying_guest, ticket_type_id, } = add_form?.getFieldsValue();
        const saveParkApplicableTicketTypeResponse = await parkService?.saveParkApplicableTicketType(park_id, {
            image : image?.file, 
            advance_day, change_date_advance_day, date_range, descriptions, min_purchase, max_purchase, min_paying_guest, ticket_type_id,
        }).catch((err) => {
            setState(state => ({...state, save_loading : false, }));
        })
        
        const { save_park_applicable_ticket_type } = saveParkApplicableTicketTypeResponse;
        if(save_park_applicable_ticket_type){
            // get name of ticket type
            notification?.success({
                message : 'Ticket Type Added To Park',
                description : `${save_park_applicable_ticket_type?.name}`
            })
    
            setState(state => ({...state, save_loading : false, add_new_modal : false, }));
            add_form.resetFields();
            refreshPage && refreshPage();
        }
    }

    const updateParkApplicableTicketType = async () => {
        setState(state => ({...state, update_loading : true, }));
        const park_applicable_ticket_type_id = state?.edit_modal;
        const { image, advance_day, change_date_advance_day, date_range, descriptions, min_purchase, max_purchase, min_paying_guest, } = edit_form?.getFieldsValue();
        
        const updateParkApplicableTicketTypeResponse = await parkService?.updateParkApplicableTicketType(park_applicable_ticket_type_id, {
            image : image?.file, 
            advance_day, change_date_advance_day, date_range, descriptions, min_purchase, max_purchase, min_paying_guest,
        }).catch((err) => {
            setState(state => ({...state, save_loading : false, }));
        })

        const { update_park_applicable_ticket_type, } = updateParkApplicableTicketTypeResponse;
        if(update_park_applicable_ticket_type){
            notification?.success({
                message : 'Ticket Type Updated',
            })
    
            setState(state => ({...state, update_loading : false, edit_modal : false, }));
            refreshPage && refreshPage();
        }
    }

    const deleteParkApplicableTicketType = async (park_applicable_ticket_type_id) => {
        const deleteParkApplicableTicketTypeResponse = await parkService?.deleteParkApplicableTicketType(park_applicable_ticket_type_id);
        refreshPage();
    }

    const fetchTicketTypes = async () => {
        setState(state => ({...state, loading : true,}));
        const fetchTicketTypeResponse = await parkService?.getTicketType();
        const { ticket_types : all_ticket_types, } = fetchTicketTypeResponse;

        setState(state => ({...state, loading : false, all_ticket_types : all_ticket_types, }));
    }

    useEffect(() => {
        fetchTicketTypes();
    }, [ticket_types])

    return (
        <>
            <div style={{textAlign : 'start', }}>
                <div>
                    <Row gutter={[12, 12]}>
                        {
                            ticket_types?.map((ticket_type, index) => {
                                const { image, descriptions, park_applicable_ticket_type_id, } = ticket_type?.ParkApplicableTicketType;
                                return (
                                    <Col span={4} xs={24} sm={8} md={6} xl={4} key={index}> 
                                        <Card
                                        style={{height:'100%',}}
                                        // bodyStyle={{ padding : 12, paddingTop : 12, height : 160,}}
                                        styles={{ body : { padding : 12, paddingTop : 12, height : 160, }}}
                                        // actions={[
                                        //     <EditOutlined onClick={() => openEditModal(park_applicable_ticket_type_id)} />,
                                        //     <DeleteOutlined />
                                        // ]}
                                        cover={
                                            <div style={{padding : 8, paddingBottom : 0, }}>
                                                <div style={{ height:'100%', width:'auto', textAlign:'center',}}>
                                                    <div style={{ background:'var(--secondary-button-color)', maxWidth:'100%'}}>
                                                        {
                                                            image ?
                                                            (
                                                                <Image preview={false} src={image} style={{objectFit:'cover', height : 120, borderRadius : 0,}}/>
                                                            )
                                                            :
                                                            (
                                                                <div style={{height : 120,}} />
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        >
                                            <div style={{display : 'flex', flexDirection :'column', height : '100%',}}>
                                                <div style={{textAlign:'center',}}>
                                                    <div>
                                                        <span className='header-font' style={{fontWeight:'500', color : '#434343'}}>{ticket_type?.name}</span>
                                                    </div>
                                                    <div>
                                                        <span style={{color:'#6f7287', fontSize:12,}}>{descriptions}</span>
                                                    </div>
                                                </div>

                                                <div style={{ marginTop : 'auto', }}>
                                                    <div style={{ display : 'flex', }}>
                                                        <div style={{ marginLeft : 'auto', }}>
                                                            <Space>
                                                                <Button
                                                                icon={<EditOutlined style={{ color : 'var(--secondary-text-color)' }} />}
                                                                size='small'
                                                                onClick={() => openEditModal(park_applicable_ticket_type_id)}
                                                                />
                                                                <Popconfirm
                                                                title="Delete Ticket Type"
                                                                description={ticket_type?.name}
                                                                overlayStyle={{ minWidth : 250, }}
                                                                onConfirm={() => deleteParkApplicableTicketType(park_applicable_ticket_type_id)}
                                                                >
                                                                    <Button
                                                                    icon={<DeleteOutlined style={{ color : 'var(--secondary-text-color)' }} />}
                                                                    size='small'
                                                                    />
                                                                </Popconfirm>
                                                            </Space>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                        <Col span={4} xs={24} sm={8} md={6} xl={4}>
                            <Card
                            style={{background : 'var(--secondary-button-color)', height : '100%', cursor : 'pointer', border : '1px dashed var(--secondary-text-color)', }}
                            // bodyStyle={{ minHeight : 200, height : '100%', display : 'flex', justifyContent:'center', alignItems:'center', }}
                            styles={{ body : { minHeight : 200, height : '100%', display : 'flex', justifyContent:'center', alignItems:'center', } }}
                            onClick={() => setState(state => ({...state, add_new_modal : true, }))}
                            >
                                <div style={{textAlign : 'center', color : 'var(--secondary-text-color)',  }}>
                                    <div>
                                        <PlusOutlined />
                                    </div>
                                    <div>
                                        <span style={{ fontSize:12, fontWeight:500, }}>
                                        Add New Ticket Type
                                        </span>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>

            <Modal
            title="Add New Ticket Type"
            open={state?.add_new_modal}
            onCancel={() => {
                setState(state => ({...state, add_new_modal : false,}));
                add_form?.resetFields();
            }}
            onOk={() => add_form.submit()}
            width={800}
            style={{top: 20,}}
            confirmLoading={state?.save_loading}
            >
                <Form
                form={add_form}
                layout='vertical'
                onFinish={saveParkApplicableTicketType}
                >
                    <AddTicketTypeModal form={add_form} selected_ticket_types={ticket_types} all_ticket_types={state?.all_ticket_types} />
                </Form>
            </Modal>

            <Modal
            title="Edit Ticket Type"
            open={state?.edit_modal}
            onCancel={() => {
                setState(state => ({...state, edit_modal : null,}));
                // edit_form?.resetFields();
            }}
            onOk={() => edit_form?.submit()}
            width={800}
            style={{top : 20,}}
            confirmLoading={state?.update_loading}
            >
                <Form
                form={edit_form}
                layout='vertical'
                onFinish={updateParkApplicableTicketType}
                >
                    <EditTicketTypeModal park_applicable_ticket_type_id={state?.edit_modal} form={edit_form} selected_ticket_types={ticket_types} all_ticket_types={state?.all_ticket_types} />
                </Form>
            </Modal>
        </>
    );
}

export default TicketType;