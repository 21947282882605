import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function generateSalesReport(query){
    const queryString = serviceHelper.queryString(query);
    const salesReportResponse = await axios.get(`ticket/report/sales?${queryString}`, { withCredentials : true });
    return salesReportResponse?.data;
}

async function generateVisitors(query){
    const queryString = serviceHelper.queryString(query);
    const visitorResponse = await axios.get(`ticket/report/visitors?${queryString}`, { withCredentials : true });

    return visitorResponse?.data;
}


export default {
    generateSalesReport,
    generateVisitors,
}