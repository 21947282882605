import React from 'react';
import VisitorReport from '../report/visitor/visitor_report';

const VisitorReportLayout = ({ default_filter }) => {
    return (
        <>
            <VisitorReport default_filter={default_filter}/>
        </>
    );
}

export default VisitorReportLayout;