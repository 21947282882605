import { Checkbox, Switch, Table } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

const TaxApplicableTicketTableConfiguration = ({ form, park_tax, ticket_user_types, ticket_types, park, }) => {
    const [state, setState] = useState({
        loading : false,
        columns : [],
        datas : [],

        // changeable
        checked_ticket_applicable_users : [],
        all_ticket_applicable_users : [],
    });

    const is_checkall_toggled = useMemo(() => {
        const ticket_applicable_user_id_arr = (ticket_user_types?.map(x => x?.ticket_applicable_users)?.flat()?.map(x => x?.ticket_applicable_user_id));
        const checked_ticket_applicable_user_arr = (state?.checked_ticket_applicable_users?.filter(x => x?.is_applicable == true));
        return ticket_applicable_user_id_arr?.length === checked_ticket_applicable_user_arr?.length;
    }, [state?.checked_ticket_applicable_users, ticket_user_types])

    const setForm = (ticket_applicable_user_id, is_checked) => {
        const { park_tax_ticket_applicable_users, } = form?.getFieldsValue();
        // check whether is checked
        const is_added_item = park_tax_ticket_applicable_users?.find(x => x?.ticket_applicable_user_id === ticket_applicable_user_id);
        let park_tax_ticket_applicable_user_item = park_tax_ticket_applicable_users;
        if(is_added_item){
            // replace item
            park_tax_ticket_applicable_user_item = park_tax_ticket_applicable_users.map(item => {
                if (is_added_item?.ticket_applicable_user_id === item?.ticket_applicable_user_id) {
                    return { ticket_applicable_user_id, is_applicable : is_checked };
                }
                return item;
            });
        }else{
            // if not found in added list, append to array
            park_tax_ticket_applicable_user_item = [...(park_tax_ticket_applicable_users ?? []), { ticket_applicable_user_id, is_applicable : is_checked, }]
        }

        setState(state => ({...state, checked_ticket_applicable_users : park_tax_ticket_applicable_user_item, }));  
        form.setFieldsValue({ park_tax_ticket_applicable_users : park_tax_ticket_applicable_user_item, });
    }

    const generateColumns = () => {
        let columns = [];

        // map title
        columns?.push({
            title : 'Ticket Type',
            dataIndex : 'name',
            key : 'name',
            width: 150,
            fixed: 'left',
        })

        // map data
        for(const ticket_user_type of ticket_user_types){
            const { name, ticket_user_type_id, } = ticket_user_type;
            const column = {
                title : name,
                dataIndex : 'applicable',
                key : 'applicable',
                width : 150,    
                render : (applicable, row, index) => {
                    // from database
                    const { ticket_applicable_user_id, } = applicable?.[ticket_user_type_id] ?? {};
                    const { park_tax_ticket_applicable_users } = form.getFieldsValue();
                    const is_applicable = (park_tax_ticket_applicable_users?.find(x => x?.ticket_applicable_user_id === ticket_applicable_user_id)?.is_applicable);

                    return (
                        <>
                            {
                                ticket_applicable_user_id != undefined ?
                                (
                                    <>
                                        <Switch size='small' value={is_applicable} onChange={(is_checked) => setForm(ticket_applicable_user_id, is_checked)} />
                                    </>
                                )
                                :
                                (
                                    <>
                                        <div>
                                            <span style={{fontSize:12, color:('var(--secondary-text-color)')}}>Not Applicable</span>
                                        </div>
                                    </>
                                )
                            }
                            
                        </>
                    )
                }
            }
            columns?.push(column);
        }

        return columns;
    }

    const generateRows = () => {
        const { park_tax_id, is_applicables, } = park_tax ?? {};
        const map_datas = ticket_types?.map(x => {
            const { name, ParkApplicableTicketType : park_applicable_ticket_type, ticket_type_id } = x;

            // get ticket applicable user id
            let applicable = {};
            for(const ticket_user_type of ticket_user_types){
                const { ticket_applicable_users } = ticket_user_type;
                const ticket_applicable_user = ticket_applicable_users?.find(x => x?.park_applicable_ticket_type_id === park_applicable_ticket_type?.park_applicable_ticket_type_id);
                const is_applicable = is_applicables?.find(x => (x?.ticket_applicable_user_id === ticket_applicable_user?.ticket_applicable_user_id));

                applicable[ticket_user_type?.ticket_user_type_id] = {
                    is_applicable : ticket_user_type?.is_applicable,
                    ticket_applicable_user_id : ticket_applicable_user?.ticket_applicable_user_id,
                    edit : true,
                    is_checked : is_applicable?.is_applicable,
                };
            }

            return {
                name,
                ticket_type_id,
                applicable,
            }
        })

        return map_datas;
    }

    const initializeTable = async () => {
        const columns = generateColumns();
        const datas = generateRows();
        
        setState(state => ({...state, datas : datas, columns : columns, }));

        for(const data of datas?.map(x => x?.applicable)){
            // set form
            const data_arr = (Object.values(data));
            for(const applicable of data_arr){
                const { ticket_applicable_user_id, is_checked, } = applicable;
                if(ticket_applicable_user_id && is_checked != undefined){
                    setForm(ticket_applicable_user_id, is_checked);
                }
            }
        }
    }

    useEffect(() => {
        initializeTable();
    }, [park_tax?.park_tax_id, park?.park_id])

    // if is toggle all, auto check all switch by updating the form
    const toggleSwitch = (is_check_all) => {
        const ticket_applicable_user_id_arr = (ticket_user_types?.map(x => x?.ticket_applicable_users)?.flat()?.map(x => x?.ticket_applicable_user_id))
        for(const ticket_applicable_user_id of ticket_applicable_user_id_arr){
            setForm(ticket_applicable_user_id, is_check_all);
        }
    }

    return (
        <>
            <div>
                <div style={{ display : 'flex', alignContent : 'center', width : 'inherit', }}>
                    <div>
                        <span>Ticket Type and Variant</span>
                    </div>
                    <div style={{ marginLeft : 'auto', }}>
                        <Checkbox checked={is_checkall_toggled} onChange={(e) => toggleSwitch(e?.target?.checked)}>Toggle All</Checkbox>
                    </div>
                </div>
                <div style={{ marginTop : 12, }}>
                    <Table
                    scroll={{x : 600,}}
                    pagination={false}
                    className='hub-table bordered'
                    columns={state?.columns}
                    dataSource={state?.datas}
                    size='small'
                    />
                </div>
            </div>
        </>
    );
}

export default TaxApplicableTicketTableConfiguration;