import { Col, ColorPicker, Form, Input, InputNumber, Row, Spin, Switch, } from 'antd';
import React, { useEffect, useState } from 'react';
import ticketUserTypeService from '../../../../services/config/ticket_user_type.service';
import { LoadingOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const EditTicketUserTypeModal = ({ ticket_user_type_id, form, modal, }) => {
    const [state, setState] = useState({
        loading : false,
        ticket_user_type : null,
    })

    const fetchTicketUserType = async () => {
        setState(state => ({...state, loading : true, }));
        const ticketUserTypeResponse = await ticketUserTypeService?.fetchTicketUserType({
            ticket_user_type_id,
        });

        const { ticket_user_types } = ticketUserTypeResponse;
        const ticket_user_type = ticket_user_types?.[0];

        setState(state => ({...state, loading : false, ticket_user_type : ticket_user_type, }));
        if(ticket_user_type){
            const { name, descriptions, min_age, max_age, color, is_config_face, } = ticket_user_type;
            form.setFieldsValue({ name, descriptions, min_age, max_age, color, is_config_face, });
        }
        
    }

    useEffect(() => {
        fetchTicketUserType();
    }, [ticket_user_type_id, modal,])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <Form.Item name="name" label="Name">
                    <Input />
                </Form.Item>

                <Form.Item name="descriptions" label="Description">
                    <TextArea rows={4} style={{ resize : 'none', }} />
                </Form.Item>

                <Row gutter={[12, 12]}>
                    <Col span={12}>
                        <Form.Item name="min_age" label={'Min Age'}>
                            <InputNumber min={0} style={{ width : '100%', }}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="max_age" label={'Max Age'}>
                            <InputNumber min={0} style={{ width : '100%', }}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[12, 12]}>
                    <Col span={12}>
                        <Form.Item name="is_config_face" label={'Is Facial Required'}>
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="color" label={'Color'}>
                            <ColorPicker defaultValue={"#000"} format='hex' showText />
                        </Form.Item>
                    </Col>
                </Row>
            </Spin>
        </>
    );
}

export default EditTicketUserTypeModal;