import { Button, Form, Modal, Popconfirm, Space, Table, notification } from 'antd';
import React, { Children, useEffect, useMemo, useState } from 'react';
import numberHelper from '../../../helpers/functions/number.helper';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import globalHelper from '../../../helpers/functions/global.helper';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import EditAdvanceDayModal from './edit_advance_day_modal';
import parkService from '../../../services/config/park.service';
import AddAdvanceDayDiscount from './add_advance_day_modal';

const AdvanceDayDiscount = ({ advance_day_discount, ticket_type, ticket_applicable_users, park_id, refreshPage, }) => {
    const [save_form] = Form.useForm();
    const [edit_form] = Form.useForm();
    const [state, setState] = useState({
        columns : [],

        create_modal : false,
        edit_modal : null,

        save_loading : false,
        update_loading : false,
    })

    const editModalAdvanceDayDiscount = useMemo(() => {
        const advance_day = (advance_day_discount.find(x => x?.advance_day_discount_id === state?.edit_modal));
        return advance_day;
    }, [state?.edit_modal]);

    const saveAdvanceDay = async () => {
        setState(state => ({...state, save_loading : true, }));
        const { name, min_advance_day, max_advance_day, percentage, value, } = save_form?.getFieldsValue();
        const { park_applicable_ticket_type_id, } = (ticket_type?.ParkApplicableTicketType ?? {});
        const saveAdvanceDayResponse = await parkService?.saveAdvanceDayDiscount(park_applicable_ticket_type_id, {
            name,
            advance_day : min_advance_day,
            max_advance_day,
            percentage,
            value,
        }).catch((err) => {
            setState(state => ({...state, save_loading : false, }));
        });

        const { save_advance_day_discount } = saveAdvanceDayResponse;
        notification?.success({
            message : 'Advance Day Discount Added',
            description : `${save_advance_day_discount?.name}`,
        })
        save_form.resetFields();
        setState(state => ({...state, save_loading : false, create_modal : false, }));
        refreshPage && refreshPage();
    }

    const updateAdvanceDay = async () => {
        setState(state => ({...state, update_loading : true, }));
        const advance_day_discount_id = state?.edit_modal;
        const { name, min_advance_day, max_advance_day, percentage, value, } = edit_form?.getFieldsValue();

        const updateAdvanceDayResponse = await parkService?.updateAdvanceDayDiscount(advance_day_discount_id, {
            name,
            advance_day : min_advance_day,
            max_advance_day,
            percentage,
            value,
        }).catch((err) => {
            setState(state => ({...state, update_loading : false, }));
        });

        notification?.success({
            message : 'Advance Day Discount Updated',
        })
        // edit_form?.resetFields();
        setState(state => ({...state, update_loading : false, refresh : (state?.refresh + 1), edit_modal : null, }));
        refreshPage && refreshPage();
    }

    const deleteAdvanceDay = async (advance_day_discount_id) => {
        setState(state => ({...state, delete_loading : true, }));

        const deleteAdvanceDayResponse = await parkService?.deleteAdvanceDayDiscount(advance_day_discount_id).catch((err) => {
            setState(state => ({...state, delete_loading : false, }));
        });

        notification?.success({
            message : 'Advance Day Discount Deleted',
        })

        setState(state => ({...state, delete_loading : false,}));
        refreshPage && refreshPage();
    }

    const initialize = async () => {
        let columns = [];
        // generate children columns
        const ticket_user_type_price = ticket_applicable_users?.map((ticket_applicable_user, index) => {
            const { is_fixed_price, price, ticket_user_type, } = ticket_applicable_user;
            return {
                price, is_fixed_price, name : ticket_user_type?.name
            }
        })
        let children = ticket_user_type_price;
        columns = ([...columns, {
            title : 'Days in Advance',
            key : 'advance_day',
            dataIndex : 'advance_day',
            render : (advance_day, row, index) => {
                const { name, max_advance_day, } = row;
                return (
                    <>
                        <div>
                            <div>
                                <span>{`${advance_day}-${max_advance_day} Days`}</span>
                            </div>
                            <div>
                                <span style={{fontSize :12, color : 'var(--secondary-text-color)',}}>{name}</span>
                            </div>
                        </div>
                    </>
                )
            }
        },{
            title : 'Discount',
            key : 'percentage',
            dataIndex : 'percentage',
            render : (percentage, row, index) => {
                return (
                    <>
                        <span>{`${percentage}%`}</span>
                    </>
                )
            }
        },{
            title : 'Discounted Price',
            children : children?.map((child) => {
                const { price, is_fixed_price, name, } = child ?? {};
                return {
                    title : `${name}`,
                    // You can define a custom className for the column header
                    // to style it with CSS
                    className : 'discount-ticket-type',
                    render : (text, row, index) => {
                        const { percentage, } = row ?? {};
                        const discounted_price = price - (price * (isNaN(parseFloat(percentage)) ? 0 : parseFloat(percentage / 100)));
                        const final_price = is_fixed_price ? price : discounted_price;
                    
                        return (
                            <span>{numberHelper?.commaNumber(final_price)}</span>
                        )
                    }
                }
            }),
        },{
            title : 'Action',
            key : 'advance_day_discount_id',
            dataIndex : 'advance_day_discount_id',
            width : 100,
            render : (advance_day_discount_id, row, index) => {
                return (
                    <>
                        <Button.Group>
                            <Button
                            size='small'
                            icon={<EditOutlined style={{color : 'var(--secondary-text-color)'}} />}
                            onClick={() => setState(state => ({...state, edit_modal : advance_day_discount_id, }))}
                            />
                            <Popconfirm
                            onConfirm={() => deleteAdvanceDay(advance_day_discount_id)}
                            title="Delete Advance Day Discount"
                            // description={name}
                            overlayStyle={{ width : 250,}}
                            >
                                <Button
                                size='small'
                                icon={<DeleteOutlined style={{color : 'var(--secondary-text-color)',}} />}
                                />
                            </Popconfirm>
                        </Button.Group>
                    </>
                )
            }
        },]);

        setState(state => ({...state, columns : columns, }));
    }

    useEffect(() => {
        initialize()
    }, [park_id, ticket_applicable_users])

    return (
        <>
            <div>
                <div style={{ display : 'flex', textAlign : 'start', alignItems : 'center', }}>
                    <PageHeaderComponent title={(
                        <>
                            <span style={{ fontSize : 12, color : 'var(--secondary-text-color)', }}>Advance Day Discount</span>
                        </>
                    )} />
                    <div style={{ marginLeft : 'auto', }}>
                        <Button
                        // size='small'
                        icon={<PlusOutlined />}
                        onClick={() => setState(state => ({...state, create_modal : true, }))}
                        >
                            Advance Day
                        </Button>
                    </div>
                </div>
                <div style={{ marginTop : 8, }}>
                    <Table
                    {...globalHelper?.isMobileScreen() && ({ scroll : { x : 1000,} })}
                    bordered
                    className='hub-table bordered nested-table'
                    size='small'
                    dataSource={advance_day_discount}
                    pagination={false}
                    columns={state?.columns ?? []}
                    />
                </div>
            </div>

            <Modal
            title="New Advance Day Discount"
            open={state?.create_modal}
            onCancel={() => {
                setState(state => ({...state, create_modal : false,}));
                save_form?.resetFields();
            }}
            onOk={() => save_form.submit()}
            width={800}
            confirmLoading={state?.save_loading}
            >
                <Form
                form={save_form}
                onFinish={saveAdvanceDay}
                layout='vertical'
                >
                    <AddAdvanceDayDiscount ticket_type={ticket_type} form={save_form} />
                </Form>
            </Modal>

            <Modal
            title="Edit Advance Day Discount"
            open={state?.edit_modal}
            onCancel={() => {
                setState(state => ({...state, edit_modal : null,}));
            }}
            onOk={() => edit_form.submit()}
            width={800}
            confirmLoading={state?.update_loading}
            >
                <Form
                form={edit_form}
                onFinish={updateAdvanceDay}
                layout='vertical'
                >
                    <EditAdvanceDayModal ticket_type={ticket_type} form={edit_form} advance_day_discount={editModalAdvanceDayDiscount} />
                </Form>
            </Modal>
        </>
    );
}

export default AdvanceDayDiscount;