import { Divider, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import numberHelper from '../../../helpers/functions/number.helper';
import ticketService from '../../../services/ticket/ticket.service';

const GenerateParkTransaction = ({ park, sub_total, total_amount, taxes, }) => {
    return (
        <>
            <Space direction='vertical' style={{width:'100%',}}>
                <div>
                    <div style={{display:'flex', alignItems:'center'}}>
                        <div style={{ minWidth: 200 }}>
                            <span className='label' style={{ color: '#595959', fontWeight:500, }}>Sub Total</span>
                        </div>
                        <div style={{marginLeft:'auto',}}>
                            <span className='description' style={{color:'var(--secondary-text-color)',}}>{`${park?.currency?.symbol}${numberHelper?.commaNumber((sub_total), 2)}`}</span>
                        </div>
                    </div>
                </div>

                {/* park taxes */}
                {
                    park?.park_taxes?.map((tax, index) => {
                        const { name, percentage, park_tax_id, } = tax ?? {};
                        const tax_item = taxes?.find(x => x.park_tax_id === park_tax_id);
                        const { amount, } = tax_item ?? {};
                        return (
                            <>
                                <div key={index}>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <div style={{ minWidth: 200 }}>
                                            <span className='label' style={{ color: '#595959', fontWeight:500, }}>{`${name}`}</span>
                                        </div>
                                        <div style={{marginLeft:'auto',}}>
                                            <span className='description' style={{color:'var(--secondary-text-color)',}}>{`${park?.currency?.symbol}${numberHelper?.commaNumber((amount), 2)}`}</span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })
                }

                <Divider style={{margin:0,}} />
                <div>
                    <div style={{display:'flex', alignItems:'center'}}>
                        <div style={{ minWidth: 200 }}>
                            <span className='label' style={{ color: '#595959', fontWeight:500, }}>Total Amount</span>
                        </div>
                        <div style={{marginLeft:'auto',}}>
                            <span className='description' style={{color:'var(--secondary-text-color)',}}>{`${park?.currency?.symbol}${numberHelper?.commaNumber((total_amount), 2)}`}</span>
                        </div>
                    </div>
                </div>
            </Space>
        </>
    );
}

export default GenerateParkTransaction;