import React, { useEffect, useState } from 'react';
import staffService from '../../../services/config/staff.service';
import ContainerComponent from '../../../helpers/components/container.component';
import { Avatar, Divider, Space, Spin } from 'antd';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import DetailPersonalInfo from './detail_personal_info';
import DetailImage from './detail_image';
import DetailAssociate from './detail_associate';
import moment from 'moment';
import globalHelper from '../../../helpers/functions/global.helper';

const StaffDetailLayout = ({ staff_id, }) => {
    const [state, setState] = useState({
        loading : false,
        staff : null,

        refresh : 0,
    })

    const fetchStaff = async () => {
        setState(state => ({...state, loading : true, }));
        const staffResponse = await staffService?.getStaff(staff_id);

        const { staff } = staffResponse;
        setState(state => ({...state, loading : false, staff : staff, }));
    }

    const refreshStaff = () => {
        setState(state => ({...state, refresh : (state?.refresh + 1)}));
    }
    
    useEffect(() => {
        fetchStaff();
    }, [state?.refresh])
    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <div>
                    <div className='title-header mobile-padding-24' style={{textAlign:'start'}}>
                        <ContainerComponent span={20}>
                            <div style={{ display : 'flex', alignItems : 'center',}}>
                                <div>
                                    <div>
                                        <PageHeaderComponent title={'Staff Information'} />
                                    </div>
                                    <div>
                                        <span style={{fontSize:18, color:'#595959'}}>{state?.staff?.pass?.pass_code}</span>
                                    </div>
                                </div>
                            </div>
                        </ContainerComponent>
                    </div>
                    <div className='mobile-padding-24' {...!globalHelper?.isMobileScreen() && ({ style : { marginTop :24, } })}>
                        <ContainerComponent span={20}>
                            <div>
                                <div>
                                    <DetailImage staff={state?.staff} refreshStaff={refreshStaff} />
                                </div>
                            </div>
                            {/* <Divider /> */}
                            <div style={{marginTop:24,}}>
                                <div>
                                    <DetailPersonalInfo staff={state?.staff} refreshStaff={refreshStaff} />
                                </div>
                            </div>
                            {/* <Divider /> */}
                            <div style={{marginTop:24,}}>
                                <div>
                                    <DetailAssociate staff={state?.staff} refreshStaff={refreshStaff} />
                                </div>
                            </div>
                        </ContainerComponent>
                    </div>
                </div>
            </Spin>
        </>
    );
}

export default StaffDetailLayout;