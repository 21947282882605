import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import FaceLayout from './components/face_layout';

const FaceIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <FaceLayout />
            </ContainerComponent>
        </>
    );
}

export default FaceIndex;