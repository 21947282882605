import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import EmployeeDetail from './components/employee_detail';
import { useParams } from 'react-router-dom';

const EmployeeDetailIndex = () => {
    const { account_id } = useParams();
    return (
        <>
            <EmployeeDetail account_id={account_id} />
        </>
    );
}

export default EmployeeDetailIndex;