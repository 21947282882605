import React, { useState } from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { Button, Form, Input, notification } from 'antd';
import adminService from '../../../services/admin/admin.service';
import { useDispatch } from 'react-redux';
import { set_auth } from '../../../actions/auth.action';
import authService from '../../../services/admin/auth.service';

const PersonalInfo = ({ auth, }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { full_name, email, username, account_id, } = auth ?? {};
    const [state, setState] = useState({
        save_loading : false,
    })

    const saveAccount = async () => {
        setState(state => ({...state, save_loading : true,}));
        const { full_name, email, } = form?.getFieldsValue();
        const updateAccountResponse = await adminService?.updateAccount(account_id, { full_name, email, });

        if(updateAccountResponse?.account){
            const authAccountResponse = await authService.authAccount().catch(err => {
                setState(state => ({...state, save_loading : false}));
            });
            
            const { account } = authAccountResponse;
            dispatch(set_auth(account));
        }

        notification.success({
            message : 'Update Account Successful',
        })
        setState(state => ({...state, save_loading : false, }));
    }

    return (
        <>
            <div style={{textAlign: 'start', }}>
                <PageHeaderComponent title={<span style={{ fontSize:16, }}>Personal Info</span>} />

                <div style={{marginTop:12,}}>
                    <Form
                    onFinish={saveAccount}
                    form={form}
                    layout='vertical'
                    initialValues={{ full_name : full_name, email : email, username : username, }}
                    >
                        <div>
                            <Form.Item label="Username" name={'username'}>
                                <Input disabled />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item label="Name" name={'full_name'}>
                                <Input />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item label="Email" name={'email'}>
                                <Input />
                            </Form.Item>
                        </div>
                        <div style={{display : 'flex',}}>
                            <div style={{marginLeft : 'auto',}}>
                                <Button
                                onClick={() => form.submit()}
                                type="primary"
                                style={{ minWidth:150, }}
                                loading={state?.save_loading}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default PersonalInfo;