const initialState = {
    // promo code
    promo_code_detail_refresh : 0,
    is_valid_promo_code : false,
}

const configReducer = (state = initialState, action) => {
    switch(action.type){
        case 'REFRESH_PROMO_CODE_DETAIL':
            return {...state, promo_code_detail_refresh : (state?.promo_code_detail_refresh + 1)}
        case 'SET_VALID_PROMO_CODE' : 
            return {...state, is_valid_promo_code : (action?.is_valid_promo_code)}
        default :
            return state;
    }
}

export default configReducer;