import React, { useRef, useState, useEffect } from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { Card, Col, Row, Space, Tag } from 'antd';
import numberHelper from '../../../helpers/functions/number.helper';
import moment from 'moment';
import { Line } from 'react-chartjs-2';

const GrowthChartSale = ({ total_date_breakdown_sales, total_sales, park, }) => {
    const canvasRef = useRef();
    const [state, setState] = useState({
        loading : false,
        data : null,
    })

    var options = {
        responsive: true,
        datasetStrokeWidth : 3,
        pointDotStrokeWidth : 4,
        maintainAspectRatio : false,
        scaleLabel : "1",
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                display : false,
            },
        },
        scales : {
            x: {
                grid : {
                    drawBorder: false,
                    display : false,
                },
                ticks: {
                    display: true,
                },
            },
            y: {
                beginAtZero : true,
                grid : {
                    drawBorder: false,
                    display: false,
                },
                ticks: {
                    display: false
                }
            }
        },
    };

    const height = 220;
    const width = "100%";

    const initializeSalesChart = async () => {
        // const gradient = canvasRef.current.ctx.createLinearGradient(0, 0, 0, height);
        // gradient.addColorStop(0, 'rgba(239, 219, 255, 1)');   
        // gradient.addColorStop(1, 'rgba(239, 219, 255, 0)');
        const data = {
            labels: total_date_breakdown_sales?.map(x => moment(x.date)?.format('D MMM')),
            datasets : [{
                label : 'Sales Amount (MYR)',
                lineTension : 0.4,
                // fill: 'origin',
                // backgroundColor: 'red',
                // backgroundColor : gradient, // Put the gradient here as a fill color
                borderColor : '#13c2c2',
                borderWidth: 2,
                pointColor : "#fff",
                pointStrokeColor : '#13c2c2',
                pointBackgroundColor : '#bae0ff',
                pointHighlightFill: "#fff",
                pointHighlightStroke: '#13c2c2',
                data : total_date_breakdown_sales?.map(x => x?.date_sales_amount),
            }]
        }

        return data;
    }

    useEffect(() => {
        if(total_date_breakdown_sales?.length > 0){
            initializeSalesChart().then((data) => {
                setState(state => ({...state, data : data}));
            })
        }
    }, [total_date_breakdown_sales]);

    return (
        <>
            <Card>
                <div>
                    <PageHeaderComponent title={(<><span style={{fontSize:16,}}>Sales Amount</span></>)} textAlign={'start'} />
                </div>
                <div style={{marginTop:12,}}>
                    <Row gutter={[12, 12]}>
                        <Col span={6} xs={12} sm={12} md={12} xl={8}>
                            <div style={{textAlign:'start',}}>
                                <div>
                                    <div>
                                        <span style={{color : 'var(--secondary-text-color)', fontWeight:500,}}>Sales Amount</span>
                                    </div>
                                    <div>
                                        <Space>
                                            <div>
                                                <Space>
                                                    <div>
                                                        <Tag color='blue' style={{border:'none',}}>
                                                            <span>{park?.currency?.code}</span>
                                                        </Tag>
                                                    </div>
                                                    <div>
                                                        <span style={{ fontSize: 24, }}>{`${park?.currency?.symbol}${numberHelper?.commaNumber(total_sales?.sales_amount, 2)}`}</span>
                                                    </div>
                                                </Space>
                                                
                                            </div>
                                            {/* <div>
                                                <Tag color={ compare?.sale_count_increased >= 0 ? 'green' : 'red' } style={{border:'none',}}>
                                                    {compare?.sale_count_increased >= 0 ? <RiseOutlined /> : <FallOutlined />}
                                                    <span>{`${numberHelper?.commaNumber((Math.abs(compare?.sale_count_increased) * 100), 2)}%`}</span>
                                                </Tag>
                                            </div> */}
                                        </Space>
                                        
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div style={{width : width, height : height, marginTop:24, }}>
                    <Line
                    ref={canvasRef}
                    options={options}
                    data={( state?.data ?? {
                        labels : [],
                        datasets : [],
                    })}
                    />
                </div>
            </Card>
        </>
    );
}

export default GrowthChartSale;