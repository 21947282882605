const initialState = {
    edit_account_modal : null,
    list_refresh : 0,
    role : null,
}

const accountReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_EDIT_ACCOUNT_MODAL':
            return {...state, edit_account_modal : action.edit_account_modal}
        case 'REFRESH_ACCOUNT_LIST':
            return {...state, list_refresh : (state?.list_refresh + 1)}
        case 'SET_ROLE' :
            return {...state, role : action.role}
        default :
            return state;
    }
}

export default accountReducer;