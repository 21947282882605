import React, { useEffect, useState } from 'react';
import SummaryTable from './summary_table';
import entranceService from '../../../../services/operation/entrance.service';
import TicketTypeBreakdownTable from './ticket_type_breakdown_table';
import { Space, Spin } from 'antd';
import otherService from '../../../../services/config/other.service';
import PageHeaderComponent from '../../../../helpers/components/page_header.component';
import OverallChart from './overall_chart';
import PurchaseTypeTable from './purchase_type_table';
import AddOnTable from './add_on_table';

const VisitorTable = ({ filter, }) => {
    const [state, setState] = useState({
        loading : false,
        ticket_types : [],
        purchase_types : [],

        expected_visitors : null,
        checked_in_visitors : null,

        purchase_type_datas : [],
        add_ons : [],
    })

    const fetchVisitorReport = async () => {
        setState(state => ({...state, loading : true, }));
        const { park_id, visit_date, } = filter;
        const visitorReportResponse = await entranceService?.generateVisitorReports({
            park_id, visit_date,
        });
        const { ticket_types, add_ons, expected_visitors, checked_in_visitors } = visitorReportResponse;

        // fetch purchase types
        const purchaseTypeResponse = await otherService?.getPurchaseTypes();
        const { purchase_types } = purchaseTypeResponse;

        // fetch datas by purchase types
        let purchase_type_datas = [];
        const park_applicable_ticket_types = (ticket_types?.map(x => x.park_applicable_ticket_types[0]));
        for(const purchase_type of purchase_types){
            const purchase_type_visitors = park_applicable_ticket_types?.map(x => x.ticket_user_types?.map(x => x[purchase_type.code]));
            let expected = 0;
            let visitor = 0;
            for(const purchase_type_visitor of purchase_type_visitors){
                let expected_count = purchase_type_visitor?.reduce((a, b) => (a + b?.expected), 0);
                let visitor_count = purchase_type_visitor?.reduce((a, b) => (a + b?.visitors), 0);
                expected += expected_count;
                visitor += visitor_count;
            }
            purchase_type_datas.push({
                ...purchase_type,
                expected_visitors : expected,
                checked_in_visitors : visitor,
            })
        }

        setState(state => ({...state, loading : false, ticket_types : ticket_types, add_ons : add_ons, purchase_types : purchase_types, purchase_type_datas : purchase_type_datas, expected_visitors : expected_visitors, checked_in_visitors : checked_in_visitors, }));
    }

    useEffect(() => {
        if(filter?.park_id){
            fetchVisitorReport();
        }
        
    }, [filter,])


    return (
        <>
            <div>
                {
                    filter?.park_id ?
                    (
                        <>
                            <Spin spinning={state?.loading}>
                                <div>
                                    <div>
                                        <OverallChart expected_visitors={state?.expected_visitors} checked_in_visitors={state?.checked_in_visitors} />
                                    </div>

                                    <div style={{marginTop:24,}}>
                                        <div style={{ textAlign:'start',}}>
                                            <span
                                            className='header-font' 
                                            style={{
                                                fontWeight:500, 
                                                fontSize:16,
                                                
                                            }}>
                                                {'Summary'}
                                            </span>
                                        </div>
                                        <div style={{marginTop:8,}}>
                                            <SummaryTable ticket_types={state?.ticket_types} expected_visitors={state?.expected_visitors} />
                                        </div>
                                    </div>
                                    
                                    <div style={{marginTop:24,}}>
                                        <div style={{ textAlign:'start',}}>
                                            <span
                                            className='header-font' 
                                            style={{
                                                fontWeight:500, 
                                                fontSize:16,
                                                
                                            }}>
                                                {'Total'}
                                            </span>
                                        </div>
                                        <div style={{marginTop:8,}}>
                                            <PurchaseTypeTable purchase_types={state?.purchase_types} purchase_type_datas={state?.purchase_type_datas} />
                                        </div>
                                    </div>
                                    

                                    <div style={{marginTop:24,}}>
                                        <Space size={24} direction='vertical' style={{width:'100%'}}>
                                        {
                                            state?.ticket_types?.map((ticket_type, index) => {
                                                const { park_applicable_ticket_types, name } = ticket_type;
                                                const ticket_user_types = park_applicable_ticket_types?.length > 0 ? park_applicable_ticket_types[0]?.ticket_user_types : null;
                                                return (
                                                    <div key={index}>
                                                        <div style={{ textAlign:'start',}}>
                                                            <span
                                                            className='header-font' 
                                                            style={{
                                                                fontWeight:500, 
                                                                fontSize:16,
                                                            
                                                            }}>
                                                                {name}
                                                            </span>
                                                        </div>
                                                        <div style={{marginTop:8,}}>
                                                            <TicketTypeBreakdownTable purchase_types={state?.purchase_types} ticket_user_types={ticket_user_types} />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        </Space>
                                    </div>

                                    <div style={{marginTop:24,}}>
                                        <div style={{ textAlign:'start',}}>
                                            <span
                                            className='header-font' 
                                            style={{
                                                fontWeight:500, 
                                                fontSize:16,
                                                
                                            }}>
                                                {'Additional Options'}
                                            </span>
                                        </div>
                                        <div style={{marginTop:8,}}>
                                            <AddOnTable add_ons={state?.add_ons} />
                                        </div>
                                    </div>
                                </div>
                            </Spin>
                        </>
                    )
                    :
                    (
                        <>
                            <span>Please select parks</span>
                        </>
                    )
                }
                
            </div>
        </>
    );
}

export default VisitorTable;