import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import LoginLayout from './components/login_layout';

const LoginIndex = () => {

    return (
        <>
            <ContainerComponent span={10} bottom={20}>
                <LoginLayout />
            </ContainerComponent>
           
        </>
    );
}

export default LoginIndex;