import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function fetchTicketUserType(query){
    const queryString = serviceHelper.queryString(query);
    const ticketUserTypeResponse = await axios.get(`config/ticket_user_type/get?${queryString}`, { withCredentials : true });
    return ticketUserTypeResponse?.data;
}

async function saveTicketUserType(params){
    const saveTicketUserTypeResponse = await axios.post(`config/ticket_user_type/save`, params, { withCredentials : true, });
    return saveTicketUserTypeResponse?.data;
}

async function updateTicketUserType(ticket_user_type_id, params){
    const updateTicketUserTypeResponse = await axios.post(`config/ticket_user_type/${ticket_user_type_id}/update`, params, { withCredentials : true, });
    return updateTicketUserTypeResponse?.data;
}

async function deleteTicketUserType(ticket_user_type_id){
    const deleteTicketUserTypeResponse = await axios.delete(`config/ticket_user_type/${ticket_user_type_id}/delete`, { withCredentials : true, });
    return deleteTicketUserTypeResponse?.data;
}

export default {
    fetchTicketUserType,
    saveTicketUserType,
    updateTicketUserType,
    deleteTicketUserType,
}