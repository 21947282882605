import { Checkbox, Input, List, Progress, Radio, Space, Spin, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import ticketService from '../../../services/ticket/ticket.service';
import integrationService from '../../../services/integration/integration.service'
import IntegrationLogList from './integration_log_list';
import IntegrationTicketUserLogList from './integration_ticket_user_log_list';
import FilterBullet from '../../../helpers/components/filter_bullet';
import { CaretDownFilled, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import IntegrationTicketUserFilter from './integration_ticket_user_filter';

const IntegrationLogWrapper = ({ ticket_id, park_id, }) => {
    const { innerWidth } = window;
    const [state, setState] = useState({
        loading : false,
        integration_logs : [],
        integration_ticket_user_logs : [],
        count : null,

        // integration
        park_api_types : [],
        status : [{
            id : 1,
            name : 'Success',
        }, {
            id : 2,
            name : 'Fail',
        }],

        progress_complete : 0,
        progress_total : 0,
    })

    const [filter, setFilter] = useState({
        ticket_user_ids : [],
        park_api_type_ids : [],
        status : null,

        page : 0,
        size: 10,
    })

    const [search_filter, setSearchFilter] = useState({
        ticket_user_no : null,
    })

    const items = [{
        key : '1',
        label :  'Transaction Logs',
        children : (
            <>
                <IntegrationLogList integration_logs={state?.integration_logs} />
            </>
        )
    }, {
        key : '2',
        label :  'Ticket Logs',
        children : (
            <>
                <IntegrationTicketUserLogList
                integration_ticket_user_logs={state?.integration_ticket_user_logs}
                changePagination={changePagination}
                count={state?.count}
                size={filter?.size}
                page={filter?.page}
                />
            </>
        )
    }]

    const onCheckChange = (key, checkedValues) => {
        setFilter(filter => ({...filter, [key] : checkedValues, page : 0,}));
    }

    const initializeFilter = async () => {
        const parkApiTypeResponse = await integrationService.getParkIntegrations(park_id);
        setState(state => ({...state, park_api_types : parkApiTypeResponse?.integrations}));
    }

    const fetchIntegrationLogs = async () => {
        setState(state => ({...state, loading : true, }));
        const { ticket_user_ids, park_api_type_ids, status, page, size, } = filter;

        const integrationLogResponse = await ticketService?.getIntegrationLog({
            ticket_id : ticket_id,
            ticket_user_ids : ticket_user_ids,
            park_api_type_ids : park_api_type_ids,
            status : status,
            page : (page),
            size : size,
        });

        const { integration_logs, integration_ticket_user_logs, progress, } = integrationLogResponse;
        setState(state => ({
            ...state, 
            loading : false, 
            integration_logs : integration_logs, 
            integration_ticket_user_logs : integration_ticket_user_logs?.rows,
            count : integration_ticket_user_logs?.count,
            progress_complete : progress?.completed,
            progress_total : progress?.total,
        }))
    }

    // ticket user log pagination
    function changePagination(page){
        setFilter(filter => ({...filter, page : (page - 1)}));
    }

    useEffect(() => {
        if(ticket_id){
            // Fetch data immediately when the component mounts
            fetchIntegrationLogs();
        }
    }, [ticket_id, filter]);

    useEffect(() => {
        if(park_id){
            initializeFilter();
        }
    }, [park_id])

    return (
        <>
            <div>
                {
                    state?.park_api_types?.length > 0 &&
                    (
                        <>
                            <div className='integration-progress' style={{marginBottom:12,}}>
                                <div style={{textAlign:'start', }}>
                                    <div>
                                        <span style={{fontWeight:500,}}>Integration Progress {(`(${state?.progress_complete} / ${state?.progress_total})`)}</span>
                                    </div>
                                    <div>
                                        <span style={{ color : 'var(--secondary-text-color)', fontSize:12, }}>REGISTER_TICKET</span>
                                    </div>
                                </div>
                                <div>
                                    <Progress percent={((state?.progress_complete / state?.progress_total) * 100)} format={(percent) => `${percent?.toFixed(0)}%`}/>
                                </div>
                            </div>
                        </>
                    )
                }
                
                <div className='filter-panel' style={{textAlign:'start',}}>
                    <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                        <FilterBullet
                        popover={
                            <>
                                <div>
                                    <Input onChange={(e) => setSearchFilter(search_filter => ({...search_filter, ticket_user_no : e?.target?.value}))} prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>} placeholder='Search Tickets' />
                                </div>
                                
                                <Checkbox.Group
                                value={filter?.ticket_user_ids}
                                onChange={(checkedValues) => onCheckChange('ticket_user_ids', checkedValues)}
                                >
                                    <IntegrationTicketUserFilter ticket_id={ticket_id} ticket_user_no={search_filter?.ticket_user_no} />
                                </Checkbox.Group>
                            </>
                        }
                        >
                            <div>
                                <Space>
                                    <div>
                                        <span>Tickets {filter?.ticket_user_ids?.length > 0 ? `: ${filter?.ticket_user_ids?.length}` : ''}</span>
                                    </div>
                                    <div>
                                        <CaretDownFilled style={{color:'#a0aec0'}} />
                                    </div>
                                </Space>
                                
                            </div>
                        </FilterBullet>

                        <FilterBullet
                        popover={
                            <>
                                <div>
                                    <Input prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>} placeholder='Search Integration Types' />
                                </div>
                                
                                <Checkbox.Group
                                value={filter?.park_api_type_ids}
                                onChange={(checkedValues) => onCheckChange('park_api_type_ids', checkedValues)}
                                >
                                    <List
                                    style={{ width:280, }}
                                    dataSource={state?.park_api_types}
                                    renderItem={(item, index) => {
                                        const { park_api_type_id, condition, api_type } = item ?? {};
                                        return (
                                            <List.Item style={{width:'100%'}} key={index}>
                                                <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                    <div>
                                                        <div>
                                                            <span>{condition}</span>
                                                        </div>
                                                        <div>
                                                            <span style={{fontSize:12, color: 'var(--secondary-text-color)'}}>{api_type?.name}</span>
                                                        </div>
                                                    </div>
                                                    <div style={{marginLeft:'auto'}}>
                                                        <Checkbox
                                                        value={park_api_type_id}
                                                        />
                                                    </div>
                                                </div>
                                            </List.Item>
                                        )
                                    }}
                                    />
                                </Checkbox.Group>
                            </>
                        }
                        >
                            <div>
                                <Space>
                                    <div>
                                        <span>Integration Types {filter?.park_api_type_ids?.length > 0 ? `: ${filter?.park_api_type_ids?.length}` : ''}</span>
                                    </div>
                                    <div>
                                        <CaretDownFilled style={{color:'#a0aec0'}} />
                                    </div>
                                </Space>
                                
                            </div>
                        </FilterBullet>

                        <FilterBullet
                        popover={
                            <>                               
                                <Radio.Group
                                value={filter?.status}
                                onChange={(e) => onCheckChange('status', e?.target?.value)}
                                >
                                    <List
                                    style={{ width:280, }}
                                    dataSource={state?.status}
                                    renderItem={(item, index) => {
                                        const { id, name, } = item ?? {};
                                        return (
                                            <List.Item style={{width:'100%'}} key={index}>
                                                <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                    <div>
                                                        <div>
                                                            <span>{name}</span>
                                                        </div>
                                                    </div>
                                                    <div style={{marginLeft:'auto'}}>
                                                        <Radio
                                                        value={id}
                                                        />
                                                    </div>
                                                </div>
                                            </List.Item>
                                        )
                                    }}
                                    />
                                </Radio.Group>
                            </>
                        }
                        >
                            <div>
                                <Space>
                                    <div>
                                        <span>Status {filter?.status ? `: ${state?.status?.find(x => x.id === filter?.status)?.name}` : ''}</span>
                                    </div>
                                    <div>
                                        <CaretDownFilled style={{color:'#a0aec0'}} />
                                    </div>
                                </Space>
                                
                            </div>
                        </FilterBullet>
                    </Space>
                </div>
                <div style={{marginTop : 12,}}>
                    <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                        <Tabs className='custom-tabs' items={items} />
                    </Spin>
                </div>
            </div>
           
        </>
    );
}

export default IntegrationLogWrapper;