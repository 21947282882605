import React, { useEffect, useState } from 'react';
import EntranceTable from './entrance_table';
import { Checkbox, Col, DatePicker, Input, List, Radio, Row, Skeleton, Space, Tabs, Tag } from 'antd';
import { CaretDownOutlined, SearchOutlined } from '@ant-design/icons';
import FilterBullet from '../../../../helpers/components/filter_bullet';
import entranceService from '../../../../services/operation/entrance.service';
import dayjs from 'dayjs';
import PageHeaderComponent from '../../../../helpers/components/page_header.component';
import otherService from '../../../../services/config/other.service';
import serviceHelper from '../../../../helpers/functions/service.helper';
import EntranceMotion from '../../../../helpers/components/entrance_motion';

const { RangePicker } = DatePicker;
const EntranceReport = ({ default_filter, }) => {
    const { innerWidth } = window;
    const [state, setState] = useState({
        loading : false,
        entrances : [],
        tabs : [],
    })
    
    const [errors, setErrors] = useState([]);

    const [filter, setFilter] = useState({
        search : default_filter?.search ?? null,
        entrance : default_filter?.entrance ?? [],
        inout : default_filter?.inout ?? [],
        park : [],
        start_date : default_filter?.start_date ? dayjs(default_filter?.start_date).format("YYYY-MM-DD") : dayjs()?.startOf('days')?.format("YYYY-MM-DD"),
        end_date : default_filter?.end_date ? dayjs(default_filter?.end_date).format("YYYY-MM-DD") : dayjs()?.endOf('days')?.format("YYYY-MM-DD"),
    })

    const [transaction_type, setTransactionType] = useState(default_filter?.transaction_type ?? 'ticket');

    const [timer, setTimer] = useState(null);
    
    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
            changeFilter('search', input);
        }, 300);
        setTimer(newTimer);
    }

    const onCheckChange = (key, checkedValues) => {
        setFilter(filter => ({...filter, [key] : checkedValues}));
        changeFilter(key, checkedValues);
    }
    
    const onChangeDate = (key1, key2, date) => {
        if(date){
            const start_date = dayjs(date[0])?.format('YYYY-MM-DD');
            const end_date = dayjs(date[1])?.format('YYYY-MM-DD');

            setFilter(filter => ({...filter, [key1] : start_date, [key2] : end_date}));
            changeFilter(key1, start_date);
            changeFilter(key2, end_date);
        }else{
            setFilter(filter => ({...filter, [key1] : null, [key2] : null}));
            changeFilter(key1, null);
            changeFilter(key2, null);
        }
    }

    const fetchFilterItem = async () => {
        setState(state => ({...state, loading : true, }));
        // get entrances
        const entranceResponse = await entranceService.fetchEntrances();

        // get pass types
        const passTypeResponse = await otherService?.getPassType();
        const { pass_types } = passTypeResponse;
        let tab_items = [...pass_types, {
            name : 'Ticket',
            prefix : 'ticket',
        },
        // account will be obselete soon
        // {
        //     name : 'Account',
        //     prefix : 'account',
        // }
        ];

        setState(state => ({...state, entrances : entranceResponse?.entrances, tabs : tab_items, loading : false, }));
    }
    
    const changeTransactionType = (transaction_type) => {
        setTransactionType(transaction_type);
        changeFilter('transaction_type', transaction_type);
    }

    // for changing filter
    const changeFilter = (key, value) => {
        let generate_filter = default_filter;
        generate_filter[key] = value;
        
        setFilter(filter => ({...filter, page : 1,}));
        const queryString = serviceHelper?.queryString(generate_filter);
        window.history.replaceState({}, '', `${window.location.pathname}?${queryString}`);
    }

    useEffect(() => {
        fetchFilterItem();
    }, []);

    return (
        <>
            <div className='mobile-padding-12'>
                <div style={{marginBottom: 24, textAlign:'start'}}>
                    <PageHeaderComponent title={'Entrance'} />
                </div>
                <div className='filter-panel'>
                    <div>
                        <Input
                        onChange={(e) => doneTyping(e.target.value)}
                        defaultValue={filter?.search}
                        name="search"
                        prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                        placeholder='Search Ticket Number'
                        allowClear
                        {...(errors?.find(x => x?.type === 'search_length') && ({ status : 'warning', }))}
                        />
                        {
                            errors?.find(x => x?.type === 'search_length') && (
                                <>
                                    <EntranceMotion>
                                        <div style={{ textAlign : 'start', }}>
                                            <span style={{ color : '#faad14' }}>{errors?.find(x => x?.type === 'search_length')?.message}</span>
                                        </div>
                                    </EntranceMotion>
                                </>
                            )
                        }
                    </div>

                    <div style={{display:'flex', alignItems:'center', marginTop:12,}}>
                        <div style={{textAlign:'start',}}>
                            <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                                <FilterBullet
                                popover={
                                    <>
                                        <div>
                                            <Input prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>} placeholder='Search Entrances' />
                                        </div>
                                        
                                        <Checkbox.Group
                                        value={filter?.entrance}
                                        onChange={(checkedValues) => onCheckChange('entrance', checkedValues)}
                                        >
                                            <List
                                            style={{ width:280, }}
                                            dataSource={state?.entrances}
                                            renderItem={(item, index) => {
                                                const { entrance_id, name, park, entrance_type } = item ?? {};
                                                return (
                                                    <List.Item style={{width:'100%'}} key={index}>
                                                        <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                            <div>
                                                                <span>{name}</span>
                                                            </div>
                                                            <div style={{marginLeft:'auto'}}>
                                                                <Checkbox
                                                                value={entrance_id}
                                                                />
                                                            </div>
                                                        </div>
                                                    </List.Item>
                                                )
                                            }}
                                            />
                                        </Checkbox.Group>
                                    </>
                                }
                                >
                                    <div>
                                        <Space>
                                            <div>
                                                <span>Entrance{filter?.entrance?.length > 0 && `: ${filter?.entrance?.length}` }</span>
                                            </div>
                                            <div>
                                                <CaretDownOutlined style={{color:'#a0aec0'}} />
                                            </div>
                                        </Space>
                                        
                                    </div>
                                </FilterBullet>
                                <FilterBullet
                                {...errors?.find(x => x?.type === 'filter_date_range') && ({ type : 'warning', })}
                                popover={
                                    <>
                                        <div>
                                            <RangePicker
                                            onChange={(e) => onChangeDate('start_date', 'end_date', e)}
                                            />
                                        </div>
                                    </>
                                }
                                >
                                    <div>
                                        <Space>
                                            <div>
                                                <span>Date
                                                    <span style={{fontSize:12, fontWeight:500, color:'var(--secondary-text-color)'}}>
                                                        {filter?.start_date && filter?.end_date && `: ${filter?.start_date}-${filter?.end_date}`}
                                                    </span>
                                                </span>
                                            </div>
                                            <div>
                                                <CaretDownOutlined style={{color:'#a0aec0'}} />
                                            </div>

                                            {
                                                errors?.find(x => x?.type === 'filter_date_range') && (
                                                    <>
                                                        <span style={{ fontSize : 12, color : '#fa8c16', fontWeight : '500', }}>{errors?.find(x => x?.type === 'filter_date_range')?.message}</span>
                                                    </>
                                                )
                                            }
                                        </Space>
                                    </div>
                                </FilterBullet>
                                <FilterBullet
                                popover={
                                    <>
                                        <Checkbox.Group
                                        value={filter?.inout}
                                        onChange={(checkedValues) => onCheckChange('inout', checkedValues)}
                                        >
                                            <List
                                            style={{ width: 180, }}
                                            dataSource={[{
                                                name : 'In',
                                                is_in : true,
                                            },{
                                                name : 'Out',
                                                is_in : false,
                                            }]}
                                            renderItem={(item, index) => {
                                                const { name, is_in } = item ?? {};
                                                return (
                                                    <List.Item style={{width:'100%'}}>
                                                        <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                            <div>
                                                                <span>{name}</span>
                                                            </div>
                                                            <div style={{marginLeft:'auto'}}>
                                                                <Checkbox
                                                                value={is_in}
                                                                />
                                                            </div>
                                                        </div>
                                                    </List.Item>
                                                )
                                            }}
                                            />
                                        </Checkbox.Group>
                                    </>
                                }
                                >
                                    <div>
                                        <Space>
                                            <div>
                                                <span>In/Out{filter?.inout?.length > 0 && `: ${filter?.inout?.length}` }</span>
                                            </div>
                                            <div>
                                                <CaretDownOutlined style={{color:'#a0aec0'}} />
                                            </div>
                                        </Space>
                                        
                                    </div>
                                </FilterBullet>
                            </Space>
                        </div>
                    </div>
                </div>
                <div style={{marginTop:12,}}>
                    {
                        state?.loading ?
                        (
                            <>
                                <div style={{ textAlign : 'start', }}>
                                    <Row gutter={[12, 12]} justify="start">
                                        {
                                            [...Array(4)]?.map((i, index) => {
                                                return (
                                                    <Col xs={8} sm={4} lg={3} xl={2} span={6} key={index} style={{width : '100%'}}>
                                                        <Skeleton.Input block active style={{ minWidth : 40, width : '100%',  }} />
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                </div>
                            </>
                        )
                        :
                        (
                            <>
                                <Tabs
                                className='custom-tabs'
                                activeKey={transaction_type}
                                onChange={changeTransactionType}
                                destroyInactiveTabPane
                                items={state?.tabs?.map((pass_type, index) => {
                                    const { prefix, name } = pass_type;
                                    return {
                                        key : prefix,
                                        label : name,
                                        children : <EntranceTable transaction_type={transaction_type} filter={filter} default_filter={default_filter} setErrors={setErrors}/>,
                                    }
                                })}
                                />
                            </>
                        )
                    }
                </div>
            </div>
        </>
    );
}

export default EntranceReport;