import { CheckCircleFilled, CloseCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Card, Checkbox, Col, Divider, Form, Input, List, Row, Select, Space, Switch, Upload } from 'antd';
import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import adminService from '../../../services/admin/admin.service';

const CreateEmployeeDetail = ({ roles, parks, form, }) => {
    const [state, setState] = useState({
        loading : false,
        is_username_valid : false,
        username : null,
    })

    const checkUsername = async (username) => {
        const checkUsernameResponse = await adminService?.checkUsername(username);
        const { is_valid } = checkUsernameResponse;
        return is_valid;
    }

    const generatePassword = async () => {
        const random_string = Math.random().toString(36).slice(-8);
        form.setFieldsValue({ password : random_string });
    }

    return (
        <>
            <div style={{marginTop:24,}}>
                <div>
                    <div>
                        <span
                        className='header-font' 
                        style={{
                            fontWeight:500,
                        }}>
                            {'Account Info'}
                        </span>
                    </div>
                    <div style={{marginTop:12,}}>
                        <Row gutter={[12, 12]}>
                            <Col span={12} xs={24} sm={12}>
                                <Form.Item
                                name={'username'}
                                label={'Username'}
                                requiredMark={'optional'}
                                rules={[
                                    { required : true, message : 'Please enter username' }, 
                                    { validator : async (rule, value) => {
                                        const is_valid = await checkUsername(value);
                                        setState(state => ({...state, username : value, is_username_valid : is_valid}));
                                        return new Promise((resolve, reject) => {
                                            if(value && is_valid){
                                                resolve();
                                            }else{
                                                reject("Username invalid");
                                            }
                                        })
                                    }}
                                ]}
                                hasFeedback
                                validateStatus={state?.username && (state?.is_username_valid ? 'success' : 'error')}
                                >
                                    <Input placeholder='john_doe' />
                                </Form.Item>
                            </Col>
                            <Col span={12} xs={24} sm={12}>
                                <Form.Item label={'Password'} style={{ margin : 0, }}>
                                    <Row gutter={8}>
                                        <Col span={18} xs={14} md={18}>
                                            <Form.Item
                                            style={{ width : '100%', }}
                                            name={'password'}
                                            // label={'Password'}
                                            requiredMark={'optional'}
                                            rules={[{ required : true, message : 'Please enter password' }]}
                                            >
                                                <Input.Password visibilityToggle={{ visible : true, }} placeholder='password' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6} xs={10} md={6}>
                                            <div style={{width : '100%'}}>
                                                <Button onClick={generatePassword} style={{color : 'var(--secondary-text-color)', width : '100%',}}>Generate</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col span={12} xs={24} sm={12}>
                                <Form.Item
                                name={'role'}
                                label={'Role'}
                                requiredMark={'optional'}
                                rules={[{ required : true, message : 'Please select role' }]}
                                >
                                    <Select
                                    placeholder={roles[0]?.name}
                                    options={roles?.map(role => {
                                        const { role_id, name } = role;
                                        return {
                                            value : role_id,
                                            label : name,
                                        }
                                    })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12} xs={24} sm={12}>
                                <Form.Item
                                valuePropName='checked'
                                name={'is_active'}
                                label={'Status'}
                                >
                                    <Switch />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Divider style={{ marginTop: 12, }}/>
                <div>
                    <div>
                        <span
                        className='header-font' 
                        style={{
                            fontWeight:500,
                        }}>
                            {'Personal Info'}
                        </span>
                    </div>
                    <div style={{marginTop:12,}}>
                        <Row gutter={[12, 12]}>
                            <Col span={12} xs={24} sm={12}>
                                <Form.Item name={'full_name'} label={'Name'}>
                                    <Input placeholder='John Doe' />
                                </Form.Item>
                            </Col>
                            <Col span={12} xs={24} sm={12}>
                                <Form.Item name={'email'} label={'Email Address'}>
                                    <Input placeholder='test@example.com' />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Divider style={{marginTop: 12,}} />
                <div>
                    <div>
                        <span
                        className='header-font' 
                        style={{
                            fontWeight:500,
                        }}>
                            {'Parks'}
                        </span>
                    </div>
                    <div style={{marginTop: 12, }}>
                        <Form.Item name={'parks'}>
                            <Checkbox.Group
                            style={{width:'100%'}}
                            // value={filter?.entrance}
                            // onChange={(checkedValues) => onCheckChange('entrance', checkedValues)}
                            >
                                <List
                                style={{width:'100%'}}
                                dataSource={parks}
                                split={false}
                                renderItem={(item, index) => {
                                    const { park_id, name, } = item ?? {};
                                    return (
                                        <List.Item style={{width:'100%', background:'#fff', borderRadius:8, paddingInline : 12, border:'1px solid #d5dce6', marginBottom:12, }} key={index}>
                                            <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                <div>
                                                    <span>{name}</span>
                                                </div>
                                                <div style={{marginLeft:'auto'}}>
                                                    <Checkbox
                                                    value={park_id}
                                                    />
                                                </div>
                                            </div>
                                        </List.Item>
                                    )
                                }}
                                />
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateEmployeeDetail;