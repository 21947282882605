import React from 'react';
import EmployeeLayout from './components/employee_layout';
import ContainerComponent from '../../helpers/components/container.component';

const EmployeeIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <EmployeeLayout />
            </ContainerComponent>
        </>
    );
}

export default EmployeeIndex;