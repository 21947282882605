import { Card, Checkbox, Col, Divider, Form, Input, InputNumber, Row, Select, Space, Switch, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import StepNoItem from '../../../helpers/components/step_no_item';
import otherService from '../../../services/config/other.service';
import PageHeaderComponent from '../../../helpers/components/page_header.component';

const BasicConfiguration = ({ form, park, }) => {
    const [state, setState] = useState({
        loading : false,
        currencies : [],
        payment_gateways : [],
        purchase_types : [],
    })

    const [selected, setSelected] = useState({
        currency : null,
    })

    const fetchCurrency = async () => {
        const currencyResponse = await otherService?.getCurrency();

        const { currencies, } = currencyResponse;
        return currencies;
    }

    const fetchPaymentGateway = async () => {
        const paymentGatewayResponse = await otherService?.getPaymentGateway();
        const { payment_gateways } = paymentGatewayResponse;
        return payment_gateways;
    }

    const fetchPurchaseTypes = async () => {
        const purchaseTypeResponse = await otherService?.getPurchaseTypes({
            is_capacity : 1,
        });
        const { purchase_types, } = purchaseTypeResponse;
        return purchase_types;
    }
    
    const initialize = async () => {
        if(park){
            setState(state => ({...state, loading : true, }));
            const currencies = await fetchCurrency();
            const payment_gateways = await fetchPaymentGateway();
            const purchase_types = await fetchPurchaseTypes();

            setState(state => ({...state, loading : false, currencies : currencies, payment_gateways : payment_gateways, purchase_types : purchase_types, }));
            setSelected(selected => ({...selected, currency : park?.currency_id}));
        }
    }

    useEffect(() => {
        initialize();
    }, [park])

    return (
        <>
            <Card
            // headStyle={{ padding : 12, }}
            styles={{ header : { padding : 12, } }}
            title={(
                <>
                    <div style={{textAlign : 'start', }}>
                        <StepNoItem no={3} text={'Configuration'} />
                    </div>
                </>
            )}
            >
                <div>
                    <div>
                        <div style={{ textAlign : 'start', }}>
                            <span style={{ fontSize : 16, fontWeight : 500, }}>Payment Configuration</span>
                        </div>
                        <div style={{ marginTop: 12, }}>
                            <Row gutter={[12, 12]}>
                                <Col span={12}>
                                    <Form.Item label={'Payment Gateway'} name={'payment_gateway'} style={{textAlign:'start',}}>
                                        <Select
                                        options={state?.payment_gateways?.map(payment_gateway => ({ label : payment_gateway?.name, value : payment_gateway?.payment_gateway_id, }))}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item extra={(
                                        <>
                                            <div style={{ textAlign : 'start', }}>
                                                <span>Display purpose, does not associate with payment gateway</span>
                                            </div>
                                        </>
                                    )} label={'Currency'} name={'currency'}>
                                        <Select
                                        // removeIcon
                                        onChange={(currency_id) => setSelected(selected => ({...selected, currency : (currency_id)}))}
                                        options={state?.currencies?.map(currency => ({ label : currency?.name, value : currency?.currency_id, }))}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={'Date Change Price'} name={'date_change_price'}>
                                        <InputNumber min={0} addonBefore={ state?.currencies?.find(x => x.currency_id === selected?.currency)?.code } precision={2} style={{ width : '100%', }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <Divider style={{ marginBlock : 12, }} />
                    <div>
                        <div style={{ textAlign : 'start', }}>
                            <span style={{ fontSize : 16, fontWeight : 500, }}>Others</span>
                        </div>
                        <div style={{ marginTop: 12, }}>
                            <Row gutter={[12, 12]}>
                                <Col span={12}>
                                    <Form.Item extra={(
                                        <>
                                            <div style={{textAlign : 'start',}}>
                                                <span>Ticket capacity for park</span>
                                            </div>
                                        </>
                                    )} label={'Capacity'} name={'capacity'}>
                                        <InputNumber min={0} precision={0} style={{ width : '100%', }}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={'Capacity applies to'} name={'purchase_type_capacities'} style={{ textAlign : 'start', }}>
                                        <Checkbox.Group options={state?.purchase_types?.map((purchase_type, index) => {
                                            const { purchase_type_id, name, } = purchase_type ?? {};
                                            return {
                                                label : name,
                                                value : purchase_type_id,
                                            }
                                        })}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                    valuePropName="checked"
                                    style={{ textAlign : 'start', }}
                                    label={'Required User Detail'}
                                    name={'is_user_detail_required'}
                                    extra={"Name, gender and age required when customers uploading selfies"}
                                    >
                                        <Switch />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
}

export default BasicConfiguration;