import { Button, Checkbox, Dropdown, Input, List, Modal, Radio, Space, Spin, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import SpecialLinkList from './special_link_list';
import urlService from '../../../services/ticket/url.service';
import { CaretDownFilled, DownOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import parkService from '../../../services/config/park.service';
import SpecialLinkGenerated from './special_link_generated';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import FilterBullet from '../../../helpers/components/filter_bullet';

const SpecialLinkLayout = () => {
    const { innerWidth } = window;
    const [state, setState] = useState({
        loading : false,

        //dropdown menu
        parks_dropdown : [],

        //after selected, generate special links
        generate_loading : false,
        generated_special_link : null,


        //table refresh
        refresh : 0,
    });

    const [filter, setFilter] = useState({
        parks : [],
        status : 'active',
    })

    const generateSpecialLink = async (park_id) => {
        const generateSpecialLinkResponse = await urlService.generateSpecialLink({
            park_id : park_id,
            minutes : 30,
            is_ignore_rules : true,
        });

        return generateSpecialLinkResponse?.save_special_link;
    }

    const onCheckChange = (key, checkedValues) => {
        setFilter(filter => ({...filter, [key] : checkedValues}));
        // changeFilter(key, checkedValues);
    }

    const initialize = async () => {
        setState(state => ({...state, loading : true,}));
        
        const parkResponse = await parkService.getParkByAuth();
        let parks_dropdown = [];
        for(const park of parkResponse?.parks){
            const { park_id, name } = park;
            const item = {
                key: park_id,
                // type: 'group',
                label: name,
                onClick : async () => {
                    setState(state => ({...state, generate_loading : true}));
                    const special_link = await generateSpecialLink(park_id,);

                    setTimeout(() => {
                        setState(state => ({...state, generate_loading : false, generated_special_link : special_link, refresh : (state?.refresh + 1)}));
                    }, 800);
                }
            }

            parks_dropdown?.push(item);
        }

        setState(state => ({...state, parks_dropdown : parks_dropdown, loading : false,}));
    }

    useEffect(() => {
        initialize();
    }, []);

    return (
        <>
            <div className='mobile-padding-12'>
                <div style={{marginBottom: 24, textAlign:'start'}}>
                    <PageHeaderComponent title={'Special Links'} />
                </div>
                <div style={{display:'flex'}}>
                    <div className='filter-panel' style={{textAlign:'start'}}>
                        <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                            <FilterBullet
                            popover={
                                <>
                                    <div>
                                        <Input prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>} placeholder='Search Parks' />
                                    </div>
                                    
                                    <Checkbox.Group
                                    // disabled={ticketRedux?.list_loading}
                                    value={filter?.parks}
                                    onChange={(checkedValues) => onCheckChange('parks', checkedValues)}
                                    >
                                        <List
                                        style={{ width:280, }}
                                        dataSource={state?.parks_dropdown}
                                        renderItem={(item, index) => {
                                            const { label : name, key : park_id, } = item ?? {};
                                            return (
                                                <List.Item style={{width:'100%'}} key={index}>
                                                    <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                        <div>
                                                            <span>{name}</span>
                                                        </div>
                                                        <div style={{marginLeft:'auto'}}>
                                                            <Checkbox
                                                            value={park_id}
                                                            />
                                                        </div>
                                                    </div>
                                                </List.Item>
                                            )
                                        }}
                                        />
                                    </Checkbox.Group>
                                </>
                            }
                            >
                                <div>
                                    <Space>
                                        <div>
                                            <span>Park {filter?.parks?.length > 0 ? `: ${filter?.parks?.length}` : ''}</span>
                                        </div>
                                        <div>
                                            <CaretDownFilled style={{color:'#a0aec0'}} />
                                        </div>
                                    </Space>
                                    
                                </div>
                            </FilterBullet>


                            <FilterBullet
                            popover={
                                <>
                                    <div>
                                        <Radio.Group
                                        // disabled={ticketRedux?.list_loading}
                                        value={filter?.status}
                                        onChange={(e) => onCheckChange('status', e.target.value)}
                                        >
                                            <List
                                            style={{ width:280, }}
                                            dataSource={[{
                                                label : 'Active',
                                                key : 'active',
                                                is_expired : false,
                                                is_used : false,
                                            },{
                                                label : 'Used',
                                                key : 'used',
                                                is_expired : false,
                                                is_used : true,
                                            },{
                                                label : 'Expired',
                                                key : 'expired',
                                                is_expired : true,
                                                is_used : false,
                                            }]}
                                            renderItem={(item, index) => {
                                                const { label, key, is_expired, is_used } = item ?? {};
                                                return (
                                                    <List.Item style={{width:'100%'}} key={index}>
                                                        <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                            <div>
                                                                <Tag color={!(is_expired || is_used) ? 'green' : 'red'}>
                                                                    <span>{label}</span>
                                                                </Tag>
                                                            </div>
                                                            <div style={{marginLeft:'auto'}}>
                                                                <Radio
                                                                value={key}
                                                                />
                                                            </div>
                                                        </div>
                                                    </List.Item>
                                                )
                                            }}
                                            />
                                        </Radio.Group>
                                    </div>
                                </>
                            }
                            >
                                <div>
                                    <Space>
                                        <div>
                                            <span>Status {filter?.status ? `: ${filter?.status}` : ''}</span>
                                        </div>
                                        <div>
                                            <CaretDownFilled style={{color:'#a0aec0'}} />
                                        </div>
                                    </Space>
                                    
                                </div>
                            </FilterBullet>
                        </Space>
                    </div>
                    <div style={{marginLeft:'auto'}}>
                        <Dropdown
                        placement='bottomRight'
                        overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                        menu={{
                            items: state?.parks_dropdown,
                        }}
                        >
                            <Button loading={state?.generate_loading} type='primary' icon={<DownOutlined />}>
                                Generate Special Link
                            </Button>
                        </Dropdown>
                    </div>
                </div>
                <div style={{marginTop:12,}}>
                    <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                        <SpecialLinkList filter={filter} refresh={state?.refresh} />
                    </Spin>
                </div>
            </div>

            <Modal
            title={"Special Link"}
            open={state?.generated_special_link}
            onCancel={() => setState(state => ({...state, generated_special_link : null,}))}
            footer={null}
            >
                <SpecialLinkGenerated special_link={state?.generated_special_link} />
            </Modal>
        </>
    );
}

export default SpecialLinkLayout;