import React, { useState } from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { Button, Form, Input, notification } from 'antd';
import adminService from '../../../services/admin/admin.service';

const ChangePassword = () => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        save_loading : false,
    })

    const checkPassword = async (password) => {
        const checkPasswordResponse = await adminService?.checkPassword(password);
        const { is_match, } = checkPasswordResponse;
        return is_match;
    }

    const changePassword = async () => {
        setState(state => ({...state, save_loading : true, }));
        const { old_password, new_password_1, new_password_2, } = form.getFieldsValue();
        const changePasswordResponse = await adminService?.changePassword({
            new_password : new_password_1,
        })

        notification.success({
            message : 'Change Password Successful',
        })
        form.resetFields();
        setState(state => ({...state, save_loading : false, }));
    }

    return (
        <>
            <div style={{textAlign: 'start', }}>
                <PageHeaderComponent title={<span style={{ fontSize:16, }}>Change Password</span>} />

                <div style={{marginTop: 12,}}>
                    <Form
                    onFinish={changePassword}
                    form={form}
                    layout='vertical'
                    >
                        <Form.Item
                        name={'old_password'}
                        label={'Old Password'}
                        rules={[{ validator : async (rule, value) => {
                            const is_valid = await checkPassword(value);
                            // setState(state => ({...state, username : value, is_username_valid : is_valid}));
                            return new Promise((resolve, reject) => {
                                if(value && is_valid){
                                    resolve();
                                }else{
                                    reject("Password incorrect");
                                }
                            })
                        }},]}
                        hasFeedback
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                        name={'new_password_1'}
                        label={'New Password'}
                        hasFeedback
                        requiredMark='optional'
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password.',
                            },
                        ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                        name={'new_password_2'}
                        label={'Confirm Password'}
                        dependencies={['new_password_1']}
                        hasFeedback
                        requiredMark='optional'
                        rules={[
                        {
                            required: true,
                            message: 'Please confirm your password.',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                            if (!value || getFieldValue('new_password_1') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The new password that you entered do not match.'));
                            },
                        }),
                        ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <div style={{display : 'flex',}}>
                            <div style={{marginLeft : 'auto',}}>
                                <Button
                                onClick={() => form.submit()}
                                type="primary"
                                style={{ minWidth:150, }}
                                loading={state?.save_loading}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default ChangePassword;