import React, { useState } from 'react';
import SaleReportLayout from './components/sale_report_layout';
import ContainerComponent from '../../helpers/components/container.component';
import { useLocation } from 'react-router-dom';

const SaleReport = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [filter, setFilter] = useState({
        park : isNaN(parseInt(queryParams.get("park"))) ? null : parseInt(queryParams.get("park")),
        start_date : queryParams.get("start_date"),
        end_date : queryParams.get("end_date"),
        view_type : queryParams.get("view_type"),
    })

    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <SaleReportLayout default_filter={filter} /> {/* default filter from url */}
            </ContainerComponent>
        </>
    );
}

export default SaleReport;