import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';

const GrowthChart = ({ breakdown_sales, park, }) => {
    const canvasRef = useRef();
    const [state, setState] = useState({
        loading : false,
        data : null,
    })

    var options = {
        responsive: true,
        datasetStrokeWidth : 3,
        pointDotStrokeWidth : 4,
        maintainAspectRatio : false,
        scaleLabel : "1",
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                display : false,
            },
        },
        scales : {
            x: {
                ticks: {
                    display: true,
                },
            },
            y: {
                beginAtZero : true,
                grid : {
                    drawBorder: false,
                    display: false,
                },
                ticks: {
                    display: false
                }
            }
        }
        
    };
    const height = 250;
    const width = "100%";

    const initializeSalesChart = async () => {
        const gradient = canvasRef.current.ctx.createLinearGradient(0, 0, 0, height);
        gradient.addColorStop(0, 'rgba(239, 219, 255, 1)');   
        gradient.addColorStop(1, 'rgba(239, 219, 255, 0)');
        const data = {
            labels : breakdown_sales?.map(x => x.date),
            datasets : [{
                label : `Sales Amount (${park?.currency?.code})`,
                lineTension : 0.4,
                // fill: 'origin',
                // backgroundColor: 'red',
                // backgroundColor : gradient, // Put the gradient here as a fill color
                borderColor : '#0555E1',
                borderWidth: 2,
                pointColor : "#fff",
                pointStrokeColor : '#0555E1',
                pointBackgroundColor : '#bae0ff',
                pointHighlightFill: "#fff",
                pointHighlightStroke: '#0555E1',
                data : breakdown_sales?.map(x => (x.date_sales_amount ?? 0))
            }]
        }

        return data;
    }

    useEffect(() => {
        initializeSalesChart().then((data) => {
            setState(state => ({...state, data : data}));
        })
    }, [breakdown_sales]);

    return (
        <>
            <div style={{width : width, height : height, padding : '20px 8px'}}>
                <Line
                ref={canvasRef}
                options={options}
                data={( state?.data ?? {
                    labels : [],
                    datasets : [],
                })}
                />
            </div>
        </>
    );
}

export default GrowthChart;