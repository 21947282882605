import { LoadingOutlined } from '@ant-design/icons';
import { Divider, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import customPassService from '../../../services/config/custom_pass.service';
import CustomPassImage from './custom_pass_image';
import CustomPassPersonalInfo from './custom_pass_personal_info';
import ContainerComponent from '../../../helpers/components/container.component';
import globalHelper from '../../../helpers/functions/global.helper';

const CustomPassDetailLayout = ({ custom_pass_id, }) => {
    const [state, setState] = useState({
        loading : false,
        custom_pass : null,
        refresh : 0,
    })

    const fetchCustomPass = async () => {
        setState(state => ({...state, loading : true, }));
        const customPassResponse = await customPassService?.getCustomPass(custom_pass_id);
        const { custom_pass } = customPassResponse;
        setState(state => ({...state, loading : false, custom_pass : custom_pass, }));
    }

    const refreshCustomPass = async () => {
        setState(state => ({...state, refresh : (state?.refresh + 1)}));
    }

    useEffect(() => {
        fetchCustomPass();
    }, [state?.refresh]);

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <div>
                    <div className='title-header mobile-padding-24' style={{textAlign:'start'}}>
                        <ContainerComponent span={20}>
                            <div style={{ display : 'flex', alignItems : 'center',}}>
                                <div>
                                    <div>
                                        <PageHeaderComponent title={'Custom Pass Information'} />
                                    </div>
                                    <div>
                                        <span style={{fontSize:18, color:'#595959'}}>{state?.custom_pass?.pass?.pass_code}</span>
                                    </div>
                                </div>
                            </div>
                        </ContainerComponent>
                    </div>
                    <div className='mobile-padding-24' {...!globalHelper?.isMobileScreen() && ({ style : { marginTop : 24, } })}>
                        <ContainerComponent span={20}>
                            <div>
                                <div>
                                    <CustomPassImage custom_pass={state?.custom_pass} refreshCustomPass={refreshCustomPass} />
                                </div>
                            </div>
                            <div style={{marginTop:24,}}>
                                <div>
                                    <CustomPassPersonalInfo custom_pass={state?.custom_pass} refreshCustomPass={refreshCustomPass} />
                                </div>
                            </div>
                        </ContainerComponent>
                    </div>
                </div>
            </Spin>
        </>
    );
}

export default CustomPassDetailLayout;