const initialState = {
    tickets : [],
    count : null,
    refresh : 0,
    list_loading : false,

    // edit ticket
    is_editable : true, // default is true, means can save ticket
}

const ticketReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_TICKETS':
            return {...state, tickets : action.tickets, count : action.count}
        case 'REFRESH_TICKET_DETAIL' : 
            return {...state, refresh : (state?.refresh + 1)}
        case 'SET_LIST_LOADING':
            return {...state, list_loading : action.loading }
        case 'SET_EDITABLE' :
            return {...state, is_editable : action.is_editable,}
        default :
            return state;
    }
}

export default ticketReducer;