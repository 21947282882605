import React, { useEffect, useState } from 'react';
import authService from './services/admin/auth.service';
import { useDispatch, } from 'react-redux';
import { set_auth } from './actions/auth.action';
import AuthRouter from './routes/auth.router';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import roleService from './services/admin/role.service';
import { set_role } from './actions/account.action';

const Auth = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [state, setState] = useState({
        loading : true,
    });
    const authAccount = async () => {
        setState(state => ({...state, loading : true}));
        const authAccountResponse = await authService.authAccount().catch(err => {
            setState(state => ({...state, loading : false}));
        });
        
        const { account } = authAccountResponse;
        if(account){
            // get role detail
            const roleDetailResponse = await roleService?.getRoleDetail();
            const { role, } = roleDetailResponse;
            
            dispatch(set_auth(account));
            dispatch(set_role(role));
            //force direct push
            history.push(account ? (location?.pathname != '/login' ? `${location.pathname}${(location.search ?? '')}` : '/') : location.pathname);
        }
        
        setState(state => ({...state, loading : false}));
    }

    useEffect(() => {
        authAccount();
    }, [])

    return (
        <>
            {
                state?.loading ?
                (
                    <>
                        <LoadingOutlined />
                    </>
                )
                :
                (
                    <>
                        <AuthRouter />
                    </>
                )
                

            }
            
        </>
    );
}

export default Auth;