import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import AffiliateLayout from './components/affiliate_layout';

const AffiliateIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <AffiliateLayout />
            </ContainerComponent>
        </>
    );
}

export default AffiliateIndex;