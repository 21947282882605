import { Button, Space, Spin, Switch, Table, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import promotionService from '../../../services/config/promotion.service';
import { CheckCircleFilled, CheckOutlined, CheckSquareFilled, CheckSquareOutlined, CloseCircleFilled, CloseOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import EntranceMotion from '../../../helpers/components/entrance_motion';

const PromoTicketTypeAvailability = ({ promotion_code }) => {
    const [state, setState] = useState({
        loading : false,
        save_loading : false,
        columns : [],
        datas : [],
        ticket_user_types : [],

        // edit
        edit_mode : false,
        
    });

    const [updated, setUpdated] = useState([]); // to set state on changed toggle

    const fetchPromoCodeTicketApplicableUser = async () => {
        setState(state => ({...state, loading : true, }));
    
        let columns = [];
        let datas = [];
        let get_ticket_user_types = [];
        if(promotion_code){
            const { promotion_code_id } = promotion_code;
            const promoCodeTicketApplicableUserResponse = await promotionService?.getPromoCodeApplicableTicketUser(promotion_code_id, {

            });

            const { ticket_types, ticket_user_types } = promoCodeTicketApplicableUserResponse;
            get_ticket_user_types = ticket_user_types;
            columns = await generateColumns(ticket_user_types);
            datas = await generateDataSource(ticket_types);
        }

        setState(state => ({...state, loading : false, columns, datas, ticket_user_types : get_ticket_user_types }))
    }

    const generateColumns = async (ticket_user_types) => {
        let columns = [];

        // map title
        columns?.push({
            title : 'Ticket Type',
            dataIndex : 'name',
            key : 'name',
            width: 150,
            fixed: 'left',
        })

        // map data
        for(const ticket_user_type of ticket_user_types){
            const { name, ticket_user_type_id, } = ticket_user_type;
            const column = {
                title : name,
                dataIndex : 'applicable',
                key : 'applicable',
                width : 150,
                render : (applicable, row, index) => {
                    // from database
                    const { is_applicable, ticket_applicable_user_id } = applicable[ticket_user_type_id] ?? {};
                    // from state
                    const checked_toggle = updated?.find(x => x.ticket_applicable_user_id === ticket_applicable_user_id) ?? false;

                    return (
                        <>
                            {
                                is_applicable != undefined ?
                                (
                                    <>
                                        {
                                            state?.edit_mode ?
                                            (
                                                <>
                                                    <EntranceMotion delay={index * 0.1}>
                                                        <Switch
                                                        onChange={(e) => toggleChanges(e, ticket_applicable_user_id)}
                                                        size='small'
                                                        checked={checked_toggle?.is_applicable}
                                                        // defaultChecked={checked_toggle?.is_applicable}
                                                        />
                                                    </EntranceMotion>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <EntranceMotion delay={index * 0.1}>
                                                    {
                                                        is_applicable ?
                                                        (
                                                            <>
                                                                <CheckCircleFilled style={{color:'#95de64'}} />
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                <CloseCircleFilled style={{color:'#d9d9d9',}} />
                                                            </>
                                                        )
                                                    }
                                                    </EntranceMotion>
                                                </>
                                            )
                                        }
                                    </>
                                )
                                :
                                (
                                    <>
                                        <div>
                                            <span style={{fontSize:12, color:('var(--secondary-text-color)')}}>Not Applicable</span>
                                        </div>
                                    </>
                                )
                            }
                        </>
                    )
                }
            }
            columns?.push(column);
        }

        return columns;
    }

    const generateDataSource = async (ticket_types) => {
        // map data
        const map_datas = ticket_types?.map(x => {
            const { name, park_applicable_ticket_types, ticket_type_id } = x;
            const { ticket_user_types } = park_applicable_ticket_types ?? {};

            let applicable = {};
            for(const ticket_user_type of ticket_user_types){
                const { TicketApplicableUser : ticket_applicable_user } = ticket_user_type;
                applicable[ticket_user_type?.ticket_user_type_id] = {
                    is_applicable : ticket_user_type?.is_applicable,
                    ticket_applicable_user_id : ticket_applicable_user?.ticket_applicable_user_id,
                };

                toggleChanges(ticket_user_type?.is_applicable, ticket_applicable_user?.ticket_applicable_user_id);
            }

            return {
                name,
                ticket_type_id,
                applicable,
            }
        })

        return map_datas;
    }

    const toggleChanges = (is_applicable, ticket_applicable_user_id) => {
        let updated_data = updated;
        const exisiting_updated_date = updated_data?.find(x => x.ticket_applicable_user_id == ticket_applicable_user_id);
        if(exisiting_updated_date){
            // update existing
            exisiting_updated_date.is_applicable = is_applicable;
        }else{
            // push new
            updated_data?.push({ is_applicable, ticket_applicable_user_id, });
        }

        setUpdated(updated_data);
        renderColumns();
    }

    const savePromotionCodeTicketApplicableUsers = async () => {
        setState(state => ({...state, save_loading : true, }));
        const savePromotionCodeTicketApplicableUsersResponse = await promotionService?.savePromoCodeApplicableTicketUser(promotion_code?.promotion_code_id, {
            ticket_applicable_users : updated,
        });

        setTimeout(() => {
            const { saved_items } = savePromotionCodeTicketApplicableUsersResponse;
            //notify
            notification.success({
                message : "Promotion Code Updated",
                description : `Updated Ticket Type and Variant`
            })

            setState(state => ({...state, edit_mode : false, save_loading : false, }));
            setUpdated([]); //reset updated items
        }, 500);
    }


    const toggleAll = () => {
        // check whether is toggle all or toggle false
        const mapped_updated = updated?.map(x => x.is_applicable);
        const is_true = mapped_updated.includes(false);

        let ticket_user_types = state?.ticket_user_types;
        const ticket_applicable_user_ids = [].concat(...ticket_user_types?.map(x => x?.ticket_applicable_users?.map(tau => tau?.ticket_applicable_user_id)));

        for(const ticket_applicable_user_id of ticket_applicable_user_ids){
            toggleChanges(is_true, ticket_applicable_user_id)
        }
    }

    const renderColumns = async () => {
        const columns = await generateColumns(state?.ticket_user_types);
        setState(state => ({...state, columns : columns, }));
    }

    useEffect(() => {
        fetchPromoCodeTicketApplicableUser();
    }, [promotion_code, state?.edit_mode,])

    return (
        <>
            <div>
                <div style={{textAlign:'start'}}>
                    <div style={{display:'flex',}}>
                        <div>
                            <span style={{fontWeight:500,}}>Ticket Type and Variant</span>
                        </div>

                        <div style={{marginLeft:'auto'}}>
                            {
                                state?.edit_mode ?
                                (
                                    <>
                                        <Space>
                                            <Button
                                            size='small'
                                            type='text'
                                            style={{ background : 'var(--secondary-button-color)', color:'var(--secondary-text-color)' }}
                                            icon={<CheckSquareOutlined />}
                                            onClick={toggleAll}
                                            disabled={state?.save_loading}
                                            >
                                                Toggle All
                                            </Button>
                                            <Button
                                            loading={state?.save_loading}
                                            onClick={savePromotionCodeTicketApplicableUsers}
                                            size='small'
                                            icon={<CheckOutlined style={{color:'var(--secondary-text-color)'}} />}
                                            />
                                            <Button
                                            disabled={state?.save_loading}
                                            onClick={() => setState(state => ({...state, edit_mode : false,}))}
                                            size='small'
                                            icon={<CloseOutlined style={{color:'var(--secondary-text-color)'}} />}
                                            />
                                        </Space>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <Space>
                                            <Button
                                            onClick={() => setState(state => ({...state, edit_mode : true,}))}
                                            size='small'
                                            icon={<EditOutlined style={{color:'var(--secondary-text-color)'}} />}
                                            />
                                        </Space>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div style={{marginTop:12,}}>
                    <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                        <Table
                        scroll={{x : 600,}}
                        pagination={false}
                        // pagination={{ current : state?.page, onChange : (page) => paginateChange('page', page)}}
                        className='hub-table bordered'
                        dataSource={state?.datas}
                        columns={state?.columns}
                        />
                    </Spin>
                </div>
            </div>
        </>
    );
}

export default PromoTicketTypeAvailability;