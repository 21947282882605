import React, { useState, useEffect } from 'react';
import TicketByPark from '../ticket/ticket_by_park';
import TicketGrowth from '../ticket/ticket_growth';
import VisitorCount from '../visitor/visitor_count';

const DashboardType = ({ dashboard, }) => {
    const [state, setState] = useState({
        dashboard : (<></>),
    })
    // assign dashboard
    const assignDashboard = async () => {
        const { code } = dashboard;
        let dashboard_component = (<></>)
        switch(code){
            case 'TRANS' :
                dashboard_component = (<><TicketByPark dashboard={dashboard} /></>)
                break;
            case 'TICKET_GROWTH' :
                dashboard_component = (<><TicketGrowth dashboard={dashboard} /></>)
                break;
            case 'VISITOR' :
                // dashboard_component = (<><VisitorCount dashboard={dashboard} /></>)
                break;
            default :
                break;
        }

        setState(state => ({...state, dashboard : dashboard_component }));
    }


    useEffect(() => {
        assignDashboard();
    }, [])

    return (
        <>
            {state?.dashboard}
        </>
    );
}

export default DashboardType;