import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import CustomPassDetailLayout from './components/custom_pass_detail_layout';
import { useParams } from 'react-router-dom';

const CustomPassDetail = () => {
    const { custom_pass_id, } = useParams();
    return (
        <>
            <CustomPassDetailLayout custom_pass_id={custom_pass_id} />
        </>
    );
}

export default CustomPassDetail;