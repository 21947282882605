import React, { useEffect, useState } from 'react';
import entranceService from '../../../services/config/entrance.service';
import { Button, Card, Col, Empty, Form, Modal, Popconfirm, Row, Space, Spin, Tag } from 'antd';
import { DeleteOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import EditEntranceModal from './edit_entrance_modal';

const EntranceList = ({ park_id, filter, refresh, openEditModal = () => {}, deleteEntrance = async () => {} }) => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        entrances : [],

        delete_loading : false,
    })

    const deleteEntranceConfirm = async (entrance_id) => {
        setState(state => ({...state, delete_loading : true, }));
        const delete_entrance = await deleteEntrance(entrance_id);
        setState(state => ({...state, delete_loading : false, }));
    }

    const fetchEntrances = async () => {
        setState(state => ({...state, loading : true, }));

        const entranceResponse = await entranceService?.getEntranceByPark({
            park_id,
        })

        const { entrances } = entranceResponse?.parks?.[0];
        setState(state => ({...state, loading : false, entrances : entrances, }));
    }

    useEffect(() => {
        fetchEntrances();
    }, [park_id, filter, refresh])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <Row gutter={[12, 12]}>
                    {
                        state?.entrances?.length > 0 ?
                        (
                            <>
                                {
                                    state?.entrances?.map((entrance, index) => {
                                        const { name, entrance_type, inout_type, entrance_id, } = entrance;
                                        return (
                                            <Col xs={24} sm={12} lg={6} md={8} span={6} key={index}>
                                                <Card
                                                styles={{ body : { height : '100%', padding : 12, } }}
                                                style={{ height : '100%', }}
                                                >
                                                    <div style={{ textAlign : 'start', display : 'flex', flexDirection :'column', height : '100%', }}>
                                                        <div>
                                                            <div>
                                                                <span>{name}</span>
                                                            </div>
            
                                                            <div style={{marginTop:12,}}>
                                                                <Space>
                                                                    <Tag color={entrance_type?.is_entrance ? 'blue' : 'pink'} style={{border:'none'}}>
                                                                        <span>{entrance_type?.name}</span>
                                                                    </Tag>
                                                                    <Tag  color={inout_type ? 'green' : 'red'} style={{border:'none'}}>
                                                                        <span>{inout_type ? 'In' : 'Out'}</span>
                                                                    </Tag>
                                                                </Space>
                                                            </div>
                                                        </div>
                                                        
                                                        <div style={{ marginTop : 'auto', }}>
                                                            <div style={{ display : 'flex', marginTop : 12, }}>
                                                                <div style={{ marginLeft : 'auto', }}>
                                                                    <Space>
                                                                        <Button
                                                                        icon={<EditOutlined style={{ color : 'var(--secondary-text-color)' }} />}
                                                                        size='small'
                                                                        onClick={() => openEditModal(entrance_id)}
                                                                        />
                                                                        <Popconfirm
                                                                        onConfirm={() => deleteEntranceConfirm(entrance_id)}
                                                                        title={`Are you confirm to delete entrance?`}
                                                                        description={name}
                                                                        okButtonProps={{ loading : state?.delete_loading, }}
                                                                        >
                                                                                <Button
                                                                                icon={<DeleteOutlined style={{ color : 'var(--secondary-text-color)' }} />}
                                                                                size='small'
                                                                                />
                                                                            </Popconfirm>
                                                                        
                                                                    </Space>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                }
                            </>
                        )
                        :
                        (
                            <div style={{ padding : 12, textAlign : 'center', width : '100%',}}>
                                <div>
                                    <Empty style={{margin : 12,}} description={"No entrance"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                </div>
                            </div>
                        )
                    }
                </Row>
            </Spin>
        </>
    );
}

export default EntranceList;