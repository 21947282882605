import React, { useState, useEffect, } from 'react';
import dashboardTicketService from '../../../services/dashboard/dashboard_ticket.service';
import { Card, Col, Divider, List, Row, Skeleton, Space } from 'antd';
import numberHelper from '../../../helpers/functions/number.helper';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import { Link } from 'react-router-dom';
import IconComponent from '../../../helpers/components/icon.component';
import PageHeaderComponent from '../../../helpers/components/page_header.component';

const TicketByPark = ({ dashboard, }) => {
    const [state, setState] = useState({
        loading : false,
        parks : [],
        total_ticket_count : null,
        total_ticket_user_count : null,
    })

    const fetchTicketDashboard = async () => {
        setState(state => ({...state, loading : true, }))
        const ticketCountResponse = await dashboardTicketService?.getTicketCountByPark();
        const { parks } = ticketCountResponse;
        const total_ticket_count = parks?.reduce((a, b) => (a + b?.ticket_count), 0);
        const total_ticket_user_count = parks?.reduce((a, b) => (a + b?.ticket_user_count), 0);
        setState(state => ({...state, loading : false, parks : parks, total_ticket_count : total_ticket_count, total_ticket_user_count : total_ticket_user_count, }))
    }

    useEffect(() => {
        fetchTicketDashboard();
    }, [])

    return (
        <>
            <Card
            cover={
                <div style={{padding : 24, background : 'rgba(242, 245, 255, 0.6)', borderTopLeftRadius:8, borderTopRightRadius:8,}}>
                    <div style={{display:'flex',}}>
                        <div>
                            <Space style={{width:'100%',}}>
                                <div style={{ display:'flex', height: 40, width: 40, border : '1px solid #e6e8eb', background:'#fff', borderRadius:'.5rem'}}>
                                    <div style={{margin:'auto'}}>
                                        <IconComponent name={dashboard?.menu?.icon} size={24} />
                                    </div>
                                </div>
                                <div>
                                    <PageHeaderComponent title={dashboard?.name} />
                                </div>
                            </Space>
                        </div>
                        <div style={{marginLeft:'auto'}}>
                            <div>
                                <Link to="/ticket">
                                    <span style={{color:'var(--secondary-text-color)', fontSize:12,}}>See more</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop:12,}}>
                        <Row>
                            <Col span={12}>
                                <div>
                                    <span style={{color:'var(--secondary-text-color)'}}>Transactions</span>
                                </div>
                                <div>
                                    <span style={{fontSize: 24, fontWeight:500,}}>{ numberHelper?.commaNumber(state?.total_ticket_count, 0) }</span>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <span style={{color:'var(--secondary-text-color)'}}>Tickets</span>
                                </div>
                                <div>
                                    <span style={{fontSize: 24, fontWeight:500,}}>{ numberHelper?.commaNumber(state?.total_ticket_user_count, 0) }</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            }
            >
                <div>
                    <Divider style={{margin:0,}} />
                    <div style={{padding:12,}}>
                    {
                        state?.loading ?
                        (
                            <>
                                <Skeleton active />
                            </>
                        )
                        :
                        (
                            <>
                                <List
                                size='small'
                                dataSource={state?.parks}
                                renderItem={(item) => {
                                    const { park_id, name, ticket_user_count, ticket_count, } = item ?? {};
                                    return (
                                        <List.Item>
                                            <div style={{ display : 'flex', width : '100%', }}>
                                                <div>
                                                    <Link to={`/ticket?parks=${park_id}`}>
                                                        <div>
                                                            <span>{name}</span>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div style={{marginLeft:'auto', textAlign:'end',}}>
                                                    <div>
                                                        <Space>
                                                            <div>
                                                                <span>{numberHelper?.commaNumber(ticket_count, 0)}</span>
                                                            </div>
                                                            <div>
                                                                <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>Transactions</span>
                                                            </div>
                                                        </Space>
                                                    </div>
                                                    <div>
                                                        <Space>
                                                            <div>
                                                                <span>{numberHelper?.commaNumber(ticket_user_count, 0)}</span>
                                                            </div>
                                                            <div>
                                                                <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>Tickets</span>
                                                            </div>
                                                        </Space>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </List.Item>
                                    )
                                }}
                                />
                            </>
                        )
                    }
                    </div>
                    
                </div>
            </Card>
        </>
    );
}

export default TicketByPark;