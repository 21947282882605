import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import StaffDetailLayout from './components/staff_detail_layout';
import { useParams } from 'react-router-dom';

const StaffDetail = () => {
    const { staff_id } = useParams();
    return (
        <>
            <StaffDetailLayout staff_id={staff_id} />
        </>
    );
}

export default StaffDetail;