import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import SpecialLinkLayout from './components/special_link_layout';

const SpecialLinkIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <SpecialLinkLayout />
            </ContainerComponent>
        </>
    );
}

export default SpecialLinkIndex;