import { Col, Divider, Empty, Form, Input, List, Row, Select, Switch } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import entranceService from '../../../services/config/entrance.service';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import establishmentService from '../../../services/config/establishment.service';

const { TextArea } = Input;
const AddEntranceModal = ({ form, park, }) => {
    const [state, setState] = useState({
        loading : false,
        entrance_settings : [],
        entrance_types : [],

        establishments : [],
    })
    const [selected_entrance_type, setSelectedEntranceType] = useState();

    const entrance_type_is_entrance = useMemo(() => {
        let is_entrance_type_entrance = null;
        if(selected_entrance_type){
            const entrance_type = state?.entrance_types?.find(x => x?.entrance_type_id === selected_entrance_type);
            const { is_entrance, } = entrance_type ?? {};
            is_entrance_type_entrance = is_entrance;
        }
        
        return is_entrance_type_entrance;
    }, [state?.entrance_types, selected_entrance_type])

    const fetchEntranceTypes = async () => {
        const entranceTypeResponse = await entranceService?.getEntranceTypes();
        const { entrance_types } = entranceTypeResponse;
        return entrance_types;
    }

    const fetchEntranceSettings = async () => {
        const entranceSettingResponse = await entranceService?.getEntranceSettings();
        const { entrance_settings, } = entranceSettingResponse;

        return entrance_settings;
    }

    const fetchEstablishments = async () => {
        // get establishments
        const establishmentResponse = await establishmentService?.getEstablishment({
            park_id : park?.park_id,
        });
        
        const { establishments, } = establishmentResponse;
        return establishments;
    }
    
    const initializeSelections = async () => {
        setState(state => ({...state, loading : true, }));
        const entrance_types = await fetchEntranceTypes();
        const entrance_settings = await fetchEntranceSettings();
        const establishments = await fetchEstablishments();

        setState(state => ({...state, loading : false, entrance_types, entrance_settings, establishments, }));
    }

    useEffect(() => {
        initializeSelections();
    }, [park?.park_id])

    return (
        <>
            <div>
                <Divider orientationMargin="0" orientation='left'><PageHeaderComponent title={<span style={{ fontSize : 14, }}>Entrance Detail</span>} /></Divider>
                <div>
                    <Form.Item name={'name'} label={"Name"}>
                        <Input />
                    </Form.Item>
                </div>
                <div>
                    <Row gutter={[12, 12]}>
                        <Col xs={24} sm={12} span={12}>
                            <Form.Item name={'entrance_no'} label={"Code"}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} span={12}>
                            <Form.Item name={'inout_type'} label={"Is Entry"}>
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </div>
            <div>
                <Divider orientationMargin="0" orientation='left'><PageHeaderComponent title={<span style={{ fontSize : 14, }}>Entrance Type</span>} /></Divider>
                <Row gutter={[12, 12]}>
                    <Col xs={24} sm={12} span={12}>
                        <Form.Item name={"entrance_type_id"} label={"Entrance Type"}>
                            <Select
                            onChange={setSelectedEntranceType}
                            options={state?.entrance_types?.map((entrance_type, index) => {
                                const { entrance_type_id, name, } = entrance_type;
                                return {
                                    value : entrance_type_id,
                                    label : name,
                                }
                            })}
                            />
                        </Form.Item>
                    </Col>
                    {
                        entrance_type_is_entrance != null ?
                        (
                            <>
                                {/* Show Selections for Espark */}
                                <Col xs={24} sm={12} span={12}>
                                    {
                                        entrance_type_is_entrance ?
                                        (
                                            <EntranceMotion>
                                                <Form.Item initialValue={park?.park_id} name={"espark_id"} label={"Park"}>
                                                    <span style={{ fontWeight : 500, color : 'var(--secondary-text-color)', }}>{park?.name}</span>
                                                </Form.Item>
                                            </EntranceMotion>
                                        )
                                        :
                                        (
                                            <EntranceMotion>
                                                <Form.Item name={"espark_id"} label={"Establishment"}>
                                                    <Select
                                                    showSearch
                                                    optionFilterProp='label'
                                                    options={state?.establishments?.map((establishment, index) => {
                                                        const { establishment_id, name } = establishment;
                                                        return {
                                                            value : establishment_id,
                                                            label : name,
                                                        }
                                                    })}
                                                    />
                                                </Form.Item>
                                            </EntranceMotion>
                                        )
                                    }
                                </Col>
                            </>
                        )
                        :
                        (
                            <>
                                <div style={{ margin : 'auto', }}>
                                    <span style={{ fontSize : 12, color : 'var(--secondary-text-color)', }}>Please select entrance type</span>
                                </div>
                            </>
                        )
                    }
                </Row>
            </div>

            <div>
                <Divider orientationMargin="0" orientation='left'><PageHeaderComponent title={<span style={{ fontSize : 14, }}>Voice Message</span>} /></Divider>
                <div>
                    <Form.Item name={"display_message"} label={"Welcome Message"}>
                        <TextArea placeholder={`Welcome to ${park?.name}`} rows={2} style={{ resize : 'none', }} />
                    </Form.Item>
                </div>
                <div>
                    <Form.Item name={"exit_message"} label={"Exit Message"}>
                        <TextArea placeholder={`Thank you, have a nice day`} rows={2} style={{ resize : 'none', }} />
                    </Form.Item>
                </div>
            </div>
            
            <div>
                <Divider orientationMargin="0" orientation='left'><PageHeaderComponent title={<span style={{ fontSize : 14, }}>Entrance Setting</span>} /></Divider>
                <div>
                        <List
                        size='small'
                        dataSource={state?.entrance_settings}
                        bordered
                        renderItem={(item, index) => {
                            const { entrance_setting_id, name, } = item;
                            return (
                                <>
                                    <List.Item>
                                        <div style={{ display : 'flex', width : '100%', alignItems : 'center', }}>
                                            <div>
                                                <span>{name}</span>
                                            </div>
                                            <div style={{ marginLeft : 'auto', }}>
                                                <Form.Item hidden initialValue={entrance_setting_id} name={['entrance_setting_values', index, `entrance_setting_id`]} style={{ marginBottom : 0, }} />
                                                <Form.Item key={index} name={['entrance_setting_values', index, `value`]} style={{ marginBottom : 0, }}>
                                                    <Input />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </List.Item>
                                </>
                            )
                        }}
                        />
                </div>
            </div>
        </>
    );
}

export default AddEntranceModal;