import React, { useEffect, useState } from 'react';
import salesService from '../../../services/ticket/sales.service';
import { Col, Divider, Empty, Row, Space, Spin } from 'antd';
import ChartView from './chart/chart_view';
import GridView from './grid/grid_view';
import EntranceMotion from '../../../helpers/components/entrance_motion';

const ReportItem = ({ filter, view_type, park, setErrors = () => {} }) => {
    const [state, setState] = useState({
        loading : false,
        total_sales : null,
        purchase_types : [],
        ticket_types : [],
        add_on_types : [],
        total_date_breakdown_sales : [],
        errors : [],
    })

    const fetchSalesReport = async () => {
        setState(state => ({...state, loading : true}));
        const { park, start_date, end_date, } = filter;
        const reportResponse = await salesService.generateSalesReport({
            park_id : park,
            start_date : start_date,
            end_date : end_date,
        });

        const { total_sales, purchase_types, ticket_types, total_date_breakdown_sales, add_on_types, errors, } = reportResponse ?? {};
        setState(state => ({...state, total_sales, purchase_types, ticket_types, total_date_breakdown_sales, add_on_types, errors, loading : false, }));

        setErrors(errors)
    }

    useEffect(() => {
        if(filter?.park){
            fetchSalesReport();
        }
        
    }, [filter])

    return (
        <>
            <Spin spinning={state?.loading}>
                {
                    state?.errors?.length > 0 ?
                    (
                        <>
                            <div style={{ padding : 42, }}>
                                <EntranceMotion>
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={state?.errors?.[0]?.message} />
                                </EntranceMotion>
                            </div>
                        </>
                    )
                    :
                    (
                        <>
                            {
                                view_type === 'chart' &&
                                (
                                    <>
                                        <ChartView
                                        filter={filter}
                                        total_date_breakdown_sales={state?.total_date_breakdown_sales}
                                        total_sales={state?.total_sales}
                                        ticket_types={state?.ticket_types}
                                        purchase_types={state?.purchase_types}
                                        add_on_types={state?.add_on_types}
                                        park={park}
                                        />
                                    </>
                                )
                            }
                            {
                                view_type === 'grid' &&
                                (
                                    <>
                                        <GridView
                                        filter={filter}
                                        total_date_breakdown_sales={state?.total_date_breakdown_sales}
                                        total_sales={state?.total_sales}
                                        ticket_types={state?.ticket_types}
                                        purchase_types={state?.purchase_types}
                                        add_on_types={state?.add_on_types}
                                        park={park}
                                        />
                                    </>
                                )
                            }
                        </>
                    )
                }
            </Spin>
        </>
    );
}

export default ReportItem;