import React, { useEffect, useState } from 'react';
import EmployeeList from './employee_list';
import { Button, Checkbox, Form, Input, List, Modal, Space, notification } from 'antd';
import { SearchOutlined, CaretDownFilled } from '@ant-design/icons';
import adminService from '../../../services/admin/admin.service';
import configService from '../../../services/config/park.service';
import FilterBullet from '../../../helpers/components/filter_bullet';
import globalHelper from '../../../helpers/functions/global.helper';
import { useDispatch, useSelector } from 'react-redux';
import { refresh_account_list, set_edit_account_modal } from '../../../actions/account.action';
import EmployeeDetail from './employee_detail';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import CreateEmployeeDetail from './create_employee_detail';

const EmployeeLayout = () => {
    const dispatch = useDispatch();
    const accountRedux = useSelector(state => state.account);
    const [create_form] = Form.useForm();
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        roles : [],
        parks : [],

        //modal
        account_edit : null,
        create_modal : false,
        save_loading : false,
    });
    const [timer, setTimer] = useState(null);
    const [filter, setFilter] = useState({
        search : null,
        roles : [],
        parks : [],
    })

    const initialize = async () => {
        setState(state => ({...state, loading : true}));
        const roleResponse = await adminService.getRole();
        const parkResponse = await configService.getPark();
        setState(state => ({...state, loading : false, roles : roleResponse?.roles, parks : parkResponse?.parks}));
    }

    const onCheckChange = (key, checkedValues) => {
        setFilter(filter => ({...filter, [key] : checkedValues}));
    }

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
        }, 300);
        setTimer(newTimer);
    }

    const createAccount = async () => {
        setState(state => ({...state, save_loading : true, }));
        const { username, email, role : role_id, parks, full_name, password, is_active, } = create_form.getFieldsValue();
        const registerAccountResponse = await adminService?.createAccount({
            username, email, role_id, parks, full_name, password,
        }).catch(err => setState(state => ({...state, save_loading : false,})));

        const { account } = registerAccountResponse;
        notification.success({
            placement : 'bottomLeft',
            description : "Account has successfully created."
        })

        setState(state => ({...state, save_loading : false, create_modal : false, }));
        //close modal and refresh table
        dispatch(refresh_account_list());
        create_form?.resetFields();
    }

    useEffect(() => {
        initialize();
    }, [])

    return (
        <>
            <div className='mobile-padding-12'>
                <div style={{marginBottom: 24, textAlign:'start'}}>
                    <PageHeaderComponent title={'Accounts'} />
                </div>
                <div className='filter-panel'>
                    <div>
                        <Input
                        onChange={(e) => doneTyping(e.target.value)}
                        name="search"
                        prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                        placeholder='Search Accounts'
                        style={{minWidth: '100%',}}
                        allowClear
                        />
                    </div>

                    <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                        <div>
                            <div className='filter-panel' style={{textAlign:'start'}}>
                                <Space direction={globalHelper?.isMobileScreen() ? 'vertical' : 'horizontal'}>
                                    <FilterBullet
                                    popover={
                                        <>
                                            <div>
                                                <Input prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>} placeholder='Search Role' />
                                            </div>
                                            
                                            <Checkbox.Group
                                            value={filter?.roles}
                                            onChange={(checkedValues) => onCheckChange('roles', checkedValues)}
                                            >
                                                <List
                                                style={{ width:280, }}
                                                dataSource={state?.roles}
                                                renderItem={(item, index) => {
                                                    const { role_id, name, } = item ?? {};
                                                    return (
                                                        <List.Item style={{width:'100%'}} key={index}>
                                                            <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                                <div>
                                                                    <span>{name}</span>
                                                                </div>
                                                                <div style={{marginLeft:'auto'}}>
                                                                    <Checkbox
                                                                    value={role_id}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </List.Item>
                                                    )
                                                }}
                                                />
                                            </Checkbox.Group>
                                        </>
                                    }
                                    >
                                        <div>
                                            <Space>
                                                <div>
                                                    <span>Roles</span>
                                                </div>
                                                <div>
                                                    <CaretDownFilled style={{color:'#a0aec0'}} />
                                                </div>
                                            </Space>
                                        </div>
                                    </FilterBullet>
                                    <FilterBullet
                                    popover={
                                        <>
                                            <div>
                                                <Input prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>} placeholder='Search Role' />
                                            </div>
                                            
                                            <Checkbox.Group
                                            value={filter?.parks}
                                            onChange={(checkedValues) => onCheckChange('parks', checkedValues)}
                                            >
                                                <List
                                                style={{ width:280, }}
                                                dataSource={state?.parks}
                                                renderItem={(item, index) => {
                                                    const { park_id, name, } = item ?? {};
                                                    return (
                                                        <List.Item style={{width:'100%'}} key={index}>
                                                            <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                                <div>
                                                                    <span>{name}</span>
                                                                </div>
                                                                <div style={{marginLeft:'auto'}}>
                                                                    <Checkbox
                                                                    value={park_id}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </List.Item>
                                                    )
                                                }}
                                                />
                                            </Checkbox.Group>
                                        </>
                                    }
                                    >
                                        <div>
                                            <Space>
                                                <div>
                                                    <span>Parks</span>
                                                </div>
                                                <div>
                                                    <CaretDownFilled style={{color:'#a0aec0'}} />
                                                </div>
                                            </Space>
                                            
                                        </div>
                                    </FilterBullet>
                                </Space>
                            </div>
                        </div>

                        <div style={{marginLeft:'auto'}}>
                            <Button
                            type='primary'
                            onClick={() => setState(state => ({...state, create_modal : true,}))}
                            >
                                Create New Account
                            </Button>
                        </div>
                    </div>
                </div>

                <div style={{marginTop:24, }}>
                    <EmployeeList filter={filter} />
                </div>
            </div>

            {/* Action Modal */}
            <Modal
            confirmLoading={state?.save_loading}
            open={state?.create_modal}
            className='create-modal'
            title={"Create New Account"}
            onOk={() => create_form.submit()}
            onCancel={() => setState(state => ({...state, create_modal : false,}))}
            width={800}
            style={{top : 20,}}
            >
                <Form
                id={'account_form'}
                form={create_form}
                layout='vertical'
                onFinish={createAccount}
                initialValues={{ is_active : true, }}
                >
                    <CreateEmployeeDetail form={create_form} roles={state?.roles} parks={state?.parks} />
                </Form>
            </Modal>
        </>
    );
}

export default EmployeeLayout;