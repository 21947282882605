import React from 'react';
import PromoDetailEdit from './promo_detail_edit';

const PromoDetailGeneral = ({ promotion_code }) => {
    return (
        <>
            <div>
                <PromoDetailEdit promotion_code={promotion_code} />
            </div>
        </>
    );
}

export default PromoDetailGeneral;