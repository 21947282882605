import React from 'react';
import DetailLayout from './components/detail_layout';
import ContainerComponent from '../../helpers/components/container.component';
import { useParams } from 'react-router-dom';

const FaceDetail = () => {
    const { collection_id } = useParams();
    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <DetailLayout collection_id={collection_id} />
            </ContainerComponent>
        </>
    );
}

export default FaceDetail;