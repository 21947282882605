import { CheckCircleFilled, CloseCircleFilled, DisconnectOutlined, LoadingOutlined } from '@ant-design/icons';
import { Alert, Button, Input, Modal, Space, Tooltip, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import kidzoftService from '../../services/integration/kidzoft/kidzoft.service';
import { useDispatch } from 'react-redux';
import { refresh_ticket_detail, set_editable } from '../../actions/ticket.action';
import EntranceMotion from '../../helpers/components/entrance_motion';
import { useHistory } from 'react-router-dom';

const { confirm } = Modal;
const IntegrationPazzport = ({ field, form, ticket_user_field, ticket_user, }) => {
    const history = useHistory();
    const { response : integrated_pazzport, } = (ticket_user_field?.FieldResponse) ?? {};
    const { entrance_transactions = [], ticket, ticket_user_no, } = ticket_user ?? {};
    const dispatch = useDispatch();
    const [timer, setTimer] = useState(null);
    const [state, setState] = useState({
        loading : false,
        pazzport : null,
        input : null,
        errors : [],
    })

    const fetchPazzport = async (input) => {
        setState(state => ({...state, loading : true, }));
        dispatch(set_editable(false));
        const pazzportResponse = await kidzoftService?.fetchPazzport(input, ticket?.park_id);
        const { pazzport } = pazzportResponse;
        let errors = [];
        if(pazzport){
            dispatch(set_editable(true));
        }else{
            errors.push({ type : 'not_found', });
        }
        setState(state => ({...state, loading : false, pazzport : pazzport, errors : errors, }));
    }

    const confirmUnlink = async () => {
        confirm({
            title: 'Do you want to unlink pazzport?',
            // icon: <ExclamationCircleFilled />,
            content : (
                <>
                    {integrated_pazzport}
                </>
            ),
            onOk() {
              return new Promise(async (resolve, reject) => {
                await unlinkPazzport().then(() => {
                    resolve();
                });
              }).catch(() => console.error('Oops errors!'));
            },
            onCancel() {},
          });
    }

    const unlinkPazzport = async () => {
        const unlinkPazzportResponse = await kidzoftService?.unlinkPazzport({
            ticket_user_id : ticket_user?.ticket_user_id,
        });

        const { unlinkPazzportIntegration } = unlinkPazzportResponse;
        const { status, name, response } = unlinkPazzportIntegration ?? {};
        if(status === 1){
            // success
            notification?.success({
                message : `Ticket ${integrated_pazzport} Unlinked`,
                description : (
                    <>
                        <div>
                            <Alert type='success' message={response} />
                            <div>
                                <a style={{color:'var(--main-color)'}} onClick={() => history.push(`/ticket/detail/${ticket_user?.ticket_id}`)}>
                                    <span>{ticket_user?.ticket?.unique_no}</span>
                                </a>
                            </div>
                            <div>
                                <span>{`Please check log for integration status`}</span>
                            </div>
                        </div>
                    </>
                ),
            });
        }else{
            notification?.error({
                message : name,
                description : (
                    <>
                        <div>
                            <Alert type='error' message={response} />
                            <div>
                                <a style={{color:'var(--main-color)'}} onClick={() => history.push(`/ticket/detail/${ticket_user?.ticket_id}`)}>
                                    <span>{ticket_user?.ticket?.unique_no}</span>
                                </a>
                            </div>
                            <div>
                                <span>{`Please check log for integration status`}</span>
                            </div>
                        </div>
                    </>
                ),
            });
        }

        dispatch(refresh_ticket_detail());
        form.setFieldsValue({[field?.key] : null });
        setState(state => ({...state, input : null,}))
    }

    const doneTyping = async (input) => {
        setState(state => ({...state, input : input}))
        form.setFieldsValue({ pazzport : input, })
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            if(input){
                fetchPazzport(input);
            }else{
                dispatch(set_editable(true));
                setState(state => ({...state, pazzport : null, input : null,}));
            }
        }, 300);
        setTimer(newTimer);
    }

    useEffect(() => {
        const { pazzport } = form?.getFieldsValue() ?? {};
        pazzport && fetchPazzport(pazzport);
        setState(state => ({...state, input : pazzport,}))
    }, [form]);

    return (
        <>
            <div style={{width : '100%', flex : 1, display : 'flex', gap : 8, }}>
                <Input
                suffix={
                    state?.loading ? 
                    (<><LoadingOutlined /></>)
                    :
                    (
                        <>
                            {
                                state?.input && (
                                    <>
                                        {
                                            state?.pazzport ? 
                                            (
                                                <>
                                                    <CheckCircleFilled style={{color:'#95de64'}} />
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <CloseCircleFilled style={{color:'#ff7875'}} />
                                                </>
                                            )
                                        }
                                    </>
                                )
                                
                            }
                        </>
                    )
                }
                disabled={integrated_pazzport}
                value={state?.input}
                onChange={(e) => doneTyping(e.target.value)}
                placeholder={field?.placeholder}
                />
                {
                    integrated_pazzport &&
                    (
                        <>
                            <EntranceMotion>
                                <div>
                                    <Tooltip title={"Unlink Pazzport"}>
                                        <Button onClick={confirmUnlink} icon={<DisconnectOutlined />} />
                                    </Tooltip>
                                </div>
                            </EntranceMotion>
                        </>
                    )
                }
            </div>
            {
                state?.pazzport &&
                (
                    <>
                        <div style={{marginTop:12,}}>
                            <EntranceMotion>
                                <Alert
                                style={{padding : 6, border : 'none', }}
                                // message={(
                                //     <>
                                //         <span style={{fontSize:14, color : 'var(--secondary-text-color)'}}>Welcome Back</span>
                                //     </>
                                // )}
                                description={`${state?.pazzport?.name} ${state?.pazzport?.lastName}`}
                                />
                            </EntranceMotion>
                        </div>
                    </>
                )
            }
            {/* {
                entrance_transactions?.length > 0 ?
                (
                    <>
                    
                    </>
                )
                :
                (
                    <>
                        <div style={{textAlign:'start',}}>
                            <span style={{fontSize:12, color : 'var(--secondary-text-color)',}}>
                                This ticket is not checked in, please integrate {field?.label} on <a target='_blank' href={`${ticket?.park?.base_url}ticket/user/${ticket_user_no}`}>ticket page</a>.
                            </span>
                        </div>
                    </>
                )
            } */}
        </>
    );
}

export default IntegrationPazzport;