import React, { useEffect, useState } from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { Button, Form, Input, Modal, Space, notification } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import globalHelper from '../../../helpers/functions/global.helper';
import AffiliateList from './affiliate_list';
import InviteAffiliate from './invite_affiliate';
import affiliateService from '../../../services/config/affiliate.service';

const AffiliateLayout = () => {
    const [form] = Form.useForm();
    const [timer, setTimer] = useState(null);
    const [state, setState] = useState({
        create_modal : false,

        save_loading : false,
        refresh : 0,

        disable_affiliate : false,
    });

    const [filter, setFilter] = useState({
        search : null,
    });

    const onCreateAffiliate = async () => {
        setState(state => ({...state, save_loading : true, }));
        const { email } = form.getFieldsValue();
        const registerAffiliateResponse = await affiliateService?.registerAffiliate({
            email,
        }).catch(err => setState(state => ({...state, save_loading : false, })));

        // notification
        notification?.success({
            message : 'Success invite affiliate',
            description : `${email}`
        })
        setState(state => ({...state, save_loading : false, refresh : (state?.refresh + 1), create_modal : false, }));
    }

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
            // changeFilter('search', input);
        }, 300);
        setTimer(newTimer);
    }

    const disableAffiliate = (is_disable) => {
        setState(state => ({...state, disable_affiliate : is_disable, }));
    }

    return (
        <>
            <div className='mobile-padding-12'>
                <div style={{marginBottom: 24, textAlign:'start'}}>
                    <PageHeaderComponent title={'Affiliate'} />
                </div>
                <div className='filter-panel'>
                    <div>
                        <Input
                        onChange={(e) => doneTyping(e.target.value)}
                        name="search"
                        prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                        placeholder='Search Affiliates'
                        style={{minWidth: '100%',}}
                        allowClear
                        />
                    </div>

                    <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                        <div>
                            <div className='filter-panel' style={{textAlign:'start'}}>
                                <Space direction={globalHelper?.isMobileScreen() ? 'vertical' : 'horizontal'}>

                                </Space>
                            </div>
                        </div>

                        <div style={{marginLeft:'auto'}}>
                            <Button
                            type='primary'
                            onClick={() => setState(state => ({...state, create_modal : true,}))}
                            >
                                Invite Affiliate
                            </Button>
                        </div>
                    </div>
                </div>

                <div style={{marginTop:24, }}>
                    <AffiliateList filter={filter} refresh={state?.refresh} />
                </div>
            </div>

            <Modal
            open={state?.create_modal}
            title="Invite Affiliates"
            onCancel={() => setState(state => ({...state, create_modal : false,}))}
            confirmLoading={state?.save_loading}
            onOk={() => form.submit()}
            okButtonProps={{ disabled : state?.disable_affiliate }}
            >
                <div>
                    <div>
                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>You're inviting affiliates to The Cool Melon.</span>
                    </div>
                    <div style={{marginTop:12,}}>
                        <Form
                        onFinish={onCreateAffiliate}
                        id={'affiliate_account_form'}
                        form={form}
                        layout='vertical'
                        >
                            <InviteAffiliate form={form} disableAffiliate={disableAffiliate} />
                        </Form>
                        </div>
                </div>
            </Modal>
        </>
    );
}

export default AffiliateLayout;