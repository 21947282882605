import { Progress, Space } from 'antd';
import React, { useEffect, useState } from 'react';

const OverallChart = ({ checked_in_visitors, expected_visitors }) => {
    const percent = ((checked_in_visitors / expected_visitors) * 100);

    return (
        <>
            <Space size={24}>
                <div>
                    <Progress type="circle" status="active" percent={percent} format={(p) => {
                        return (
                            <>
                                <div>
                                    <div>
                                        <span style={{fontSize:18, fontWeight:500, }}>
                                            {isNaN(percent) ? '0%' : `${percent?.toFixed(2)}%`}
                                        </span>
                                        
                                    </div>
                                    <div>
                                        <span style={{fontSize:14, color:'var(--secondary-text-color)'}}>{(checked_in_visitors && expected_visitors) ? (`${checked_in_visitors} / ${expected_visitors}`) : ''}</span>
                                    </div>
                                </div>
                            </>
                        )
                    }} />
                </div>
                <div style={{textAlign:'start'}}>
                    <Space direction='vertical'>
                        <div>
                            <span style={{color:'var(--secondary-text-color)'}}>Expected Visitor : </span>
                            <span style={{fontWeight:500,}}>{expected_visitors}</span>
                        </div>
                        <div>
                            <span style={{color:'var(--secondary-text-color)'}}>Checked-in Visitor : </span>
                            <span style={{fontWeight:500,}}>{checked_in_visitors}</span>
                        </div>
                    </Space>
                    
                </div>
            </Space>
        </>
    );
}

export default OverallChart;