import { Button, Col, Divider, Empty, Form, Input, InputNumber, Modal, Row, Select, Space, Table, Tag, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import StepNoItem from '../../../helpers/components/step_no_item';
import parkService from '../../../services/config/park.service';
import ticketService from '../../../services/ticket/ticket.service';
import { Link, useHistory } from 'react-router-dom';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import GenerateTicketUserList from './generate_ticket_user_list';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import numberHelper from '../../../helpers/functions/number.helper';
import IconWrapper from '../../../helpers/components/icon_wrapper';
import GenerateParkTransaction from '../components/generate_park_transaction';

const { Option } = Select;
const { confirm } = Modal;
const { TextArea } = Input;

const GenerateTicketLayout = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const [state, setState] = useState({
        loading : true,
        parks : [],

        //filter
        selected_park_id : null,
        selected_ticket_type_id : null,


        //loading
        save_loading : false,
    })

    const fetchDetail = async () => {
        setState(state => ({...state, loading : true}));
        const parkResponse = await parkService?.getParkByAuth();
        setState(state => ({...state, loading : false, parks : parkResponse?.parks}));
    }

    const changePark = (e) => {
        setState(state => ({...state, selected_park_id : e, selected_ticket_type_id : null}));
        form.setFieldsValue({ticket_type : null});
    }

    const confirmPurchase = async () => {
        const { ticket_users, agents } = form.getFieldsValue();
        const tickets_count = ticket_users?.reduce((a, b) => a + parseInt(b?.quantity), 0);
        const park = state?.parks?.find(x => x.park_id === state?.selected_park_id);
        const ticket_type = park?.ticket_types?.find(x => x.ticket_type_id == state?.selected_ticket_type_id);

        //calculate total price
        // const total_ticket_price = (ticket_users?.reduce((a, b) => a + (parseFloat(b.price) * parseFloat(b.quantity)), 0)) ?? 0;
        // const total_price = total_ticket_price;

        // calculate tax
        const calculateResponse = await ticketService?.calculateOpenPricing({
            park_id : park?.park_id,
            ticket_type_id : ticket_type?.ticket_type_id,
            ticket_users,
            // add_ons,
        });
        const { sub_total, tax_amount, total_amount, taxes, ticket_amount : total_ticket_price, add_on_amount : total_add_on_price, } = calculateResponse;
        const total_price = total_amount;
        
        confirm({
            title : `Generate OTA Tickets?`,
            okButtonProps : {
                type : 'primary',
            },
            content : (
                <>
                    <div>
                        <div>
                            <Space>
                                <div>
                                    <Tag color='blue' style={{border : 'none',}}>
                                        {park?.currency?.code}
                                    </Tag>
                                </div>
                                <div>
                                    <span style={{ fontSize: 24, fontWeight: 500 }}>{`${park?.currency?.symbol}${numberHelper?.commaNumber(total_price)}`}</span>
                                </div>
                            </Space>
                            <div>
                                
                            </div>
                        </div>
                        <Divider style={{margin: 4,}} />
                        <div style={{marginTop:12,}}>
                            <div>
                                <Space>
                                    <IconWrapper icon={'ticket.svg'} />
                                    <div>
                                        <span style={{ fontWeight: 500 }}>Transactions</span>
                                    </div>
                                </Space>
                            </div>
                            <div style={{marginTop:8,}}>
                                <Row>
                                    <Col xs={24} md={8}>
                                        <div>
                                            <div>
                                                <span style={{color:'#595959', fontWeight:500,}}>
                                                    Park
                                                </span>
                                            </div>
                                            <div>
                                                <span style={{color:'var(--secondary-text-color)'}}>
                                                    {park?.name}
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <div>
                                            <div>
                                                <span style={{color:'#595959', fontWeight:500,}}>
                                                    Ticket Type
                                                </span>
                                            </div>
                                            <div>
                                                <span style={{color:'var(--secondary-text-color)'}}>
                                                    {ticket_type?.name}
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <div>
                                            <div>
                                                <span style={{color:'#595959', fontWeight:500,}}>
                                                    Visit Date
                                                </span>
                                            </div>
                                            <div>
                                                <span style={{color:'var(--secondary-text-color)'}}>
                                                    Open Date
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div style={{marginTop:12,}}>
                            <div>
                                <Space>
                                    <IconWrapper icon={'profile.svg'} />
                                    <div>
                                        <span style={{ fontWeight: 500 }}>Purchaser Info</span>
                                    </div>
                                </Space>
                            </div>
                            <div style={{marginTop:8,}}>
                                <Row>
                                    <Col xs={24} md={8}>
                                        <div>
                                            <div>
                                                <span style={{color:'#595959', fontWeight:500,}}>
                                                    Agent
                                                </span>
                                            </div>
                                            <div>
                                                <span style={{color:'var(--secondary-text-color)'}}>
                                                    {agents}
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                    
                                </Row>
                            </div>
                        </div>
                        <Divider />
                        <div>
                            <div>
                                <span style={{ fontWeight: 500 }}>Tickets</span>
                            </div>
                            <div style={{marginTop: 8,}}>
                                <Table
                                size='small'
                                scroll={{ x : 400, }}
                                pagination={false}
                                className='hub-table bordered'
                                dataSource={ticket_users}
                                columns={[{
                                    title : 'Name',
                                    dataIndex : 'name',
                                    key : 'name',
                                },{
                                    title : 'Quantity',
                                    dataIndex : 'quantity',
                                    key : 'quantity',
                                },{
                                    title : (
                                        <>
                                            <Space>
                                                <div>
                                                    <span>Price</span>
                                                </div>
                                                <div>
                                                    <Tag color='blue' style={{border : 'none',}}>
                                                        {park?.currency?.code}
                                                    </Tag>
                                                </div>
                                            </Space>
                                        </>
                                    ),
                                    dataIndex : 'price',
                                    key : 'price',
                                    render : (text, row, index) => {
                                        const price = isNaN(parseFloat(text)) ? 0 : parseFloat(text);
                                        return (
                                            <>
                                                <div>
                                                    <span>{`${park?.currency?.symbol}${numberHelper?.commaNumber(price, 2)}`}</span>
                                                </div>
                                            </>
                                        )
                                    }
                                },{
                                    title : (
                                        <>
                                            <Space>
                                                <div>
                                                    <span>Total Price</span>
                                                </div>
                                                <div>
                                                    <Tag color='blue' style={{border : 'none',}}>
                                                        {park?.currency?.code}
                                                    </Tag>
                                                </div>
                                            </Space>
                                        </>
                                    ),
                                    dataIndex : 'price',
                                    key : 'total_price',
                                    // fixed:'right',
                                    align:'right',
                                    render : (text, row, index) => {
                                        const total_price = text * row?.quantity;
                                        return (
                                            <>
                                                <div>
                                                    <span>{`${park?.currency?.symbol}${numberHelper?.commaNumber(total_price, 2)}`}</span>
                                                </div>
                                            </>
                                        )
                                    }
                                }]}
                                footer={() => {
                                    return (
                                        <>
                                            <div style={{display:'flex'}}>
                                                <div style={{marginLeft:'auto'}}>
                                                    <Space>
                                                        <div>
                                                            <Tag color='blue' style={{border : 'none',}}>
                                                                {park?.currency?.code}
                                                            </Tag>
                                                        </div>
                                                        <div>
                                                            <span>{`${park?.currency?.symbol}${(numberHelper?.commaNumber(total_ticket_price))}`}</span>
                                                        </div>
                                                    </Space>
                                                    
                                                </div>
                                            </div>
                                        </>
                                    )
                                }}
                                />
                            </div>
                        </div>
                        <Divider />
                        <div>
                            <div>
                                <span style={{ fontWeight: 500 }}>Amount</span>
                            </div>
                            <div style={{marginTop:12,}}>
                                <Row>
                                    <Col span={12}>
                                        <GenerateParkTransaction park={park} sub_total={sub_total} total_amount={total_amount} taxes={taxes}/>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </>
            ),
            onOk(){
                return new Promise(async (resolve, reject) => {
                    const { generate_ota_ticket, error, } = await purchaseOtaTickets();
                    generate_ota_ticket && resolve();
                    error?.length && reject();
                }).catch(err => {
                    console.error(err);
                })
            },
            width : 800
        })
    }

    const purchaseOtaTickets = async () => {
        const { park, ticket_type, ticket_users, remarks, agents, valid_days } = form.getFieldsValue();
        const calculateResponse = await ticketService?.calculateOpenPricing({
            park_id : state?.selected_park_id,
            ticket_type_id : state?.selected_ticket_type_id,
            ticket_users,
            // add_ons,
        });
        const { tax_amount, total_amount } = calculateResponse;
        // const final_price = ticket_users?.reduce((a, b) => (a + (b?.price * b?.quantity)), 0);
        const final_price = total_amount;
        const generateTicketResponse = await ticketService.generateTicket({
            park_id : park, ticket_type_id : ticket_type, ticket_users, remarks, purchase_type_code : 'OTA', agents, valid_days, final_price,
            // email,
        });

        const { ticket : generate_ota_ticket, error, } = generateTicketResponse;

        if(error){
            const err = error[0];
            notification?.warning({
                message : err?.message,
            })
        }else{
            notification.success({
                message : 'Successful created Ota Tickets',
                description : (
                    <>
                        <a style={{color:'var(--main-color)'}} onClick={() => history.push(`/ticket/detail/${generateTicketResponse?.ticket?.ticket_id}`)}>
                            {`${generate_ota_ticket?.unique_no}${generate_ota_ticket?.salt ?? ''}`}
                        </a>
                    </>
                ),
                duration: 10,
                placement : 'bottomLeft'
            })
    
            resetFields();
        }
        return { generate_ota_ticket, error, };
    }

    const resetFields = () => {
        form.resetFields();
        setState(state => ({...state, selected_park_id : null, selected_ticket_type_id : null}));
    }

    useEffect(() => {
        fetchDetail();
    }, []);

    return (
        <>
            <div className='mobile-padding-12'>
                <div style={{marginBottom: 24, textAlign:'start'}}>
                    <PageHeaderComponent isBack title={'Generate OTA Tickets'} />
                </div>
                <Form
                onFinish={confirmPurchase}
                form={form}
                layout={'vertical'}
                >
                    <EntranceMotion delay={0.1}>
                        <div style={{marginTop:24,}}>
                            <Form.Item
                            name={'agents'}
                            label={
                                <StepNoItem no={1}
                                text={<span style={{fontSize:14, fontWeight:500, color:'var(--secondary-text-color)'}}>Agents</span>} 
                                />
                            }
                            >
                                <Input placeholder='Enter Agents'/>
                            </Form.Item>
                        </div>
                    </EntranceMotion>
                    <EntranceMotion>
                        <div style={{textAlign:'start', border:'2px solid #cbd5e0', borderRadius:8, background:'#f7fafc', padding : 24, margin:'12px 0'}}>
                            <Row gutter={[12, 12]}>
                                <Col span={12}>
                                    <div>
                                        <Form.Item
                                        name="park" 
                                        label={
                                            <StepNoItem no={2}
                                            text={<span style={{fontSize:14, fontWeight:500, color:'var(--secondary-text-color)'}}>Park</span>} 
                                            />
                                        }
                                        >
                                            <Select
                                            value={state?.selected_park_id}
                                            placeholder={'Select Park'}
                                            onChange={changePark}
                                            // onChange={(e) => setState(state => ({...state, selected_park_id : e}))}
                                            >
                                                {
                                                    state?.parks?.map((park, index) => {
                                                        return (
                                                            <Option key={index} value={park?.park_id}>
                                                                {park?.name}
                                                            </Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                    name={'ticket_type'}
                                    label={
                                        <StepNoItem no={3}
                                        text={<span style={{fontSize:14, fontWeight:500, color:'var(--secondary-text-color)'}}>Ticket Group</span>} 
                                        />
                                    }
                                    >
                                        <Select
                                        // defaultValue={state?.selected_ticket_type_id}
                                        value={state?.selected_ticket_type_id}
                                        disabled={!state?.selected_park_id}
                                        placeholder={'Select Ticket Group'}
                                        onChange={(e) => setState(state => ({...state, selected_ticket_type_id : e}))}
                                        >
                                            {
                                                state?.parks?.find(x => x.park_id === state?.selected_park_id)?.ticket_types?.map((ticket_type, index) => {
                                                    return (
                                                        <Option key={index} value={ticket_type?.ticket_type_id}>
                                                            {ticket_type?.name}
                                                        </Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                            
                                </Col>
                            </Row>
                        </div>
                    </EntranceMotion>

                    <EntranceMotion delay={0.1}>
                        <div style={{marginTop:24,}}>
                            <Form.Item
                            name={'ticket_users'}
                            label={
                                <StepNoItem no={4}
                                text={<span style={{fontSize:14, fontWeight:500, color:'var(--secondary-text-color)'}}>Tickets</span>} 
                                />
                            }
                            >
                                {
                                    state?.selected_park_id && state?.selected_ticket_type_id ?
                                    (
                                        <>
                                            <GenerateTicketUserList 
                                            form={form} 
                                            park={state?.parks?.find(x => x.park_id === state?.selected_park_id)} 
                                            ticket_type={(state?.parks?.find(x => x.park_id === state?.selected_park_id)?.ticket_types?.find(x => x.ticket_type_id === state?.selected_ticket_type_id))}
                                            />
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`Please select park and ticket group`}/>
                                        </>
                                    )
                                }
                                
                            

                            </Form.Item>
                        </div>
                    </EntranceMotion>

                    <EntranceMotion>
                        <div style={{marginTop:24,}}>
                            <Form.Item
                            name={'valid_days'}
                            label={
                                <StepNoItem no={5}
                                text={<span style={{fontSize:14, fontWeight:500, color:'var(--secondary-text-color)'}}>Valid Days</span>} 
                                />
                            }
                            >
                                <InputNumber min={0} type='number' placeholder='Eg. 90 days' style={{width:'100%'}} />
                            </Form.Item>
                        </div>
                    </EntranceMotion>

                    <EntranceMotion delay={0.2}>
                        <div style={{marginTop:24,}}>
                            <Form.Item
                            name={'remarks'}
                            label={
                                <StepNoItem no={6}
                                text={<span style={{fontSize:14, fontWeight:500, color:'var(--secondary-text-color)'}}>Remarks</span>} 
                                />
                            }
                            >
                                <TextArea placeholder='Enter your remarks' rows={4} style={{resize : 'none'}} />
                            </Form.Item>
                        </div>
                    </EntranceMotion>


                    <div style={{marginTop:24, display:'flex'}}>
                            <div style={{marginLeft:'auto'}}>
                                <Form.Item>
                                    <Space>
                                        <Button onClick={() => history?.goBack()}>
                                            <span>Cancel</span>
                                        </Button>

                                        <Button htmlType="submit" type="primary" style={{fontWeight:500, padding:'0 24px'}}>
                                            <span>Checkout</span>
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </div>
                        </div>
                </Form>
            </div>
        </>
    );
}

export default GenerateTicketLayout;