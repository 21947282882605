import { Table } from 'antd';
import React, { useEffect } from 'react';
import numberHelper from '../../../../helpers/functions/number.helper';

const OtherSalesTable = ({ add_on_types, park, }) => {

    return (
        <>
            <Table
            scroll={{ x : 400, }}
            pagination={false}
            className='hub-table bordered'
            columns={[{
                title : "Name",
                dataIndex : 'name',
                key : "name",
            },{
                title : "Total Sales",
                dataIndex : 'sales_amount',
                key : "sales_amount",
                render : (text, row, index) => {
                    const { count, } = row ?? {};
                    return (
                        <>
                            <div>
                                <div>
                                    <span style={{color:'var(--main-color)'}}>{numberHelper?.commaNumber(count, 0)}</span>
                                </div>
                                <div>
                                    <span>{`${park?.currency?.code}${numberHelper?.commaNumber(text)}`}</span>
                                </div>
                            </div>
                        </>
                    )
                }
            }]}
            dataSource={add_on_types}
            />
        </>
    );
}

export default OtherSalesTable;