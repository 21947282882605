import { Button, Col, DatePicker, Divider, Form, Input, Row, Select, Space, Tag, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customPassService from '../../../services/config/custom_pass.service';
import moment from 'moment';
import parkService from '../../../services/config/park.service';

const { TextArea } = Input;
const CustomPassPersonalInfo = ({ custom_pass, refreshCustomPass, }) => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        edit_mode : false,

        save_loading : false,
    })
    const [field, setField] = useState({
        is_active : false,
    });
    const [select, setSelect] = useState({
        parks : [],
    })

    const updateCustomPass = async () => {
        setState(state => ({...state, save_loading : true, }));
        const { name, identification_number, expired_date, parks, remarks, } = form.getFieldsValue();
        
        const updateCustomPassResponse = await customPassService?.updateCustomPass(custom_pass?.custom_pass_id, {
            name, identification_number, parks, remarks,
            expired_date : expired_date ? dayjs(expired_date)?.format("YYYY-MM-DD") : null, 
        });

        setState(state => ({...state, save_loading : false, edit_mode : false, }));
        notification.success({
            message : 'Custom Pass updated',
            description : custom_pass?.pass?.pass_code
        })
        refreshCustomPass && refreshCustomPass();
    }

    const initializeSelection = async () => {
        const getParkResponse = await parkService?.getParkByAuth();

        const { parks } = getParkResponse;
        setSelect(select => ({...select, parks : parks}));
    }

    const initializeForm = () => {
        const { pass, remarks, } = custom_pass ?? {};
        const { parks, is_active, expired_date, } = pass;
        const mapped_parks = parks?.map(x => x.park_id);
        form.setFieldsValue({
            name : pass?.name,
            remarks : remarks,
            identification_number : pass?.identification_number,
            expired_date : expired_date ? dayjs(expired_date) : null,
            is_active : is_active,
            parks : mapped_parks,
        })

        // set isactive
        setField(field => ({...field, is_active : is_active}));
    }

    const initialize = async () => {
        setState(state => ({...state, loading : true, }));
        await initializeForm();
        await initializeSelection();

        setState(state => ({...state, loading : false, }));
    }

    const tagRender = ({ label, value, closable, onClose }) => {
        const onPreventMouseDown = (event) => {
          event.preventDefault();
          event.stopPropagation();
        };
        return (
          <Tag
            color={'var(--secondary-button-color)'}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ color : 'var(--secondary-text-color)'}}
            closeIcon={<CloseOutlined style={{color:'var(--secondary-text-color)'}} />}
          >
            {label}
          </Tag>
        );
    };

    useEffect(() => {
        custom_pass && initialize();
    }, [custom_pass])

    return (
        <>
            <div>
                <div style={{textAlign:'start', border:'2px solid #cbd5e0', borderRadius:8, background:'#f7fafc', padding : 24, margin:'12px 0'}}>
                    <Form
                    form={form}
                    layout='vertical'
                    onFinish={updateCustomPass}
                    >
                        <div>
                            <div>
                                <div style={{display:'flex', alignItems:'center',}}>
                                    <div>
                                        <PageHeaderComponent title={<span style={{fontSize:16,}}>Personal Information</span>} textAlign={'start'} />
                                    </div>
                                    <div style={{marginLeft:'auto', }}>
                                        <Space>
                                            {
                                                state?.edit_mode ?
                                                (
                                                    <>
                                                        <Button loading={state?.save_loading} onClick={() => form.submit()} size='small' icon={<CheckOutlined style={{color:'var(--secondary-text-color)'}} />} />
                                                        <Button onClick={() => setState(state => ({...state, edit_mode : false, }))} size='small' icon={<CloseOutlined style={{color:'var(--secondary-text-color)'}} />} />
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <Button onClick={() => setState(state => ({...state, edit_mode : true, }))} size='small' icon={<EditOutlined style={{color:'var(--secondary-text-color)'}} />} />
                                                    </>
                                                )
                                            }
                                        </Space>
                                    </div>
                                </div>
                            </div>
                            <div style={{marginTop:12,}}>
                                <div>
                                    <Row gutter={[20, 0]}>
                                        <Col xs={24} sm={12} span={12}>
                                            <Form.Item name={'name'} label={'Name'}>
                                                {
                                                    state?.edit_mode ?
                                                    (
                                                        <Input />
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <div style={{textAlign:'start',}}>
                                                                <span style={{color:'var(--secondary-text-color)'}}>{custom_pass?.pass?.name}</span>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} span={12}>
                                            <Form.Item name={'identification_number'} label={'Identification Number'}>
                                                {
                                                    state?.edit_mode ?
                                                    (
                                                        <Input />
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <div style={{textAlign:'start',}}>
                                                                <span style={{color:'var(--secondary-text-color)'}}>{custom_pass?.pass?.identification_number}</span>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} span={12}>
                                            <Form.Item name={'expired_date'} label={'Expiry Date'}>
                                                {
                                                    state?.edit_mode ?
                                                    (
                                                        <DatePicker style={{ width : '100%', }} />
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <div style={{textAlign:'start',}}>
                                                                <Space>
                                                                    <div>
                                                                        {
                                                                            custom_pass?.pass?.expired_date ?
                                                                            (
                                                                                <>
                                                                                    <span style={{color:'var(--secondary-text-color)'}}>{custom_pass?.pass?.expired_date}</span>
                                                                                </>
                                                                            )
                                                                            :
                                                                            (
                                                                                <>
                                                                                    <span style={{color:'var(--secondary-text-color)', fontSize:12,}}>{"Expiry date not set"}</span>
                                                                                    
                                                                                </>
                                                                            )
                                                                        }
                                                                        
                                                                    </div>
                                                                    <div>
                                                                    <Tag color={field?.is_active ? 'green' : 'orange'} style={{border:'none',}}>
                                                                        {field?.is_active ? 'Active' : 'Inactive'}
                                                                    </Tag>
                                                                    </div>
                                                                </Space>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24}>
                                            <Form.Item name={'remarks'} label={'Remarks'}>
                                                {
                                                    state?.edit_mode ?
                                                    (
                                                        <TextArea placeholder='Enter your remarks' rows={4} style={{resize : 'none'}}/>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <div>
                                                                {
                                                                    custom_pass?.remarks ?
                                                                    (
                                                                        <>
                                                                            <span style={{color:'var(--secondary-text-color)'}}>{custom_pass?.remarks}</span>
                                                                        </>
                                                                    )
                                                                    :
                                                                    (
                                                                        <>
                                                                            <span style={{color:'var(--secondary-text-color)', fontSize:12,}}>{"No remarks"}</span>
                                                                        </>
                                                                    )
                                                                }
                                                                
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        <Divider />
                        <div>
                            <div>
                                <PageHeaderComponent title={<span style={{fontSize:16,}}>Park Access</span>} textAlign={'start'} />
                            </div>
                            <div style={{marginTop:12,}}>
                                <Row gutter={[20, 0]}>
                                    <Col xs={24} sm={12} span={12}>
                                        <Form.Item name="parks" label="Parks" style={{textAlign:'start',}}>
                                        {
                                            state?.edit_mode ?
                                            (
                                                <Select
                                                tagRender={tagRender}
                                                mode='multiple'
                                                options={select?.parks?.map((park, index) => {
                                                    const { name, park_id } = park;
                                                    return {
                                                        label : name,
                                                        value : park_id,
                                                    }
                                                })}
                                                />
                                            )
                                            :
                                            (
                                                <Space>
                                                    {
                                                        custom_pass?.pass?.parks?.length > 0 ?
                                                        (
                                                            <>
                                                                {
                                                                    custom_pass?.pass?.parks?.map((park, index) => {
                                                                        const { park_id, name } = park ?? {};
                                                                        return (
                                                                            <div key={index}>
                                                                                <Tag color='var(--secondary-button-color)' style={{border:'none', color:'var(--secondary-text-color)'}}>
                                                                                    {name}
                                                                                </Tag>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                <span style={{fontSize:12, color : 'var(--secondary-text-color)',}}>No parks is assigned</span>
                                                            </>
                                                        )
                                                    }
                                                </Space>
                                            )
                                        }
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default CustomPassPersonalInfo;