import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import numberHelper from '../../../../helpers/functions/number.helper';

const PurchaseTypeTable = ({ purchase_types, total_sales, filter, park, }) => {
    const [state, setState] = useState({
        purchase_type_datas : [],
        columns : [],
    })

    const initializeTable = () => {
        let purchase_type_table = {};
        let columns = [];
        //re-arrange the sequence
        purchase_types = purchase_types?.sort((a, b) => a.purchase_type_id - b.purchase_type_id);
        for(const purchase_type of purchase_types){
            const { ticket_user_count, sales_amount, transaction_count, name } = purchase_type;
            purchase_type_table[name] = {
                ticket_user_count : ticket_user_count,
                sales_amount : sales_amount,
            };

            columns.push({
                title : name,
                dataIndex : name,
                key : name,
                render: (text, row, index) => {
                    const { ticket_user_count, sales_amount } = text ?? {};
                    return (
                        <>
                            <div>
                                <span style={{color:'var(--main-color)'}}>{numberHelper?.commaNumber(ticket_user_count, 0)}</span>
                            </div>
                            <div>
                                <span>{`${park?.currency?.code}${numberHelper?.commaNumber(sales_amount)}`}</span>
                            </div>
                        </>
                    )
                }
            })
        }


        //total sales
        purchase_type_table['total_sales'] = {
            ticket_user_count : total_sales?.ticket_user_count,
            sales_amount : total_sales?.sales_amount,
        };
        columns.push({
            title : "Total Sales",
            dataIndex : 'total_sales',
            key : "total_sales",
            render: (text, row, index) => {
                const { ticket_user_count, sales_amount } = text ?? {};
                return (
                    <>
                        <div>
                            <span style={{color:'var(--main-color)'}}>{numberHelper?.commaNumber(ticket_user_count, 0)}</span>
                        </div>
                        <div>
                            <span>{`${park?.currency?.code}${numberHelper?.commaNumber(sales_amount)}`}</span>
                        </div>
                    </>
                )
            }
        })


        setState(state => ({...state, purchase_type_datas : purchase_type_table, columns : columns}))
    }

    useEffect(() => {
        initializeTable();
    }, [purchase_types])

    return (
        <>
            <Table
            scroll={{ x : 400, }}
            size='small'
            pagination={false}
            className='hub-table bordered'
            dataSource={[state?.purchase_type_datas]}
            columns={state?.columns}
            />
        </>
    );
}

export default PurchaseTypeTable;