import React, { useEffect, useState } from 'react';
import parkService from '../../../services/config/park.service';
import FilterBullet from '../../../helpers/components/filter_bullet';
import { Checkbox, Col, DatePicker, Divider, Input, List, Radio, Row, Segmented, Space } from 'antd';
import { CaretDownFilled, SearchOutlined } from '@ant-design/icons';
import ReportItem from './report_item';
import dayjs from 'dayjs';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import serviceHelper from '../../../helpers/functions/service.helper';

const { RangePicker } = DatePicker;
const SaleReport = ({ default_filter }) => {
    const { innerWidth } = window;
    const [state, setState] = useState({
        // loading : false,
        parks : [],

        selected_view_type : default_filter?.view_type ?? 'chart',
    });

    const [errors, setErrors] = useState();

    const [filter, setFilter] = useState({
        park : default_filter?.park,
        start_date : default_filter?.start_date ? dayjs(default_filter?.start_date).format("YYYY-MM-DD HH:mm") : dayjs()?.startOf('days')?.format("YYYY-MM-DD HH:mm"),
        end_date : default_filter?.end_date ? dayjs(default_filter?.end_date)?.format("YYYY-MM-DD HH:mm") : dayjs()?.endOf('days')?.format("YYYY-MM-DD HH:mm"),
    })

    const fetchFilterItem = async () => {
        const parkResponse = await parkService.getParkByAuth();
        setState(state => ({...state, parks : parkResponse?.parks}));
    }

    const changeDate = (date) => {
        if(date){
            const start_date = dayjs(date[0])?.format("YYYY-MM-DD HH:mm");
            const end_date = dayjs(date[1])?.format("YYYY-MM-DD HH:mm");

            setFilter(filter => ({...filter, start_date : start_date, end_date : end_date}));

            changeFilter("start_date", start_date);
            changeFilter("end_date", end_date);
        }else{
            setFilter(filter => ({...filter, start_date : null, end_date : null}));
            changeFilter("start_date", null);
            changeFilter("end_date", null);
        }
    }

    const changeViewType = (view_type) => {
        setState(state => ({...state, selected_view_type : view_type}));
        changeFilter('view_type', view_type);

    }

    const onChange = (key, checkedValues) => {
        setFilter(filter => ({...filter, [key] : checkedValues}));
        changeFilter(key, checkedValues);
    }

    // for changing filter
    const changeFilter = (key, value) => {
        let generate_filter = default_filter;
        generate_filter[key] = value;
        const queryString = serviceHelper?.queryString(generate_filter);
        window.history.replaceState({}, '', `${window.location.pathname}?${queryString}`);
    }

    useEffect(() => {
        fetchFilterItem();
    }, [])

    return (
        <>
            <div className='mobile-padding-12'>
                <div style={{marginBottom: 24, textAlign:'start'}}>
                    <PageHeaderComponent title={'Sales Report'} />
                </div>
                <div style={{textAlign:'left', display:'flex', alignItems:'center'}}>
                    <div>
                        <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                            <FilterBullet
                            popover={
                                <>
                                    <div>
                                        <Input prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>} placeholder='Search Parks' />
                                    </div>
                                    
                                    <Radio.Group
                                    value={filter?.park}
                                    // defaultValue={(state?.parks?.length > 0 && state?.parks[0]?.park_id)}
                                    onChange={(e) => onChange('park', e.target.value)}
                                    >
                                        <List
                                        style={{ width:280, }}
                                        dataSource={state?.parks}
                                        renderItem={(item, index) => {
                                            const { park_id, name, } = item ?? {};
                                            return (
                                                <List.Item style={{width:'100%'}} key={index}>
                                                    <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                        <div>
                                                            <span>{name}</span>
                                                        </div>
                                                        <div style={{marginLeft:'auto'}}>
                                                            <Radio
                                                            value={park_id}
                                                            >

                                                            </Radio>
                                                        </div>
                                                    </div>
                                                </List.Item>
                                            )
                                        }}
                                        />
                                    </Radio.Group>
                                </>
                            }
                            >
                                <div>
                                    <Space>
                                        <div>
                                            <span>Park{filter?.park && `: ${state?.parks?.find(x => x.park_id === filter?.park)?.name}` }</span>
                                        </div>
                                        <div>
                                            <CaretDownFilled style={{color:'#a0aec0'}} />
                                        </div>
                                    </Space>
                                    
                                </div>
                            </FilterBullet>

                            <FilterBullet
                            {...errors?.find(x => x?.type === 'filter_date_range') && ({ type : 'warning', })}
                            popover={
                                <>
                                    <div>
                                        <RangePicker
                                        showTime={{ format: 'HH:mm' }}
                                        format="YYYY-MM-DD HH:mm"
                                        onChange={(e) => changeDate(e)}
                                        />
                                    </div>
                                </>
                            }
                            >
                                <div>
                                    <Space>
                                        <div>
                                            <span>Date
                                                <span style={{fontSize:12, fontWeight:500, color:'var(--secondary-text-color)'}}>
                                                    {filter?.start_date && filter?.end_date && `: ${filter?.start_date}-${filter?.end_date}`}
                                                </span>
                                            </span>
                                        </div>
                                        <div>
                                            <CaretDownFilled style={{color:'#a0aec0'}} />
                                        </div>
                                        {
                                            errors?.find(x => x?.type === 'filter_date_range') && (
                                                <>
                                                    <span style={{ fontSize : 12, color : '#fa8c16', fontWeight : '500', }}>{errors?.find(x => x?.type === 'filter_date_range')?.message}</span>
                                                </>
                                            )
                                        }
                                    </Space>
                                    
                                </div>
                            </FilterBullet>
                        </Space>
                    </div>

                    <div style={{marginLeft:'auto'}}>
                        <Segmented
                        onChange={changeViewType}
                        defaultValue={state?.selected_view_type}
                        options={['chart', 'grid',]}
                        />

                        {/* <Radio.Group
                        onChange={changeViewType}
                        defaultValue={state?.selected_view_type}
                        >
                            <Radio.Button value="chart">Chart</Radio.Button>
                            <Radio.Button value="grid">Grid</Radio.Button>
                        </Radio.Group> */}
                    </div>
                </div>

                <div style={{marginTop:24,}}>
                    {
                        filter?.park ?
                        (
                            <>
                                <ReportItem view_type={state?.selected_view_type} filter={filter} park={state?.parks?.find(x => x?.park_id === filter?.park)} setErrors={setErrors} />
                            </>
                        )
                        :
                        (
                            <>
                                <span>Please select parks</span>
                            </>
                        )
                    }
                    
                </div>
            </div>

            
        </>
    );
}

export default SaleReport;