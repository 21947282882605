import React, { useEffect, useState } from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { Button, Checkbox, DatePicker, Input, List, Space } from 'antd';
import { CaretDownFilled, DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import InvoiceList from './invoice_list';
import FilterBullet from '../../../helpers/components/filter_bullet';
import parkService from '../../../services/config/park.service';
import serviceHelper from '../../../helpers/functions/service.helper';
import dayjs from 'dayjs';
import invoiceService from '../../../services/ticket/invoice.service';
import moment from 'moment';

const { RangePicker } = DatePicker;
const InvoiceLayout = ({ default_filter, }) => {
    const { innerWidth } = window;
    const [timer, setTimer] = useState();
    const [state, setState] = useState({
        loading : false,
        download_loading : false,
        parks : [],
    })
    const [filter, setFilter] = useState({
        parks : default_filter?.parks,
        start_date : default_filter?.start_date,
        end_date : default_filter?.end_date,
        search : default_filter?.search,
    })
    const [table_checked, setTableChecked] = useState({
        is_all : false,
        checked_invoices : [],
    });

    const fetchParks = async () => {
        const parkResponse = await parkService?.getParkByAuth();
        return parkResponse?.parks;
    }

    const onCheckChange = (key, checkedValues) => {
        setFilter(filter => ({...filter, [key] : checkedValues, }));
        changeFilter(key, checkedValues);
    }

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
            changeFilter('search', input);
        }, 300);
        setTimer(newTimer);
    }

    // for changing filter
    const changeFilter = (key, value) => {
        let generate_filter = default_filter;
        generate_filter[key] = value;
        
        setFilter(filter => ({...filter, page : 1,}));
        const queryString = serviceHelper?.queryString(generate_filter);
        window.history.replaceState({}, '', `${window.location.pathname}?${queryString}`);
    }

    const changeDate = (key1, key2, date) => {
        if(date){
            const start_date = dayjs(date[0])?.format("YYYY-MM-DD");
            const end_date = dayjs(date[1])?.format("YYYY-MM-DD");

            setFilter(filter => ({...filter, [key1] : start_date, [key2] : end_date}));
            changeFilter(key1, start_date);
            changeFilter(key2, end_date);
        }else{
            setFilter(filter => ({...filter, [key1] : null, [key2] : null}));
            changeFilter(key1, null);
            changeFilter(key2, null);
        }
    }

    const initialize = async () => {
        setState(state => ({...state, loading : true, }));

        const parks = await fetchParks();
        
        setState(state => ({...state, loading : false, parks : parks, }));
    }

    const selectRows = (selected_rows, info) => {
        setTableChecked(table_checked => ({...table_checked, checked_invoices : selected_rows, is_all : (info?.type === 'all')}));
    }

    const downloadInvoices = async () => {
        setState(state => ({...state, download_loading : true, }));
        const { is_all, checked_invoices } = table_checked;
        const { parks, start_date, end_date, } = filter;
        const invoice_ids = checked_invoices?.map(x => x?.invoice_id);

        const downloadInvoiceResponse = await invoiceService?.downloadBatchInvoice({
            invoice_ids,
            is_all : (is_all ? 1 : 0),
            park_ids : parks,
            start_date,
            end_date,
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `tax-invoice-[${moment()?.format("MM-YYYY")}].zip`); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch(err => setState(state => ({...state, download_loading : false, })))
        .finally(() => setState(state => ({...state, download_loading : false, })));
    }
    
    useEffect(() => {
        initialize();
    }, [])

    return (
        <>
            <div className='mobile-padding-12'>
                <div style={{marginBottom: 24, textAlign:'start'}}>
                    <PageHeaderComponent title={'Invoice List'} />
                </div>

                <div>
                    <Input
                    onChange={(e) => doneTyping(e.target.value)}
                    name="search"
                    defaultValue={filter?.search}
                    prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                    placeholder='Search Invoice Number'
                    style={{minWidth: '100%',}}
                    allowClear
                    />
                </div>

                <div style={{ display : 'flex', alignItems:'center', marginTop:12, gap:8, }}>
                    <div className='filter-panel' style={{textAlign:'start'}}>
                        <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                            <FilterBullet
                            popover={
                                <>
                                    <div>
                                        <Input prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>} placeholder='Search Parks' />
                                    </div>
                                    
                                    <Checkbox.Group
                                    value={filter?.parks}
                                    onChange={(checkedValues) => onCheckChange('parks', checkedValues)}
                                    >
                                        <List
                                        style={{ width:280, }}
                                        dataSource={state?.parks}
                                        renderItem={(item, index) => {
                                            const { park_id, name, } = item ?? {};
                                            return (
                                                <List.Item style={{width:'100%'}} key={index}>
                                                    <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                        <div>
                                                            <span>{name}</span>
                                                        </div>
                                                        <div style={{marginLeft:'auto'}}>
                                                            <Checkbox
                                                            value={park_id}
                                                            />
                                                        </div>
                                                    </div>
                                                </List.Item>
                                            )
                                        }}
                                        />
                                    </Checkbox.Group>
                                </>
                            }
                            >
                                <div>
                                    <Space>
                                        <div>
                                            <span>Park {filter?.parks?.length > 0 ? `: ${filter?.parks?.length}` : ''}</span>
                                        </div>
                                        <div>
                                            <CaretDownFilled style={{color:'#a0aec0'}} />
                                        </div>
                                    </Space>
                                    
                                </div>
                            </FilterBullet>

                            <FilterBullet
                                popover={
                                    <>
                                        <div>
                                            <RangePicker
                                            {...(filter?.start_date && filter?.end_date) && ({ defaultValue : [dayjs(filter?.start_date), dayjs(filter?.end_date)] })}
                                            onChange={(e) => changeDate('start_date', 'end_date', e)} format={"YYYY-MM-DD"} 
                                            />
                                        </div>
                                    </>
                                }
                                >
                                    <div>
                                        <Space>
                                            <div>
                                                <span>Payment Date
                                                    <span style={{fontSize:12, fontWeight:500, color:'var(--secondary-text-color)'}}>
                                                        {filter?.start_date && filter?.end_date && `: ${filter?.start_date}-${filter?.end_date}`}
                                                    </span>
                                                </span>
                                            </div>
                                            <div>
                                                <CaretDownFilled style={{color:'#a0aec0'}} />
                                            </div>
                                        </Space>
                                    </div>
                                </FilterBullet>
                        </Space>
                    </div>

                    <div style={{ marginLeft : 'auto', }}>
                        <Space>
                            <Button disabled={table_checked?.checked_invoices?.length <= 0} loading={state?.download_loading} onClick={downloadInvoices} icon={<DownloadOutlined />}>
                                Download
                            </Button>
                        </Space>
                    </div>
                </div>

                <div style={{marginTop:12,}}>
                    {/* Listing */}
                    <InvoiceList filter={filter} selectRows={selectRows} />
                </div>
            </div>
        </>
    );
}

export default InvoiceLayout;