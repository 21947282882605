import { AppstoreOutlined } from '@ant-design/icons';
import { Avatar, Col, Divider, Form, InputNumber, Row, Space, Tag } from 'antd';
import React, { useEffect } from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';

const EditPeakDayModal = ({ form, ticket_type, peak_day_discount, }) => {

    const initialize = () => {
        const { percentage, value, } = peak_day_discount ?? {};
        form.setFieldsValue({
            percentage,
            value,
        })
    }

    useEffect(() => {
        initialize();
    }, [peak_day_discount?.is_peak_day])

    return (
        <>
            <div>
                <Form.Item label={'Day Type'}>
                    <span style={{ color : 'var(--secondary-text-color)', }}>{peak_day_discount?.is_peak_day ? 'Peak Day' : 'Non-Peak Day'}</span>
                </Form.Item>
            </div>
            <div>
                <Form.Item label={'Ticket Type'}>
                    <Space>
                        <div>
                            <Avatar icon={<AppstoreOutlined />} shape='square' size='small' src={ticket_type?.ParkApplicableTicketType?.image} />
                        </div>
                        <div>
                            <span>{ticket_type?.name}</span>
                        </div>
                    </Space>
                </Form.Item>
            </div>
            <div>
                <Divider orientation='left' orientationMargin="0"><PageHeaderComponent title={<span style={{fontSize:14,}}>Discount Amount</span>} textAlign={'start'} /></Divider>

                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item label={'Percentage'} name={'percentage'}>
                            <InputNumber min={0} precision={2} style={{ width : '100%', }} />
                        </Form.Item>
                    </Col>
                    {/* <Col span={12}>
                        <Form.Item label={'Value'} name={'value'}>
                            <InputNumber precision={2} style={{ width : '100%', }} />
                        </Form.Item>
                    </Col> */}
                </Row>
            </div>
        </>
    );
}

export default EditPeakDayModal;