import React, { useEffect, useState } from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { Table } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const TaxApplicableAddOnTable = ({ park_tax, add_ons, }) => {
    const [state, setState] = useState({
        loading : false,
        columns : [],
        datas : [],
    });

    const generateColumns = () => {
        let columns = [];

        // map title
        columns?.push({
            title : 'Ticket Type',
            dataIndex : 'name',
            key : 'name',
            width: 150,
            fixed: 'left',
        })

        // map data
        for(const add_on of add_ons){
            const { name, add_on_id, add_on_setups, } = add_on;
            const column = {
                title : name,
                dataIndex : 'applicable',
                key : 'applicable',
                width : 150,
                render : (applicable, row, index) => {
                    // from database
                    const { park_applicable_ticket_type_id, } = row;
                    const add_on_setup = add_on_setups?.find(x => x?.park_applicable_ticket_type_id === park_applicable_ticket_type_id);
                    const { is_applicable, } = applicable?.[add_on_setup?.add_on_setup_id] ?? {};
                    return (
                        <>
                            {
                                is_applicable != undefined ?
                                (
                                    <>
                                        {
                                            is_applicable ?
                                            (
                                                <>
                                                    <CheckCircleFilled style={{color:'#95de64'}} />
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <CloseCircleFilled style={{color:'#d9d9d9',}} />
                                                </>
                                            )
                                        }
                                    </>
                                )
                                :
                                (
                                    <>
                                        <div>
                                            <span style={{fontSize:12, color:('var(--secondary-text-color)')}}>Not Applicable</span>
                                        </div>
                                    </>
                                )
                            }
                        </>
                    )
                }
            }
            columns?.push(column);
        }

        return columns;
    }
    
    const generateRows = () => {
        const { park_tax_add_ons, ticket_types, } = park_tax ?? {};
        const map_datas = ticket_types?.map(x => {
            const { name, park_applicable_ticket_types, ticket_type_id } = x;
            const { add_on_setups, park_applicable_ticket_type_id, } = park_applicable_ticket_types ?? {};

            let applicable = {};
            for(const add_on_setup of add_on_setups){
                const { add_on_id, add_on_setup_id, } = add_on_setup;
                
                const park_tax_add_on = park_tax_add_ons?.find(x => x?.add_on_setup_id === add_on_setup_id);
                applicable[add_on_setup_id] = {
                    is_applicable : Boolean(park_tax_add_on?.is_applicable),
                    add_on_id : add_on_id,
                    add_on_setup_id : add_on_setup_id,
                };
            }

            return {
                name,
                ticket_type_id,
                park_applicable_ticket_type_id,
                applicable,
            }
        })

        return map_datas;
    }

    const initializeTable = async () => {
        const columns = generateColumns();
        const rows = generateRows();

        setState(state => ({...state, columns : columns, datas : rows, }));
    }

    useEffect(() => {
        initializeTable();
    }, [add_ons])

    return (
        <>
            <div>
                <Table
                scroll={{x : 600,}}
                pagination={false}
                className='hub-table bordered'
                columns={state?.columns}
                dataSource={state?.datas}
                size='small'
                />
            </div>
        </>
    );
}

export default TaxApplicableAddOnTable;