import React, { useState } from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import TicketLayout from './components/ticket_layout';
import { useLocation, } from 'react-router-dom';

const OtaIndex = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [filter, setFilter] = useState({
        page : queryParams.get("page"),
        parks : queryParams.get("parks")?.split(',')?.map(Number)?.filter(x => x != 0),
        start_date : queryParams.get("start_date"),
        end_date : queryParams.get("end_date"),
        purchase_types : queryParams.get("purchase_types")?.split(',')?.map(Number)?.filter(x => x != 0),
        ticket_types : queryParams.get("ticket_types")?.split(',').map(Number)?.filter(x => x != 0),
        // visit_date : queryParams.get("visit_date"),
        visit_start_date : queryParams.get("visit_start_date"),
        visit_end_date : queryParams.get("visit_end_date"),
        search : queryParams.get("search"),
    })

    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <TicketLayout default_filter={filter}/>
            </ContainerComponent>
        </>
    );
}

export default OtaIndex;