import { ExclamationCircleFilled, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Modal, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import AffiliateSelection from '../../affiliate/components/affiliate_selection';
import { useDispatch } from 'react-redux';
import { refresh_promo_code_detail } from '../../../actions/config.action';
import globalHelper from '../../../helpers/functions/global.helper';
import promotionService from '../../../services/config/promotion.service';

const { confirm } = Modal;
const PromoAffiliate = ({ promotion_code, }) => {
    const { affiliate_account } = promotion_code ?? {};
    const avatar_color = affiliate_account ? globalHelper?.generateFixedColorFromString(affiliate_account?.email) : 'var(--secondary-text-color)';
    const dispatch = useDispatch();
    const [state, setState] = useState({
        affiliate_select_modal : false,
        selected_affiliate_account : null,
        save_loading : false,
    })

    const onAffiliateConfim = async () => {
        setState(state => ({...state, save_loading : true,}));
        // update promotion code
        const { promotion_code_id } = promotion_code;
        const updatePromoCodeResponse = await promotionService?.updatePromoCode(promotion_code_id, {
            affiliate_account_id : state?.selected_affiliate_account,
        });
        
        dispatch(refresh_promo_code_detail());
        setState(state => ({...state, affiliate_select_modal : false, save_loading : false, }));
    }

    const unassignAffiliate = async () => {
        setState(state => ({...state, selected_affiliate_account : null}));
        confirm({
            title: 'Do you want to unassign affiliate?',
            icon: <ExclamationCircleFilled />,
            content : (
                <>
                    <Space>
                        <div>
                            <Avatar icon={<UserOutlined />} style={{background : avatar_color}} />
                        </div>
                        <div>
                            <div>
                                <span>{affiliate_account?.full_name}</span>
                            </div>
                            <div>
                                <span style={{fontSize:12, color:'var(--secondary-text-color)',}}>{affiliate_account?.email}</span>
                            </div>
                        </div>
                    </Space>
                    
                </>
            ),
            onOk() {
              return new Promise(async (resolve, reject) => {
                await onAffiliateConfim().then(() => {
                    resolve();
                });
              }).catch(() => console.error('Oops errors!'));
            },
            onCancel() {},
          });
    }

    return (
        <>
            <Card style={{height : '100%',}}>
                <div>
                    <div style={{textAlign:'start',}}>
                        <span style={{fontWeight:500,}}>Affiliate</span>
                    </div>

                    <div style={{marginTop:12,}}>
                        <Row gutter={[12, 12]}>
                            <Col span={16} xs={14} md={16} xl={16}>
                                <Button
                                style={{width : '100%', height : 34,}}
                                onClick={() => setState(state => ({...state, affiliate_select_modal : true,}))}
                                >
                                    <div>
                                        <Space>
                                            <div>
                                                <Avatar size='small' icon={<UserOutlined />} style={{background : avatar_color}} />
                                            </div>
                                            <div>
                                                <span style={{color : 'var(--secondary-text-color)'}}>{affiliate_account ? affiliate_account?.full_name : "Select Affiliate"}</span>
                                            </div>
                                        </Space>
                                    </div>
                                </Button>
                            </Col>
                            <Col span={4} xs={10} md={8} xl={8}>
                                <Button disabled={!affiliate_account} onClick={unassignAffiliate} type='text' style={{background : 'var(--secondary-button-color)', width :'100%'}}>Unassigned</Button>
                            </Col>
                        </Row>
                        
                    </div>
                </div>
            </Card>

            <AffiliateSelection
            confirmLoading={state?.save_loading}
            default_affiliate_id={affiliate_account?.affiliate_account_id}
            is_open={state?.affiliate_select_modal}
            onCancel={() => setState(state => ({...state, affiliate_select_modal : false,}))}
            onOk={onAffiliateConfim}
            onAffiliateSelect={(affiliate_account_id) => setState(state => ({...state, selected_affiliate_account : affiliate_account_id}))}
            />
        </>
    );
}

export default PromoAffiliate;