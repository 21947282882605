import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function fetchTicketType(query){
    const queryString = serviceHelper.queryString(query);
    const ticketTypeResponse = await axios.get(`config/ticket_type/get?${queryString}`, { withCredentials : true });
    return ticketTypeResponse?.data;
}

async function saveTicketType(params){
    const saveTicketTypeResponse = await axios.post(`config/ticket_type/save`, params, { withCredentials : true, });
    return saveTicketTypeResponse?.data;
}

async function updateTicketType(ticket_type_id, params){
    const updateTicketTypeResponse = await axios.post(`config/ticket_type/${ticket_type_id}/update`, params, { withCredentials : true, });
    return updateTicketTypeResponse?.data;
}

async function deleteTicketType(ticket_type_id){
    const deleteTicketTypeResponse = await axios.delete(`config/ticket_type/${ticket_type_id}/delete`, { withCredentials : true, });
    return deleteTicketTypeResponse?.data;
}

export default {
    fetchTicketType,
    saveTicketType,
    updateTicketType,
    deleteTicketType,
}