import menuService from "../../services/admin/menu.service"

async function checkAccess(query){
    const accessResponse = await menuService?.getAccesses(query);
    const access = accessResponse?.accesses?.length > 0 ? accessResponse?.accesses[0] : null;
    return access;
}

export default {
    checkAccess,
}