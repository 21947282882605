import { Image } from 'antd';
import React from 'react';

const IconComponent = ({name, size}) => {
    return (
        <>
            <Image preview={false} src={name} height={size} width={size}/>
        </>
    );
}

export default IconComponent;