import { message, notification } from 'antd';
import axios from 'axios';
import React from 'react';
import MasterLayout from './components/master_layout';
import './layout.css';

const LayoutIndex = ({children}) => {

    //auto error message
    axios.interceptors.response.use(function (response) {
    // Do something with response data
        return response;
    }, function (error) {
            // Close any existing notification before opening a new one
            notification.destroy();
            if(error?.response){
                const err_message = (error?.response?.data?.message);
                if(401 === error.response.status){
                    //auth error
                }
                else if(422 === error.response.status){
                    notification.error({
                        message : err_message,
                        duration : 5,
                    })
                return Promise.reject(error);
                }
                else{
                    let show_error_message = err_message;
                    // Do something with response error
                    // 500
                    if(!(error?.request?.responseURL?.includes('fc/recognize')) && !(error?.response?.data?.message === 'Faces not found')){
                        // if response is buffer
                        if((error?.response?.data instanceof ArrayBuffer)){
                            // Create a new TextDecoder
                            const decoder = new TextDecoder('utf-8');
                            
                            // Decode the ArrayBuffer into a string
                            const text = decoder.decode(new Uint8Array(error?.response?.data));
                            
                            // Parse the string into JSON
                            const json = JSON.parse(text);
                            show_error_message = (json?.message)
                        }
                        
                        notification.error({
                            message : show_error_message ?? "Something went wrong, please contact administrator",
                            duration : 5,
                        })
                    }
                    
                    return Promise.reject(error);
                }
            }else{
                // Do something with response error
                notification.error({
                    message : "Something went wrong, please contact our support",
                    duration : 5,
                })
                return Promise.reject(error);
            }
    });
    return (
        <>
            <MasterLayout>
                {children}
            </MasterLayout>
        </>
    );
}

export default LayoutIndex;