import React, { useEffect, useMemo, useState } from 'react';
import adminService from '../../../services/admin/admin.service';
import { Avatar, Badge, Button, Checkbox, Col, Divider, Form, Input, List, Modal, Row, Select, Space, Spin, Switch, Tag, Upload, notification, } from 'antd';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import parkService from '../../../services/config/park.service';
import ContainerComponent from '../../../helpers/components/container.component';
import globalHelper from '../../../helpers/functions/global.helper';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const EmployeeDetail = ({ account_id, }) => {
    const history = useHistory();
    const accountRedux = useSelector(state => state.account);
    const [form] = Form.useForm();
    const [reset_password_form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        save_loading : false,
        account : null,
        refresh : 0,
        
        // reset password
        reset_password_modal : false,
        reset_password_loading : false,
    });
    const [selection, setSelection] = useState({
        roles : [],
        parks : [],
    })
    
    const fetchAccount = async () => {
        setState(state => ({...state, loading : true,}));
        const accountResponse = await adminService.getAccount({
            account_id : account_id,
        });

        const { username, email, role_id, full_name, parks, is_active, } = accountResponse?.accounts;
        const park_ids = parks?.map(x => x.park_id);
        form.setFieldsValue({ full_name, username, email, role_id, parks, is_active, parks : park_ids, });
        setState(state => ({...state, loading : false, account : accountResponse?.accounts,}));
    }

    const changePassword = async () => {
        setState(state => ({...state, reset_password_loading : true,}));
        const { password, } = reset_password_form?.getFieldsValue();
        const updateAccountResponse = await adminService.updateAccount(account_id, { 
            password,
        })
        .catch(err => setState(state => ({...state, reset_password_loading : false,})));

        setTimeout(() => {
            if(updateAccountResponse?.update_account){
                // if successfully updated account
                //notification for successfully updated
                const { account } = updateAccountResponse;
                notification.success({
                    placement : 'bottomLeft',
                    message : 'Password has reset successfully',
                })
    
                setState(state => ({...state, save_loading : false, reset_password_modal : false,}));
            }
        }, 500);
    }

    const generatePassword = async () => {
        const random_string = Math.random().toString(36).slice(-8);
        reset_password_form.setFieldsValue({ password : random_string });
    }

    const updateAccount = async () => {
        setState(state => ({...state, save_loading : true,}));
        const { full_name, is_active, parks, role_id, email, password, } = form.getFieldsValue();

        const updateAccountResponse = await adminService.updateAccount(account_id, { 
            full_name, is_active, park_ids : parks, role_id, email, password,
        })
        .catch(err => setState(state => ({...state, save_loading : false,})));

        setTimeout(() => {
            if(updateAccountResponse?.update_account){
                // if successfully updated account
                //notification for successfully updated
                const { account } = updateAccountResponse;
                notification.success({
                    placement : 'bottomLeft',
                    message : account?.username,
                    description : "Account detail has successfully updated."
                })
    
                setState(state => ({...state, save_loading : false, refresh : (state?.refresh + 1)}));
            }
        }, 500);
    }
    
    const isResetPassword = useMemo(() => {
        const feature = accountRedux?.role?.features?.find(x => x.code === 'RESET_PASSWORD' && x?.RoleFeature.enabled === true);
        return Boolean(feature);
    }, [])

    const is_role_level = useMemo(() => {
        const auth_level = accountRedux?.role?.level;
        const account_level = state?.account?.role?.level;
        const is_accessible_level = auth_level <= account_level;
        return is_accessible_level;
    }, [accountRedux?.role, state?.account])

    const initialize = async () => {
        const roleResponse = await adminService.getRole();
        const parkResponse = await parkService.getPark();
        setSelection(selection => ({...selection, roles : roleResponse?.roles, parks : parkResponse?.parks}));
    }

    useEffect(() => {
        initialize();
    }, [])

    useEffect(() => {
        fetchAccount();
    }, [account_id, state?.refresh]);

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <div style={{textAlign:'start', marginBottom : 20,}}>
                    <div className='title-header mobile-padding-24'>
                        <ContainerComponent span={20}>
                            <PageHeaderComponent title={'Account Detail'} textAlign={'start'} />
                            <div>
                                <span style={{color : 'var(--secondary-text-color)', fontSize : 16, }}>{state?.account?.username}</span>
                            </div>
                        </ContainerComponent>
                    </div>
                    <div
                    className='mobile-padding-24'
                    {...!globalHelper?.isMobileScreen() && ({ style : { marginTop : 24, }})}
                    >
                        <ContainerComponent span={20}>
                            <Form
                            form={form}
                            layout='vertical'
                            onFinish={updateAccount}
                            >
                                <div>
                                    <div>
                                        <PageHeaderComponent title={<span style={{fontSize:16,}}>Account Information</span>} textAlign={'start'} />
                                    </div>
                                    <div style={{marginTop:12,}}>
                                        <div>
                                            <Row gutter={24}>
                                                <Col span={12} xs={24} sm={12}>
                                                    <Form.Item label={'Username'} name='username'>
                                                        <Input disabled />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12} xs={24} sm={12}>
                                                    <Form.Item label={'Password'} style={{ margin : 0, }}>
                                                        <Row gutter={8}>
                                                            <Col span={18} xs={14} md={18}>
                                                                <Form.Item
                                                                style={{ width : '100%', }}
                                                                // name={'password'}
                                                                requiredMark={'optional'}
                                                                rules={[{ required : true, message : 'Please enter password' }]}
                                                                >
                                                                    <Input.Password disabled visibilityToggle={false} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={6} xs={10} md={6}>
                                                                <div style={{width : '100%'}}>
                                                                    <Button
                                                                    {...(!isResetPassword && ({ disabled : true, })) }
                                                                    style={{color : 'var(--secondary-text-color)', width : '100%',}}
                                                                    onClick={() => setState(state => ({...state, reset_password_modal : true,}))}
                                                                    >
                                                                        Reset
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12} xs={24} sm={12}>
                                                    <Form.Item label={'Role'} name='role_id'>
                                                        <Select
                                                        disabled={!is_role_level}
                                                        options={selection?.roles?.map(x => {
                                                            const { role_id, name } = x;
                                                            return {
                                                                value : role_id,
                                                                label : name,
                                                            }
                                                        })}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12} xs={24} sm={12}>
                                                    <Form.Item label={'Status'} name='is_active' {...state?.account?.is_active && ({ valuePropName : 'checked', })}>
                                                        <Switch />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                                <Divider />
                                <div>
                                    <div>
                                        <PageHeaderComponent title={<span style={{fontSize:16,}}>Personal Information</span>} textAlign={'start'} />
                                    </div>
                                    <div style={{marginTop : 12,}}>
                                        <Row gutter={24}>
                                            <Col span={12} xs={24} sm={12}>
                                                <Form.Item label={'Name'} name='full_name'>
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12} xs={24} sm={12}>
                                                <Form.Item label={'Email'} name='email'>
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <Divider />
                                <div>
                                    <div>
                                        <PageHeaderComponent title={<span style={{fontSize:16,}}>Parks</span>} textAlign={'start'} />
                                    </div>
                                    <div style={{marginTop : 12,}}>
                                        <Form.Item name={'parks'}>
                                            <Checkbox.Group
                                            style={{width:'100%'}}
                                            // value={state?.account?.parks?.map(x => x.park_id) ?? []}
                                            >
                                                <List
                                                style={{width:'100%',}}
                                                dataSource={selection?.parks}
                                                split={false}
                                                renderItem={(item, index) => {
                                                    const { park_id, name, } = item ?? {};
                                                    const { parks = [], } = (form?.getFieldsValue());
                                                    return (
                                                        <List.Item style={{width:'100%', background:'#fff', borderRadius:8, border:'1px solid #d5dce6', marginBottom:12, paddingInline : 12, }} key={index}>
                                                            <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                                <div>
                                                                    <span>{name}</span>
                                                                </div>
                                                                <div style={{marginLeft:'auto'}}>
                                                                    <Checkbox
                                                                    value={park_id}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </List.Item>
                                                    )
                                                }}
                                                />
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </div>
                                </div>
                                <Divider />
                                <div style={{display : 'flex',}}>
                                    <div {...globalHelper?.isMobileScreen() ? ({ style : { width : '100%', } }) : ({ style : { marginLeft : 'auto',} })}>
                                        <Row gutter={8}>
                                            <Col span={12}>
                                                <Button onClick={() => history.goBack()} style={{ width : '100%', minWidth : 150 }}>Back</Button>
                                            </Col>
                                            <Col span={12}>
                                                <Button
                                                loading={state?.save_loading}
                                                type='primary'
                                                style={{ width : '100%', minWidth : 150, }}
                                                onClick={() =>form.submit()}
                                                >
                                                    Save
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Form>
                        </ContainerComponent>
                    </div>
                </div>
            </Spin>

            <Modal
            open={state?.reset_password_modal}
            title="Reset Password"
            onCancel={() => setState(state => ({...state, reset_password_modal : false,}))}
            onOk={() => reset_password_form?.submit()}
            confirmLoading={state?.reset_password_loading}
            >
                <Form
                onFinish={changePassword}
                form={reset_password_form}
                layout='vertical'
                >
                    <Form.Item
                    name={'password'}
                    label={'Password'}
                    hasFeedback
                    requiredMark='optional'
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password.',
                        },
                    ]}
                    >
                        <Row gutter={8}>
                            <Col span={18} xs={14} md={18}>
                                <Form.Item
                                style={{ width : '100%', }}
                                name={'password'}
                                requiredMark={'optional'}
                                rules={[{ required : true, message : 'Please enter password' }]}
                                >
                                    <Input.Password visibilityToggle={{ visible : true, }} placeholder='password' />
                                </Form.Item>
                            </Col>
                            <Col span={6} xs={10} md={6}>
                                <div style={{width : '100%'}}>
                                    <Button
                                    style={{color : 'var(--secondary-text-color)', width : '100%',}}
                                    onClick={generatePassword}
                                    >
                                        Generate
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default EmployeeDetail;