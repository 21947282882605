import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import GenerateTicketLayout from './ota/generate_ticket_layout';
import { useParams } from 'react-router-dom';
import GenerateMaTicketLayout from './ma/generate_ma_ticket_layout';

const GenerateTicket = () => {
    const { purchase_code } = useParams();
    const purchase_code_lower = purchase_code?.toLowerCase();
    return (
        <>
            <ContainerComponent span={16} top={20} bottom={20}>
                {
                    purchase_code_lower === 'ota' ?
                    (
                        <>
                            <GenerateTicketLayout />
                        </>
                    )
                    :
                    purchase_code_lower === 'ma' ?
                    (
                        <>
                            <GenerateMaTicketLayout />
                        </>
                    )
                    :
                    null
                }
                
            </ContainerComponent>
        </>
    );
}

export default GenerateTicket;