import { Avatar, Card, Col, Input, Modal, Row, Space, Spin, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import affiliateService from '../../../services/config/affiliate.service';
import globalHelper from '../../../helpers/functions/global.helper';
import { LoadingOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';

const AffiliateSelection = ({ is_open, onCancel, onOk, default_affiliate_id, onAffiliateSelect, confirmLoading }) => {
    const [state, setState] = useState({
        loading : false,
        affiliate_accounts : [],
        selected_affiliate : null,
    })
    const [filter, setFilter] = useState({
        search : null,
    })
    const [timer, setTimer] = useState(null);

    const fetchAffiliates = async () => {
        setState(state => ({...state, loading : true, }));
        const { search, } = filter;
        const affiliateResponse = await affiliateService?.fetchAffiliate({
            search,
        });

        const { affiliates } = affiliateResponse;
        setState(state => ({...state, loading : false, affiliate_accounts : affiliates }));
    }

    const onSelect = async (affiliate_account_id) => {
        setState(state => ({...state, selected_affiliate : affiliate_account_id }))
        typeof onAffiliateSelect === 'function' && onAffiliateSelect(affiliate_account_id);
    }

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
            // changeFilter('search', input);
        }, 300);
        setTimer(newTimer);
    }

    useEffect(() => {
        is_open &&
        fetchAffiliates();
    }, [filter, is_open]);

    useEffect(() => {
        setState(state => ({...state, selected_affiliate : default_affiliate_id}));
    }, [default_affiliate_id])

    return (
        <>
            <Modal
            confirmLoading={confirmLoading}
            open={is_open}
            onCancel={onCancel}
            onOk={onOk}
            maskClosable
            title="Select Affiliate"
            >
                <div>
                    <div>
                        <Input onChange={(e) => doneTyping(e?.target?.value)} prefix={<SearchOutlined style={{color : 'var(--secondary-text-color)'}} />} placeholder='Search' />
                    </div>
                    <div style={{marginTop:24,}}>
                        <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                            <Row gutter={[12, 12,]}>
                            {
                                state?.affiliate_accounts?.map((affiliate, index) => {
                                    const { affiliate_account_id, full_name, username, email, is_active, } = affiliate;
                                    const generated_color = globalHelper?.generateFixedColorFromString(email);
                                    let is_selected = affiliate_account_id === state?.selected_affiliate;
                                    return (
                                        <Col
                                        xs={24}
                                        sm={12}
                                        span={12} key={index} style={{height : 'inherit',}}
                                        onClick={() => onSelect(affiliate_account_id)}
                                        >
                                            <Card
                                            className={`selection-card ${is_selected ? 'selected' : ''}`}
                                            style={{border:'none', cursor:'pointer', height : '100%',}}
                                            styles={{ body : { padding : 12 }, }}
                                            >
                                                <div>
                                                    <Space>
                                                        <div>
                                                            <Avatar style={{ backgroundColor: generated_color }} icon={<UserOutlined />} />
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <span>{full_name}</span>
                                                            </div>
                                                            <div>
                                                                <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{email}</span>
                                                            </div>
                                                        </div>
                                                    </Space>
                                                </div>
                                            </Card>
                                        </Col>
                                    )
                                })
                            }
                            </Row>
                        </Spin>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default AffiliateSelection;