import React, { useEffect, useState } from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import ContainerComponent from '../../../helpers/components/container.component';
import { Card, Divider, Tabs } from 'antd';
import ChangePassword from './change_password';
import PersonalInfo from './personal_info';
import { useSelector } from 'react-redux';
import serviceHelper from '../../../helpers/functions/service.helper';

const ProfileLayout = ({ default_filter, }) => {
    const authRedux = useSelector(state => state.auth);
    const [state, setState] = useState({
        selected_tab : default_filter?.selected_tab ?? 'personal_info',
    })

    const changeFilter = (key, value) => {
        let generate_filter = default_filter;
        generate_filter[key] = value;

        setState(state => ({...state, [key] : value, }));
        const queryString = serviceHelper?.queryString(generate_filter);
        window.history.replaceState({}, '', `${window.location.pathname}?${queryString}`);
    }

    return (
        <>
            <div>
                <div style={{ textAlign : 'start', }}>
                    <ContainerComponent span={20} top={20}>
                        <PageHeaderComponent title={'Account'} />
                        <div>
                            <span style={{ color : 'var(--secondary-text-color)', }}>Manage your profile</span>
                        </div>
                    </ContainerComponent>
                </div>
                <Divider />
                <div style={{ marginTop : 12, }}>
                    <ContainerComponent span={20}>
                        <Card>
                            <Tabs
                            className='custom-tabs'
                            animated
                            activeKey={state?.selected_tab}
                            onChange={(e) => changeFilter('selected_tab', e)}
                            items={[{
                                key : 'personal_info',
                                label : 'Personal Info',
                                children : <PersonalInfo auth={authRedux?.auth} />,
                            },{
                                key : 'change_password',
                                label : 'Change Password',
                                children : <ChangePassword />,
                            }]}
                            />
                        </Card>
                    </ContainerComponent>
                </div>
            </div>
        </>
    );
}

export default ProfileLayout;