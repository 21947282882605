import { Avatar, Badge, Button, Col, DatePicker, Divider, Form, Input, Row, Select, Space, Spin, Tag, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { CloseOutlined, EditOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons';
import parkService from '../../../services/config/park.service';
import Compressor from 'compressorjs';

const { TextArea } = Input;
const CreateCustomPassLayout = ({ form, }) => {
    const acceptable_file_format = ['image/jpeg', 'image/png'];
    const [state, setState] = useState({
        loading : false,
        file : null,
    })

    const [select, setSelect] = useState({
        parks : [],
    })

    const handleChange = async info => {
        // update here
        setState(state => ({...state, loading : true, }))
        const { file } = info;

        // compress image
        let compressed_file = null;
        if(file){
            const compressor = new Promise((resolve, reject) => 
                new Compressor(file, {
                    quality : 0.8,
                    height : 800,
                    width : 600,
                    mimeType : 'image/jpeg',
                    success : resolve,
                    error : reject,
                    // convertSize: 500000,
                })
            )
            compressed_file = await compressor;
        }

        form && form.setFieldsValue({ image_url : compressed_file, });
        setState(state => ({...state, loading : false, }))
    }

    const beforeUpload = async (file) => {
        const is_image = acceptable_file_format?.find(x => x == file?.type);

        if(is_image){
            const uploaded_file = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    file.base64 = reader.result.split(',')[1];
                    resolve(file);
                };
                reader.onerror = (error) => reject(error);
            });
    
            const base64Image = `data:image/jpeg;base64,${uploaded_file?.base64}`
            setState(state => ({...state, file : base64Image, }));
        }else{
            // errorModal("Please upload in image format");
        }

        return false;
    }

    const tagRender = ({ label, value, closable, onClose }) => {
        const onPreventMouseDown = (event) => {
          event.preventDefault();
          event.stopPropagation();
        };
        return (
          <Tag
            color={'var(--secondary-button-color)'}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ color : 'var(--secondary-text-color)'}}
            closeIcon={<CloseOutlined style={{color:'var(--secondary-text-color)'}} />}
          >
            {label}
          </Tag>
        );
    };

    const initializeSelection = async () => {
        // get parks
        const getParkResponse = await parkService?.getParkByAuth();

        const { parks } = getParkResponse;
        setSelect(select => ({...select, parks : parks}));
    }

    const initialize = async () => {
        await initializeSelection();
    }

    useEffect(() => {
        initialize();
    }, [])

    return (
        <>
            <div className='mobile-padding-12'>
                <Divider />
                <div>
                    <div>
                        <PageHeaderComponent title={<span style={{fontSize:16,}}>Facial Picture</span>} textAlign={'start'} />
                    </div>
                    <div style={{textAlign:'start', marginTop:12, }}>
                        <Form.Item name="image_url">
                            <Badge
                            offset={[-10, 100]}
                            count={
                                <Button.Group>
                                    <Upload
                                    beforeUpload={beforeUpload}
                                    maxCount={1}
                                    onChange={handleChange}
                                    showUploadList={false}
                                    >
                                        <Button
                                        size='small'
                                        shape='circle'
                                        icon={<EditOutlined style={{color:'var(--secondary-text-color)',}} />}
                                        style={{borderBottomRightRadius : 0, borderTopRightRadius:0,}} 
                                        />
                                    </Upload>
                                    <Button
                                    // onClick={removeImage}
                                    type='text'
                                    size='small'
                                    shape='circle'
                                    icon={<CloseOutlined style={{color:'var(--secondary-text-color)',}} />}
                                    style={{background:'var(--secondary-button-color)',}}
                                    />
                                </Button.Group>
                            }>
                                <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                                    <Avatar src={state?.file} size={120} icon={<UserOutlined />} style={{background: 'var(--secondary-text-color)', }} />
                                </Spin>
                            </Badge>
                        </Form.Item>
                    </div>
                </div>
                <div>
                    <div style={{display:'flex', alignItems:'center',}}>
                        <div>
                            <PageHeaderComponent title={<span style={{fontSize:16,}}>Personal Information</span>} textAlign={'start'} />
                        </div>
                    </div>
                    <div style={{marginTop:12,}}>
                        <div>
                            <Row gutter={[20, 0]}>
                                <Col xs={24} sm={12} span={12}>
                                    <Form.Item name={'name'} label={'Name'}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} span={12}>
                                    <Form.Item name={'identification_number'} label={'Identification Number'}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} span={12}>
                                    <Form.Item name={'expired_date'} label={'Expiry Date'} style={{textAlign:'start',}}>
                                        <DatePicker style={{width : '100%',}} />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={24} span={12}>
                                    <Form.Item name={'remarks'} label={'Remarks'} style={{textAlign:'start',}}>
                                        <TextArea placeholder='Enter your remarks' rows={4} style={{resize : 'none'}}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <div>
                                <PageHeaderComponent title={<span style={{fontSize:16,}}>Park Access</span>} textAlign={'start'} />
                            </div>
                            <div style={{marginTop:12,}}>
                                <Row gutter={[20, 0]}>
                                    <Col xs={24} sm={12} span={12}>
                                        <Form.Item name="parks" label="Parks" style={{textAlign:'start',}}>
                                            <Select
                                            tagRender={tagRender}
                                            mode='multiple'
                                            options={select?.parks?.map((park, index) => {
                                                const { name, park_id } = park;
                                                return {
                                                    label : name,
                                                    value : park_id,
                                                }
                                            })}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateCustomPassLayout;