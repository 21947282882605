import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getPromoCode(query){
    const queryString = serviceHelper.queryString(query);
    const parkResponse = await axios.get(`config/promotion_code?${queryString}`, { withCredentials : true });
    return parkResponse?.data;
}

async function getPromoCodeApplicableTicketUser(promotion_code_id, query){
    const queryString = serviceHelper.queryString(query);
    const promoCodeApplicableTicketUserResponse = await axios.get(`config/promotion_code/${promotion_code_id}/applicable/ticket_user?${queryString}`, { withCredentials : true });
    return promoCodeApplicableTicketUserResponse?.data;
}

async function getPromoCodeApplicableAddOn(promotion_code_id, query){
    const queryString = serviceHelper.queryString(query);
    const promoCodeApplicableAddOnResponse = await axios.get(`config/promotion_code/${promotion_code_id}/applicable/add_on?${queryString}`, { withCredentials : true });
    return promoCodeApplicableAddOnResponse?.data;
}

async function getPromoCodeDate(promotion_code_id, query){
    const queryString = serviceHelper.queryString(query);
    const promoCodeDateResponse = await axios.get(`config/promotion_code/${promotion_code_id}/applicable/date?${queryString}`, { withCredentials : true, });
    return promoCodeDateResponse?.data;
}

async function savePromoCodeApplicableTicketUser(promotion_code_id, params){
    const savePromoCodeApplicableTicketUserResponse = await axios.post(`config/promotion_code/${promotion_code_id}/applicable/ticket_user/save`, params, { withCredentials : true, });
    return savePromoCodeApplicableTicketUserResponse?.data;
}

async function savePromoCodeAddOn(promotion_code_id, params){
    const savePromoCodeAddOnResponse = await axios.post(`config/promotion_code/${promotion_code_id}/applicable/add_on/save`, params, { withCredentials : true, });
    return savePromoCodeAddOnResponse?.data;
}

async function savePromoCodeDate(promotion_code_id, params){
    const savePromoCodeDateResponse = await axios.post(`config/promotion_code/${promotion_code_id}/applicable/date/save`, params, { withCredentials : true, });
    return savePromoCodeDateResponse?.data;
}

async function updatePromoCode(promotion_code_id, params){
    const updatePromoCodeResponse = await axios.post(`config/promotion_code/${promotion_code_id}/update`, params, { withCredentials : true, });
    return updatePromoCodeResponse?.data;
}

async function getTicketByPromoCode(promotion_code_id, query){
    const queryString = serviceHelper.queryString(query);
    const ticketPromoCodeResponse = await axios.get(`config/promotion_code/${promotion_code_id}/ticket/count?${queryString}`, { withCredentials : true, });
    return ticketPromoCodeResponse?.data;
}

async function validatePromoCode(query){
    const queryString = serviceHelper.queryString(query);
    const promoCodeResponse = await axios.get(`config/promotion_code/validate?${queryString}`, { withCredentials : true });
    return promoCodeResponse?.data;
}

async function savePromoCode(params){
    const promoCodeResponse = await axios.post(`config/promotion_code/save`, params, { withCredentials : true, });
    return promoCodeResponse?.data;
}

async function deletePromoCode(promotion_code_id){
    const promoCodeResponse = await axios.delete(`config/promotion_code/${promotion_code_id}/delete`, { withCredentials : true, });
    return promoCodeResponse?.data;
}

async function generateReport(query){
    const queryString = serviceHelper.queryString(query);
    const reportResponse = await axios.get(`affiliate/report?${queryString}`, { withCredentials : true });
    return reportResponse?.data;
}

async function ticketListing(query){
    const queryString = serviceHelper?.queryString(query);
    const ticketListingResponse = await axios.get(`affiliate/ticket/listing?${queryString}`, { withCredentials : true, });
    return ticketListingResponse?.data;
}

export default {
    getPromoCode,
    getPromoCodeApplicableTicketUser,
    getPromoCodeApplicableAddOn,
    getPromoCodeDate,
    savePromoCodeApplicableTicketUser,
    savePromoCodeAddOn,
    savePromoCodeDate,
    updatePromoCode,
    savePromoCode,
    getTicketByPromoCode,
    validatePromoCode,
    deletePromoCode,
    generateReport,
    ticketListing,
}