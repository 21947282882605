import React, { useEffect, useState } from 'react';
import preferenceService from '../../../services/config/preference.service';
import { Card, Col, Row, Spin } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

const PreferenceList = ({ form, park, ticket_type, }) => {
    const { visit_date } = form.getFieldsValue();
    const [state, setState] = useState({
        loading : false,
        preference_groups : [],
        selected_preference : null,
    });

    const selectPreference = async (preference) => {
        setState(state => ({...state, selected_preference : preference, }));
        form.setFieldsValue({ preference : preference });
    }

    const fetchPreferences = async () => {
        setState(state => ({...state, loading : true, }));
        const { ParkApplicableTicketType } = ticket_type;
        const preferenceResponse = await preferenceService?.getPreferences({
            park_applicable_ticket_type_id : ParkApplicableTicketType?.park_applicable_ticket_type_id,
        });

        const { preference_groups } = preferenceResponse;
        setState(state => ({...state, loading : false, preference_groups : preference_groups, }));
    }

    useEffect(() => {
        fetchPreferences();
    }, [park, ticket_type, visit_date])

    return (
        <>
            <Spin spinning={state?.loading}>
                <Card>
                    <Row gutter={[32, 32]}>
                        {
                            state?.preference_groups?.length > 0 ?
                            (
                                <>
                                    {
                                        state?.preference_groups?.map((preference_group, index) => {
                                            const { preferences, name : preference_group_name, } = preference_group;
                                            return (
                                                <Col xs={24} md={24} lg={24} key={index}>
                                                    <div>
                                                        <div style={{textAlign:'start',}}>
                                                            <span style={{fontWeight:500,}}>{preference_group_name}</span>
                                                        </div>
                                                        <div style={{marginTop: 12,}}>
                                                            <Row gutter={[8, 8]}>
                                                            {
                                                                preferences?.map((preference, pindex) => {
                                                                    const { preference_id, name : preference_name, } = preference;
                                                                    const selected_preference = preference_id === state?.selected_preference?.preference_id;
                                                                    return (
                                                                        <Col span={6} key={pindex}>
                                                                            <Card
                                                                            onClick={() => selectPreference(preference)}
                                                                            className='ticket-type-card'
                                                                            style={{ cursor : 'pointer', ...(selected_preference && ({ background : '#e6f4ff', color : 'var(--main-color)', borderColor : 'var(--main-color)' }))}}
                                                                            // bodyStyle={{ padding : 8, }}
                                                                            styles={{ body : { padding : 8, } }}
                                                                            >
                                                                                <div style={{display : 'flex', }}>
                                                                                    <div>
                                                                                        <span>{preference_name}</span>
                                                                                    </div>
                                                                                    {
                                                                                        selected_preference &&
                                                                                        (
                                                                                            <div style={{marginLeft:'auto'}}>
                                                                                                <span><CheckOutlined /></span>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    
                                                                                </div>
                                                                            </Card>
                                                                        </Col>
                                                                    )
                                                                })
                                                            }
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </>
                            )
                            :
                            (
                                <>
                                    <div style={{textAlign:'center', width:'100%',}}>
                                        <span style={{color : 'var(--secondary-text-color)', fontSize:12,}}>No Visit Time</span>
                                    </div>
                                </>
                            )
                        }
                    </Row>
                </Card>
            </Spin>
        </>
    );
}

export default PreferenceList;