import { Col, DatePicker, Form, Input, Radio, Row, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import otherService from '../../../services/config/other.service';
import moment from 'moment';

const { RangePicker, } = DatePicker;
const AddMaintenanceDayModal = ({ form, }) => {
    const [state, setState] = useState({
        loading : false,
        maintenance_frequencies : [],
    })

    const [selected, setSelected] = useState({
        maintenance_frequency_id : null,
    })

    const is_weekly_selected = useMemo(() => {
        const { maintenance_frequency_id } = form?.getFieldsValue();
        return (state?.maintenance_frequencies?.find(x => x?.maintenance_frequency_id === maintenance_frequency_id)?.code === 'WL');
    }, [selected?.maintenance_frequency_id]);

    const fetchMaintenanceFrequencies = async () => {
        const maintenanceFrequencyResponse = await otherService?.getMaintenanceFrequency();
        const { maintenance_frequencies, } = maintenanceFrequencyResponse;
        return maintenance_frequencies;
    }

    const initialize = async () => {
        setState(state => ({...state, loading : true, }));
        const maintenance_frequencies = await fetchMaintenanceFrequencies();

        setState(state => ({...state, loading : false, maintenance_frequencies : maintenance_frequencies, }));
    }

    const selectMaintenanceFrequency = (maintenance_frequency_id) => {
        const is_weekly = (state?.maintenance_frequencies?.find(x => x?.maintenance_frequency_id === maintenance_frequency_id)?.code === 'WL');
        form.setFieldsValue({ is_weekly : is_weekly, })
        setSelected(selected => ({...selected, maintenance_frequency_id : maintenance_frequency_id}));
    }

    useEffect(() => {
        initialize();
    }, []);

    return (
        <>
            <Form.Item label={"Name"} name={"name"}>
                <Input />
            </Form.Item>

            <Form.Item label={'Frequence'} name={"maintenance_frequency_id"}>
                <Select
                onChange={(maintenance_frequency_id) => selectMaintenanceFrequency(maintenance_frequency_id)}
                options={state?.maintenance_frequencies?.map(x => ({ value : x?.maintenance_frequency_id, label : x?.name, }))}
                />
            </Form.Item>

            <div>
            {
                    selected?.maintenance_frequency_id ?
                    (
                        <>
                            {
                                is_weekly_selected ?
                                (
                                    <>
                                        {/* Week Picker */}
                                        <Form.Item label={'Date'}  style={{ marginBottom : 0, }}>
                                            <Form.Item name={"start_date"}>
                                                <Radio.Group
                                                options={[...Array(7)]?.map((x, i) => {
                                                    return {
                                                        label : (moment()?.day(i)?.format("dddd")),
                                                        value : (moment()?.day(i)?.format("YYYY-MM-DD")),
                                                    }
                                                })}
                                                optionType="button"
                                                buttonStyle="solid"
                                                />
                                            </Form.Item>
                                            <Form.Item name={"end_date"}>
                                                <DatePicker placeholder='End Date' style={{ width : 300, }} />
                                            </Form.Item>
                                        </Form.Item>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <Form.Item label={'Date'} name={"date"} extra={<span style={{ fontSize:12, }}>Leave blank on End Date if maintenance days exist forever</span>}>
                                            <RangePicker
                                            style={{ width : '100%', }}
                                            allowEmpty={[false, true]}
                                            />
                                        </Form.Item>
                                    </>
                                )
                            }
                        </>
                    )
                    :
                    (
                        <>
                            <Form.Item label={"Date"}>
                                <span style={{ fontSize : 12, color : 'var(--secondary-text-color)', }}>Please select frequency</span>
                            </Form.Item>
                        </>
                    )
                    
                }
            </div>

            <Form.Item hidden name={'is_weekly'}>

            </Form.Item>
        </>
    )
}

export default AddMaintenanceDayModal;