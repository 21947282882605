import React from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import PromoChart from './promo_chart';

const PromoDetailHeader = ({ promotion_code }) => {
    return (
        <>
            <div>
                <div style={{ textAlign:'start' }}>
                    <PageHeaderComponent title={'Edit Promotion Code'} />
                </div>

                <div style={{marginTop:12,}}>
                    <PromoChart promotion_code={promotion_code} />
                </div>
            </div>
        </>
    );
}

export default PromoDetailHeader;