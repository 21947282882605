import { Col, Divider, Form, Input, Row, Select, Space, Switch, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import DetailAssociateImage from './detail_associate_image';
import parkService from '../../../services/config/park.service';
import { CloseOutlined } from '@ant-design/icons';

const DetailAssociateNew = ({ refreshStaff, staff_id, form, }) => {
    const [state, setState] = useState({
        loading : false,
    })
    const [field, setField] = useState({
        is_active : false,
    })
    const [select, setSelect] = useState({
        parks : [],
    })

    const onFieldChange = (key, value) => {
        setField(field => ({...field, [key] : value, }));
        form.setFieldsValue({ [key] : value });
    }

    const initializeSelection = async () => {
        const getParkResponse = await parkService?.getParkByAuth();

        const { parks } = getParkResponse;
        setSelect(select => ({...select, parks : parks}));
    }

    const tagRender = ({ label, value, closable, onClose }) => {
        const onPreventMouseDown = (event) => {
          event.preventDefault();
          event.stopPropagation();
        };
        return (
          <Tag
            color={'var(--secondary-button-color)'}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ color : 'var(--secondary-text-color)'}}
            closeIcon={<CloseOutlined style={{color:'var(--secondary-text-color)'}} />}
          >
            {label}
          </Tag>
        );
    };

    useEffect(() => {
        initializeSelection();
    }, [])

    return (
        <>
            <div>
                {
                    state?.staff_associate?.staff?.pass?.name &&
                    (
                        <>
                            <div>
                                <span style={{fontSize:12, color : 'var(--secondary-text-color)',}}>Family members of {state?.staff_associate?.staff?.pass?.name}</span>
                            </div>
                        </>
                    )
                }
                
                <div style={{marginTop:24,}}>
                    <Form.Item name={'image_url'}>
                        <DetailAssociateImage is_upload={false} form={form} />
                    </Form.Item>
                </div>
                <div>
                    <div>
                        <Row gutter={[20, 0]}>
                            <Col xs={24} sm={12}>
                                <Form.Item name={'name'} label={'Name'}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Form.Item name={'identification_number'} label={'Identification Number'}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Form.Item name={'relationship'} label={'Relationship'}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Form.Item name={'is_active'} label={'Status'}>
                                    <Space size={12}>
                                        <Switch onChange={(e) => onFieldChange('is_active', e)} checked={field?.is_active}/>
                                        <Tag color={field?.is_active ? 'green' : 'orange'} style={{border:'none',}}>
                                            {field?.is_active ? 'Active' : 'Inactive'}
                                        </Tag>
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <Divider />
                    <div>
                        <Row gutter={[20, 0]}>
                            <Col xs={24} sm={12}>
                                <Form.Item name={'parks'} label={'Parks'}>
                                    <Select
                                    tagRender={tagRender}
                                    mode='multiple'
                                    options={select?.parks?.map((park, index) => {
                                        const { name, park_id } = park;
                                        return {
                                            label : name,
                                            value : park_id,
                                        }
                                    })}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DetailAssociateNew;