import { Calendar } from 'antd';
import moment from 'moment';
import React from 'react';

const GenerateMaTicketDate = ({ onChange, park }) => {
    const disabledDate = (current) => {
        const custom_format = "YYYY-MM-DD";
        let custom_date = moment().format(custom_format);
        const previous_date = current < moment(custom_date, custom_format);

        //get all maintenance date
        const maintenances = park?.maintenance_frequencies ?? [];
        let frequence_disabled = false;
        for(const maintenance of maintenances){
            const start_date = maintenance?.ParkMaintenance?.start_date;
            const end_date = maintenance?.ParkMaintenance?.end_date;
            const maintenance_frequency = (maintenance?.code === 'DL') ? // days calculate differently
            (current.format(maintenance?.format)) :
            (moment(start_date)?.format(maintenance?.format));

            if(end_date){
                if(
                    (current.format(custom_format) >= moment(start_date).format(custom_format)) &&
                    (current.format(custom_format) <= moment(end_date).format(custom_format))
                ){
                    frequence_disabled = (current.format(maintenance?.format) == maintenance_frequency)
                }
                
            }else{
                if(current.format(custom_format) >= moment(start_date).format(custom_format)){
                    frequence_disabled = (current.format(maintenance?.format) == maintenance_frequency)
                }
                
            }
            
        }
         
        const disabled = frequence_disabled || previous_date;


        return disabled;
    }

    return (
        <>
            <Calendar fullscreen={false} disabledDate={disabledDate} onChange={onChange} />
        </>
    );
}

export default GenerateMaTicketDate;