import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function fetchAffiliate(query){
    const queryString = serviceHelper.queryString(query);
    const purchaseTypeResponse = await axios.get(`affiliate/account/get?${queryString}`, { withCredentials : true });
    return purchaseTypeResponse?.data;
}

async function registerAffiliate(params){
    const registerAffiliateResponse = await axios.post(`affiliate/account/register`, params, { withCredentials : true, });
    return registerAffiliateResponse?.data;
}

async function updateAffiliateAccount(affiliate_account_id, params){
    const updateAffiliateAccountResponse = await axios.post(`affiliate/account/${affiliate_account_id}/update`, params, { withCredentials : true, });
    return updateAffiliateAccountResponse?.data;
}

async function deleteAffiliateAccount(affiliate_account_id){
    const deleteAffiliateAccountResponse = await axios.delete(`affiliate/account/${affiliate_account_id}/delete`, { withCredentials : true, });
    return deleteAffiliateAccountResponse?.data;
}

async function resendInvitationEmail(affiliate_account_id){
    const invitationEmailResponse = await axios.post(`affiliate/account/resend_invitation/${affiliate_account_id}`, {}, { withCredentials : true, });
    return invitationEmailResponse?.data;
}

export default {
    fetchAffiliate,
    registerAffiliate,
    updateAffiliateAccount,
    deleteAffiliateAccount,
    resendInvitationEmail,
}