import React, { useState, useEffect } from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { Button, Checkbox, Form, Input, List, Modal, Radio, Space, notification } from 'antd';
import { CaretDownFilled, SearchOutlined } from '@ant-design/icons';
import StaffList from './staff_list';
import StaffCreateLayout from './staff_create_layout';
import staffService from '../../../services/config/staff.service';
import { useHistory } from 'react-router-dom';
import FilterBullet from '../../../helpers/components/filter_bullet';
import parkService from '../../../services/config/park.service';
import serviceHelper from '../../../helpers/functions/service.helper';

const StaffLayout = ({ default_filter, }) => {
    const history = useHistory();
    const { innerWidth } = window;
    const [form] = Form.useForm();
    const [timer, setTimer] = useState();
    const [filter, setFilter] = useState({
        search : default_filter?.search,
        status : default_filter?.status,
        page : default_filter?.page ?? 1,
        parks : default_filter?.parks ?? [],
    });
    const [state, setState] = useState({
        loading : false,
        open_create_modal : false,
        save_loading : false,

        // selections
        parks : [],
    })

    const saveNewStaff = async () => {
        setState(state => ({...state, save_loading : true, }));
        const { name, identification_number, is_active, parks, image_url } = form.getFieldsValue();
        const saveStaffResponse = await staffService?.saveStaff({
            name, identification_number, is_active, parks, image_url,
        })

        setState(state => ({...state, save_loading : false, }));
        const { save_staff, save_pass, } = saveStaffResponse;
        notification?.success({
            message : 'Create Staff',
            description : `${save_pass?.name} has been created`
        })
        history.push(`/staff/detail/${save_staff?.staff_id}`)
    }

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
            changeFilter('search', input);
        }, 300);
        setTimer(newTimer);
    }

    const onCheckChange = (key, checkedValues) => {
        setFilter(filter => ({...filter, [key] : checkedValues, }));
        changeFilter(key, checkedValues);
    }

    // for changing filter
    const changeFilter = (key, value) => {
        let generate_filter = default_filter;
        generate_filter[key] = value;
        
        setFilter(filter => ({...filter, page : 1,}));
        const queryString = serviceHelper?.queryString(generate_filter);
        window.history.replaceState({}, '', `${window.location.pathname}?${queryString}`);
    }

    const initializeSelection = async () => {
        setState(state => ({...state, loading : true}));
        const parkResponse = await parkService.getParkByAuth();

        setState(state => ({...state, loading : false, parks : parkResponse?.parks,}));
    }

    useEffect(() => {
        initializeSelection();
    }, [])

    return (
        <>
            <div className='mobile-padding-12'>
                <div style={{marginBottom: 24, textAlign:'start'}}>
                    <PageHeaderComponent title={'Staff Management'} />
                </div>

                <div>
                    <Input
                    onChange={(e) => doneTyping(e.target.value)}
                    name="search"
                    defaultValue={filter?.search}
                    prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                    placeholder='Search Staff'
                    style={{minWidth: '100%',}}
                    allowClear
                    />
                </div>

                <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                    <div>
                        <div className='filter-panel' style={{textAlign:'start'}}>
                            <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                                <FilterBullet
                                popover={
                                    <>
                                        <div>
                                            <Input prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>} placeholder='Search Parks' />
                                        </div>
                                        
                                        <Checkbox.Group
                                        value={filter?.parks}
                                        onChange={(checkedValues) => onCheckChange('parks', checkedValues)}
                                        >
                                            <List
                                            style={{ width:280, }}
                                            dataSource={state?.parks}
                                            renderItem={(item, index) => {
                                                const { park_id, name, } = item ?? {};
                                                return (
                                                    <List.Item style={{width:'100%'}} key={index}>
                                                        <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                            <div>
                                                                <span>{name}</span>
                                                            </div>
                                                            <div style={{marginLeft:'auto'}}>
                                                                <Checkbox
                                                                value={park_id}
                                                                />
                                                            </div>
                                                        </div>
                                                    </List.Item>
                                                )
                                            }}
                                            />
                                        </Checkbox.Group>
                                    </>
                                }
                                >
                                    <div>
                                        <Space>
                                            <div>
                                                <span>Park {filter?.parks?.length > 0 ? `: ${filter?.parks?.length}` : ''}</span>
                                            </div>
                                            <div>
                                                <CaretDownFilled style={{color:'#a0aec0'}} />
                                            </div>
                                        </Space>
                                        
                                    </div>
                                </FilterBullet>
                                <FilterBullet
                                popover={
                                    <>
                                        <Radio.Group
                                        value={filter?.status}
                                        onChange={(e) => onCheckChange('status', e?.target?.value)}
                                        >
                                            <List
                                            style={{ width:280, }}
                                            dataSource={[{is_active : true, label : 'Active',}, {is_active : false, label : 'Inactive',}]}
                                            renderItem={(item, index) => {
                                                const { label, is_active, } = item ?? {};
                                                return (
                                                    <List.Item style={{width:'100%'}} key={index}>
                                                        <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                            <div>
                                                                <span>{label}</span>
                                                            </div>
                                                            <div style={{marginLeft:'auto'}}>
                                                                <Radio
                                                                value={is_active}
                                                                />
                                                            </div>
                                                        </div>
                                                    </List.Item>
                                                )
                                            }}
                                            />
                                        </Radio.Group>
                                    </>
                                }
                                >
                                    <div>
                                        <Space>
                                            <div>
                                                <span>Status {filter?.status != null ? `: ${filter?.status ? 'Active' : 'Inactive'}` : ''}</span>
                                            </div>
                                            <div>
                                                <CaretDownFilled style={{color:'#a0aec0'}} />
                                            </div>
                                        </Space>
                                        
                                    </div>
                                </FilterBullet>
                            </Space>
                        </div>
                    </div>

                    <div style={{marginLeft:'auto'}}>
                        <Space>
                            <Button
                            type='primary'
                            onClick={() => setState(state => ({...state, open_create_modal : true,}))}
                            >
                                New Staff
                            </Button>
                        </Space>
                    </div>
                </div>

                <div style={{marginTop:12,}}>
                    <StaffList filter={filter} />
                </div>
            </div>

            <Modal
            title="Create New Staff"
            open={state?.open_create_modal}
            style={{top : 60,}}
            onCancel={() => setState(state => ({...state, open_create_modal : false,}))}
            width={800}
            onOk={() => form.submit()}
            confirmLoading={state?.save_loading}
            >
                <Form
                onFinish={saveNewStaff}
                form={form}
                layout='vertical'
                >
                    <StaffCreateLayout form={form} />
                </Form>
            </Modal>
        </>
    );
}

export default StaffLayout;