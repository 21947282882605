import React, { useEffect, useState } from 'react';
import ticketService from '../../../services/ticket/ticket.service';
import { Button, Space, Spin, Table, Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { set_list_loading, set_tickets } from '../../../actions/ticket.action';
import otherService from '../../../services/config/other.service';
import serviceHelper from '../../../helpers/functions/service.helper';

const TicketList = ({filter, default_filter, setErrors = () => {}, }) => {
    const ticketRedux = useSelector(state => state?.ticket);
    const dispatch = useDispatch();
    const [state, setState] = useState({
        loading : false,
        // page : parseInt(filter?.page),
        page : null,
        display_size : 10,
        // tickets : [],
    });

    const [sorter, setSorter] = useState({
        key : null,
        order : null,
    })

    const fetchTickets = async () => {
        // setState(state => ({...state, loading : true}));
        dispatch(set_list_loading(true));
        const { parks, purchase_types, start_date, end_date, search, payment_statuses, ticket_types, visit_start_date, visit_end_date, } = filter;
        const { key, order, } = sorter;
        const paymentStatusResponse = await otherService.getPaymentStatus();

        //set filter on only completed payment status
        const complete_payment_status = paymentStatusResponse?.payment_statuses?.filter(x => x.is_payment_complete);
        const complete_payment_status_ids = complete_payment_status?.map(x => x.payment_status_id);
        const ticketListingResponse = await ticketService?.getTicketListing({
            purchase_type_ids : purchase_types?.join(","), // 2 for offline
            park_ids : parks?.join(","),
            ticket_type_ids : ticket_types?.join(","),
            // payment_status_ids : payment_statuses?.join(","),
            payment_status_ids : complete_payment_status_ids,
            // ticket_unique_no : search,
            search : search,
            // payment date
            start_date, end_date,
            // visit date
            visit_start_date, visit_end_date,

            // pagination
            page : (state?.page - 1),
            size : state?.display_size,

            // ordering
            order : key ?? "ticket_id",
            order_position : order ?? 'DESC',
        }).catch(err => {
            dispatch(set_list_loading(false));
        });

        const { tickets : ticket_list, errors } = ticketListingResponse;
        const { rows : tickets, count : ticket_count } = ticket_list ?? {};
        // const ticketResponse = await ticketService.getTicket({
        //     purchase_type_ids : purchase_types?.join(","), // 2 for offline
        //     park_ids : parks?.join(","),
        //     ticket_type_ids : ticket_types?.join(","),
        //     // payment_status_ids : payment_statuses?.join(","),
        //     payment_status_ids : complete_payment_status_ids,
        //     ticket_unique_no : search,
        //     start_date, end_date,
        // });

        // setState(state => ({...state, loading : false, }));
        setErrors(errors);
        dispatch(set_list_loading(false));
        dispatch(set_tickets(tickets, ticket_count));
        // dispatch(set_tickets(ticketResponse?.tickets))
    }

    const paginateChange = (key, value) => {
        // set state
        setState(state => ({...state, [key] : value}));

        let generate_filter = default_filter;
        generate_filter[key] = value;
        const queryString = serviceHelper?.queryString(generate_filter);
        window.history.replaceState({}, '', `${window.location.pathname}?${queryString}`);
    }

    const filterChangeDefaultPage = () => {
        if(filter){
            paginateChange("page", parseInt(filter?.page));
        }
    }

    useEffect(() => {
        if(state?.page){ // prevent duplicates call
            fetchTickets();
        }
    }, [filter, sorter, state?.page]);

    useEffect(() => {
        filterChangeDefaultPage();
    }, [filter,]) // decide to change the page

    return (
        <>
            <Spin spinning={ticketRedux?.list_loading} indicator={<LoadingOutlined />}>
                <Table
                scroll={{x : 400,}}
                dataSource={ticketRedux?.tickets}
                onChange={(pagination, filters, sorter) => {
                    if(sorter){
                        const { field, order, } = sorter;
                        const sequel_order = order === 'ascend' ? 'ASC' : 'DESC';
                        setSorter(sorter => ({...sorter, key : field, order : sequel_order,}));
                    }
                }}
                pagination={{
                    size : state?.display_size,
                    current : (ticketRedux?.count > state?.display_size) ? state?.page : 1, 
                    total : ticketRedux?.count,
                    showSizeChanger: false,
                    onChange : (page) => paginateChange('page', page)
                }}
                className='hub-table bordered'
                columns={[{
                    title : 'Transaction No',
                    dataIndex : 'unique_no',
                    key : 'unique_no',
                    render : (unique_no, row, index) => {
                        const { salt } = row;
                        return (
                            <>
                                <span>{`${unique_no}${salt ?? ''}`}</span>
                            </>
                        )
                    }
                },
                {
                    title : 'Park',
                    dataIndex : 'park_name',
                    key : 'park_name',
                    render : (park_name, row, index) => {
                        const { ticket_type_name, } = row ?? {};
                        return (
                            <>
                                <div>
                                    <span>
                                        {park_name}
                                    </span>
                                </div>
                                <div>
                                    <span style={{fontSize:12, color:'var(--secondary-text-color)' }}>
                                        {ticket_type_name}
                                    </span>
                                </div>
                            </>
                        )
                    }
                },
                {
                    title : 'Visit Date',
                    dataIndex : 'visit_date',
                    key : 'visit_date',
                    // sorting features
                    showSorterTooltip : false,
                    sorter: true,
                    render : (visit_date, row, index) => {
                        const { is_open_date } = row;
                        return (
                            <>
                                <div>
                                    {
                                        is_open_date ?
                                        (
                                            <>
                                                <span style={{color:'var(--secondary-text-color)', fontSize:12,}}>
                                                {"Open Date"}
                                                </span>
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <Tag style={{background:'var(--secondary-button-color)', border:'none', minWidth:100, textAlign:'center',}}>
                                                    <span style={{color:'var(--secondary-text-color)',}}>{visit_date}</span>
                                                </Tag>
                                            </>
                                        )
                                    }
                                </div>
                                
                            </>
                        )
                    }
                },
                {
                    title : 'Purchase Type',
                    dataIndex : 'purchase_type_name',
                    key : 'purchase_type_name',
                    // sorting features
                    showSorterTooltip : false,
                    sorter: true,
                    render : (purchase_type_name, row, index) => {
                        return (
                            <>
                                {purchase_type_name}
                            </>
                        )
                    }
                },
                {
                    title : 'Payment',
                    dataIndex : 'payment_date',
                    key : 'payment_date',
                    render : (text, row, index) => {
                        const { payment_amount, is_payment_complete, currency_code, currency_symbol, } = row ?? {};
                        return (
                            <>
                                <div>
                                    <div>
                                        <Space>
                                            <Tag color='blue' style={{border : 'none', }}>
                                                {currency_code}
                                            </Tag>
                                        </Space>
                                        <span>{`${currency_symbol}${payment_amount}`}</span>
                                    </div>
                                    <div>
                                        <Tag color={(is_payment_complete ? 'green' : 'orange')} style={{border:'none'}}>
                                            {
                                                is_payment_complete ?
                                                <>
                                                    {`Paid: ${moment(text)?.format("YYYY-MM-DD HH:mm:ssa")}`}
                                                </>
                                                :
                                                <>
                                                    Pending Payment
                                                </>
                                            }
                                        </Tag>
                                    
                                    </div>
                                </div>
                            </>
                        )
                    }
                },
                {
                    title : 'Action',
                    dataIndex : 'ticket_id',
                    key : 'ticket_id',
                    render : (text, row, index) => {
                        return (
                            <>
                                <Link to={`/ticket/detail/${text}`}>
                                    <Button>
                                        View
                                    </Button>
                                </Link>
                            </>
                        )
                    }
                }]}
                />
            </Spin>
        </>
    );
}

export default TicketList;