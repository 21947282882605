import React, { useState, useEffect } from 'react';
import promotionService from '../../../services/config/promotion.service';
import { Alert, Avatar, Button, Dropdown, Popconfirm, Space, Spin, Table, Tag } from 'antd';
import { CheckCircleFilled, CloseCircleFilled, DeleteOutlined, EditOutlined, EllipsisOutlined, LineChartOutlined, LoadingOutlined, UserOutlined, } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import globalHelper from '../../../helpers/functions/global.helper';

const PromoList = ({ filter }) => {
    const display_size = 10;
    const [state, setState] = useState({
        loading : false,
        promotion_codes : [],

        // refresh
        refresh : 0,

        // pagination
        page : 0,
        count : null,

        // delete
        delete_loading : false,
        delete_count : 0,
    });

    const fetchPromotionCodes = async () => {
        setState(state => ({...state, loading : true, }));
        const { parks : park_ids, affiliates : affiliate_account_ids } = filter;
        const promotionCodeResponse = await promotionService?.getPromoCode({
            page : state?.page,
            size : display_size,

            park_ids,
            affiliate_account_ids,
        });

        const { promotion_codes } = promotionCodeResponse;
        setState(state => ({...state, loading : false, promotion_codes : promotion_codes?.rows, count : promotion_codes?.count }))
    }

    const fetchPromotionTicketCount = async (is_open, promotion_code_id) => {
        if(is_open && promotion_code_id){
            setState(state => ({...state, delete_loading : true,}))
            const ticketByPromoCodeResponse = await promotionService?.getTicketByPromoCode(promotion_code_id);

            setTimeout(() => {
                const { ticket_user_count, ticket_count, } = ticketByPromoCodeResponse;
                setState(state => ({...state, delete_loading : false, delete_count : ticket_count, }))
            }, 500);
            
        }
    }

    const deletePromotionCode = async (promotion_code_id) => {
        const deletePromotionCodeResponse = await promotionService?.deletePromoCode(promotion_code_id);

        // refresh table after success delete
        setState(state => ({...state, refresh : (state?.refresh + 1)}));
    }

    useEffect(() => {
        fetchPromotionCodes();
    }, [filter, state?.page, state?.refresh])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <Table
                dataSource={state?.promotion_codes}
                scroll={{x : 400,}}
                pagination={{ 
                    size : display_size,
                    showSizeChanger: false,
                    total : state?.count,
                    current : (state?.page + 1),
                    onChange : (page) => {
                        setState(state => ({...state, page : (page - 1)}))
                        // dispatch(set_list_page(page - 1));
                        
                    }
                }}
                // pagination={{ current : state?.page, onChange : (page) => paginateChange('page', page)}}
                className='hub-table bordered'
                columns={[{
                    title : 'Promotion Code',
                    dataIndex : 'promo_code',
                    key : 'promo_code',
                    render : (promo_code, row, index) => {
                        const { name, } = row;
                        return (
                            <>
                                <div>
                                    <div>
                                        <Tag color='orange' style={{border: 'none',}}>
                                            <span>{promo_code}</span>
                                        </Tag>
                                    </div>
                                    <div>
                                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{name}</span>
                                    </div>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Park',
                    dataIndex : 'parks',
                    key : 'parks',
                    render : (parks, row, index) => {
                        const { name, } = parks;
                        return (
                            <>
                                <div>
                                    <span>{name}</span>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Assigned Affiliate',
                    dataIndex : 'affiliate_account',
                    key : 'affiliate_account',
                    render : (affiliate_account, row, index) => {
                        const { full_name, email } = affiliate_account ?? {};
                        const generated_color = email && globalHelper?.generateFixedColorFromString(email);
                        return (
                            <>
                                <div>
                                    <Space>
                                        <div>
                                            <Avatar icon={<UserOutlined />} style={{backgroundColor : generated_color, }}/>
                                        </div>
                                        <div>
                                            {
                                                affiliate_account ?
                                                (
                                                    <>
                                                        <div>
                                                            <span>{full_name}</span>
                                                        </div>
                                                        <div>
                                                            <span style={{ fontSize:12, color : 'var(--secondary-text-color)', }}>{email}</span>
                                                        </div>
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <div>
                                                            <span style={{ fontSize:12, color : 'var(--secondary-text-color)', }}>No affiliate is assigned</span>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            
                                        </div>
                                    </Space>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Discount',
                    dataIndex : 'percentage',
                    key : 'percentage',
                    render : (text, row, index) => {
                        const { percentage, value, is_stack, parks, } = row;
                        return (
                            <>
                                <div>
                                    <div>
                                        <span>
                                            {
                                                percentage && !value ?
                                                (
                                                    <>
                                                        {`${percentage}%`}
                                                    </>
                                                )
                                                :
                                                !percentage && value ?
                                                (
                                                    <>
                                                        {`${parks?.currency?.symbol}${value}`}
                                                    </>
                                                )
                                                :
                                                percentage && value &&
                                                (
                                                    <>
                                                        {`${percentage}% + ${parks?.currency?.symbol}${value}`}
                                                    </>
                                                )
                                            }
                                        </span>
                                    </div>
                                </div>
                            </>
                        )
                    }
                },
                {
                    title : 'Action',
                    dataIndex : 'promotion_code_id',
                    key : 'promotion_code_id',
                    render : (promotion_code_id, row, index) => {
                        const { promo_code, } = row;
                        return (
                            <>
                                <Button.Group>
                                    <Dropdown
                                    placement='bottomRight'
                                    menu={{ items : [{
                                        key : '1',
                                        icon : <EditOutlined />,
                                        label : (
                                            <>
                                                <Link to={`/promo/detail/${promotion_code_id}`}>
                                                    Edit
                                                </Link>
                                            </>
                                        ),
                                    },{
                                        key : '2',
                                        icon : <LineChartOutlined />,
                                        label : (
                                            <>
                                                <Link to={`/promo/dashboard/${promotion_code_id}`}>
                                                    Dashboard
                                                </Link>
                                            </>
                                        )
                                    }]}}
                                    >
                                        <Button
                                        // size='small'
                                        icon={<EllipsisOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                        // onClick={() => openEditModal(account_id)}
                                        />
                                    </Dropdown>

                                    <Popconfirm
                                    onConfirm={() => deletePromotionCode(promotion_code_id)}
                                    title="Delete Promotion Code"
                                    overlayStyle={{ width : 300,}}
                                    description={
                                        <>
                                            {
                                                state?.delete_loading ? 
                                                (
                                                    <>
                                                        <LoadingOutlined />
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        {
                                                            state?.delete_count ?
                                                            (
                                                                <>
                                                                    <Alert type='warning' message="There's transactions applied this promo code" />
                                                                </>
                                                            )
                                                            :
                                                            (
                                                                <>
                                                                    <span style={{color : 'var(--secondary-text-color)'}}>
                                                                        Delete Promotion Code <span style={{fontWeight:500}}>{promo_code}</span> ?
                                                                    </span>
                                                                </>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                            
                                        </>
                                    }
                                    onOpenChange={(is_open) => fetchPromotionTicketCount(is_open, promotion_code_id)}
                                    okButtonProps={{ disabled : state?.delete_count > 0 }}
                                    >
                                        <Button icon={<DeleteOutlined style={{color:'var(--secondary-text-color)'}}/>} />
                                    </Popconfirm>
                                </Button.Group>
                                
                            </>
                        )
                    }
                }
                ]}
                />
            </Spin>
        </>
    );
}

export default PromoList;