import { Col, Divider, Form, Input, InputNumber, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import parkService from '../../../services/config/park.service';

const AddFreeTicketRuleModal = ({ form, park, modal_visible, }) => {
    const [state, setState] = useState({
        loading : false,
        ticket_applicable_users : [],

        selected_park_applicable_ticket_type_id : null,
        selected_free_ticket_applicable_user_id : null,
    })

    const fetchTicketApplicableUser = async () => {
        if(park){
            const selected_park_applicable_ticket_type_id = state?.selected_park_applicable_ticket_type_id;
            if(selected_park_applicable_ticket_type_id){
                setState(state => ({...state, loading : true, }))
                const fetchParkApplicableTicketTypeResponse = await parkService?.getParkApplicableTicketTypeitems({
                    park_applicable_ticket_type_id : selected_park_applicable_ticket_type_id,
                    is_check_purchase_type : false,
                });

                const { park_applicable_ticket_users, } = fetchParkApplicableTicketTypeResponse;
                setState(state => ({...state, loading : false, ticket_applicable_users : park_applicable_ticket_users, }))
            }
        }
    }

    useEffect(() => {
        fetchTicketApplicableUser();
    }, [state?.selected_park_applicable_ticket_type_id])

    return (
        <>
            <Form.Item label={'Name'} name={'name'}>
                <Input />
            </Form.Item>
            <Form.Item label={'Ticket Type'} name={'park_applicable_ticket_type_id'}>
                <Select
                onChange={(park_applicable_ticket_type_id) => setState(state => ({...state, selected_park_applicable_ticket_type_id : park_applicable_ticket_type_id, }))}
                options={park?.ticket_types?.map(x => ({ value : x?.ParkApplicableTicketType?.park_applicable_ticket_type_id, label : x?.name, }))}
                />
            </Form.Item>

            <Divider style={{ margin : 0, marginBottom : 12, }} />
            <div>
                <PageHeaderComponent title={<span style={{ fontSize : 14, }}>Ticket</span>} />
                <Row gutter={[12, 12]}>
                    <Col xs={24} md={12}>
                        <Form.Item label={'Entitled Quantity'} name={'free_quantity'}>
                            <InputNumber min={0} style={{ width : '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item label={'Entitled Ticket'} name={'free_ticket_applicable_user'}>
                            <Select
                            onChange={(ticket_applicable_user_id) => setState(state => ({...state, selected_free_ticket_applicable_user_id : ticket_applicable_user_id, }))}
                            options={state?.ticket_applicable_users?.map(x => ({ value : x?.ticket_applicable_user_id, label : x?.ticket_user_type?.name, }))}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>

            <Divider style={{ margin : 0, marginBottom : 12, }} />
            <div>
                <PageHeaderComponent title={<span style={{ fontSize : 14, }}>Condition For Ticket</span>} />
                <Row gutter={[12, 12]}>
                    <Col xs={24} md={12}>
                        <Form.Item label={'Required Quantity'} name={'required_quantity'}>
                            <InputNumber min={0} style={{ width : '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item label={'Required Ticket'} name={'free_ticket_rule_requires'}>
                            <Select
                            mode='multiple'
                            options={state?.ticket_applicable_users?.map(x => ({ value : x?.ticket_applicable_user_id, label : x?.ticket_user_type?.name, disabled : (x?.ticket_applicable_user_id == state?.selected_free_ticket_applicable_user_id), }))}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default AddFreeTicketRuleModal;