import { Button, Popconfirm, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import ticketTypeService from '../../../../services/config/ticket_type.service';
import { DeleteOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';

const ConfigTicketTypeList = ({ filter, refresh, openEditModal = () => {}, deleteTicketType = () => {}, }) => {
    const [state, setState] = useState({
        loading : false,
        ticket_types : [],
    })

    const fetchTicketTypes = async () => {
        setState(state => ({...state, loading : true, }));
        const { search, } = filter;
        const ticketTypeResponse = await ticketTypeService?.fetchTicketType({
            search,
        });
        const { ticket_types } = ticketTypeResponse;

        setState(state => ({...state, loading : false, ticket_types : ticket_types, }));
    }

    useEffect(() => {
        fetchTicketTypes();
    }, [filter, refresh])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <Table 
                scroll={{ x : 400, }}
                className='hub-table bordered'
                dataSource={state?.ticket_types}
                columns={[{
                    title : 'Name',
                    dataIndex : 'name',
                    key : 'name',
                },{
                    title : 'Action',
                    dataIndex : 'ticket_type_id',
                    key : 'ticket_type_id',
                    width : '10%',
                    render : (ticket_type_id, row, index) => {
                        return (
                            <>
                                <Button.Group>
                                    <Button
                                    onClick={() => openEditModal(ticket_type_id)}
                                    icon={<EditOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                    />

                                    <Popconfirm
                                    onConfirm={() => deleteTicketType(ticket_type_id)}
                                    title="Delete Ticket Type"
                                    overlayStyle={{ width : 250,}}
                                    >
                                        <Button
                                        icon={<DeleteOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                        />
                                    </Popconfirm>
                                </Button.Group>
                            </>
                        )
                    }
                },]}
                />
            </Spin>
        </>
    );
}

export default ConfigTicketTypeList;