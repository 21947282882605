import axios from 'axios';
import serviceHelper from '../../../helpers/functions/service.helper';

async function updateVisitDate(params){
    const updateVisitDateResponse = await axios.post(`integration/kidzoft/updateDate`, params, { withCredentials : true, });
    return updateVisitDateResponse?.data;
}

async function fetchPazzport(pazzport, park_id){
    const pazzportResponse = await axios.get(`integration/kidzoft/park/${park_id}/pazzport/${pazzport}`, { withCredentials : true });
    return pazzportResponse?.data;
}

async function linkPazzport(params){
    const linkPazzportResponse = await axios.post(`integration/kidzoft/linkPazzport`, params, { withCredentials : true, });
    return linkPazzportResponse?.data;
}

async function unlinkPazzport(params){
    const unlinkPazzportResponse = await axios.post(`integration/kidzoft/unlinkPazzport`, params, { withCredentials : true, });
    return unlinkPazzportResponse?.data;
}

export default {
    updateVisitDate,
    fetchPazzport,
    linkPazzport,
    unlinkPazzport,
}