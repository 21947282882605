import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getRoleDetail(query){
    const queryString = serviceHelper.queryString(query);
    const roleDetailResponse = await axios.get(`admin/role/detail/get?${queryString}`, { withCredentials : true });
    return roleDetailResponse?.data;
}

export default {
    getRoleDetail,
}