import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function downloadInvoice(invoice_id){
    const downloadInvoiceResponse = await axios.post(`ticket/invoice/${invoice_id}/download`, {}, { withCredentials : true, responseType : 'arraybuffer', headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf'
    }});
    return downloadInvoiceResponse?.data;
}

async function downloadBatchInvoice(query){
    const queryString = serviceHelper.queryString(query);
    const downloadBatchInvoiceResponse = await axios.post(`ticket/invoice/download_batch?${queryString}`, {}, { withCredentials : true, responseType : 'arraybuffer', headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/zip',
    }});
    return downloadBatchInvoiceResponse?.data;
}

async function generateInvoice(ticket_id){
    const generateInvoiceResponse = await axios.post(`ticket/invoice/tk/${ticket_id}/invoice/generate`, { }, { withCredentials : true, });
    return generateInvoiceResponse?.data;
}

async function invoiceList(query){
    const queryString = serviceHelper.queryString(query);
    const invoiceListResponse = await axios.get(`ticket/invoice/get?${queryString}`, { withCredentials : true, });
    return invoiceListResponse?.data;
}

export default {
    downloadInvoice,
    downloadBatchInvoice,
    generateInvoice,
    invoiceList,
}