import { PlusOutlined } from '@ant-design/icons';
import { Form, Image, Input, Select, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import addOnService from '../../../../services/config/add_on.service';

const { TextArea } = Input;
const AddAddOnModal = ({ form, }) => {
    const [state, setState] = useState({
        loading : false,
        image : null,
        add_on_types : [],
    })

    const acceptable_file_format = ['image/jpeg', 'image/png'];
    const beforeUpload = async (file) => {
        const is_image = acceptable_file_format?.find(x => x == file?.type);

        if(is_image){
            const uploaded_file = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    file.base64 = reader.result.split(',')[1];
                    resolve(file);
                };
                reader.onerror = (error) => reject(error);
            });
    
            const base64Image = `data:image/jpeg;base64,${uploaded_file?.base64}`;
            setState(state => ({...state, image : base64Image}));
        }else{
            // errorModal("Please upload in image format");
        }
        return false;
    }

    const fetchAddOnTypes = async () => {
        setState(state => ({...state, loading : false, }));
        const addOnTypeResponse = await addOnService?.fetchAddOnType();

        const { add_on_types } = addOnTypeResponse;
        setState(state => ({...state, add_on_types : add_on_types, loading : false, }));
    }

    useEffect(() => {
        fetchAddOnTypes();
    }, [])

    return (
        <>
            <Form.Item label={"Image"} name={'image'}>
                <Upload
                name="company_logo"
                listType='picture-card'
                beforeUpload={beforeUpload}
                showUploadList={false}
                style={{width : '100%',}}
                >
                    {
                        state?.image ?
                        (
                            <>
                                <Image preview={false} src={state?.image} />
                            </>
                        )
                        :
                        (
                            <>
                                <div>
                                    <div>
                                        <PlusOutlined />
                                    </div>
                                    <div>Upload</div>
                                </div>
                            </>
                        )
                    }
                </Upload>
            </Form.Item>

            <Form.Item label={"Name"} name={'name'}>
                <Input />
            </Form.Item>

            <Form.Item label={"Description"} name={'description'}>
                <TextArea rows={4} style={{ resize : 'none', }} />
            </Form.Item>

            <Form.Item label={"Add On Type"} name={'add_on_type_id'}>
                <Select
                placeholder={"Select Add On Type"}
                allowClear
                options={state?.add_on_types?.map(x => ({ label : x?.name, value : x?.add_on_type_id, }))}
                />
            </Form.Item>
        </>
    );
}

export default AddAddOnModal;