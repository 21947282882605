import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function authAccount(){
    const authAccountResponse = await axios.get(`admin/account/auth`, { withCredentials : true });
    return authAccountResponse?.data;
}

async function login(params,){
    const loginResponse = await axios.post(`admin/account/login`, params, { withCredentials : true });
    return loginResponse?.data;
}

async function logout(){
    const logoutResponse = await axios.post(`admin/account/logout`, {}, { withCredentials : true, });
    return logoutResponse?.data;
}

export default {
    authAccount,
    login,
    logout,
}