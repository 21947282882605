export const set_tickets = (tickets, count,) => {
    return {
        type : 'SET_TICKETS',
        tickets,
        count,
    };
}

export const refresh_ticket_detail = () => {
    return {
        type : 'REFRESH_TICKET_DETAIL',
    }
}

export const set_list_loading = (loading) => {
    return {
        type : 'SET_LIST_LOADING',
        loading,
    }
}

export const set_editable = (is_editable) => {
    return {
        type : 'SET_EDITABLE',
        is_editable,
    }
}