import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getTicketCountByPark(query){
    const queryString = serviceHelper.queryString(query);
    const ticketCountResponse = await axios.get(`dashboard/park/ticket_count?${queryString}`, { withCredentials : true });
    return ticketCountResponse?.data;
}

async function getTicketGrowth(query){
    const queryString = serviceHelper.queryString(query);
    const ticketGrowthResponse = await axios.get(`dashboard/ticket/growth?${queryString}`, { withCredentials : true });
    return ticketGrowthResponse?.data;
}

export default {
    getTicketCountByPark,
    getTicketGrowth,
}