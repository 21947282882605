import React, { useEffect, useState } from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { Avatar, Badge, Button, Form, Spin, Upload, notification } from 'antd';
import { CloseOutlined, EditOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons';
import globalHelper from '../../../helpers/functions/global.helper';
import Compressor from 'compressorjs';
import customPassService from '../../../services/config/custom_pass.service';

const CustomPassImage = ({ custom_pass, refreshCustomPass, is_upload = true, }) => {
    const [form] = Form.useForm();
    const acceptable_file_format = ['image/jpeg', 'image/png'];
    const { pass_code, } = custom_pass?.pass ?? {};
    const generated_color = pass_code ? globalHelper?.generateFixedColorFromString(pass_code) : 'var(--secondary-text-color)';
    const [state, setState] = useState({
        loading : false,
        file : null,
    })

    const beforeUpload = async (file) => {
        const is_image = acceptable_file_format?.find(x => x == file?.type);

        if(is_image){
            const uploaded_file = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    file.base64 = reader.result.split(',')[1];
                    resolve(file);
                };
                reader.onerror = (error) => reject(error);
            });
    
            const base64Image = `data:image/jpeg;base64,${uploaded_file?.base64}`
            setState(state => ({...state, file : base64Image, }));
        }else{
            // errorModal("Please upload in image format");
        }

        return false;
    }

    const handleChange = async info => {
        // update here
        setState(state => ({...state, loading : true, }))
        const { file } = info;

        // compress image
        let compressed_file = null;
        if(file){
            const compressor = new Promise((resolve, reject) => 
                new Compressor(file, {
                    quality : 0.8,
                    height : 800,
                    width : 600,
                    mimeType : 'image/jpeg',
                    success : resolve,
                    error : reject,
                    // convertSize: 500000,
                })
            )
            compressed_file = await compressor;
        }

        if(is_upload){
            // upload image to server
            const uploadResponse = await customPassService?.uploadCustomPassImage(custom_pass?.custom_pass_id, compressed_file);
            // show notification for success upload
            notification.success({
                message : 'Success uploaded image',
            })
            refreshCustomPass && refreshCustomPass();
        }

        form.setFieldsValue({ image_url : compressed_file, });
        setState(state => ({...state, loading : false, }))
    }

    const removeImage = async () => {
        if(is_upload){
            // upload image to server
            const uploadResponse = await customPassService?.uploadCustomPassImage(custom_pass?.custom_pass_id, null);
            // show notification for success upload
            notification.success({
                message : 'Success removed image',
            })
            refreshCustomPass && refreshCustomPass();
        }
    }

    const initializeImage = () => {
        const file = custom_pass?.pass?.url_image;
        setState(state => ({...state, file : file,}));
    }

    useEffect(() => {
        initializeImage();
    }, [custom_pass])

    return (
        <>
            <div>
                <div>
                    <PageHeaderComponent title={<span style={{fontSize:16,}}>Facial Picture</span>} textAlign={'start'} />
                </div>
                <div style={{textAlign:'start', marginTop:12, }}>
                    <Form
                    form={form}
                    layout='vertical'
                    >
                        <Form.Item>
                            <Badge
                            offset={[-10, 100]}
                            count={
                                <Button.Group>
                                    <Upload
                                    beforeUpload={beforeUpload}
                                    maxCount={1}
                                    onChange={handleChange}
                                    showUploadList={false}
                                    >
                                        
                                        <Button
                                        size='small'
                                        shape='circle'
                                        // disabled={staff?.pass?.parks?.length <= 0}
                                        icon={<EditOutlined style={{color:'var(--secondary-text-color)',}} />}
                                        style={{borderBottomRightRadius : 0, borderTopRightRadius:0,}} 
                                        />
                                        
                                    </Upload>
                                    <Button
                                    onClick={removeImage}
                                    // type='text'
                                    size='small'
                                    shape='circle'
                                    disabled={!state?.file}
                                    icon={<CloseOutlined style={{color:'var(--secondary-text-color)',}} />}
                                    style={{background:'#fff',}}
                                    />
                                        
                                </Button.Group>
                            }>
                                <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                                    <Avatar src={state?.file} size={120} icon={<UserOutlined />} style={{background: generated_color, }} />
                                </Spin>
                            </Badge>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default CustomPassImage;