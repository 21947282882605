import { Image } from 'antd';
import React from 'react';

const IconWrapper = ({icon}) => {
    return (
        <>
            <div>
                <Image preview={false} height={40} width={40} src={`/cicons/${icon}`} />
            </div>
        
        </>
    );
}

export default IconWrapper;