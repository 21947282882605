import { CheckCircleFilled, CloseCircleFilled, CopyFilled } from '@ant-design/icons';
import { Button, Col, Divider, QRCode, Row, Space, Tag, notification } from 'antd';
import moment from 'moment';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import IconWrapper from '../../../helpers/components/icon_wrapper';

const SpecialLinkGenerated = ({special_link}) => {
    const is_used = special_link?.is_used;
    const is_expired = moment()?.isAfter(moment(special_link?.expired_datetime));

    const onCopy = () => {
        notification.success({
            message : 'Copied!',
            description : 'Special Link Copied Successfully',
            placement : 'bottomLeft',
        })
    }
    return (
        <>
            <div>
                <div style={{padding:12, border:'none', borderRadius:8,}}>
                    <div style={{display:'flex', justifyContent:'center',}}>
                        <QRCode value={special_link?.generated_url} />
                    </div>
                    <div style={{textAlign:'center', marginTop:24,}}>
                        <Space direction='vertical'>
                            <div>
                                <a href={special_link?.generated_url} target='_blank'>
                                    <Tag style={{border : 'none',}}>
                                        <Space>
                                            <div>
                                                <span style={{color:'var(--main-color)'}}>
                                                    {special_link?.unique_code}
                                                </span>
                                            </div>
                                            <div>
                                                {/* Special Link Status */}
                                                {
                                                    !(is_used || is_expired) ?
                                                    (
                                                        <>
                                                            <CheckCircleFilled style={{color:'#95de64'}} />
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <CloseCircleFilled style={{color:'#ff7875',}} />
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </Space>
                                    </Tag>
                                </a>
                            </div>
                            <div>
                                <CopyToClipboard text={special_link?.generated_url} onCopy={onCopy}>
                                    <Button icon={<CopyFilled />} size='small' style={{color : 'var(--secondary-text-color)'}}>
                                        Copy To Clipboard
                                    </Button>
                                </CopyToClipboard>
                            </div>
                        </Space>
                    </div>
                </div>


                {/* Ticket detail if the special link is used */}
                {
                    special_link?.is_used &&
                    (
                        <>
                            <Divider />
                            <div style={{marginTop:12,}}>
                                <div>
                                    <div>
                                        <Space>
                                            <IconWrapper icon={'ticket.svg'} />
                                            <div>
                                                <span style={{ fontWeight: 500 }}>Tickets</span>
                                            </div>
                                        </Space>
                                    </div>
                                    <div style={{marginTop:8,}}>
                                        
                                        <Row>
                                            <Col span={12}>
                                                <div>
                                                    <div>
                                                        <span style={{color:'#595959', fontWeight:500,}}>
                                                            Transaction Number
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span style={{color:'var(--secondary-text-color)'}}>
                                                            {special_link?.ticket?.unique_no}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                <div>
                                                    <div>
                                                        <span style={{color:'#595959', fontWeight:500,}}>
                                                            Park
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span style={{color:'var(--secondary-text-color)'}}>
                                                            {special_link?.ticket?.park?.name}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                <div>
                                                    <div>
                                                        <span style={{color:'#595959', fontWeight:500,}}>
                                                            Ticket Type
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span style={{color:'var(--secondary-text-color)'}}>
                                                            {special_link?.ticket?.ticket_type?.name}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                <div>
                                                    <div>
                                                        <span style={{color:'#595959', fontWeight:500,}}>
                                                            Visit Date
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span style={{color:'var(--secondary-text-color)'}}>
                                                            {
                                                                special_link?.ticket?.is_open_date ?
                                                                (
                                                                    <>
                                                                        Open Date
                                                                    </>
                                                                )
                                                                :
                                                                (
                                                                    <>
                                                                        {special_link?.ticket?.visit_date}
                                                                    </>
                                                                )
                                                            }
                                                            
                                                        </span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>


                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
                
            </div>
        </>
    );
}

export default SpecialLinkGenerated;