import { Card, Col, Empty, Modal, Row } from 'antd';
import React, { useMemo, useState } from 'react';
import InvoiceThumbnail from '../../invoice/components/invoice_thumbnail';
import { FilePdfFilled } from '@ant-design/icons';
import ViewInvoice from '../../invoice/components/view_invoice';

const InvoiceList = ({ ticket, }) => {
    const [state, setState] = useState({
        open_invoice_modal : null,
    })

    const invoices = useMemo(() => {
        return ticket?.invoices;
    }, [ticket])

    const invoice_view_running_number = useMemo(() => {
        return ticket?.running_number;
    }, [state?.open_invoice_modal])

    const openPdf = async (invoice_id) => {
        setState(state => ({...state, open_invoice_modal : invoice_id, }));
    }

    return (
        <>
            {
                invoices?.length > 0 ?
                (
                    <>
                        <Row gutter={[12, 12]}>
                            {
                                invoices?.map((invoice, index) => {
                                    const { invoice_id, invoice_file, } = invoice;

                                    // Split the URL by '/'
                                    const parts = invoice_file.split('/');
                                    // Get the last part of the URL
                                    const invoice_file_name = parts[parts.length - 1];

                                    return (
                                        <Col key={index} span={4} xs={12} sm={8} md={6} lg={6} xl={4}>
                                            <Card
                                            className='invoice-card'
                                            onClick={() => openPdf(invoice_id)}
                                            styles={{ body : { paddingInline : 12, paddingBlock : 6, cursor : 'pointer', } }}
                                            >
                                                <span style={{ color : 'var(--secondary-text-color)', }}>{invoice_file_name}</span>
                                                {/* <InvoiceThumbnail invoice_id={invoice_id} /> */}
                                            </Card>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </>
                )
                :
                (
                    <>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </>
                )
            }

            <Modal
            open={state?.open_invoice_modal}
            onCancel={() => setState(state => ({...state, open_invoice_modal : null, }))}
            title={invoice_view_running_number}
            width={800}
            footer={null}
            style={{ top : 20, }}
            >
                <ViewInvoice invoice_id={state?.open_invoice_modal} running_number={ticket?.running_number} />
            </Modal>
        </>
    );
}

export default InvoiceList;