import React, { useEffect, useState } from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { Avatar, Button, Checkbox, Col, Divider, Form, Input, InputNumber, Row, Select, Space, Spin, Switch, Tag } from 'antd';
import { AppstoreOutlined, LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';
import parkService from '../../../services/config/park.service';
import otherService from '../../../services/config/other.service';

const { TextArea } = Input;
const EditTicketGroupModal = ({ form, ticket_type, ticket_applicable_user_id, modal_visible, }) => {
    const [state, setState] = useState({
        loading : false,
        ticket_applicable_user : null,
        purchase_types : [],
    })

    const fetchTicketApplicableUser = async () => {
        const ticketApplicableUserResponse = await parkService?.getTicketApplicableUser({
            ticket_applicable_user_id,
        }).catch(err => setState(state => ({...state, loading : false,})));

        // set form
        const { ticket_applicable_users } = ticketApplicableUserResponse ?? {};
        const ticket_applicable_user = ticket_applicable_users?.[0];

        return ticket_applicable_user;
    }

    const fetchPurchaseTypes = async () => {
        const purchaseTypeResponse = await otherService?.getPurchaseTypes();
        const { purchase_types, } = purchaseTypeResponse;
        return purchase_types;
    }

    const initialize = async () => {
        setState(state => ({...state, loading : true, }));
        const ticket_applicable_user = await fetchTicketApplicableUser();
        const purchase_types_arr = await fetchPurchaseTypes();

        const { descriptions, price, min_purchase, is_fixed_price, purchase_types, } = ticket_applicable_user ?? {};
        const purchase_type_ticket_applicable_users = (purchase_types?.filter(x => x?.PurchaseTypeTicketApplicableUser?.is_purchaseable)?.map(x => x?.purchase_type_id));
        form.setFieldsValue({ descriptions, price, min_purchase, is_fixed_price, purchase_type_ticket_applicable_users, });

        setState(state => ({...state, loading : false, purchase_types : purchase_types_arr, ticket_applicable_user : ticket_applicable_user, }))
    }

    useEffect(() => {
        initialize();
    }, [ticket_applicable_user_id, modal_visible])
    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <div>
                    <Divider orientation='left' orientationMargin="0"><PageHeaderComponent title={<span style={{fontSize:14,}}>Ticket Detail</span>} textAlign={'start'} /></Divider>
                    <div>
                        <Form.Item label={"Ticket"} name={"ticket_user_type_id"}>
                            <Tag color='var(--secondary-button-color)' style={{ border : 'none', fontWeight:500, color : 'var(--secondary-text-color)', }}>
                                {(state?.ticket_applicable_user?.ticket_user_type?.name)}
                            </Tag>
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item label="Ticket Type">
                            <Space>
                                <div>
                                    <Avatar icon={<AppstoreOutlined />} shape='square' size='small' 
                                    src={ticket_type?.ParkApplicableTicketType?.image}
                                    />
                                </div>
                                <div>
                                    <span>{ticket_type?.name}</span>
                                </div>
                            </Space>
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item label={"Description"} name={"descriptions"}>
                            <TextArea placeholder='Enter descriptions' rows={4} style={{ resize : 'none', }} />
                        </Form.Item>
                    </div>
                </div>
                <div>
                    <Divider orientation='left' orientationMargin="0"><PageHeaderComponent title={<span style={{fontSize:14,}}>Purchase Detail</span>} textAlign={'start'} /></Divider>
                    <div>
                        <Row gutter={12}>
                            <Col xs={24} md={12} span={12}>
                                <Form.Item label={"Price"} name={"price"}>
                                    <InputNumber min={0} addonBefore={"MYR"} style={{ width : '100%', }} precision={2} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} span={12}>
                                <Form.Item label={"Purchase Types"} name={"purchase_type_ticket_applicable_users"}>
                                    <Checkbox.Group options={state?.purchase_types?.map((purchase_type, index) => {
                                        const { purchase_type_id, name, } = purchase_type ?? {};
                                        return {
                                            label : name,
                                            value : purchase_type_id,
                                        }
                                    })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} span={12}>
                                <Form.Item label={"Minimum Purchase"} name={"min_purchase"}>
                                    <InputNumber min={0} style={{ width : '100%', }} precision={0} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} span={12}>
                                <Form.Item extra={(
                                    <span style={{fontSize:12, color : 'var(--secondary-text-color)',}}>If this is switch on, advance and peak day price discount will be ignored</span>
                                )} label={"Is Fixed Price"} name={"is_fixed_price"}>
                                    <Switch />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
        </>
    );
}

export default EditTicketGroupModal;