import React, { useEffect, useState } from 'react';
import parkService from '../../../services/config/park.service';
import { Avatar, Col, Divider, Form, InputNumber, Row, Space, Spin } from 'antd';
import { AppstoreOutlined, LoadingOutlined } from '@ant-design/icons';
import PageHeaderComponent from '../../../helpers/components/page_header.component';

const EditTicketAddOnModal = ({ form, ticket_type, add_on_setup_id, modal_visible, }) => {
    const [state, setState] = useState({
        loading : false,
        add_on_setup : null,
    })

    const fetchAddOnSetup = async () => {
        setState(state => ({...state, loading : false, }));
        const { park_applicable_ticket_type_id } = ticket_type?.ParkApplicableTicketType ?? {};
        const addOnSetupResponse = await parkService?.getAddOn({
            park_applicable_ticket_type_id : park_applicable_ticket_type_id,
            add_on_setup_id : add_on_setup_id,
        })

        const { add_ons } = addOnSetupResponse;
        const add_on = add_ons?.[0];
        const { price, advance_day, } = add_on ?? {};
        form.setFieldsValue({ price, advance_day, });

        setState(state => ({...state, loading : false, add_on_setup : add_on, }));
    }

    useEffect(() => {
        fetchAddOnSetup();
    }, [add_on_setup_id, modal_visible])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <div>
                    <Divider orientation='left' orientationMargin="0"><PageHeaderComponent title={<span style={{fontSize:14,}}>Add On Detail</span>} textAlign={'start'} /></Divider>
                    <div>
                        <Form.Item label={"Add On"}>
                            <Space>
                                <div>
                                    <Avatar icon={<AppstoreOutlined />} shape='square' size='small' src={state?.add_on_setup?.add_on?.image} />
                                </div>
                                <div>
                                    <span>{state?.add_on_setup?.add_on?.name}</span>
                                </div>
                            </Space>
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item label="Ticket Type">
                            <Space>
                                <div>
                                    <Avatar icon={<AppstoreOutlined />} shape='square' size='small' src={ticket_type?.ParkApplicableTicketType?.image} />
                                </div>
                                <div>
                                    <span>{ticket_type?.name}</span>
                                </div>
                            </Space>
                        </Form.Item>
                    </div>
                    <div>
                        <Divider orientation='left' orientationMargin="0"><PageHeaderComponent title={<span style={{fontSize:14,}}>Purchase Detail</span>} textAlign={'start'} /></Divider>
                        <Row gutter={12}>
                            <Col xs={24} md={12} span={12}>
                                <Form.Item label={"Price"} name={"price"}>
                                    <InputNumber min={0} addonBefore={"MYR"} style={{ width : '100%', }} precision={2} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} span={12}>
                                <Form.Item label={"Advance Day"} name={"advance_day"}>
                                    <InputNumber min={0} style={{ width : '100%', }} precision={0} />
                                </Form.Item>
                            </Col>

                            {/* <Col span={12}>
                                <Form.Item label={"Date Range"} name={"date_range"}>
                                    <InputNumber style={{ width : '100%', }} precision={0} />
                                </Form.Item>
                            </Col> */}
                        </Row>
                    </div>
                </div>
            </Spin>
        </>
    );
}

export default EditTicketAddOnModal;