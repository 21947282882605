import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Select, Space, notification } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import ticketService from '../../../services/ticket/ticket.service';
import { useDispatch, useSelector } from 'react-redux';
import { refresh_ticket_detail } from '../../../actions/ticket.action';
import dayjs from 'dayjs';
import moment from 'moment';
import integrationService from '../../../services/integration/integration.service';
import kidzoftService from '../../../services/integration/kidzoft/kidzoft.service';

const EditTicketDetail = ({ ticket_id, message_name, name, value, type, park_id, selections, select_value, feature_code, }) => {
    const dispatch = useDispatch();
    const accountRedux = useSelector(state => state.account);
    const [form] = Form.useForm();
    const [state, setState] = useState({
        edit_mode : false,
        save_loading : false,
    })

    const setEditMode = (is_edit_mode) => {
        setState(state => ({...state, edit_mode : is_edit_mode}))
    }

    const initialize = () => {
        let val;
        switch(type){
            case 'date' :
                val = dayjs(value);
                break;
            case 'text' :
                val = value;
                break;
            case 'select':
                val = select_value;
                break;
            default :
                break;
        }
        // const val = type == 'date' ? dayjs(value) : value;
        form.setFieldsValue({[name] : val, });
    }

    const isFeatures = useMemo(() => {
        let is_enabled = true;
        if(feature_code){
            const feature = accountRedux?.role?.features?.find(x => x.code === feature_code && x?.RoleFeature.enabled === true);
            is_enabled = Boolean(feature);
        }
        
        return is_enabled;
    }, [feature_code])

    const updateTicket = async () => {
        setState(state => ({...state, save_loading : true, }));
        const form_item = form.getFieldsValue();
        let value;
        switch(type){
            case 'date' :
                value = dayjs(form_item[name])?.format("YYYY-MM-DD");
                break;
            case 'text' :
                value = form_item[name];
                break;
            case 'select':
                value = form_item[name];
                break;
            default :
                break;
        }
        // const value = type == 'date' ? dayjs(form_item[name])?.format("YYYY-MM-DD") : form_item[name];
        const updateTicketResponse = await ticketService?.updateTicket(ticket_id, { [name] : value, });

        // integration
        if(name === 'visit_date'){
            await integrateUpdateVisitDate({
                visit_date : value
            });
        }

        setTimeout(() => {
            setState(state => ({...state, save_loading : false, edit_mode : false, }));
            dispatch(refresh_ticket_detail());
            notification?.success({
                message : 'Ticket Updated',
                description : (
                    <>
                        <div>
                            <span>{message_name} have been updated to {value}</span>
                        </div>
                    </>
                )
            })
        }, 500);
    }

    const integrateUpdateVisitDate = async ({ visit_date, }) => {
        let result;
        const integrationResponse = await integrationService?.getParkIntegrations(park_id, {
            condition : 'UPDATE_DATE',
        });

        const { integrations } = integrationResponse;
        const integration = integrations?.length > 0 ? integrations[0] : null;
        // let result;
        if(integration && visit_date){
            switch(integration?.api_type?.fn_code){
                case 'KIDZOFT_ACT' :
                    result = await kidzoftService.updateVisitDate({
                        ticket_id, visit_date,
                    });
                    break;
                default :
                    result = null;
            }
        }
    }

    useEffect(() => {
        initialize();
    }, [value])

    return (
        <>
            <div>
                <Form
                form={form}
                onFinish={updateTicket}
                >
                {
                    value &&
                    (
                        <>
                            {
                                state?.edit_mode ?
                                (
                                    <>
                                        <Space>
                                            <div>
                                                <Form.Item name={name}>
                                                    {
                                                        type == "date" ?
                                                        (
                                                            <DatePicker
                                                            clearIcon={false}
                                                            format={"YYYY-MM-DD"} 
                                                            size='small' 
                                                            />
                                                            
                                                        )
                                                        : type == "text" ?
                                                        (
                                                            <Input size='small' />
                                                        )
                                                        : type == 'select' &&
                                                        (
                                                            <Select
                                                            options={selections ?? []}
                                                            size='small'
                                                            style={{ minWidth : 150, }}
                                                            />
                                                        )
                                                    }
                                                    
                                                </Form.Item>
                                            </div>
                                            <div>
                                                <Form.Item>
                                                    <Space>
                                                        <div>
                                                            <Button htmlType='submit' loading={state?.save_loading} size={'small'} icon={<CheckOutlined style={{color:'var(--secondary-text-color)'}} />} />
                                                        </div>
                                                        <div>
                                                            <Button onClick={() => setEditMode(false)} size={'small'} icon={<CloseOutlined style={{color:'var(--secondary-text-color)'}} />} />
                                                        </div>
                                                    </Space>
                                                </Form.Item>
                                            </div>
                                        </Space>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <Space>
                                            <div>
                                                <span style={{color:'var(--secondary-text-color)'}}>
                                                    {value}
                                                </span>
                                            </div>
                                            {
                                                isFeatures && (
                                                    <>
                                                        <div>
                                                            <Button onClick={() => setEditMode(true)} size={'small'} icon={<EditOutlined style={{color:'#8c8c8c'}} />} />
                                                        </div>
                                                    </>
                                                )
                                            }
                                            
                                        </Space>
                                    </>
                                )
                            }
                        </>
                    )
                }
                </Form>
            </div>
        </>
    );
}

export default EditTicketDetail;