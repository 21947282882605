import { AppstoreOutlined } from '@ant-design/icons';
import { Avatar, Col, Divider, Form, Input, InputNumber, Row, Space } from 'antd';
import React from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';

const AddAdvanceDayDiscount = ({ form, ticket_type, }) => {
    return (
        <>
            <div>
                <Form.Item label={'Name'} name={'name'}>
                    <Input />
                </Form.Item>
            </div>
            <div>
                <Form.Item label={'Ticket Type'}>
                    <Space>
                        <div>
                            <Avatar icon={<AppstoreOutlined />} shape='square' size='small' src={ticket_type?.ParkApplicableTicketType?.image} />
                        </div>
                        <div>
                            <span>{ticket_type?.name}</span>
                        </div>
                    </Space>
                </Form.Item>
            </div>
            <div>
                <Divider orientation='left' orientationMargin="0"><PageHeaderComponent title={<span style={{fontSize:14,}}>Advance Day</span>} textAlign={'start'} /></Divider>
                <Row gutter={12}>
                    <Col xs={24} md={12} span={12}>
                        <Form.Item label={'Min Advance Day'} name={'min_advance_day'}>
                            <InputNumber min={0} precision={0} style={{ width : '100%', }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} span={12}>
                        <Form.Item label={'Max Advance Day'} name={'max_advance_day'}>
                            <InputNumber min={0} precision={0} style={{ width : '100%', }} />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
            <div>
                <Divider orientation='left' orientationMargin="0"><PageHeaderComponent title={<span style={{fontSize:14,}}>Discount Amount</span>} textAlign={'start'} /></Divider>
                <Row gutter={12}>
                    <Col xs={24} md={12} span={12}>
                        <Form.Item label={'Percentage'} name={'percentage'}>
                            <InputNumber min={0} precision={2} style={{ width : '100%', }} />
                        </Form.Item>
                    </Col>
                    {/* <Col xs={24} md={12} span={12}>
                        <Form.Item label={'Value'} name={'value'}>
                            <InputNumber precision={2} style={{ width : '100%', }} />
                        </Form.Item>
                    </Col> */}
                </Row>
            </div>
        </>
    );
}

export default AddAdvanceDayDiscount;