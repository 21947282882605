import { Avatar, Button, Popconfirm, Space, Spin, Table, Tag, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import staffService from '../../../services/config/staff.service';
import globalHelper from '../../../helpers/functions/global.helper';
import { DeleteOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import serviceHelper from '../../../helpers/functions/service.helper';

const StaffList = ({ filter, }) => {
    const [state, setState] = useState({
        loading : false,
        staffs : [],
        count : 0,
        refresh : 0,

        // pagination
        display_size : 10,
        page : filter?.page,

        // delete
        delete_loading : false,
    })

    const deleteStaff = async (staff_id, name) => {
        setState(state => ({...state, delete_loading : true, }));
        const deleteStaffResponse = await staffService?.deleteStaff(staff_id);

        notification.success({
            message : 'Delete successful',
            description : name,
        })

        setState(state => ({...state, delete_loading : false, refresh : (state?.refresh + 1)}));
    }

    const paginateChange = (key, value) => {
        setState(state => ({...state, [key] : value}));
        let generate_filter = filter;
        generate_filter[key] = value;
        const queryString = serviceHelper?.queryString(generate_filter);
        window.history.replaceState({}, '', `${window.location.pathname}?${queryString}`);
    }

    const fetchStaff = async () => {
        setState(state => ({...state, loading : true, }));
        const { parks, search, status, } = filter;
        const staffResponse = await staffService?.getStaffListing({
            parks,
            search,

            // pagination
            page : (state?.page - 1),
            size : state?.display_size,
            is_active : status,
        });

        const { staffs } = staffResponse;
        setState(state => ({...state, loading : false, staffs : staffs?.rows, count : staffs?.count }));
    }

    useEffect(() => {
        fetchStaff();
    }, [filter, state?.page, state?.refresh])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <Table
                    dataSource={state?.staffs}
                    scroll={{x : 400,}}
                    pagination={{ 
                        size : state?.display_size,
                        showSizeChanger: false,
                        total : state?.count,
                        current : parseInt(state?.page),
                        onChange : (page) => paginateChange('page', page),
                    }}
                    className='hub-table bordered'
                    columns={[{
                        title : 'Staff',
                        dataIndex : 'name',
                        key : 'name',
                        width : '15%',
                        render : (name, row, index) => {
                            const { url_image, pass_code, } = row;
                            const generated_color = pass_code ? globalHelper?.generateFixedColorFromString(pass_code) : 'var(--secondary-text-color)';
                            
                            return (
                                <>
                                    <Space>
                                        <div>
                                            <Avatar src={url_image} style={{ backgroundColor: generated_color }} icon={<UserOutlined />} />
                                        </div>
                                        <div>
                                            <div>
                                                <span>{name}</span>
                                            </div>
                                            <div>
                                                <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{pass_code}</span>
                                            </div>
                                        </div>
                                    </Space>
                                </>
                            )
                        }
                    },{
                        title : 'Family Members',
                        dataIndex : 'staff_associate_count',
                        key : 'staff_associate_count',
                        width : '15%',
                        render : (staff_associate_count, row, index) => {
                            return (
                                <>
                                    <div
                                    style={{
                                        background : 'var(--secondary-button-color)',
                                        borderRadius:'50%',
                                        width : 30,
                                        height : 30,
                                        display: 'flex',
                                        justifyContent : 'center',
                                        alignItems : 'center',
                                    }}>
                                        <span style={{color:'var(--secondary-text-color)', fontWeight:500,}}>{staff_associate_count}</span>
                                    </div>
                                </>
                            )
                        }
                    },{
                        title : 'Parks',
                        dataIndex : 'parks',
                        key : 'parks',
                        render : (parks, row, index) => {
                            return (
                                <>
                                    <Space key={index} wrap>
                                    {
                                        parks?.map((park, index) => {
                                            const { name } = park ?? {};
                                            return (
                                                <Tag style={{background : 'var(--secondary-button-color)', color : 'var(--secondary-text-color)', border:'none',}}>
                                                    {name}
                                                </Tag>
                                            )
                                        })
                                    }
                                    </Space>
                                </>
                            )
                        }
                    },{
                        title : 'Creation Date',
                        dataIndex : 'created_at',
                        key : 'created_at',
                        render : (created_at, row, index) => {
                            return (
                                <>
                                    {moment(created_at)?.format("YYYY-MM-DD HH:mm:ssa")}
                                </>
                            )
                        }
                    },{
                        title : 'Status',
                        dataIndex : 'is_active',
                        key : 'is_active',
                        render : (is_active, row, index) => {
                            return (
                                <>
                                    <Tag color={is_active ? 'green' : 'orange'} style={{border: 'none',}}>
                                        {is_active ? 'Active' : 'Inactive'}
                                    </Tag>
                                </>
                            )
                        }
                    },{
                        title : 'Action',
                        dataIndex : 'staff',
                        key : 'staff',
                        width : '10%',
                        // fixed : 'right',
                        render : (staff, row, index) => {
                            const { name, } = row;
                            const { staff_id, } = staff;
                            return (
                                <>
                                    <Button.Group>
                                        <Link to={`/staff/detail/${staff_id}`}>
                                            <Button style={{borderTopRightRadius : 0, borderBottomRightRadius : 0,}}>
                                                View
                                            </Button>
                                        </Link>
                                        <Popconfirm
                                        onConfirm={() => deleteStaff(staff_id, name)}
                                        title={`Are you confirm to delete ${name}`}
                                        okButtonProps={{ loading : state?.delete_loading, }}
                                        >
                                            <Button icon={<DeleteOutlined style={{color:'var(--secondary-text-color)'}}/>} />
                                        </Popconfirm>
                                    </Button.Group>
                                </>
                            )
                        }
                    },]}
                />
            </Spin>
        </>
    );
}

export default StaffList;