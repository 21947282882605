import React from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import { useParams } from 'react-router-dom';
import TicketDetail from './components/ticket_detail';
import useAccess from '../../helpers/hooks/useAccess';
import { useDispatch } from 'react-redux';
import AccessContext from '../../helpers/context/access.context';

const OtaDetail = () => {
    const dispatch = useDispatch();
    const { ticket_id } = useParams();
    const access = useAccess("/ticket");
    
    return (
        <>
            <ContainerComponent span={20} top={20} bottom={20}>
                <AccessContext.Provider value={access}>
                    <TicketDetail ticket_id={ticket_id} />
                </AccessContext.Provider>
            </ContainerComponent>
        </>
    );
}

export default OtaDetail;