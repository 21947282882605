import React, { useEffect, useMemo, useState } from 'react';
import parkService from '../../../services/config/park.service';
import { Avatar, Button, Card, Col, Empty, Form, Modal, Popconfirm, Row, Space, Spin, Table, notification } from 'antd';
import { AppstoreOutlined, DeleteOutlined, EditOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import numberHelper from '../../../helpers/functions/number.helper';
import AddTicketAddOnModal from './add_ticket_add_on_modal';
import EditTicketAddOnModal from './edit_ticket_add_on_modal';

const AddOnSetup = ({ park, }) => {
    const [save_form] = Form.useForm();
    const [edit_form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        save_loading : false,
        update_loading : false,
        add_on_ticket_types : [],

        create_modal : null,
        edit_modal : null,

        refresh : 0,
        modal : 0,
    });

    const configured_ticket_types = useMemo(() => {
        const { park_applicable_ticket_type_id } = state?.create_modal?.ParkApplicableTicketType ?? {};
        const configured_add_on_setups = state?.add_on_ticket_types?.find(x => x?.ParkApplicableTicketType?.park_applicable_ticket_type_id === park_applicable_ticket_type_id);
        return configured_add_on_setups;
    }, [state?.create_modal]);

    const get_ticket_type_by_add_on_setup_id = useMemo(() => {
        const add_on_setup_id = state?.edit_modal;
        let add_on_setup;
        for(const ticket_type of state?.add_on_ticket_types){
            add_on_setup = ticket_type?.add_on_setups?.find(x => x?.add_on_setup_id === add_on_setup_id);
            if(add_on_setup){
                break;
            }
        }

        const { park_applicable_ticket_type_id } = add_on_setup ?? {};
        const configured_ticket_type = state?.add_on_ticket_types?.find(x => x?.ParkApplicableTicketType?.park_applicable_ticket_type_id === park_applicable_ticket_type_id);

        return configured_ticket_type;
    })

    const saveAddOnSetup = async () => {
        setState(state => ({...state, save_loading : true,}));
        const { park_applicable_ticket_type_id } = state?.create_modal?.ParkApplicableTicketType;
        const { add_on_id, advance_day, price, } = save_form?.getFieldsValue();
        const saveAddOnSetupResponse = await parkService?.saveAddOnSetup(park_applicable_ticket_type_id, {
            add_on_id, advance_day, price,
        }).catch(err => setState(state => ({...state, save_loading : false, })));

        const { save_add_on_setup } = saveAddOnSetupResponse ?? {};
        if(save_add_on_setup){
            notification?.success({
                message : 'Add On Added',
            })
            setState(state => ({...state, save_loading : false, refresh : (state?.refresh + 1), create_modal : false,}));
            save_form?.resetFields();
        }
    }

    const updateAddOnSetup = async () => {
        setState(state => ({...state, update_loading : true, }));
        const add_on_setup_id = state?.edit_modal;
        const { price, advance_day,} = (edit_form?.getFieldsValue());

        const updateAddOnSetupResponse = await parkService?.updateAddOnSetup(add_on_setup_id, {
            price, advance_day,
        })

        notification?.success({
            message : 'Add On Updated',
        })
        setState(state => ({...state, update_loading : false, refresh : (state?. refresh + 1), edit_modal : false,}));
    }

    const deleteAddOnSetup = async (add_on_setup_id) => {
        const deleteAddOnSetupResponse = await parkService?.deleteAddOnSetup(add_on_setup_id);

        notification?.success({
            message : 'Add On Deleted',
        })
        setState(state => ({...state, refresh : (state?.refresh + 1)}));
    }

    const fetchAddOnSetupByTicketType = async () => {
        let add_on_ticket_types = [];
        const { ticket_types } = park ?? {};

        for(const ticket_type of (ticket_types ?? [])){
            const { park_applicable_ticket_type_id, name, } = ticket_type?.ParkApplicableTicketType ?? {};
            const AddOnSetupResponse = await parkService?.getAddOn({
                park_applicable_ticket_type_id,
            });
            const { add_ons : add_on_setups, } = AddOnSetupResponse;

            add_on_ticket_types.push({...ticket_type, add_on_setups,});
        }

        return add_on_ticket_types;
    }

    const initialize = async () => {
        setState(state => ({...state, loading : true, }));
        const add_on_ticket_types = await fetchAddOnSetupByTicketType();
        setState(state => ({...state, loading : false, add_on_ticket_types,}));
    }

    useEffect(() => {
        initialize();
    }, [park?.park_id, state?.refresh])

    return (
        <>
            <div>
                <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                    <Space size={20} direction='vertical' style={{ width : '100%', }}>
                    {
                        state?.add_on_ticket_types?.map((add_on_ticket_type, index) => {
                            const { name, image, ParkApplicableTicketType, add_on_setups } = add_on_ticket_type;
                            return (
                                <div key={index}>
                                    <Card
                                    className='card-shadow'
                                    styles={{ body : { padding : 12, } }}
                                    style={{ border: 'none', }}
                                    >
                                        <div>
                                            <div style={{ textAlign : 'start', display : 'flex', alignContent : 'center', }}>
                                                <div>
                                                    <Space>
                                                        <div>
                                                            <Avatar icon={<AppstoreOutlined />} shape='square' size='small' src={ParkApplicableTicketType?.image} />
                                                        </div>
                                                        <div>
                                                            <span>{name}</span>
                                                        </div>
                                                    </Space>
                                                </div>
                                                <div style={{ marginLeft : 'auto', }}>
                                                    <Button icon={<PlusOutlined />} onClick={() => setState(state => ({...state, create_modal : add_on_ticket_type, }))}>Add On</Button>
                                                </div>
                                            </div>

                                            <div style={{ marginTop : 12, }}>
                                                {
                                                    add_on_setups?.length > 0 ?
                                                    (
                                                        <>
                                                            <Table
                                                            size='small'
                                                            pagination={false}
                                                            scroll={{ x : 1000, }}
                                                            className='hub-table bordered'
                                                            dataSource={add_on_setups}
                                                            columns={[{
                                                                title : 'Add On',
                                                                dataIndex : 'add_on',
                                                                key : 'add_on',
                                                                width : 600,
                                                                render : (add_on, row, index) => {
                                                                    const { name, image, description } = add_on ?? {};
                                                                    return (
                                                                        <>
                                                                            <div>
                                                                                <Space wrap align='center'>
                                                                                    {
                                                                                        image && (
                                                                                            <>
                                                                                                <div>
                                                                                                    <Avatar shape='square' src={image} />
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                    
                                                                                    <div>
                                                                                        <span>{name}</span>
                                                                                    </div>
                                                                                </Space>
                                                                            </div>
                
                                                                            <div>
                                                                                <span style={{color : 'var(--secondary-text-color)', fontSize : 12,}}>{description}</span>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                            },{
                                                                title : 'Price',
                                                                dataIndex : 'price',
                                                                key : 'price',
                                                                render : (price, row, index) => {
                                                                    return (
                                                                        <>
                                                                            <span>{numberHelper?.commaNumber(price)}</span>
                                                                        </>
                                                                    )
                                                                }
                                                            },{
                                                                title : 'Advance Day',
                                                                dataIndex : 'advance_day',
                                                                key : 'advance_day',
                                                            },{
                                                                title : 'Action',
                                                                dataIndex : 'add_on_setup_id',
                                                                key : 'add_on_setup_id',
                                                                fixed : 'right',
                                                                width : 100,
                                                                render : (add_on_setup_id, row, index) => {
                                                                    const { add_on } = row ?? {};
                                                                    return (
                                                                        <>
                                                                            <Button.Group>
                                                                                <Button
                                                                                size='small'
                                                                                icon={<EditOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                                                                onClick={() => setState(state => ({...state, edit_modal : add_on_setup_id, modal : (state?.modal + 1) }))}
                                                                                />
                                                                                <Popconfirm
                                                                                onConfirm={() => deleteAddOnSetup(add_on_setup_id)}
                                                                                title="Delete Add On"
                                                                                description={`${add_on?.name}`}
                                                                                overlayStyle={{ width : 250,}}
                                                                                >
                                                                                    <Button size='small' icon={<DeleteOutlined style={{color : 'var(--secondary-text-color)',}} />} />
                                                                                </Popconfirm>
                                                                            </Button.Group>
                                                                        </>
                                                                    )
                                                                }
                                                            },]}
                                                            />
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <div style={{ padding : 12,}}>
                                                                <div>
                                                                    <Empty style={{margin : 12,}} description={"No add on"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                                </div>
                                                                <div>
                                                                    <Button icon={<PlusOutlined />} onClick={() => setState(state => ({...state, create_modal : add_on_ticket_type, modal : (state?.modal + 1)}))}>Add On</Button>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            )
                        })
                    }
                    </Space>
                </Spin>
            </div>

            <Modal
            title="New Add On"
            open={state?.create_modal}
            onCancel={() => {
                setState(state => ({...state, create_modal : false, modal : (state?.modal + 1)}));
                // save_form?.resetFields();
            }}
            onOk={() => save_form.submit()}
            width={800}
            style={{ top : 20, }}
            confirmLoading={state?.save_loading}
            >
                <Form
                form={save_form}
                onFinish={saveAddOnSetup}
                layout='vertical'
                >
                    <AddTicketAddOnModal
                    form={save_form}
                    modal_visible={state?.modal}
                    ticket_type={configured_ticket_types}
                    add_on_setups={configured_ticket_types?.add_on_setups}
                    />
                </Form>
            </Modal>

            <Modal
            title="Update Add On"
            open={state?.edit_modal}
            onCancel={() => {
                setState(state => ({...state, edit_modal : false, modal : (state?.modal + 1) }));
            }}
            onOk={() => edit_form?.submit()}
            width={800}
            style={{ top : 20, }}
            confirmLoading={state?.update_loading}
            >
                <Form
                layout='vertical'
                form={edit_form}
                onFinish={updateAddOnSetup}
                >
                    <EditTicketAddOnModal
                    form={edit_form}
                    ticket_type={get_ticket_type_by_add_on_setup_id}
                    add_on_setup_id={state?.edit_modal}
                    modal_visible={state?.modal}
                    />
                </Form>
            </Modal>
        </>
    );
}

export default AddOnSetup;