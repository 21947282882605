import React from 'react';
import CollectionList from './collection_list';
import PageHeaderComponent from '../../../helpers/components/page_header.component';

const FaceLayout = () => {
    return (
        <>
            <div className='mobile-padding-12'>
                <div style={{textAlign:'start',}}>
                    <PageHeaderComponent title={'Collection List'} />
                </div>
                <div style={{marginTop:24,}}>
                    <CollectionList />
                </div>
            </div>
        </>
    );
}

export default FaceLayout;