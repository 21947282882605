import { Checkbox, Spin, Switch, Table } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import parkService from '../../../services/config/park.service';
import { LoadingOutlined } from '@ant-design/icons';

const TaxApplicableAddOnTableConfiguration = ({ form, park_id, park_tax, ticket_types, }) => {
    const [state, setState] = useState({
        loading : false,
        columns : [],
        datas : [],
        add_on_setups : [],
        all_add_ons : [],
        checked_add_ons : [],
    });

    const is_checkall_toggled = useMemo(() => {
        const add_on_setup_arr = (state?.add_on_setups?.map(x => x?.add_ons)?.flat()?.map(x => x?.add_on_setup_id));
        const checked_add_on_arr = (state?.checked_add_ons?.filter(x => x?.is_applicable == true));
        return add_on_setup_arr?.length === checked_add_on_arr?.length;
    }, [state?.checked_add_ons, state?.add_on_setups])

    const setForm = (add_on_setup_id, is_checked) => {
        const { park_tax_add_ons, } = form?.getFieldsValue();
        // check whether is checked
        const is_added_item = park_tax_add_ons?.find(x => x?.add_on_setup_id === add_on_setup_id);
        let park_tax_add_on_item = park_tax_add_ons;
        if(is_added_item){
            // replace item
            park_tax_add_on_item = park_tax_add_ons.map(item => {
                if (is_added_item?.add_on_setup_id === item?.add_on_setup_id) {
                    return { add_on_setup_id, is_applicable : is_checked };
                }
                return item;
            });
        }else{
            // if not found in added list, append to array
            park_tax_add_on_item = [...(park_tax_add_ons ?? []), { add_on_setup_id, is_applicable : is_checked, }]
        }
        
        setState(state => ({...state, checked_add_ons : park_tax_add_on_item}));
        form.setFieldsValue({ park_tax_add_ons : park_tax_add_on_item, });
    }

    const generateColumns = (all_add_ons) => {
        let columns = [];

        // map title
        columns?.push({
            title : 'Ticket Type',
            dataIndex : 'name',
            key : 'name',
            width: 150,
            fixed: 'left',
        })

        // assign to generate columns
        for(const add_on of all_add_ons){
            const { name, add_on_id, } = add_on;
            const column = {
                title : name,
                dataIndex : 'applicable',
                key : 'applicable',
                width : 150,
                render : (applicable, row, index) => {
                    // from database
                    const { is_applicable, add_on_setup_id, } = applicable?.[add_on_id] ?? {};
                    const { park_tax_add_ons, } = form?.getFieldsValue();
                    const is_checked = (park_tax_add_ons?.find(x => x?.add_on_setup_id === add_on_setup_id)?.is_applicable);

                    return (
                        <>
                            {
                                is_applicable ?
                                (
                                    <>
                                        <Switch
                                        value={is_checked}
                                        size='small'
                                        onChange={(is_checked) => setForm(add_on_setup_id, is_checked)}
                                        />
                                    </>
                                )
                                :
                                (
                                    <>
                                        <div>
                                            <span style={{fontSize:12, color:('var(--secondary-text-color)')}}>Not Applicable</span>
                                        </div>
                                    </>
                                )
                            }
                        </>
                    )
                }
            }
            columns?.push(column);
        }

        return columns;
    }

    const generateRows = ({add_on_setups, all_add_ons}) => {
        const { park_tax_add_ons, } = park_tax ?? {};
        const map_datas = ticket_types?.map(x => {
            const { name, ParkApplicableTicketType : park_applicable_ticket_type, ticket_type_id } = x;

            // get ticket applicable user id
            let applicable = {};
            const add_on_setups_by_ticket_type = (add_on_setups?.find(x => x?.ticket_type_id === ticket_type_id));
            for(const add_on of all_add_ons){
                const add_on_setup = (add_on_setups_by_ticket_type?.add_ons?.find(x => x?.add_on_id === add_on?.add_on_id));
                const is_checked = park_tax_add_ons?.find(x => (x?.add_on_setup_id === add_on_setup?.add_on_setup_id));

                applicable[add_on?.add_on_id] = {
                    is_applicable : Boolean(add_on_setup),
                    add_on_setup_id : add_on_setup?.add_on_setup_id,
                    add_on_id : add_on?.add_on_id,
                    is_checked : Boolean(is_checked?.is_applicable),
                };
            }

            return {
                name,
                ticket_type_id,
                applicable,
            }
        })

        return map_datas;
    }

    const initializeAddOnSetup = async () => {
        setState(state => ({...state, loading : true, }));
        let add_on_datas = [];
        for(const ticket_type of ticket_types){
            const { ParkApplicableTicketType : park_applicable_ticket_type, } = ticket_type;
            const addOnSetupResponse = await parkService?.getAddOn({ park_applicable_ticket_type_id : park_applicable_ticket_type?.park_applicable_ticket_type_id, });
            const { add_ons, } = addOnSetupResponse;

            add_on_datas?.push({
                ticket_type_id : ticket_type?.ticket_type_id,
                add_ons,
            });
        }

        // Merge the three arrays into one
        const merged_array = [].concat.apply([], (add_on_datas?.map(x => x?.add_ons)));
        // Remove duplicates based on add_on_id
        const unique_array = merged_array.reduce((acc, current) => {
            const x = acc.find(item => item.add_on_id === current.add_on_id);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);

        const all_add_ons = unique_array?.map(x => {
            const { add_on_id, add_on, } = x
            const { name } = add_on;

            return {
                name,
                add_on_id,
            }
        });

        const columns = generateColumns(all_add_ons);
        const datas = generateRows({ add_on_setups : add_on_datas, all_add_ons});

        // initialize form
        for(const data of datas?.map(x => x?.applicable)){
            // set form
            const data_arr = (Object.values(data));
            for(const applicable of data_arr){
                const { add_on_id, add_on_setup_id, is_checked, } = applicable;
                // check is already exist
                if(add_on_setup_id){
                    setForm(add_on_setup_id, is_checked);
                }
            }
        }

        setState(state => ({...state, add_on_setups : add_on_datas, all_add_ons : all_add_ons, datas : datas, columns : columns, loading : false, }));
    }

    // if is toggle all, auto check all switch by updating the form
    const toggleSwitch = (is_check_all) => {
        const add_on_setup_arr = (state?.add_on_setups?.map(x => x?.add_ons)?.flat()?.map(x => x?.add_on_setup_id));
        for(const add_on_setup_id of add_on_setup_arr){
            setForm(add_on_setup_id, is_check_all);
        }
    }

    useEffect(() => {
        initializeAddOnSetup();
    }, [park_id, park_tax])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <div style={{ display : 'flex', alignContent : 'center', width : 'inherit', }}>
                    <div>
                        <span>Add Ons</span>
                    </div>
                    <div style={{ marginLeft : 'auto', }}>
                        <Checkbox checked={is_checkall_toggled} onChange={(e) => toggleSwitch(e?.target?.checked)}>Toggle All</Checkbox>
                    </div>
                </div>
                <div style={{ marginTop : 12, }}>
                    <Table
                    scroll={{x : 600,}}
                    pagination={false}
                    className='hub-table bordered'
                    columns={state?.columns}
                    dataSource={state?.datas}
                    size='small'
                    />
                </div>
            </Spin>
        </>
    );
}

export default TaxApplicableAddOnTableConfiguration;