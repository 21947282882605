import React, { useEffect, useState, useContext, useMemo, } from 'react';
import ticketService from '../../../services/ticket/ticket.service';
import { Button, Col, Divider, Dropdown, Modal, Row, Space, Spin, Tabs, Tag, notification } from 'antd';
import moment from 'moment';
import IconWrapper from '../../../helpers/components/icon_wrapper';
import TicketDetailUserList from './ticket_detail_user_list';
import TicketDetailAddOnList from './ticket_detail_add_on_list';
import { CloseOutlined, DownOutlined, EditOutlined, ExportOutlined, FileTextOutlined, LoadingOutlined, MailOutlined, PlusOutlined } from '@ant-design/icons';
import globalHelper from '../../../helpers/functions/global.helper';
import TicketDetailDateChangeList from './ticket_detail_date_change_list';
import EditTicketDetail from './edit_ticket_detail';
import { useDispatch, useSelector } from 'react-redux';
import TicketVisitDate from './ticket_visit_date';
import IntegrationLogWrapper from './integration_log_wrapper';
import TicketTransaction from './ticket_transaction';
import numberHelper from '../../../helpers/functions/number.helper';
import AccessContext from '../../../helpers/context/access.context';
import otherService from '../../../services/config/other.service';
import { refresh_ticket_detail } from '../../../actions/ticket.action';
import preferenceService from '../../../services/config/preference.service';
import parkService from '../../../services/config/park.service';
import invoiceService from '../../../services/ticket/invoice.service';
import InvoiceList from './invoice_list';

const { confirm } = Modal;
const TicketDetail = ({ticket_id}) => {
    const dispatch = useDispatch();
    const ticketRedux = useSelector(state => state?.ticket);
    const accountRedux = useSelector(state => state?.account);
    const access = useContext(AccessContext);
    const [state, setState] = useState({
        loading : false,
        ticket : null,
        action_loading : false,
        generate_button_loading : false,
    })

    const [select, setSelect] = useState({
        preferences : [],
    })

    const fetchTicketDetail = async () => {
        setState(state => ({...state, loading : true }));
        const ticketsResponse = await ticketService.getTicket({
            ticket_id : ticket_id,
        });

        const ticket = ticketsResponse?.tickets[0] ?? null;

        setTimeout(() => {
            setState(state => ({...state, loading : false, ticket : ticket }));
        }, 200);
    }

    const exportTicketList = async (ticket) => {
        setState(state => ({...state, action_loading : true, }));
        const { ticket_id, ticket_type } = ticket ?? {}

        const ticketUserResponse = await ticketService.getTicketUser({
            ticket_id,
            is_all : true,
        });
        
        const { ticket : get_ticket, ticket_users, } = ticketUserResponse ?? {};
        const count = ticket_users?.count;
        const { park, agents } = get_ticket;
        const ticket_user_data = ticket_users?.rows?.map((ticket_user, index) => {
            const { ticket_user_no, ticket_user_type } = ticket_user;
            return {
                "Ticket No" : ticket_user_no,
                "Ticket Type" : ticket_user_type?.name,
                "Facial Link" : `${park?.base_url}ticket/user/${ticket_user_no}`
            }
        });
        
        globalHelper.exportToExcel({
            data : ticket_user_data,
            additional_data : [...(agents ? [[`Agent: ${agents}`]] : []), [`Group : ${ticket_type?.name}`], ...(get_ticket?.expired_date ? [[`Expired Date: ${moment(get_ticket?.expired_date)?.format("YYYY-MM-DD")}`]] : []), [`Total: ${count} tickets`]], 
            name : `Ticket_${get_ticket?.unique_no}${get_ticket?.salt ?? ''}`,
        });

        setState(state => ({...state, action_loading : false,}));
    }

    const sendEmail = async () => {
        setState(state => ({...state, action_loading : true,}));
        const sendEmailResponse = await ticketService?.sendEmail(ticket_id);
        const { email } = sendEmailResponse;
        setState(state => ({...state, action_loading : false,}));
        notification?.success({
            message : 'Successful Sent Email',
            description : (
                <>
                    <span>Email sent to {email}</span>
                </>
            ),
            duration: 10,
            // placement : 'bottomLeft'
        })
    }

    const openInvoice = async () => {
        setState(state => ({...state, action_loading : true, }));
        const invoices = state?.ticket?.invoices;
        // Sort invoices by created_at in descending order
        invoices.sort(function(a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
        });
        const invoice = state?.ticket?.invoices?.[0];
        const invoice_name = `tax-invoice-${state?.ticket?.running_number}`;
        const downloadInvoiceResponse = await invoiceService?.downloadInvoice(invoice?.invoice_id)
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${invoice_name}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch(err => setState(state => ({...state, action_loading : false, })))
        .finally(() => setState(state => ({...state, action_loading : false, })));
    }

    const generateInvoice = async () => {
        setState(state => ({...state, generate_button_loading : true, }));
        const generateInvoiceResponse = await invoiceService?.generateInvoice(ticket_id).then((response) => {
            const { invoice_name } = response ?? {};
            notification.success({
                message : 'Generate Invoice',
                description : `Invoice ${invoice_name} is generated`,
            })
            dispatch(refresh_ticket_detail());
        })
        .finally(() => {
            setState(state => ({...state, generate_button_loading : false,}))
        });
    }

    const cancelPaymentStatusConfirm = () => {
        confirm({
            title: 'Cancel Transaction',
            // icon: <ExclamationCircleFilled />,
            content: `Are you sure to cancel transaction ${state?.ticket?.unique_no}`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    await updateTicketPaymentStatus();
                    resolve(); 
                }).catch(() => console.error('Oops errors!'));
            },
            onCancel() {},
          });
    }

    const updateTicketPaymentStatus = async () => {
        setState(state => ({...state, action_loading : true,}));
        // get payment status first
        const paymentTypeResponse = await otherService?.getPaymentStatus({ is_fail : 1, });
        const { payment_statuses, } = paymentTypeResponse;
        const fail_payment_status = payment_statuses?.length > 0 && payment_statuses[0];

        // update ticket type
        const updateTicketResponse = await ticketService?.updateTicket(ticket_id, {
            payment_status_id : fail_payment_status?.payment_status_id
        });

        notification.success({
            message : 'Cancel Transaction',
            description : `Transaction ${state?.ticket?.unique_no} has been cancelled`,
        })

        setState(state => ({...state, action_loading : false,}));
        dispatch(refresh_ticket_detail());
    }

    const initializeDetailSelection = async () => {
        // fetch preference selections
        const { visit_date, preference_id, park_id, ticket_type_id, } = state?.ticket;
        let preferences = [];
        if(preference_id){
            const parkApplicableTicketTypeResponse = await parkService?.getParkApplicableTicketTypes({
                park_id, ticket_type_id,
            });
            const { park_applicable_ticket_types } = parkApplicableTicketTypeResponse;
            const park_applicable_ticket_type = park_applicable_ticket_types?.length > 0 ? park_applicable_ticket_types[0] : null;
            if(park_applicable_ticket_type){
                const preferenceResponse = await preferenceService?.getPreferences({
                    park_applicable_ticket_type_id : park_applicable_ticket_type?.park_applicable_ticket_type_id,
                    visit_date : visit_date,
                });
                const { preference_groups } = preferenceResponse;
                const flat_preferences = preference_groups?.flatMap(x => x?.preferences);
                preferences = flat_preferences?.map(x => {
                    const { preference_group, name, preference_id } = x;
                    return {
                        label : `${preference_group ? `${preference_group?.name} - ` : ''}${name}`,
                        value : preference_id,
                    }
                })
            }
        }
        setSelect(select => ({...select, preferences : preferences,}));
    }

    const isDeleteTransactionFeature = useMemo(() => {
        const feature = accountRedux?.role?.features?.find(x => x.code === 'DELETE_TRANSACTION' && x?.RoleFeature.enabled === true);
        return Boolean(feature);
    }, []);

    const isDisplayInvoiceFeature = useMemo(() => {
        const feature = accountRedux?.role?.features?.find(x => x.code === 'DISPLAY_INVOICE' && x?.RoleFeature.enabled === true);
        return Boolean(feature);
    }, [])

    const isGenerateInvoiceFeature = useMemo(() => {
        const feature = accountRedux?.role?.features?.find(x => x.code === 'GENERATE_INVOICE' && x?.RoleFeature.enabled === true);
        return Boolean(feature);
    }, [])

    useEffect(() => {
        fetchTicketDetail();
    }, [ticketRedux?.refresh]);

    useEffect(() => {
        state?.ticket && initializeDetailSelection();    
    }, [state?.ticket])

    return (
        <>
            <div className='mobile-padding-12'>
                <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                    <div style={{ display: 'flex', alignItems: 'end' }}>
                        <div style={{ textAlign: 'left' }}>
                            <div>
                                <div>
                                    <span style={{ fontSize: 18, color: '#595959' }}>{state?.ticket?.running_number ?? "Missing running number"}</span>
                                </div>
                            </div>
                            <div>
                                <div style={{display:'flex',  alignItems:'center',}}>
                                    <div>
                                        <Tag color='blue' style={{border : 'none', fontWeight:500, }}>
                                            {state?.ticket?.park?.currency?.code}
                                        </Tag>
                                    </div>
                                    <div>
                                        <span style={{fontSize: 24, fontWeight: 500,}}>
                                            {`${state?.ticket?.park?.currency?.symbol}${numberHelper?.commaNumber((parseFloat(state?.ticket?.payment_amount ?? 0)))}`}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div style={{marginTop:8,}}>
                                <Space>
                                    <div>
                                        <Tag color={state?.ticket?.payment_status?.is_payment_complete ? 'green' : 'orange'} style={{ border: 'none' }}>
                                            <span>{`Status : ${state?.ticket?.payment_status?.name ?? ""}`}</span>
                                        </Tag>
                                        <Tag color={'blue'} style={{ border: 'none' }}>
                                            <span>{`Type : ${state?.ticket?.purchase_type?.name}`}</span>
                                        </Tag>
                                        {
                                            state?.ticket?.remarks &&
                                            (
                                                <>
                                                    <Tag color={''} style={{ border: 'none' }}>
                                                        <span>{`Remarks : ${state?.ticket?.remarks}`}</span>
                                                    </Tag>
                                                </>
                                            )
                                        }
                                    </div>
                                </Space>
                            </div>
                        </div>

                        <div style={{marginLeft:'auto'}}>
                                <Dropdown
                                placement='bottomRight'
                                overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                                // arrow
                                trigger={['click']}
                                menu={{
                                    items: [{
                                        key: '1',
                                        icon : <ExportOutlined />,
                                        label: (
                                            <>
                                                <div>
                                                    <Space>
                                                        <span>Export Ticket List</span>
                                                    </Space>
                                                </div>
                                            </>
                                        ),
                                        onClick : () => exportTicketList(state?.ticket),
                                    },{
                                        key : '2',
                                        disabled : (!state?.ticket?.email),
                                        icon : <MailOutlined />,
                                        label : (
                                            <>
                                                <div>
                                                    <Space>
                                                        <span>Send Email</span>
                                                    </Space>
                                                </div>
                                            </>
                                        ),
                                        onClick : () => sendEmail(),
                                    },
                                    ...(isDisplayInvoiceFeature ? [{
                                        key : '4',
                                        disabled : (state?.ticket?.invoices?.length <= 0),
                                        icon : <FileTextOutlined />,
                                        label : (
                                            <>
                                                <div>
                                                    <Space>
                                                        <span>Download Invoice</span>
                                                    </Space>
                                                </div>
                                            </>
                                        ),
                                        onClick : () => openInvoice(),
                                    }] : []),
                                    ...(isDeleteTransactionFeature ?[{
                                        type: 'divider',
                                      },{
                                        key : '3',
                                        icon : <CloseOutlined />,
                                        label : (
                                            <>
                                                <div>
                                                    Cancel Transaction
                                                </div>
                                            </>
                                        ),
                                        onClick : () => cancelPaymentStatusConfirm(),
                                    }] : [])
                                    ]
                                }}
                                >
                                <Button loading={state?.action_loading} type='primary' icon={<DownOutlined />}>
                                    Action
                                </Button>
                            </Dropdown>
                        </div>
                    </div>

                    <Divider style={{ marginTop: 12 }} />

                    <div style={{ textAlign: 'left' }}>
                        <Row gutter={[20, 20]}>
                            <Col xs={12} md={8} lg={8} span={6}>
                                <div>
                                    <div>
                                        <Space>
                                            <IconWrapper icon={'ticket.svg'} />
                                            <div>
                                                <span style={{ fontWeight: 500 }}>Tickets</span>
                                            </div>
                                        </Space>
                                    </div>
                                    <div style={{marginTop:8,}}>
                                        <Space direction='vertical'>
                                            <div>
                                                <div>
                                                    <span style={{color:'#595959', fontWeight:500,}}>
                                                        Transaction Number
                                                    </span>
                                                </div>
                                                <div>
                                                    <span style={{color:'var(--secondary-text-color)'}}>
                                                        {`${state?.ticket?.unique_no}${state?.ticket?.salt ?? ''}`}
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <span style={{color:'#595959', fontWeight:500,}}>
                                                        Park
                                                    </span>
                                                </div>
                                                <div>
                                                    <span style={{color:'var(--secondary-text-color)'}}>
                                                        {state?.ticket?.park?.name}
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <span style={{color:'#595959', fontWeight:500,}}>
                                                        Ticket Type
                                                    </span>
                                                </div>
                                                <div>
                                                    <span style={{color:'var(--secondary-text-color)'}}>
                                                        {state?.ticket?.ticket_type?.name}
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <span style={{color:'#595959', fontWeight:500,}}>
                                                        Visit Date
                                                    </span>
                                                </div>
                                                <div>
                                                    <span style={{color:'var(--secondary-text-color)'}}>
                                                        {
                                                            state?.ticket?.is_open_date ?
                                                            (
                                                                <>
                                                                    Open Date
                                                                </>
                                                            )
                                                            :
                                                            (
                                                                <>
                                                                    <TicketVisitDate
                                                                    park_id={state?.ticket?.park_id}
                                                                    ticket_id={state?.ticket?.ticket_id}
                                                                    visit_date={state?.ticket?.visit_date}
                                                                    />
                                                                    {/* Date is changeable */}
                                                                </>
                                                            )
                                                        }
                                                        
                                                    </span>
                                                </div>
                                            </div>
                                            {/* Only show this when the ticket has preference */}
                                            {
                                                state?.ticket?.preference_id &&
                                                (
                                                    <>
                                                        <div>
                                                            <div>
                                                                <span style={{color:'#595959', fontWeight:500,}}>
                                                                    Visit Time
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <EditTicketDetail
                                                                park_id={state?.ticket?.park_id}
                                                                message_name={'Visit Time'}
                                                                type={`select`}
                                                                ticket_id={state?.ticket?.ticket_id}
                                                                name={'preference_id'}
                                                                value={(
                                                                    <span style={{color:'var(--secondary-text-color)'}}>
                                                                        {`${state?.ticket?.preference?.preference_group ? (`${state?.ticket?.preference?.preference_group?.name} - `) : ''} ${(state?.ticket?.preference?.name)}`}
                                                                    </span> 
                                                                )}
                                                                selections={select?.preferences}
                                                                select_value={state?.ticket?.preference_id}
                                                                feature_code={'CHANGE_PREFERENCE'}
                                                                />
                                                                
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </Space>

                                    </div>
                                </div>
                            </Col>

                            <Col xs={12} md={8} lg={8} span={6}>
                                <div>
                                    <div>
                                        <Space>
                                            <IconWrapper icon={'calendar.svg'} />
                                            <div>
                                                <span style={{ fontWeight: 500 }}>Calendar Date</span>
                                            </div>
                                        </Space>   
                                    </div>
                                    <div style={{marginTop:8,}}>
                                        <Space direction='vertical'>
                                            <div>
                                                <div>
                                                    <span style={{color:'#595959', fontWeight:500,}}>
                                                        Purchase Date
                                                    </span>
                                                </div>
                                                <div>
                                                    <span style={{color:'var(--secondary-text-color)'}}>
                                                        {state?.ticket?.created_at && moment(state?.ticket?.created_at)?.format("YYYY-MM-DD hh:mm:ssa")}
                                                    </span>
                                                </div>
                                            </div>

                                            <div>
                                                <div>
                                                    <span style={{color:'#595959', fontWeight:500,}}>
                                                        Payment Date
                                                    </span>
                                                </div>
                                                <div>
                                                    <span style={{color:'var(--secondary-text-color)'}}>
                                                        {state?.ticket?.payment_date && moment(state?.ticket?.payment_date)?.format("YYYY-MM-DD hh:mm:ssa")}
                                                    </span>
                                                </div>
                                            </div>

                                            <div>
                                                <div>
                                                    <span style={{color:'#595959', fontWeight:500,}}>
                                                        Expiry Date
                                                    </span>
                                                </div>
                                                <div>
                                                    <span style={{color:'var(--secondary-text-color)'}}>
                                                        <EditTicketDetail
                                                        message_name={'Expired Date'}
                                                        type={`date`}
                                                        ticket_id={ticket_id}
                                                        name={'expired_date'}
                                                        value={state?.ticket?.expired_date}
                                                        park_id={state?.ticket?.park_id}
                                                        feature_code={'CHANGE_EXPIRED_DATE'}
                                                        />
                                                        {/* {state?.ticket?.expired_date && moment(state?.ticket?.expired_date)?.format("YYYY-MM-DD")} */}
                                                    </span>
                                                </div>
                                            </div>
                                        </Space>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} md={8} lg={8} span={6}>
                                <div>
                                    <div>
                                        <Space>
                                            <IconWrapper icon={'profile.svg'} />
                                            <div>
                                                <span style={{ fontWeight: 500 }}>Purchaser Info</span>
                                            </div>
                                        </Space>   
                                    </div>
                                    <div style={{marginTop:8,}}>
                                        <Space direction='vertical'>
                                            {
                                                state?.ticket?.agents ?
                                                (
                                                    <>
                                                        <div>
                                                            <div>
                                                                <span style={{color:'#595959', fontWeight:500,}}>
                                                                    Agent Name
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span style={{color:'var(--secondary-text-color)'}}>
                                                                    {state?.ticket?.agents ?? '-'}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <div>
                                                            <div>
                                                                <span style={{color:'#595959', fontWeight:500,}}>
                                                                    Name
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span style={{color:'var(--secondary-text-color)'}}>
                                                                    {state?.ticket?.name ?? 'Anonymous'}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <span style={{color:'#595959', fontWeight:500,}}>
                                                                    Email
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <EditTicketDetail
                                                                message_name={'Email'}
                                                                type={`text`}
                                                                ticket_id={state?.ticket?.ticket_id}
                                                                name={'email'}
                                                                value={state?.ticket?.email ?? "-"}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }

                                            {
                                                !state?.ticket?.purchase_type?.is_online && 
                                                (
                                                    <>
                                                        <div>
                                                            <div>
                                                                <span style={{color:'#595959', fontWeight:500,}}>
                                                                    Ticket Generated By
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span style={{color:'var(--secondary-text-color)'}}>
                                                                    {state?.ticket?.generated_by?.username ?? '-'}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </Space>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div style={{marginTop:42,}}>
                        <div style={{textAlign:'left'}}>
                            <span style={{fontWeight:500, fontSize:16,}}>Transaction 🚀</span>
                        </div>
                        <Divider style={{marginTop:12}}/>
                        <div>
                            <TicketTransaction promotion_code={state?.ticket?.promotion_code} park={state?.ticket?.park} sub_total={(state?.ticket?.actual_price)} tax_amount={state?.ticket?.tax_amount} total_amount={state?.ticket?.payment_amount} />
                            {/* <TicketDetailPromotionCode promotion_code={state?.ticket?.promotion_code} sub_total={state?.ticket?.actual_price} total_amount={state?.ticket?.payment_amount}/> */}
                        </div>
                    </div>

                    {/* Invoices */}
                    {
                        isDisplayInvoiceFeature && (
                            <>
                                <div style={{marginTop:42,}}>
                                    <div style={{ display : 'flex', alignItems : 'center', }}>
                                        <div style={{textAlign:'left'}}>
                                            <span style={{fontWeight:500, fontSize:16,}}>Invoices 🧾</span>
                                        </div>
                                        
                                        {
                                            isGenerateInvoiceFeature && (
                                                <>
                                                    <div style={{ marginLeft : 'auto', }}>
                                                        <Button loading={state?.generate_button_loading} onClick={generateInvoice} icon={<PlusOutlined />}>Generate</Button>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                    <Divider style={{marginTop:12}}/>
                                    <div>
                                        <InvoiceList ticket={state?.ticket} />
                                    </div>
                                </div>
                            </>
                        )
                    }

                    <div style={{marginTop:42,}}>
                        <div style={{textAlign:'left'}}>
                            <span style={{fontWeight:500, fontSize:16,}}>Tickets 🎫</span>
                        </div>
                        <Divider style={{marginTop:12}}/>

                        <div>
                            <TicketDetailUserList ticket_users={state?.ticket?.ticket_users} park={state?.ticket?.park} />
                        </div>
                    </div>

                    <div style={{marginTop:42,}}>
                        <div style={{textAlign:'left'}}>
                            <span style={{fontWeight:500, fontSize:16,}}>Add Ons 🥪</span>
                        </div>
                        <Divider style={{marginTop:12}}/>

                        <div>
                            <TicketDetailAddOnList add_ons={state?.ticket?.add_ons} park={state?.ticket?.park}/>
                        </div>
                    </div>

                    <div style={{marginTop:42,}}>
                        <div style={{textAlign:'left'}}>
                            <span style={{fontWeight:500, fontSize:16,}}>Date Change History 📅</span>
                        </div>
                        <Divider style={{marginTop:12}}/>
                        <div>
                            <TicketDetailDateChangeList date_changes={state?.ticket?.date_changes} park={state?.ticket?.park}/>
                        </div>
                    </div>

                    <div style={{marginTop:42,}}>
                        <div style={{textAlign:'left'}}>
                            <span style={{fontWeight:500, fontSize:16,}}>Integration</span>
                        </div>
                        <Divider style={{marginTop:12}}/>
                        <div>
                            {/* <IntegrationLogList integration_logs={state?.ticket?.integration_logs} /> */}
                            <IntegrationLogWrapper ticket_id={state?.ticket?.ticket_id} park_id={state?.ticket?.park_id} />
                        </div>
                    </div>
                </Spin>
            </div>
        </>
    );
}

export default TicketDetail;