import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getMenus(query){
    const queryString = serviceHelper.queryString(query);
    const menuResponse = await axios.get(`admin/menu/get?${queryString}`, { withCredentials : true });
    return menuResponse?.data;
}

async function getAccesses(query){
    const queryString = serviceHelper.queryString(query);
    const accessResponse =  await axios.get(`admin/access/get?${queryString}`, { withCredentials : true, });
    return accessResponse?.data;
}

export default {
    getMenus,
    getAccesses,
}