import { AppstoreOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Col, Dropdown, Image, Input, Popover, Row, Space } from 'antd';
import React, { useState } from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import { Link, useHistory } from 'react-router-dom';
import IconComponent from '../../helpers/components/icon.component';
import { useSelector } from 'react-redux';
import authService from '../../services/admin/auth.service';
import MasterMenu from './master_menu';
import globalHelper from '../../helpers/functions/global.helper';

const MasterHeader = () => {
    const { innerWidth } = window;
    const history = useHistory();
    const authRedux = useSelector(state => state?.auth);
    const [timer, setTimer] = useState(null);
    const [state, setState] = useState({
        search_popover : false,

        //search
        input : null,
        search_input : null,
    });

    const initial = authRedux?.auth?.username?.charAt(0)?.toUpperCase();
    const handleLogout = async () => {
        const logoutResponse = await authService.logout();
        //clear local storage
        if (logoutResponse) {
            history.go(0);
        }
    }

    const doneTyping = (input) => {
        clearTimeout(timer);
        setState(state => ({...state, input : input, }));
        const newTimer = setTimeout(() => {
            setState(state => ({...state, search_input: input, }));
        }, 300);
        setTimer(newTimer);
    }


    return (
        <>
            <ContainerComponent span={22}>
                <Row>
                    <Col xs={0} xl={2}>
                        <Image preview={false} src='/banners/the_cool_melon_logo.png' style={{ width : 42, }} />
                    </Col>
                    <Col offset={innerWidth >= 768 ? 6 : 0}  xs={16} md={8} span={8}>
                        <div style={{margin:'auto'}}>
                            <Popover
                            placement='bottom'
                            open={state?.search_popover}
                            trigger={['click']}
                            arrow={{ pointAtCenter : true, }}
                            overlayStyle={{width: innerWidth >= 768 ? 600 : "100%"}}
                            overlayInnerStyle={{ overflowY : 'auto', maxHeight : (globalHelper?.isMobileScreen() ? 400 : 600) }}
                            content={
                                <>
                                    <div style={{padding:12,}}>
                                        <MasterMenu search={state?.search_input} />
                                    </div>
                                </>
                            }
                            >
                                <Input
                                prefix={<AppstoreOutlined style={{marginRight:8, color:'var(--secondary-text-color)', fontSize : 18,}}/>}
                                size='large'
                                style={{background:'var(--secondary-button-color)', borderRadius:6, maxWidth:400,}}
                                // bordered={false}
                                // style={{ borderRadius : 6, maxWidth : 400, }}
                                variant='filled'
                                placeholder={(`Menu`)}
                                allowClear
                                value={state?.input}
                                onChange={(e) => doneTyping(e.target.value)}
                                onFocus={() => setState(state => ({...state, search_popover : true}))}
                                onBlur={() => setTimeout(() => {
                                    setState(state => ({...state, search_popover : false}))
                                }, 200)}
                                />
                            </Popover>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={{textAlign:'end'}}>
                            <Space size={innerWidth >= 768 ? 40 : 20}>
                                <div>
                                    <Link to='/'>
                                        <IconComponent name={'/menus/home.svg'} size={20} />
                                    </Link>
                                </div>
                                <div>
                                    <Dropdown
                                    arrow
                                    menu={{
                                        items: [
                                            {
                                                key: '1',
                                                disabled: true,
                                                style: { padding: '5px 60px' },
                                                label: (
                                                    <>
                                                        <div style={{ margin: 'auto', textAlign: 'center' }}>
                                                            <div style={{ paddingBottom: 10 }}>
                                                                <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}>{initial}</Avatar>
                                                            </div>
                                                            <div>
                                                                <span style={{ color: '#000' }}>{authRedux?.auth?.username}</span>
                                                            </div>
                                                            <div>
                                                                <span style={{ color: '#000', color : 'var(--secondary-text-color)', }}>{authRedux?.auth?.full_name}</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                ),
                                            },
                                            {
                                                type: 'divider',
                                            },
                                            {
                                                key : '3',
                                                icon : <UserOutlined />,
                                                label : (
                                                    <>
                                                        <Link to="/account/profile">
                                                            Account
                                                        </Link>
                                                    </>
                                                )
                                            },
                                            {
                                                key: '2',
                                                icon: <LogoutOutlined />,
                                                onClick: handleLogout,
                                                label: (
                                                    <>
                                                        <span>
                                                            Logout
                                                        </span>
                                                    </>
                                                ),
                                            },
                                        ]
                                    }}
                                    trigger={['click']}>
                                        <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00', cursor: 'pointer' }}>{initial}</Avatar>
                                    </Dropdown>
                                </div>
                            </Space>
                        </div>
                    </Col>
                </Row>
            </ContainerComponent>
        </>
    );
}

export default MasterHeader;