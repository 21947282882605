import React, { useEffect, useMemo, useState } from 'react';
import parkService from '../../../services/config/park.service';
import TicketType from './ticket_type';
import { Space, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const ParkConfigDetail = ({ park_id, }) => {
    const [state, setState] = useState({
        loading : false,
        park : null,
        view_type : null,
        refresh : 0,
    })

    const refreshPage = () => {
        setState(state => ({...state, refresh : (state?.refresh + 1)}));
    }

    const selected_ticket_type = useMemo(() => {
        const ticket_type = state?.park?.ticket_types?.find(x => x?.ticket_type_id === state?.view_type);
        return ticket_type;
    }, [state?.park?.park_id, state?.view_type])

    const changeViewTicketType = (view_type) => {
        setState(state => ({...state, view_type : view_type, }))
    }

    const fetchPark = async () => {
        setState(state => ({...state, loading : true, }));
        const parkResponse = await parkService?.getPark({ park_id : park_id, });
        const { parks, } = parkResponse;
        setState(state => ({...state,  park : parks, loading : false, }));
    }

    useEffect(() => {
        fetchPark();
    }, [park_id, state?.refresh])

    useEffect(() => {
        if(state?.park){
            changeViewTicketType(state?.park?.ticket_types?.[0]?.ticket_type_id);
        }
    }, [state?.park?.park_id])

    return (
        <>
            <div>
                <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                    <Space size={24} direction='vertical' style={{ width : '100%', }}>
                        <div className='step-ticket-type'>
                            <TicketType ticket_types={state?.park?.ticket_types} park_id={park_id} refreshPage={refreshPage} />
                        </div>
                    </Space>
                </Spin>
            </div>
        </>
    );
}

export default ParkConfigDetail;