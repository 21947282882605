import React, { useEffect, useState } from 'react';
import affiliateService from '../../../services/config/affiliate.service';
import { Form, Input, Switch } from 'antd';

const AffiliateEdit = ({ form, affiliate_account_id, }) => {
    const [state, setState] = useState({
        loading : false,
        affiliate_account : null,
    });

    const [field, setField] = useState({
        is_active : false,
    })

    const fetchAffiliate = async () => {
        setState(state => ({...state, loading : true, }));
        const affiliateResponse = await affiliateService?.fetchAffiliate({
            affiliate_account_id,
        });
        const { affiliates } = affiliateResponse;
        const affiliate_account = affiliates?.length > 0 ? affiliates[0] : null;
        
        // set form
        const { email, is_active, } = affiliate_account;
        form.setFieldsValue({ email : email, is_active : is_active, });
        setState(state => ({...state, loading : false, affiliate_account : affiliate_account, }));
        setField(field => ({...field, is_active : is_active, }));
    }

    const onFieldChange = (key, value) => {
        setField(field => ({...field, [key] : value, }));
        form.setFieldsValue({ [key] : value });
    }

    useEffect(() => {
        fetchAffiliate();
    }, [affiliate_account_id])

    return (
        <>
            <div>
                <div>
                    <Form.Item label={"Email"} name="email">
                        <Input />
                    </Form.Item>
                </div>
                <div>
                    <Form.Item label={"Status"} name="is_active">
                        <Switch onChange={(e) => onFieldChange('is_active', e)} checked={field?.is_active}/>
                    </Form.Item>
                </div>
            </div>
        </>
    );
}

export default AffiliateEdit;