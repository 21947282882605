import React, { useEffect, useState } from 'react';
import parkService from '../../../services/config/park.service';
import { Button, Empty, Form, Modal, Popconfirm, Spin, Table } from 'antd';
import { DeleteOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';

const PeakDayList = ({ park_id, refresh, openEditModal = () => {}, deletePeakDay = () => {}, }) => {
    const [state, setState] = useState({
        loading : false,
        peak_days : [],
        delete_loading : false,

        // pagination
        size : 10,
        page : 0,
        count : 0,
    })

    const fetchPeakDays = async () => {
        setState(state => ({...state, loading : true, }));
        const peakDaysResponse = await parkService?.peakDayList(park_id, {
            page : state?.page,
            size : state?.size,
        });

        const { peak_days } = peakDaysResponse;
        const { rows, count, } = peak_days;
        setState(state => ({...state, loading : false, peak_days : rows, count, }));
    }

    useEffect(() => {
        fetchPeakDays();
    }, [park_id, state?.page, refresh,])

    return (
        <>
            <Spin spinning={state?.loading} indicator={(<LoadingOutlined />)}>
                {
                    state?.peak_days?.length > 0 ?
                    (
                        <>
                            <Table
                            size='small'
                            pagination={{ 
                                size : state?.size,
                                showSizeChanger: false,
                                total : state?.count,
                                current : (state?.page + 1),
                                onChange : (page) => {
                                    setState(state => ({...state, page : (page - 1)}));
                                }
                            }}
                            scroll={{ x : 400, }}
                            className='hub-table bordered'
                            dataSource={state?.peak_days}
                            columns={[{
                                title : 'Name',
                                dataIndex : 'name',
                                key : 'name',
                            },{
                                title : 'Frequency',
                                dataIndex : 'maintenance_frequency',
                                key : 'maintenance_frequency',
                                render : ((maintenance_frequency, row, index) => {
                                    const { name, } = maintenance_frequency ?? {};
                                    return (
                                        <>
                                            <span>{name}</span>
                                        </>
                                    )
                                })
                            },{
                                title : 'Start Date',
                                dataIndex : 'start_date',
                                key : 'start_date',
                            },{
                                title : 'End Date',
                                dataIndex : 'end_date',
                                key : 'end_date',
                            },{
                                title : 'Action',
                                dataIndex : 'peak_day_id',
                                key : 'peak_day_id',
                                width : 100,
                                fixed : 'right',
                                render : (peak_day_id, row, index) => {
                                    const { name, } = row;
                                    return (
                                        <>
                                            <Button.Group>
                                                <Button onClick={() => openEditModal(peak_day_id)} icon={<EditOutlined style={{color:'var(--secondary-text-color)'}}/>} size='small' style={{borderTopRightRadius : 0, borderBottomRightRadius : 0,}} />
                                                <Popconfirm
                                                onConfirm={() => deletePeakDay(peak_day_id)}
                                                title={`Are you confirm to delete peak day?`}
                                                description={name}
                                                okButtonProps={{ loading : state?.delete_loading, }}
                                                >
                                                    <Button size='small' icon={<DeleteOutlined style={{color:'var(--secondary-text-color)'}}/>} />
                                                </Popconfirm>
                                            </Button.Group>
                                        </>
                                    )
                                }
                            }]}
                            />
                        </>
                    )
                    :
                    (
                        <>
                            <div>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No peak day" />
                            </div>
                        </>
                    )
                }
            </Spin>
        </>
    );
}

export default PeakDayList;