import { PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Image, Input, InputNumber, Row, Select, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import globalHelper from '../../../helpers/functions/global.helper';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { Link } from 'react-router-dom';

const { TextArea } = Input;
const AddTicketTypeModal = ({ form, selected_ticket_types, all_ticket_types, }) => {
    const [state, setState] = useState({
        loading : false,
        image : null,
    })

    const acceptable_file_format = ['image/jpeg', 'image/png'];
    const beforeUpload = async (file) => {
        const is_image = acceptable_file_format?.find(x => x == file?.type);

        if(is_image){
            const uploaded_file = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    file.base64 = reader.result.split(',')[1];
                    resolve(file);
                };
                reader.onerror = (error) => reject(error);
            });
    
            const base64Image = `data:image/jpeg;base64,${uploaded_file?.base64}`
            setState(state => ({...state, image : base64Image, }));
        }else{
            // errorModal("Please upload in image format");
        }
        return false;
    }

    return (
        <>
            <div>
                <Divider orientation='left' orientationMargin="0"><PageHeaderComponent title={<span style={{fontSize:14,}}>Ticket Type</span>} textAlign={'start'} /></Divider>
                <div>
                    <Form.Item label={'Image'} name='image'>
                        <Upload
                        className='upload-ticket-type-image'
                        name="avatar"
                        listType='picture-card'
                        beforeUpload={beforeUpload}
                        showUploadList={false}
                        style={{width : '100%',}}
                        >
                            <div style={{display : 'flex', flexDirection : 'column', }}>
                                {
                                    state?.image ?
                                    (
                                        <>
                                            <div>
                                                <Image preview={false} src={state?.image} alt='avatar' style={{maxHeight : 102, objectFit : 'contain',}} />
                                            </div>
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <div>
                                                <div>
                                                    <PlusOutlined />
                                                </div>
                                                <div>Upload</div>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </Upload>
                    </Form.Item>
                    <Form.Item label={'Ticket Type'} name={'ticket_type_id'}>
                        <Select
                        showSearch
                        optionFilterProp="label"
                        options={all_ticket_types?.map(x => {
                            const { name, ticket_type_id } = x;
                            const is_disabled = Boolean(selected_ticket_types?.find(tt => tt?.ticket_type_id === ticket_type_id));
                            return {
                                label : name,
                                value : ticket_type_id,
                                disabled : is_disabled,
                            }
                        })}
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <div>
                                    <Divider style={{ marginBlock : 8, }} />
                                    <div style={{ padding : 8, }}>
                                        <Link to="/config/ticket_type">
                                            <Button icon={<PlusCircleOutlined />}>Ticket Type</Button>
                                        </Link>
                                    </div>
                                </div>
                            </>
                        )}
                        />
                    </Form.Item>
                    <Form.Item name={"descriptions"} label={'Descriptions'}>
                        <TextArea placeholder='Enter descriptions' rows={4} style={{ resize : 'none', }} />
                    </Form.Item>
                </div>
            </div>
            
            <div>
                <Divider orientation='left' orientationMargin="0"><PageHeaderComponent title={<span style={{fontSize:14,}}>Ticket Rules</span>} textAlign={'start'} /></Divider>
                <div>
                    <Row gutter={[12, 12]}>
                        <Col span={8} xs={24} sm={12} md={8}>
                            <Form.Item label={'Minimum Ticket'} name={'min_purchase'}>
                                <InputNumber min={0} precision={0} style={{width : '100%',}} />
                            </Form.Item>
                        </Col>
                        <Col span={8} xs={24} sm={12} md={8}>
                            <Form.Item label={'Maximum Ticket'} name={'max_purchase'}>
                                <InputNumber min={0} precision={0} style={{width : '100%',}} />
                            </Form.Item>
                        </Col>
                        <Col span={8} xs={24} sm={12} md={8}>
                            <Form.Item label={'Minimum Payable Ticket'} name={'min_paying_guest'}>
                                <InputNumber min={0} precision={0} style={{width : '100%',}} />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </div>
            
            <div>
                <Divider orientation='left' orientationMargin="0"><PageHeaderComponent title={<span style={{fontSize:14,}}>Day Rules</span>} textAlign={'start'} /></Divider>
                <div>
                    <Row gutter={[12, 12]}>
                    <Col span={8} xs={24} sm={12} md={8}>
                            <Form.Item extra="Advance day to purchase ticket" label={'Advance Days'} name={'advance_day'}>
                                <InputNumber min={0} precision={0} style={{width : '100%',}} />
                            </Form.Item>
                        </Col>
                        <Col span={8} xs={24} sm={12} md={8}>
                            <Form.Item extra="Advance day allow to change date"  label={'Change Date Advance Day'} name={'change_date_advance_day'}>
                                <InputNumber min={0} precision={0} style={{width : '100%',}} />
                            </Form.Item>
                        </Col>
                        <Col span={8} xs={24} sm={12} md={8}>
                            <Form.Item label={'Overnight'} name={'date_range'}>
                                <InputNumber min={0} precision={0} style={{width : '100%',}} />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default AddTicketTypeModal;