import { Table } from 'antd';
import React, { useEffect, useState } from 'react';

const PurchaseTypeTable = ({ purchase_types, purchase_type_datas }) => {
    const [state, setState] = useState({
        datas : [],
        columns : [],
    })

    const initializeTable = async () => {
        let columns = [];
        let datas = [];


        columns?.push({
            title : "Total Tickets",
            dataIndex : 'total_tickets',
            key : 'total_tickets',
            width : '25%',
            render : (text, row, index) => {
                return (
                    <>
                        <span>Total Ticket</span>
                    </>
                )
            }
        });

        for(const purchase_type of purchase_types){
            const { purchase_type_id, name, code, } = purchase_type;
            //get data
            const purchase_type_data = purchase_type_datas?.find(x => x?.purchase_type_id == purchase_type_id);
            const { checked_in_visitors, expected_visitors, } = purchase_type_data ?? {};
            datas[purchase_type_id] = {
                checked_in_visitors,
                expected_visitors,
            };


            columns.push({
                title : name,
                dataIndex : purchase_type_id,
                key : purchase_type_id,
                render: (text, row, index) => {
                    const { expected_visitors, checked_in_visitors } = text;
                    return (
                        <>
                            <div>
                                <span style={{color:'var(--main-color)'}}>{expected_visitors}</span>
                            </div>
                        </>
                    )
                }
            })
        }
        
        columns?.push({
            title : "Checked-In",
            dataIndex : 'checked_in_visitors',
            key : 'checked_in_visitors',
            // width : '25%',
            render : (text, row, index) => {
                const total_checked_in_visitors = row?.reduce((a, b) => (a + b?.checked_in_visitors), 0);
                return (
                    <>
                        {total_checked_in_visitors}
                    </>
                )
            }
        })

        setState(state => ({...state, columns : columns, datas : datas,}));
    }

    useEffect(() => {
        initializeTable();
    }, [purchase_type_datas])

    return (
        <>
            <Table
            scroll={{ x : 400, }}
            size='small'
            pagination={false}
            className='hub-table bordered'
            dataSource={[state?.datas]}
            columns={state?.columns}
            />
        </>
    );
}

export default PurchaseTypeTable;