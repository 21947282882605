import { CaretDownFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Space, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import FilterBullet from '../../../helpers/components/filter_bullet';
import EntranceList from './entrance_list';
import AddEntranceModal from './add_entrance_modal';
import parkService from '../../../services/config/park.service';
import entranceService from '../../../services/config/entrance.service';
import EditEntranceModal from './edit_entrance_modal';

const EntranceLayout = ({ park_id, }) => {
    const [save_form] = Form.useForm();
    const [update_form] = Form.useForm();
    const { innerWidth } = window;
    const [state, setState] = useState({
        loading : false,
        park : null,

        create_modal : false,
        edit_modal : null,

        save_loading : false,
        update_loading : false,

        refresh : 0,
        modal : 0,
    })

    const [filter, setFilter] = useState({

    })

    const openEditModal = (entrance_id) => {
        setState(state => ({...state, edit_modal : entrance_id, }));
    }

    const saveEntrance = async () => {
        setState(state => ({...state, save_loading : true, }));
        const { name, entrance_no, inout_type, entrance_type_id, espark_id, display_message, exit_message, entrance_setting_values, } = save_form?.getFieldsValue();
        const saveEntranceResponse = await entranceService?.saveEntrance({
            name, entrance_no, inout_type, entrance_type_id, espark_id, display_message, exit_message, entrance_setting_values,
        }).then(() => {
            notification?.success({
                message : 'Saved Entrance',
                description : name,
            })
            setState(state => ({...state, save_loading : false, create_modal : null, refresh : (state?.refresh + 1), }));
            save_form?.resetFields();
        }).catch(err => setState(state => ({...state, save_loading : false,})));
    }

    const updateEntrance = async () => {
        setState(state => ({...state, update_loading : true, }));
        const entrance_id = state?.edit_modal;
        const { name, entrance_no, inout_type, espark_id, display_message, exit_message, entrance_setting_values, } = update_form?.getFieldsValue();
        const updateEntranceResponse = await entranceService?.updateEntrance(entrance_id, {
            name, entrance_no, inout_type, espark_id, display_message, exit_message, entrance_setting_values,
        }).then(() => {
            notification?.success({
                message : 'Updated Entrance',
                description : name,
            })
            setState(state => ({...state, update_loading : false, edit_modal : null, refresh : (state?.refresh + 1), }));
        }).catch(err => setState(state => ({...state, update_loading : false,})));
    }

    const deleteEntrance = async (entrance_id) => {
        const deleteEntranceResponse = await entranceService?.deleteEntrance(entrance_id);
        
        notification?.success({
            message : 'Entrance Deleted',
        })

        setState(state => ({...state, refresh : (state?.refresh + 1)}));
    }

    const fetchPark = async () => {
        setState(state => ({...state, loading : true, }));
        const parkResponse = await parkService?.getPark({ park_id : park_id, });
        const { parks, } = parkResponse;
        setState(state => ({...state,  park : parks, loading : false, }));
    }

    useEffect(() => {
        fetchPark();
    }, [park_id])
    
    return (
        <>
            <div>
                <div>
                    <div>
                        <Input style={{ width : '100%', }} placeholder='Search Entrances' />
                    </div>
                    <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8, }}>
                        <div>
                            <div className='filter-panel' style={{textAlign:'start'}}>
                                <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                                    <FilterBullet
                                    popover={
                                        <>
                                            
                                        </>
                                    }
                                    >
                                        <div>
                                            <Space>
                                                <div>
                                                    <span>Entrance Type</span>
                                                </div>
                                                <div>
                                                    <CaretDownFilled style={{color:'#a0aec0'}} />
                                                </div>
                                            </Space>
                                        </div>
                                    </FilterBullet>
                                </Space>
                            </div>
                        </div>

                        <div style={{marginLeft:'auto'}}>
                            <Space>
                                <Button
                                icon={<PlusOutlined />}
                                // type='primary'
                                onClick={() => setState(state => ({...state, create_modal : true,}))}
                                >
                                    Entrance
                                </Button>
                            </Space>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop : 12, }}>
                    <EntranceList park_id={park_id} filter={filter} refresh={state?.refresh} openEditModal={openEditModal} deleteEntrance={deleteEntrance} />
                </div>
            </div>


            <Modal
            title="New Entrance"
            open={state?.create_modal}
            onCancel={() => {
                setState(state => ({...state, create_modal : false, }));
            }}
            onOk={() => save_form?.submit()}
            width={800}
            style={{ top : 20, }}
            confirmLoading={state?.save_loading}
            >
                <Form
                layout='vertical'
                form={save_form}
                onFinish={saveEntrance}
                initialValues={{ inout_type : true, }}
                >
                    <AddEntranceModal
                    form={save_form}
                    park={state?.park}
                    />
                </Form>
            </Modal>

            <Modal
            title="Update Entrance"
            open={state?.edit_modal}
            onCancel={() => {
                setState(state => ({...state, edit_modal : false, modal : (state?.modal + 1) }));
            }}
            onOk={() => update_form?.submit()}
            width={800}
            style={{ top : 20, }}
            confirmLoading={state?.update_loading}
            >
                <Form
                layout='vertical'
                form={update_form}
                onFinish={updateEntrance}
                >
                    <EditEntranceModal
                    form={update_form}
                    park={state?.park}
                    entrance_id={state?.edit_modal}
                    />
                </Form>
            </Modal>
        </>
    );
}

export default EntranceLayout;