import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function generateSpecialLink(params){
    const specialLinkResponse = await axios.post(`ticket/special_link/generate`, params, { withCredentials : true });
    return specialLinkResponse?.data;
}

async function fetchSpecialLink(query){
    const queryString = serviceHelper.queryString(query);
    const specialLinkResponse = await axios.get(`ticket/special_link/get?${queryString}`, { withCredentials : true });
    return specialLinkResponse?.data;
}

export default {
    generateSpecialLink,
    fetchSpecialLink,
}