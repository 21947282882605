import { Layout } from 'antd';
import React from 'react';
import MasterHeader from './master_header';

const { Header, Content, Sider } = Layout;
const MasterLayout = ({children}) => {
    return (
        <>
            <Layout>
                <Layout>
                    <Header style={{background : '#fff', border:'1px solid #edf2f7', position: 'sticky', top: 0, zIndex: 100, width: '100%', paddingInline : 24}}>
                        <MasterHeader />
                    </Header>
                    <Content className='main-content'>
                        <div className='content'>
                            {children}
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </>
    );
}

export default MasterLayout;