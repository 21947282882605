import { Form, Input } from 'antd';
import React from 'react';

const AddTicketTypeModal = ({ form, }) => {
    return (
        <>
            <Form.Item label={'Name'} name={'name'}>
                <Input />
            </Form.Item>
        </>
    );
}

export default AddTicketTypeModal;