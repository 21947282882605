import { Badge, Button, Form, Popconfirm, Space, Upload } from 'antd';
import React from 'react';
import StepNoItem from '../../../helpers/components/step_no_item';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';

const CoverImage = ({ form, beforeUpload = () => {}, removeImage = () => {}, image,  }) => {

    return (
        <>
            <Form.Item
            label={(
                <div style={{textAlign : 'start', }}>
                    <StepNoItem no={1} text={'Cover Image'} />
                </div>
            )}
            >
                <div
                style={{
                    width : '100%',
                    height : 200,
                    borderRadius : 8,
                    ...(image ? ({ 
                        backgroundImage : `url(${image})`,
                        backgroundRepeat : 'no-repeat',
                        backgroundSize : 'cover',
                        backgroundPosition : 'center',
                        color : '#fff',
                    }) : ({
                        color : 'var(--secondary-text-color)',
                        background : 'var(--secondary-button-color)',
                    })),
                
                }}>
                    <div
                    {...(image && ({ className : 'card-overlay', }))}
                    style={{ 
                        width : '100%',
                        height : '100%',
                        display : 'flex',
                        alignItems : 'center',
                        justifyContent : 'center',
                        borderRadius : 8,
                    }}
                    >
                        <div>
                            <Space size={20} direction='vertical' style={{ width : '100%', }}>
                                <div>
                                    <div>
                                        <span style={{ fontWeight: 500, fontSize : 16, }}>Upload an cover image</span>
                                    </div>
                                </div>
                                <div>
                                    <Space>
                                        <div>
                                            <Form.Item name={'cover_image'} noStyle>
                                                <Upload
                                                name="avatar"
                                                listType='picture-card'
                                                beforeUpload={(file) => beforeUpload('cover_image', file)}
                                                showUploadList={false}
                                                style={{width : '100%', padding :12,}}
                                                >
                                                    <div style={{ color : (image ? '#fff' : 'var(--secondary-text-color)') , }}>
                                                        <div>
                                                            <PlusOutlined />
                                                        </div>
                                                        <div>Upload</div>
                                                    </div>
                                                </Upload>
                                            </Form.Item>
                                        </div>
                                        <div>
                                            {
                                                image && (
                                                    <>
                                                        <Popconfirm
                                                        onConfirm={() => removeImage('cover_image')}
                                                        title="Remove Cover Image?"
                                                        overlayStyle={{ width : 250,}}
                                                        >
                                                            <Button type='dashed' icon={<CloseOutlined />} size='small' style={{ background : 'transparent', color : '#fff', }}></Button>
                                                        </Popconfirm>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </Space>
                                </div>
                            </Space>
                        </div>
                    </div>
                </div>
            </Form.Item>
        </>
    );
}

export default CoverImage;