import { Popover } from 'antd';
import React from 'react';

const FilterBullet = ({ children, popover, type, message, }) => {
    return (
        <>
            <Popover
            content={popover}
            trigger={['click']}
            placement='bottomLeft'
            overlayInnerStyle={{padding : 12, maxHeight : 400, overflowY : 'auto', }}
            >
                <div style={{padding: '2px 12px', borderRadius: 6, cursor:'pointer', color:'#4a5568', ...(type === 'warning' ? ({ background : '#fff7e6', }) : ({ background : '#edf2f7', })) }}>
                    {children}
                </div>
            </Popover>
        </>
    );
}

export default FilterBullet;