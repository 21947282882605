import React, { useEffect, useState } from 'react';
import parkService from '../../../services/config/park.service';
import { Button, Empty, Popconfirm, Spin, Table } from 'antd';
import { DeleteOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';

const MaintenanceDateList = ({ park_id, refresh, openEditModal = () => {}, deleteParkMaintenance = () => {} }) => {
    const [state, setState] = useState({
        loading : false,
        maintenance_dates : [],

        // pagination
        size : 10,
        page : 0,
        count : 0,
    })

    const fetchParkMaintenances = async () => {
        setState(state => ({...state, laoding : true, }));
        const maintenanceResponse = await parkService?.maintenanceList(park_id, {
            page : state?.page,
            size : state?.size,
        });

        const { park_maintenances } = maintenanceResponse;
        const { rows, count, } = park_maintenances;
        setState(state => ({...state, loading : false, maintenance_dates : rows, count, }));
    }

    useEffect(() => {
        fetchParkMaintenances();
    }, [park_id, refresh])

    return (
        <>
            <Spin spinning={state?.loading} indicator={(<LoadingOutlined />)}>
                {
                    state?.maintenance_dates?.length > 0 ?
                    (
                        <>
                            <Table
                            size='small'
                            pagination={{ 
                                size : state?.size,
                                showSizeChanger: false,
                                total : state?.count,
                                current : (state?.page + 1),
                                onChange : (page) => {
                                    setState(state => ({...state, page : (page - 1)}));
                                }
                            }}
                            scroll={{ x : 400, }}
                            className='hub-table bordered'
                            dataSource={state?.maintenance_dates}
                            columns={[{
                                title : 'Name',
                                dataIndex : 'name',
                                key : 'name',
                            },{
                                title : 'Frequency',
                                dataIndex : 'maintenance_frequency',
                                key : 'maintenance_frequency',
                                render : ((maintenance_frequency, row, index) => {
                                    const { name, } = maintenance_frequency ?? {};
                                    return (
                                        <>
                                            <span>{name}</span>
                                        </>
                                    )
                                })
                            },{
                                title : 'Start Date',
                                dataIndex : 'start_date',
                                key : 'start_date',
                            },{
                                title : 'End Date',
                                dataIndex : 'end_date',
                                key : 'end_date',
                            },{
                                title : 'Action',
                                dataIndex : 'park_maintenance_id',
                                key : 'park_maintenance_id',
                                width : 100,
                                fixed : 'right',
                                render : (park_maintenance_id, row, index) => {
                                    const { name } = row;
                                    return (
                                        <>
                                            <Button.Group>
                                                <Button
                                                icon={<EditOutlined style={{color : 'var(--secondary-text-color)',}} />}
                                                size='small'
                                                style={{borderTopRightRadius : 0, borderBottomRightRadius : 0,}}
                                                onClick={() => openEditModal(park_maintenance_id)}
                                                /> 
                                                <Popconfirm
                                                onConfirm={() => deleteParkMaintenance(park_maintenance_id)}
                                                title={`Are you confirm to delete park maintenance?`}
                                                description={name}
                                                okButtonProps={{ loading : state?.delete_loading, }}
                                                >
                                                    <Button size='small' icon={<DeleteOutlined style={{color:'var(--secondary-text-color)'}}/>} />
                                                </Popconfirm>
                                            </Button.Group>
                                        </>
                                    )
                                }
                            }]}
                            />
                        </>
                    )
                    :
                    (
                        <>
                            <div>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No maintenance day" />
                            </div>
                        </>
                    )
                }
            </Spin>
        </>
    );
}

export default MaintenanceDateList;