import { CheckCircleFilled, CheckCircleOutlined, CheckOutlined, CloseCircleFilled, CloseOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Space, Spin, Switch, Table, notification } from 'antd';
import React, { useState, useEffect } from 'react';
import promotionService from '../../../services/config/promotion.service';
import EntranceMotion from '../../../helpers/components/entrance_motion';

const PromoAddOnAvailability = ({ promotion_code }) => {
    const [state, setState] = useState({
        loading : false,
        edit_mode : false,
        columns : [],
        datas : [],
        add_ons : [],
    });

    const [updated, setUpdated] = useState([]); // to set state on changed toggle


    const fetchPromoCodeAddOn = async () => {
        setState(state => ({...state, loading : true, }));
    
        let columns = [];
        let datas = [];
        let get_add_ons = [];
        if(promotion_code){
            const { promotion_code_id } = promotion_code;
            const promoCodeAddOnResponse = await promotionService?.getPromoCodeApplicableAddOn(promotion_code_id);
            const { ticket_types, add_ons } = promoCodeAddOnResponse;
            get_add_ons = add_ons;
            columns = await generateColumns(add_ons);
            datas = await generateDataSource(ticket_types);
        }

        setState(state => ({...state, loading : false, columns, datas, add_ons : get_add_ons }))
    }

    const generateColumns = async (add_ons) => {
        let columns = [];

        // map title
        columns?.push({
            title : 'Ticket Type',
            dataIndex : 'name',
            key : 'name',
            width: 150,
            fixed: 'left',
        })


        // map data
        for(const add_on of add_ons){
            const { name, add_on_id, } = add_on;
            const column = {
                title : name,
                dataIndex : 'applicable',
                key : 'applicable',
                width : 150,
                render : (applicable, row, index) => {
                    // from database
                    const { is_applicable, add_on_setup_id } = applicable[add_on_id] ?? {};
                    // from state
                    const checked_toggle = updated?.find(x => x.add_on_setup_id === add_on_setup_id) ?? false;

                    return (
                        <>
                            {
                                is_applicable != undefined ?
                                (
                                    <>
                                        {
                                            state?.edit_mode ?
                                            (
                                                <>
                                                    <EntranceMotion delay={index * 0.1}>
                                                        <Switch
                                                        onChange={(e) => toggleChanges(e, add_on_setup_id)}
                                                        size='small'
                                                        checked={checked_toggle?.is_applicable}
                                                        // defaultChecked={checked_toggle?.is_applicable}
                                                        />
                                                    </EntranceMotion>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <EntranceMotion delay={index * 0.1}>
                                                    {
                                                        is_applicable ?
                                                        (
                                                            <>
                                                                <CheckCircleFilled style={{color:'#95de64'}} />
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                <CloseCircleFilled style={{color:'#d9d9d9',}} />
                                                            </>
                                                        )
                                                    }
                                                    </EntranceMotion>
                                                </>
                                            )
                                        }
                                    </>
                                )
                                :
                                (
                                    <>
                                        <div>
                                            <span style={{fontSize:12, color:('var(--secondary-text-color)')}}>Not Applicable</span>
                                        </div>
                                    </>
                                )
                            }
                        </>
                    )
                }
            }
            columns?.push(column);
        }

        return columns;
    }

    const generateDataSource = async (ticket_types) => {
        // map data
        const map_datas = ticket_types?.map(x => {
            const { name, park_applicable_ticket_types, ticket_type_id } = x;
            const { add_ons } = park_applicable_ticket_types ?? {};

            let applicable = {};
            for(const add_on of add_ons){
                const { AddOnSetup : add_on_setup } = add_on;
                applicable[add_on?.add_on_id] = {
                    is_applicable : add_on?.is_applicable,
                    add_on_setup_id : add_on_setup?.add_on_setup_id,
                };

                toggleChanges(add_on?.is_applicable, add_on_setup?.add_on_setup_id);
            }

            return {
                name,
                ticket_type_id,
                applicable,
            }
        })

        return map_datas;
    }

    const toggleAll = () => {
        // check whether is toggle all or toggle false
        const mapped_updated = updated?.map(x => x.is_applicable);
        const is_true = mapped_updated.includes(false);


        let add_ons = state?.add_ons;
        const add_on_setup_ids = [].concat(...add_ons?.map(x => x?.add_on_setups?.map(aos => aos?.add_on_setup_id)));

        for(const add_on_setup_id of add_on_setup_ids){
            toggleChanges(is_true, add_on_setup_id)
        }
    }

    const toggleChanges = (is_applicable, add_on_setup_id) => {
        let updated_data = updated;
        const exisiting_updated_date = updated_data?.find(x => x.add_on_setup_id == add_on_setup_id);
        if(exisiting_updated_date){
            // update existing
            exisiting_updated_date.is_applicable = is_applicable;
        }else{
            // push new
            updated_data?.push({ is_applicable, add_on_setup_id, });
        }

        setUpdated(updated_data);
        renderColumns();
    }

    const renderColumns = async () => {
        const columns = await generateColumns(state?.add_ons);
        setState(state => ({...state, columns : columns, }));
    }

    const savePromotionCodeAddOn = async () => {
        setState(state => ({...state, save_loading : true, }));
        const savePromotionCodeAddOnResponse = await promotionService.savePromoCodeAddOn(promotion_code?.promotion_code_id, {
            add_on_setups : updated,
        });


        setTimeout(() => {
            const { saved_items } = savePromotionCodeAddOnResponse;
            //notify
            notification.success({
                message : "Promotion Code Updated",
                description : `Updated Ticket Type and Variant`
            })

            setState(state => ({...state, edit_mode : false, save_loading : false, }));
            setUpdated([]); //reset updated items
        }, 500);
    }


    useEffect(() => {
        fetchPromoCodeAddOn();
    }, [promotion_code, state?.edit_mode])

    return (
        <>
            <div>
                <div style={{textAlign:'start'}}>
                    <div style={{display:'flex',}}>
                        <div>
                            <span style={{fontWeight:500,}}>Add Ons</span>
                        </div>

                        <div style={{marginLeft:'auto'}}>
                            {
                                state?.edit_mode ?
                                (
                                    <>
                                        <Space>
                                            <Button
                                            disabled={state?.save_loading}
                                            size='small'
                                            type='text'
                                            style={{ background : 'var(--secondary-button-color)', color:'var(--secondary-text-color)' }}
                                            icon={<CheckCircleOutlined />}
                                            onClick={toggleAll}
                                            >
                                                Toggle All
                                            </Button>
                                            <Button
                                            loading={state?.save_loading}
                                            onClick={savePromotionCodeAddOn}
                                            size='small'
                                            icon={<CheckOutlined style={{color:'var(--secondary-text-color)'}} />}
                                            />
                                            <Button
                                            disabled={state?.save_loading}
                                            onClick={() => setState(state => ({...state, edit_mode : false,}))}
                                            size='small'
                                            icon={<CloseOutlined style={{color:'var(--secondary-text-color)'}} />}
                                            />
                                        </Space>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <Space>
                                            <Button
                                            onClick={() => setState(state => ({...state, edit_mode : true,}))}
                                            size='small'
                                            icon={<EditOutlined style={{color:'var(--secondary-text-color)'}} />}
                                            />
                                        </Space>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div style={{marginTop:12,}}>
                    <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                        <Table
                        scroll={{x : 600,}}
                        pagination={false}
                        // pagination={{ current : state?.page, onChange : (page) => paginateChange('page', page)}}
                        className='hub-table bordered'
                        dataSource={state?.datas}
                        columns={state?.columns}
                        />
                    </Spin>
                </div>
            </div>
        </>
    );
}

export default PromoAddOnAvailability;