import { Button, Form, Modal, Popconfirm, Table, notification } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import parkService from '../../../services/config/park.service';
import numberHelper from '../../../helpers/functions/number.helper';
import globalHelper from '../../../helpers/functions/global.helper';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import EditPeakDayModal from './edit_peak_day_modal';

const PeakDayDiscount = ({ park_id, park_applicable_ticket_type_id, ticket_applicable_users, ticket_type, }) => {
    const [edit_form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        columns : [],
        peak_day_discount : [],

        edit_modal : null,

        refresh : 0,
    })

    const editModalPeakDayDiscount = useMemo(() => {
        const peak_day_discount = (state?.peak_day_discount.find(x => x?.is_peak_day === state?.edit_modal?.is_peak_day));
        return peak_day_discount;
    }, [state?.edit_modal])

    const updatePeakDayDiscount = async () => {
        setState(state => ({...state, update_loading : true,}));
        const park_applicable_ticket_type_id = ticket_type?.ParkApplicableTicketType?.park_applicable_ticket_type_id;
        const peak_day_discount_modal = state?.edit_modal;
        const is_peak_day = +peak_day_discount_modal?.is_peak_day;
        const { percentage, value, } = edit_form?.getFieldsValue();

        const saveUpdatePeakDayDiscountResponse = await parkService?.saveUpdatePeakDayDiscount(park_applicable_ticket_type_id, {
            is_peak_day, percentage, value,
        }).catch(err => setState(state => ({...state, update_loading : false, })));

        const { save_peak_day_discount, } = saveUpdatePeakDayDiscountResponse;
        if(save_peak_day_discount){
            notification.success({
                message : 'Peak Day Discount Updated',
            })
        }
        setState(state => ({...state, update_loading : false, edit_modal : false, refresh : (state?.refresh + 1)}));
    }

    // as this will get through park applicable ticket type, so loop through to get peak day discount list
    const fetchPeakDays = async () => {
        const peakDayResponse = await parkService?.peakDayDiscountList({
            park_applicable_ticket_type_id,
        });

        const { discount_array, } = peakDayResponse;
        return discount_array;
    }

    const initializeColumns = (peak_day_discount) => {
        let columns = [];
        // generate children columns
        const ticket_user_type_price = ticket_applicable_users?.map((ticket_applicable_user, index) => {
            const { is_fixed_price, price, ticket_user_type, } = ticket_applicable_user;
            return {
                price, is_fixed_price, name : ticket_user_type?.name
            }
        })
        
        let children = ticket_user_type_price;
        columns = ([...columns, {
            title : 'Day Type',
            key : 'is_peak_day',
            dataIndex : 'is_peak_day',
            render : (is_peak_day, row, index) => {
                const day_type = is_peak_day ? 'Peak Day' : 'Non-peak Day';
                return (
                    <>
                        <span>{day_type}</span>
                    </>
                )
            }
        },{
            title : 'Discount',
            key : 'percentage',
            dataIndex : 'percentage',
            render : (percentage, row, index) => {
                return (
                    <>
                        <span>{`${percentage}%`}</span>
                    </>
                )
            }
        },{
            title : 'Discounted Price',
            children : children?.map((child) => {
                const { price, is_fixed_price, name, } = child ?? {};
                return {
                    title : `${name}`,
                    // You can define a custom className for the column header
                    // to style it with CSS
                    className : 'discount-ticket-type',
                    render : (text, row, index) => {
                        const { percentage, } = row ?? {};
                        const discounted_price = price - (price * (isNaN(parseFloat(percentage)) ? 0 : parseFloat(percentage / 100)));
                        const final_price = is_fixed_price ? price : discounted_price;
                    
                        return (
                            <span>{numberHelper?.commaNumber(final_price)}</span>
                        )
                    }
                }
            }),
        },{
            title : 'Action',
            key : 'is_peak_day',
            dataIndex : 'is_peak_day',
            width : 100,
            render : (is_peak_day, row, index) => {
                return (
                    <>
                        <Button.Group>
                            <Button
                            size='small'
                            icon={<EditOutlined style={{color : 'var(--secondary-text-color)'}} />}
                            onClick={() => setState(state => ({...state, edit_modal : row, }))}
                            />
                        </Button.Group>
                    </>
                )
            }
        },]);

        return columns;
    }

    const initialize = async () => {
        setState(state => ({...state, loading : true, }));
        const peak_day_discount = await fetchPeakDays();
        const columns = initializeColumns(peak_day_discount);

        setState(state => ({...state, loading : false, peak_day_discount, columns : columns, }));
    }

    useEffect(() => {
        initialize();
    }, [park_id, state?.refresh])

    return (
        <>
            <div style={{ textAlign : 'start', }}>
                <PageHeaderComponent title={(
                    <>
                        <span style={{ fontSize : 12, color : 'var(--secondary-text-color)', }}>Peak Day Discount</span>
                    </>
                )} />
            </div>
            <Table
            {...globalHelper?.isMobileScreen() && ({ scroll : { x : 1000,} })}
            dataSource={state?.peak_day_discount}
            bordered
            className='hub-table bordered nested-table'
            size='small'
            // dataSource={peak_day_discount}
            pagination={false}
            columns={state?.columns ?? []}
            />

            <Modal
            title="Peak Day Discount"
            open={state?.edit_modal}
            onCancel={() => {
                setState(state => ({...state, edit_modal : null,}));
            }}
            onOk={() => edit_form.submit()}
            width={800}
            confirmLoading={state?.update_loading}
            >
                <Form
                form={edit_form}
                onFinish={updatePeakDayDiscount}
                layout='vertical'
                >
                    <EditPeakDayModal form={edit_form} ticket_type={ticket_type} peak_day_discount={editModalPeakDayDiscount} />
                </Form>
            </Modal>
        </>
    );
}

export default PeakDayDiscount;