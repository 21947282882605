import React from 'react';
import ConfigTicketTypeLayout from './components/config_ticket_type_layout';
import ContainerComponent from '../../../helpers/components/container.component';

const ConfigTicketTypeIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <ConfigTicketTypeLayout />
            </ContainerComponent>
        </>
    );
}

export default ConfigTicketTypeIndex;