import { Card, Checkbox, Col, Form, Input, InputNumber, List, Row, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import parkService from '../../../services/config/park.service';
import { LoadingOutlined } from '@ant-design/icons';

const AddRequiredTicketRuleModal = ({ form, park, modal_visible, }) => {
    const [state, setState] = useState({
        loading : false,
        selected_park_applicable_ticket_type_id : null,

        ticket_applicable_users : [],
    })

    const fetchTicketApplicableUser = async () => {
        const selected_park_applicable_ticket_type_id = state?.selected_park_applicable_ticket_type_id;
        if(selected_park_applicable_ticket_type_id){
            setState(state => ({...state, loading : true, }))
            const fetchParkApplicableTicketTypeResponse = await parkService?.getParkApplicableTicketTypeitems({
                park_applicable_ticket_type_id : selected_park_applicable_ticket_type_id,
                is_check_purchase_type : false,
            });

            const { park_applicable_ticket_users, } = fetchParkApplicableTicketTypeResponse;
            setState(state => ({...state, loading : false, ticket_applicable_users : park_applicable_ticket_users, }))
        }
    }

    useEffect(() => {
        fetchTicketApplicableUser();

        form.setFieldsValue({ required_type : 'OR', })
    }, [state?.selected_park_applicable_ticket_type_id]);

    return (
        <>
            <Form.Item label={'Name'} name={'name'}>
                <Input />
            </Form.Item>

            <div>
                <Row gutter={[12, 12]}>
                    <Col xs={24} md={12}>
                        <Form.Item label={'Ticket Type'} name={'park_applicable_ticket_type_id'}>
                            <Select
                            onChange={(park_applicable_ticket_type_id) => setState(state => ({...state, selected_park_applicable_ticket_type_id : park_applicable_ticket_type_id, }))}
                            options={park?.ticket_types?.map(x => ({ value : x?.ParkApplicableTicketType?.park_applicable_ticket_type_id, label : x?.name, }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item label={'Minimum Quantity'} name={'min_quantity'}>
                            <InputNumber min={0} style={{ width : '100%', }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item hidden label={'Type'} name={'required_type'}>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    
                </Row>
            </div>

            <Form.Item
            label={(
                <>
                    <div>
                        <div>
                            <span>Ticket Group</span>
                        </div>
                        <div>
                            <span style={{ color : 'var(--secondary-text-color)', fontSize : 12, }}>At least one of these is required to proceed with the purchase</span>
                        </div>
                    </div>
                </>
            )}
            name={'ticket_applicable_users'}
            >
                <Checkbox.Group
                style={{ width : '100%', }}
                >
                    <Card
                    style={{ width : '100%', }}
                    styles={{ body : { padding : 0, } }}
                    >
                        <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                            <List
                            style={{ width : '100%', }}
                            itemLayout='horizontal'
                            dataSource={state?.ticket_applicable_users}
                            renderItem={(item, index) => {
                                const { ticket_applicable_user_id, ticket_user_type, } = item;
                                return (
                                    <List.Item
                                    style={{ paddingInline: 24, }}
                                    key={index}
                                    actions={[<Checkbox value={item?.ticket_applicable_user_id} />]}
                                    >
                                        {ticket_user_type?.name}
                                    </List.Item>
                                )
                            }}
                            />
                        </Spin>
                    </Card>
                </Checkbox.Group>
            </Form.Item>
        </>
    )
}

export default AddRequiredTicketRuleModal;