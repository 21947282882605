import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getCustomPassListing(query){
    const queryString = serviceHelper.queryString(query);
    const customPassResponse = await axios.get(`config/custom_pass/listing?${queryString}`, { withCredentials : true });
    return customPassResponse?.data;
}

async function getCustomPass(custom_pass_id){
    const customPassResponse = await axios.get(`config/custom_pass/${custom_pass_id}/get`, { withCredentials : true, });
    return customPassResponse?.data;
}

async function saveCustomPass(params){
    const { image_url, name, identification_number, expired_date, remarks, parks = [], } = params ?? {};
    const formData = new FormData();
    formData.append('name', name);
    identification_number && formData.append('identification_number', identification_number);
    expired_date && formData.append("expired_date", expired_date);
    remarks && formData.append('remarks', remarks);
    image_url && formData.append('image', image_url);
    // append parks
    parks.forEach((park, index) => {
        formData.append(`parks[${index}]`, park);
    });
    const saveStaffResponse = await axios.post(`config/custom_pass/save`, formData,
    { withCredentials : true, headers : { "Content-Type": "multipart/form-data" }});
    return saveStaffResponse?.data;
}

async function updateCustomPass(custom_pass_id, params){
    const updateCustomPassResponse = await axios.post(`config/custom_pass/${custom_pass_id}/update`, params, { withCredentials : true, });
    return updateCustomPassResponse?.data;
}

async function uploadCustomPassImage(custom_pass_id, image){
    const formData = new FormData();
    formData.append('image', image);

    const uploadStaffImageResponse = await axios.post(`config/custom_pass/${custom_pass_id}/upload`, formData, 
    { withCredentials : true, headers : { "Content-Type": "multipart/form-data" } });
    return uploadStaffImageResponse?.data;
}

async function deleteCustomPass(custom_pass_id){
    const deleteCustomPassRaesponse = await axios.delete(`config/custom_pass/${custom_pass_id}/delete`, { withCredentials : true, });
    return deleteCustomPassRaesponse?.data;
}

export default {
    getCustomPassListing,
    getCustomPass,
    saveCustomPass,
    updateCustomPass,
    uploadCustomPassImage,
    deleteCustomPass,
}