import React, { useState } from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import VisitorReportLayout from './components/visitor_report_layout';
import { useLocation } from 'react-router-dom';

const VisitorIndex = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [filter, setFilter] = useState({
        park_id : isNaN(parseInt(queryParams.get("park_id"))) ? null : parseInt(queryParams.get("park_id")),
        visit_date : queryParams.get("visit_date"),
    })

    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <VisitorReportLayout default_filter={filter} />
            </ContainerComponent>
        </>
    );
}

export default VisitorIndex;