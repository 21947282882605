import { Button, Modal, QRCode, Spin, Table, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import urlService from '../../../services/ticket/url.service';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import SpecialLinkGenerated from './special_link_generated';

const SpecialLinkList = ({ filter, refresh }) => {
    const [state, setState] = useState({
        special_links : [],
        loading : false,

        selected_special_link : null,
    })

    const fetchSpecialLink = async () => {
        setState(state => ({...state, loading : true, }));
        const { parks, status, } = filter;
        const specialLinkResponse = await urlService.fetchSpecialLink({
            park_ids : parks,
            status : status,
        });

        setState(state => ({...state, loading : false,  special_links : specialLinkResponse?.special_links}));
    }

    useEffect(() => {
        fetchSpecialLink();
    }, [refresh, filter,]);

    return (
        <>
            <div>
                <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                    <Table
                    scroll={{ x : 400, }}
                    className='hub-table bordered'
                    columns={[{
                        title : 'Special Link',
                        dataIndex : 'generated_url',
                        key : 'generated_url',
                        render : (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <a href={text} target='_blank'>
                                            {text}
                                        </a>
                                    </div>
                                    <div style={{marginTop:12,}}>
                                        <QRCode value={text} size={80} />
                                    </div>
                                </>
                            )
                        }
                    },{
                        title : 'Status',
                        dataIndex : 'expired_datetime',
                        key : 'expired_datetime',
                        render : (text, row, index) => {
                            const expired_datetime = moment(text)?.format("YYYY-MM-DD hh:mm:ssa");
                            const is_used = row?.is_used;
                            const expired = moment()?.isAfter(moment(row?.expired_datetime));
                            return (
                                <>
                                    <div>
                                        <div>
                                            <Tag color={((expired || is_used) ? 'magenta' : 'green')}>
                                                {
                                                    is_used ? ('Used') : expired ? ("Expired") : "Available"
                                                }
                                            </Tag>
                                        </div>
                                        <div>
                                            <span>{expired_datetime}</span>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    },{
                        title : 'Action',
                        dataIndex : 'special_link_id',
                        key : 'special_link_id',
                        width : '10%',
                        render : (text, row, index) => {
                            return (
                                <>
                                    <div>
                                        <Button onClick={() => setState(state => ({...state, selected_special_link : row,}))}>
                                            View
                                        </Button>
                                    </div>
                                </>
                            )
                        }
                    }]}
                    dataSource={state?.special_links}
                    />
                </Spin>
            </div>

            <Modal
            title={"Special Link"}
            open={state?.selected_special_link}
            onCancel={() => setState(state => ({...state, selected_special_link : null,}))}
            footer={null}
            >
                <SpecialLinkGenerated special_link={state?.selected_special_link} />
            </Modal>
        </>
    );
}

export default SpecialLinkList;