import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Document, Page, Thumbnail, pdfjs } from 'react-pdf';
import invoiceService from '../../../services/ticket/invoice.service';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { Button, Divider, Spin } from 'antd';

const ViewInvoice = ({ invoice_id, running_number, }) => {
    const { innerHeight } = window;
    pdfjs.GlobalWorkerOptions.workerSrc = new URL(
        'pdfjs-dist/build/pdf.worker.min.js',
        import.meta.url,
    ).toString();
    
    const [state, setState] = useState({
        loading : false,
        invoice : null,
        page_number : null,

        download_loading : false,
    })

    const fetchInvoice = async () => {
        setState(state => ({...state, loading : true, }));
        const invoiceResponse = await invoiceService?.invoiceList({
            invoice_id : invoice_id,
        });
        const { invoices } = invoiceResponse;
        const invoice = invoices?.rows[0];
        setState(state => ({...state, invoice : invoice, loading : false, }));
    }
    
    useEffect(() => {
        if(invoice_id){
            fetchInvoice();
        }
    }, [invoice_id])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <div style={{ margin : 'auto', textAlign : 'center', }}>
                    <embed src={`${state?.invoice?.invoice_file}#zoom=FitH`} width="100%" style={{ minHeight : (innerHeight / 1.2), }} 
                    type="application/pdf"></embed>
                    {/* <Document
                    file={state?.invoice}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={<LoadingOutlined />}
                    className={`invoice-canvas`}
                    >
                        {Array.from(new Array(state?.page_number), (el, index) => (
                            <>
                                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                                <div>
                                    Page {index + 1}
                                </div>
                                <Divider />
                            </>
                            
                        ))}
                    </Document> */}
                </div>
            </Spin>
        </>
    );
}

export default ViewInvoice;