import { LoadingOutlined } from '@ant-design/icons';
import { Checkbox, Form, Input, InputNumber, Spin, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import parkService from '../../../services/config/park.service';
import TaxApplicableTicketTableConfiguration from './tax_applicable_ticket_table_configuration';
import TaxApplicableAddOnTableConfiguration from './tax_applicable_add_on_table_configuration';

const EditParkTaxModal = ({ form, park_tax_id, ticket_user_types, park, modal_visible, }) => {
    const [state, setState] = useState({
        loading : false,
        park_tax : null,
        is_calculated_by_total_switch : false,
    });

    const fetchParkTax = async () => {
        setState(state => ({...state, loading : true, }));
        const parkTaxResponse = await parkService?.getParkTax({
            park_tax_id : park_tax_id,
            park_id : park?.park_id,
        });
        const { park_taxes, } = parkTaxResponse;
        const park_tax = park_taxes?.[0];

        // set form
        let calculated_by_total_switch = false;
        if(park_tax){
            const { name, percentage, park_tax_ticket_applicable_users, park_tax_add_ons, is_calculated_by_total, } = park_tax;
            calculated_by_total_switch = is_calculated_by_total;
            form?.setFieldsValue({
                name : name,
                percentage : percentage,
                is_calculated_by_total : is_calculated_by_total,
            })
        }
        
        setState(state => ({...state, loading : false, park_tax : park_tax, is_calculated_by_total_switch : calculated_by_total_switch, }));
        
    }

    useEffect(() => {
        fetchParkTax();
    }, [park_tax_id, modal_visible])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <Form.Item name={'name'} label={'Name'}>
                    <Input />
                </Form.Item>

                <Form.Item name={'percentage'} label={'Percentage'}>
                    <InputNumber min={0} style={{ width : '100%', }} />
                </Form.Item>

                <Form.Item name={"is_calculated_by_total"} label={"Is Calculate By Total Amount"}>
                    <Switch
                    onChange={(e) => setState(state => ({...state, is_calculated_by_total_switch : e, }))}
                    />
                </Form.Item>

                {
                    !state?.is_calculated_by_total_switch && (
                        <>
                            {/* Table for selecting ticket applicable users */}
                            <div>
                                
                                <Form.Item className='form-width-label'
                                name={'park_tax_ticket_applicable_users'}
                                >
                                    <TaxApplicableTicketTableConfiguration park_tax={state?.park_tax} form={form} ticket_user_types={ticket_user_types} park_id={park?.park_id} ticket_types={park?.ticket_types} />
                                </Form.Item>
                            </div>

                            {/* Table for add ons */}
                            <div>
                                <Form.Item name={'park_tax_add_ons'}>
                                    <TaxApplicableAddOnTableConfiguration park_tax={state?.park_tax} park_id={park?.park_id} form={form} ticket_types={park?.ticket_types} modal_visible={modal_visible} />
                                </Form.Item>
                            </div>
                        </>
                    )
                }

                
            </Spin>
        </>
    );
}

export default EditParkTaxModal;