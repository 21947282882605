import React, { useEffect, useState } from 'react';
import parkService from '../../../services/config/park.service';
import ParkInvoiceForm from './park_invoice_form';

const InvoiceLayout = ({ park_id, }) => {
    const [state, setState] = useState({
        loading : false,
        park : null,
    })

    const fetchPark = async () => {
        setState(state => ({...state, loading : true, }));
        const parkResponse = await parkService?.getPark({ park_id : park_id, });
        const { parks, } = parkResponse;
        setState(state => ({...state,  park : parks, loading : false, }));
    }

    useEffect(() => {
        fetchPark();
    }, [park_id])

    return (
        <>
            <ParkInvoiceForm park={state?.park} />
        </>
    );
}

export default InvoiceLayout;