import { Card, Progress } from 'antd';
import React, { useEffect, useState } from 'react';
import promotionService from '../../../services/config/promotion.service';

const PromoUsage = ({ promotion_code }) => {
    const [state, setState] = useState({
        loading : false,
        ticket_user_count : 0,
        ticket_count : 0,
    })

    const fetchTicketsByPromoCode = async () => {
        if(promotion_code){
            setState(state => ({...state, loading : true,}))
            const { promotion_code_id } = promotion_code;
            const ticketByPromoCodeResponse = await promotionService?.getTicketByPromoCode(promotion_code_id);

            const { ticket_user_count, ticket_count, } = ticketByPromoCodeResponse;
            setState(state => ({...state, loading : false, ticket_count, ticket_user_count,}));
        }
    }

    useEffect(() => {
    fetchTicketsByPromoCode();
    }, [promotion_code])

    return (
        <>
            <Card style={{height : '100%',}}>
                <div>
                    <div style={{textAlign:'start'}}>
                        <span style={{fontWeight:500,}}>Usage</span>
                    </div>
                    <div style={{marginTop:12,}}>
                        <Progress percent={(`${((state?.ticket_user_count / promotion_code?.limit_number) * 100)}`)} status='active' format={p => {
                            return (
                                <>
                                    {
                                        promotion_code?.limit_number ? 
                                        (
                                            <>
                                                <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>
                                                    {(`${((state?.ticket_user_count / promotion_code?.limit_number) * 100)?.toFixed(2)}%`)}
                                                </span>
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>
                                                    No Limit
                                                </span>
                                            </>
                                        )
                                    }
                                    
                                </>
                            )
                        }} />
                    </div>
                    <div>
                        {
                            promotion_code?.limit_number ?
                            (
                                <>
                                    <span>
                                        {`${state?.ticket_user_count} / ${promotion_code?.limit_number}`}
                                    </span>
                                </>
                            )
                            :
                            (
                                <>
                                    <span>{state?.ticket_user_count}</span>
                                </>
                            )
                        }
                        
                    </div>
                </div>
            </Card>
        </>
    );
}

export default PromoUsage;