import React, { useState } from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import ProfileLayout from './components/profile_layout';
import { useLocation } from 'react-router-dom';

const ProfileIndex = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [filter, setFilter] = useState({
        selected_tab : queryParams.get("selected_tab"),
    })
    return (
        <>
            <ProfileLayout default_filter={filter} />
        </>
    );
}

export default ProfileIndex;