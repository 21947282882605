import { Col, Divider, Form, Input, List, Row, Select, Spin, Switch, Tag } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import entranceService from '../../../services/config/entrance.service';
import { LoadingOutlined } from '@ant-design/icons';
import establishmentService from '../../../services/config/establishment.service';

const { TextArea } = Input;
const EditEntranceModal = ({ form, park, entrance_id, }) => {
    const [state, setState] = useState({
        loading : false,

        entrance : null,
        entrance_settings : [],
        establishments : [],
    })

    const entrance_type_is_entrance = useMemo(() => {
        const is_entrance = state?.entrance?.entrance_type?.is_entrance;

        return is_entrance;
    }, [state?.entrance]);

    const fetchEntrance = async ({ entrance_setting_list, }) => {   
        const entranceResponse = await entranceService?.getEntrance({
            entrance_id : entrance_id,
        });

        const { entrances : entrance } = entranceResponse;

        if(entrance){
            const { name, entrance_no, inout_type, display_message, exit_message, espark_id, entrance_settings, } = entrance;
            // const entrance_setting_values = entrance_settings?.map(x => ({ entrance_setting_id : x?.entrance_setting_id, value : x?.EntranceSettingValue?.value, }));
            
            // initialize form entrance
            const form_entrance_settings = entrance_setting_list?.map((form_entrance_setting, index) => {
                const { entrance_setting_id, } = form_entrance_setting;
                const entrance_setting_value = entrance_settings?.find(x => x?.entrance_setting_id === entrance_setting_id);
                const { name, EntranceSettingValue, } = entrance_setting_value ?? {};
                return {
                    entrance_setting_id : entrance_setting_id,
                    value : EntranceSettingValue?.value,
                    name : name,
                }
            })

            form?.setFieldsValue({
                name, entrance_no, inout_type, display_message, exit_message, espark_id,
                entrance_setting_values : form_entrance_settings,
            })
        }

        return entrance;
    }

    const fetchEntranceSettings = async () => {
        const entranceSettingResponse = await entranceService?.getEntranceSettings();
        const { entrance_settings, } = entranceSettingResponse;

        return entrance_settings;
    }

    const fetchEstablishments = async () => {
        // get establishments
        const establishmentResponse = await establishmentService?.getEstablishment({
            park_id : park?.park_id,
        });
        
        const { establishments, } = establishmentResponse;
        return establishments;
    }

    // initializes
    const initialize = async () => {
        setState(state => ({...state, loading : true, }));
        
        const entrance_settings = await fetchEntranceSettings();
        const establishments = await fetchEstablishments();

        const entrance = await fetchEntrance({ entrance_setting_list : entrance_settings, });

        setState(state => ({...state, loading : false, entrance : entrance, entrance_settings : entrance_settings, establishments : establishments, }));
    }

    useEffect(() => {
        initialize();
    }, [entrance_id]);

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <div>
                    <Divider orientationMargin="0" orientation='left'><PageHeaderComponent title={<span style={{ fontSize : 14, }}>Entrance Detail</span>} /></Divider>
                    <div>
                        <Form.Item name={'name'} label={"Name"}>
                            <Input />
                        </Form.Item>
                    </div>
                    <div>
                        <Row gutter={[12, 12]}>
                            <Col xs={24} sm={12} span={12}>
                                <Form.Item name={'entrance_no'} label={"Code"}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} span={12}>
                                <Form.Item name={'inout_type'} label={"Is Entry"}>
                                    <Switch />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div>
                    <Divider orientationMargin="0" orientation='left'><PageHeaderComponent title={<span style={{ fontSize : 14, }}>Entrance Type</span>} /></Divider>
                    <Row gutter={[12, 12]}>
                        <Col xs={24} sm={12} span={12}>
                            <Form.Item name={"entrance_type_id"} label={"Entrance Type"}>
                                <Tag color={state?.entrance?.entrance_type?.is_entrance ? 'blue' : 'pink'} style={{border:'none'}}>
                                    <span>{state?.entrance?.entrance_type?.name}</span>
                                </Tag>
                            </Form.Item>
                        </Col>
                        {/* Show Selections for Espark */}
                        <Col xs={24} sm={12} span={12}>
                            {
                                entrance_type_is_entrance ?
                                (
                                    <EntranceMotion>
                                        <Form.Item initialValue={park?.park_id} name={"espark_id"} label={"Park"}>
                                            <span style={{ fontWeight : 500, color : 'var(--secondary-text-color)', }}>{park?.name}</span>
                                        </Form.Item>
                                    </EntranceMotion>
                                )
                                :
                                (
                                    <EntranceMotion>
                                        <Form.Item name={"espark_id"} label={"Establishment"}>
                                            <Select
                                            showSearch
                                            optionFilterProp='label'
                                            options={state?.establishments?.map((establishment, index) => {
                                                const { establishment_id, name } = establishment;
                                                return {
                                                    value : establishment_id,
                                                    label : name,
                                                }
                                            })}
                                            />
                                        </Form.Item>
                                    </EntranceMotion>
                                )
                            }
                        </Col>
                    </Row>
                </div>

                <div>
                    <Divider orientationMargin="0" orientation='left'><PageHeaderComponent title={<span style={{ fontSize : 14, }}>Voice Message</span>} /></Divider>
                    <div>
                        <Form.Item name={"display_message"} label={"Welcome Message"}>
                            <TextArea placeholder={`Welcome to ${park?.name}`} rows={2} style={{ resize : 'none', }} />
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item name={"exit_message"} label={"Exit Message"}>
                            <TextArea placeholder={`Thank you, have a nice day`} rows={2} style={{ resize : 'none', }} />
                        </Form.Item>
                    </div>
                </div>
                
                <div>
                    <Divider orientationMargin="0" orientation='left'><PageHeaderComponent title={<span style={{ fontSize : 14, }}>Entrance Setting</span>} /></Divider>
                    <div>
                            <List
                            size='small'
                            dataSource={state?.entrance_settings}
                            bordered
                            renderItem={(item, index) => {
                                const { entrance_setting_id, name, } = item;
                                return (
                                    <>
                                        <List.Item>
                                            <div style={{ display : 'flex', width : '100%', alignItems : 'center', }}>
                                                <div>
                                                    <span>{name}</span>
                                                </div>
                                                <div style={{ marginLeft : 'auto', }}>
                                                    <Form.Item hidden initialValue={entrance_setting_id} name={['entrance_setting_values', index, `entrance_setting_id`]} style={{ marginBottom : 0, }} />
                                                    <Form.Item key={index} name={['entrance_setting_values', index, `value`]} style={{ marginBottom : 0, }}>
                                                        <Input />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </List.Item>
                                    </>
                                )
                            }}
                            />
                    </div>
                </div>
            </Spin>
        </>
    );
}

export default EditEntranceModal;