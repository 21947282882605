import React from 'react';
import PageHeaderComponent from '../../helpers/components/page_header.component';
import ContainerComponent from '../../helpers/components/container.component';
import HomeDashboard from './dashboard/home_dashboard';
import './home.css';

const HomeIndex = () => {
    return (
        <>
            <ContainerComponent span={18} top={20} bottom={20}>
                {/* <PageHeaderComponent title={'Coming Soon'}/> */}
                <HomeDashboard />
            </ContainerComponent>
        </>
    );
}

export default HomeIndex;