import React, { useEffect, useState } from 'react';
import addOnService from '../../../../services/config/add_on.service';
import { Button, Image, Popconfirm, Spin, Table } from 'antd';
import { DeleteOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';

const ConfigAddOnList = ({ filter, refresh, openEditModal = () => {}, deleteAddOn = () => {} }) => {
    const [state, setState] = useState({
        loading : false,
        add_ons : [],
    })

    const fetchAddOn = async () => {
        setState(state => ({...state, loading : true, }));
        const { add_on_types, } = filter ?? {};
        const addOnResponse = await addOnService?.fetchAddOn({
            add_on_types,
        });

        const { add_ons } = addOnResponse;

        setState(state => ({...state, loading : false, add_ons : add_ons, }))
    }

    useEffect(() => {
        fetchAddOn();
    }, [filter, refresh])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <Table
                scroll={{ x : 400, }}
                className='hub-table bordered'
                dataSource={state?.add_ons}
                columns={[{
                    title : 'Image',
                    dataIndex : 'image',
                    key : 'image',
                    width : 200,
                    render : (image, row, index) => {
                        return (
                            <>
                                {
                                    image && (
                                        <>
                                            <Image src={image} width={80} height={80} style={{ borderRadius : 8, }}  />
                                        </>
                                    )
                                }
                            </>
                        )
                    }
                },{
                    title : 'Name',
                    dataIndex : 'name',
                    key : 'name',
                    width : '50%',
                    render : (name, row, index) => {
                        const { description, } = row;
                        return (
                            <>
                                <div>
                                    <div>
                                        <span>{name}</span>
                                    </div>
                                    <div>
                                        <span style={{ fontSize : 12, color : 'var(--secondary-text-color)', }}>{description}</span>
                                    </div>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Add On Type',
                    dataIndex : 'add_on_type',
                    key : 'add_on_type',
                    render : (add_on_type, row, index) => {
                        const { name, } = add_on_type ?? {}; // add on type might be null
                        return (
                            <>
                                <span>{name}</span>
                            </>
                        )
                    }
                },{
                    title : 'Action',
                    dataIndex : 'add_on_id',
                    key : 'add_on_id',
                    width : '10%',
                    render : (add_on_id, row, index) => {
                        return (
                            <>
                                <Button.Group>
                                    <Button
                                    onClick={() => openEditModal(add_on_id)}
                                    icon={<EditOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                    />

                                    <Popconfirm
                                    onConfirm={() => deleteAddOn(add_on_id)}
                                    title="Delete Add On"
                                    overlayStyle={{ width : 250,}}
                                    >
                                        <Button
                                        icon={<DeleteOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                        />
                                    </Popconfirm>
                                </Button.Group>
                            </>
                        )
                    }
                },]}
                />
            </Spin>
        </>
    );
}

export default ConfigAddOnList;