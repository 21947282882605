import { Checkbox, Col, ColorPicker, Form, Input, InputNumber, Row, Switch } from 'antd';
import React from 'react';

const { TextArea } = Input;
const AddTicketUserTypeModal = ({ form, modal, }) => {
    return (
        <>
            <Form.Item name="name" label="Name">
                <Input />
            </Form.Item>

            <Form.Item name="descriptions" label="Description">
                <TextArea rows={4} style={{ resize : 'none', }} />
            </Form.Item>

            <Row gutter={[12, 12]}>
                <Col span={12}>
                    <Form.Item name="min_age" label={'Min Age'}>
                        <InputNumber min={0} style={{ width : '100%', }}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="max_age" label={'Max Age'}>
                        <InputNumber min={0} style={{ width : '100%', }}/>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[12, 12]}>
                <Col span={12}>
                    <Form.Item name="is_config_face" label={'Is Facial Required'}>
                        <Switch />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="color" label={'Color'}>
                        <ColorPicker format='hex' showText />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
}

export default AddTicketUserTypeModal;