import { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import authHelper from "../functions/auth.helper";
import { set_access } from "../../actions/auth.action";

export default function useAccess(link){
    const [state, setState] = useState({
        access : null,
    });

    const getAccesses = async () => {
        const access = await authHelper.checkAccess({
            link,
        });
        setState(state => ({...state, access : access,}));
    }

    useEffect(() => {
        getAccesses();

        return () => {
            setState(state => ({...state, access : null,}));
        }
    }, [link])

    return state?.access;
}