import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getPurchaseTypes(query){
    const queryString = serviceHelper.queryString(query);
    const purchaseTypeResponse = await axios.get(`config/other/purchase_type?${queryString}`, { withCredentials : true });
    return purchaseTypeResponse?.data;
}

async function getPaymentStatus(query){
    const queryString = serviceHelper.queryString(query);
    const paymentStatusResponse = await axios.get(`config/other/payment_status?${queryString}`, { withCredentials : true });
    return paymentStatusResponse?.data;
}

async function getPassType(query){
    const queryString = serviceHelper?.queryString(query);
    const passTypeResponse = await axios.get(`config/other/pass_type?${queryString}`, { withCredentials : true, });
    return passTypeResponse?.data;
}

async function getCurrency(query){
    const queryString = serviceHelper?.queryString(query);
    const currencyResponse = await axios.get(`config/other/currency?${queryString}`, { withCredentials : true, });
    return currencyResponse?.data;
}

async function getPaymentGateway(query){
    const queryString = serviceHelper?.queryString(query);
    const paymentGatewayResponse = await axios.get(`config/other/payment_gateway?${queryString}`, { withCredentials : true, });
    return paymentGatewayResponse?.data;
}

async function getMaintenanceFrequency(query){
    const queryString = serviceHelper?.queryString(query);
    const maintenanceFrequencyResponse = await axios.get(`config/other/maintenance_frequency?${queryString}`, { withCredentials : true, });
    return maintenanceFrequencyResponse?.data;
}

export default {
    getPurchaseTypes,
    getPaymentStatus,
    getPassType,
    getCurrency,
    getPaymentGateway,
    getMaintenanceFrequency,
}