import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import otherService from '../../../../services/config/other.service';

const TicketTypeBreakdownTable = ({ purchase_types, ticket_user_types }) => {
    const [state, setState] = useState({
        loading : false,
        columns : [],
        datas : [],
    })

    const initializeTable = () => {
        let datas = ticket_user_types;
        let columns = [];

        columns?.push({
            title : "Ticket Type",
            dataIndex : 'name',
            key : 'name',
            width : '25%',
        })

        for(const purchase_type of purchase_types){
            const { purchase_type_id, name, code, } = purchase_type;

            columns.push({
                title : name,
                dataIndex : code,
                key : code,
                render: (text, row, index) => {
                    const { expected, visitors } = text;
                    return (
                        <>
                            <div>
                                <span style={{color:'var(--main-color)'}}>{expected}</span>
                            </div>
                        </>
                    )
                }
            })
        }

        columns?.push({
            title : "Checked-In",
            dataIndex : 'ticket_user_type_checked_in',
            key : 'ticket_user_type_checked_in',
            // width : '25%',
        })

        setState(state => ({...state, columns : columns, datas : datas,}));
    }

    useEffect(() => {
        initializeTable();
    }, [ticket_user_types])

    return (
        <>
            <Table
            scroll={{ x : 400, }}
            size='small'
            pagination={false}
            className='hub-table bordered'
            dataSource={state?.datas}
            columns={state?.columns}
            />
        </>
    );
}

export default TicketTypeBreakdownTable;