export const refresh_promo_code_detail = () => {
    return {
        type : 'REFRESH_PROMO_CODE_DETAIL',
    };
}

export const set_valid_promo_code = (is_valid_promo_code) => {
    return {
        type : 'SET_VALID_PROMO_CODE',
        is_valid_promo_code,
    }
}