export const set_edit_account_modal = (edit_account_modal,) => {
    return {
        type : 'SET_EDIT_ACCOUNT_MODAL',
        edit_account_modal,
    };
}

export const refresh_account_list = () => {
    return {
        type : 'REFRESH_ACCOUNT_LIST',
    }
}

export const set_role = (role) => {
    return {
        type : 'SET_ROLE',
        role,
    }
}