import React, { useEffect, useState } from 'react';
import parkService from '../../../services/config/park.service';
import { Empty, Input, Space, Spin, Table, Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const AddOnList = ({ form, park, ticket_type, }) => {
    const [state, setState] = useState({
        loading : false,
        park_applicable_add_ons : [],

        // quantity
        add_on_quantity : [],
    })

    const fetchAddOns = async () => {
        setState(state => ({...state, loading : true, }));
        const park_applicable_ticket_type_id = (ticket_type?.ParkApplicableTicketType?.park_applicable_ticket_type_id);
        const parkApplicableTicketUserResponse = await parkService.getParkApplicableTicketTypeitems({
            park_applicable_ticket_type_id
        });

        const { park_applicable_add_ons } = parkApplicableTicketUserResponse;
        setState(state => ({...state, loading : false, park_applicable_add_ons : park_applicable_add_ons, }));
    }

    const setQuantity = ({ add_on_id, quantity, name, price, add_on_setup_id, }) => {
        let add_on_quantity = state?.add_on_quantity;
        let existing_item_quantity = state?.add_on_quantity?.find(x => x.add_on_id == add_on_id);

        if(existing_item_quantity){
            const calculated_quantity = quantity;
            existing_item_quantity.quantity = calculated_quantity;
            let changed_quantity = add_on_quantity;
            if(calculated_quantity > 0){
                changed_quantity = add_on_quantity?.map((x) => {
                    return (x?.add_on_id === add_on_id) ? existing_item_quantity : x;
                })
            }else{
                changed_quantity = add_on_quantity?.filter((x) => {
                    return (x?.add_on_id != add_on_id);
                })
            }
            //add quantity
            add_on_quantity = changed_quantity;
        }else{
            add_on_quantity?.push({
                add_on_id, quantity, name, price, add_on_setup_id,
            });
        }

        setState(state => ({...state, add_on_quantity : add_on_quantity}));
        form.setFieldsValue({ add_ons : add_on_quantity });
    }


    const setPrice = ({ price, name, add_on_id, }) => {
        let add_on_quantity = state?.add_on_quantity;
        let existing_add_on_quantity = state?.add_on_quantity?.find(x => x.add_on_id === add_on_id);

        if(existing_add_on_quantity){
            const calculated_price = price;
            existing_add_on_quantity.price = calculated_price;
            let changed_price = add_on_quantity;
            changed_price = add_on_quantity?.map((x) => {
                return (x?.add_on_id === add_on_id) ? existing_add_on_quantity : x;
            })
        }else{
            add_on_quantity?.push({
                add_on_id, quantity : null, name, price,
            });
        }

        setState(state => ({...state, add_on_quantity : add_on_quantity}));
        form.setFieldsValue({ add_ons : add_on_quantity });
    }

    useEffect(() => {
        fetchAddOns();
    }, [park, ticket_type,])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <Table
                size='small'
                scroll={{ x : 400, }}
                className='hub-table bordered'
                pagination={false}
                dataSource={state?.park_applicable_add_ons}
                columns={[{
                    title : 'Name',
                    dataIndex : 'add_on',
                    key : 'add_on',
                    render : (add_on, row, index) => {
                        const { name, description } = add_on ?? {};
                        return (
                            <>
                                <div>
                                    <div><span>{name}</span></div>
                                    <div><span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{description}</span></div>
                                </div>
                            </>
                        )
                    }
                },
                {
                    // title : 'Price',
                    title : (
                        <>
                            <Space>
                                <div>
                                    <span>Price</span>
                                </div>
                                <div>
                                    <Tag color='blue' style={{border : 'none',}}>
                                        {park?.currency?.code}
                                    </Tag>
                                </div>
                            </Space>
                        </>
                    ),
                    dataIndex : 'price',
                    key : 'price',
                    // width: 150,
                    // fixed: 'right',
                    render : (price, row, index) => {
                        const { add_on_id, add_on, } = row;
                        const { name } = add_on;

                        const price_item = state?.add_on_quantity?.find(x => x.add_on_id === add_on_id);
                        const price_amount = (price_item?.price ?? price);
                        return (
                            <>
                                <Input
                                value={price_amount}
                                onChange={(e) => setPrice({ price : e.target.value, name, add_on_id, })}
                                style={{maxWidth:200}}
                                />
                            </>
                        )
                    }
                },
                {
                    title : 'Quantity',
                    dataIndex : 'add_on',
                    key : 'add_on',
                    width: 150,
                    fixed: 'right',
                    render : (add_on, row, index) => {
                        const { price, add_on_id, add_on_setup_id, } = row;
                        const { name, } = add_on ?? {};
                        const quantity_item = state?.ticket_applicable_user_quantity?.find(x => x.add_on_id === add_on_id);
                        return (
                            <>
                                <Input
                                value={quantity_item?.quantity}
                                onChange={(e) => setQuantity({ price, name, add_on_id, quantity : e?.target?.value, add_on_setup_id, })}
                                style={{maxWidth:200}}
                                />
                            </>
                        )
                    }
                }]}
                />
            </Spin>
        </>
    );
}

export default AddOnList;