import React, { useEffect, useState, useRef } from 'react';
import VisitorTable from './visitor_table';
import PageHeaderComponent from '../../../../helpers/components/page_header.component';
import { Button, Checkbox, DatePicker, Divider, Input, List, Radio, Space } from 'antd';
import FilterBullet from '../../../../helpers/components/filter_bullet';
import { CaretDownFilled, PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import parkService from '../../../../services/config/park.service';
import dayjs from 'dayjs';
import ReactToPrint from 'react-to-print';
import serviceHelper from '../../../../helpers/functions/service.helper';

const VisitorReport = ({ default_filter }) => {
    const { innerWidth } = window;
    const componentRef = useRef();
    const [state, setState] = useState({

    });
    
    const [filter, setFilter] = useState({
        park_id : default_filter?.park_id,
        visit_date : default_filter?.visit_date ? dayjs(default_filter?.visit_date)?.format("YYYY-MM-DD") : dayjs()?.format("YYYY-MM-DD"),
    });

    const fetchFilterItem = async () => {
        const parkResponse = await parkService?.getParkByAuth();
        setState(state => ({...state, parks : parkResponse?.parks}));
    }

    const changeDate = (date) => {
        const visit_date = date ? dayjs(date)?.format("YYYY-MM-DD") : dayjs()?.format("YYYY-MM-DD");
        setFilter(filter => ({...filter, visit_date : visit_date}));

        changeFilter("visit_date", visit_date);
    }

    const onChange = (key, checkedValues) => {
        setFilter(filter => ({...filter, [key] : checkedValues}))
        changeFilter(key, checkedValues);
    }

    // for changing filter
    const changeFilter = (key, value) => {
        let generate_filter = default_filter;
        generate_filter[key] = value;
        const queryString = serviceHelper?.queryString(generate_filter);
        window.history.replaceState({}, '', `${window.location.pathname}?${queryString}`);
    }

    useEffect(() => {
        fetchFilterItem();
    }, []);

    return (
        <>
            <div className='mobile-padding-12'>
                <div style={{marginBottom: 24, textAlign:'start'}}>
                    <PageHeaderComponent title={'Daily Visitor & Check-In Report'} />
                </div>
                <div className='filter-panel'>
                    <div style={{display:'flex', alignItems:'center', }}>
                        <div style={{textAlign:'start',}}>
                            <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                                <FilterBullet
                                popover={
                                    <>
                                        <div>
                                            <Input prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>} placeholder='Search Parks' />
                                        </div>
                                        <Radio.Group
                                        value={filter?.park_id}
                                        // defaultValue={(state?.parks?.length > 0 && state?.parks[0]?.park_id)}
                                        onChange={(e) => onChange('park_id', e.target.value)}
                                        >
                                            <List
                                            style={{ width:280, }}
                                            dataSource={state?.parks}
                                            renderItem={(item, index) => {
                                                const { park_id, name, } = item ?? {};
                                                return (
                                                    <List.Item style={{width:'100%'}} key={index}>
                                                        <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                            <div>
                                                                <span>{name}</span>
                                                            </div>
                                                            <div style={{marginLeft:'auto'}}>
                                                                <Radio
                                                                value={park_id}
                                                                >

                                                                </Radio>
                                                            </div>
                                                        </div>
                                                    </List.Item>
                                                )
                                            }}
                                            />
                                        </Radio.Group>
                                    </>
                                }
                                >
                                    <div>
                                        <Space>
                                            <div>
                                                <span>Park{filter?.park_id && `: ${state?.parks?.find(x => x.park_id === filter?.park_id)?.name}` }</span>
                                            </div>
                                            <div>
                                                <CaretDownFilled style={{color:'#a0aec0'}} />
                                            </div>
                                        </Space>
                                        
                                    </div>
                                </FilterBullet>


                                <FilterBullet
                                popover={
                                    <>
                                        <div>
                                            <DatePicker
                                            allowClear
                                            onChange={(e) => changeDate(e)} format={"YYYY-MM-DD"} 
                                            />
                                        </div>
                                    </>
                                }
                                >
                                    <div>
                                        <Space>
                                            <div>
                                                <span>Date
                                                    <span style={{fontSize:12, fontWeight:500, color:'var(--secondary-text-color)'}}>
                                                        {filter?.visit_date && `: ${filter?.visit_date}`}
                                                    </span>
                                                </span>
                                            </div>
                                            <div>
                                                <CaretDownFilled style={{color:'#a0aec0'}} />
                                            </div>
                                        </Space>
                                        
                                    </div>
                                </FilterBullet>
                            </Space>
                        </div>
                        <div style={{marginLeft:'auto'}}>
                        <ReactToPrint 
                        trigger={() => (
                            <Button icon={<PrinterOutlined />}>Print</Button>
                        )}
                        content={() => componentRef.current}
                        pageStyle={`@page {
                            margin: 24px;
                        }`}
                        />
                        </div>
                    </div>
                </div>
                <Divider />
                <div ref={componentRef} style={{marginTop:12,}}>
                    <VisitorTable filter={filter} />
                </div>
            </div>
        </>
    );
}

export default VisitorReport;