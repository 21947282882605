import { AppstoreOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Form, Modal, Popconfirm, Space, Table, notification } from 'antd';
import React, { useEffect, useState } from 'react'
import parkService from '../../../services/config/park.service';
import AddFreeTicketRuleModal from './add_free_ticket_rule_modal';
import EditFreeTicketRuleModal from './edit_free_ticket_rule_modal';

const FreeTicketRuleLayout = ({ park, }) => {
    const [save_form] = Form.useForm();
    const [edit_form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        free_ticket_rules : [],

        create_modal : false,
        edit_modal : null,

        save_loading : false,
        update_loading : false,

        refresh : 0,
        modal : 0,
    });

    const saveFreeTicketRule = async () => {
        setState(state => ({...state, save_loading : true, }));
        const { name, free_quantity, free_ticket_applicable_user, free_ticket_rule_requires, park_applicable_ticket_type_id, required_quantity, } = save_form?.getFieldsValue();
        const saveFreeTicketRuleResponse = await parkService?.saveFreeTicketRule({
            name,
            free_quantity,
            free_ticket_applicable_user_id : free_ticket_applicable_user,
            free_ticket_rule_require_ticket_applicable_users : free_ticket_rule_requires,
            // park_applicable_ticket_type_id,
            required_quantity,
        })
        
        notification?.success({
            message : 'Added Ticket Condition',
        })

        setState(state => ({...state, save_loading : false, refresh : (state?.refresh + 1), create_modal : false,}));
    }

    const updateFreeTicketRule = async () => {
        setState(state => ({...state, update_loading : true, }));
        const free_ticket_rule_id = state?.edit_modal;
        const { name, free_quantity, free_ticket_applicable_user, free_ticket_rule_requires, required_quantity, } = edit_form?.getFieldsValue();
        const updateFreeTicketRuleResponse = await parkService?.updateFreeTicketRule(free_ticket_rule_id, {
            name,
            free_quantity,
            required_quantity,
            free_ticket_applicable_user_id : free_ticket_applicable_user,
            free_ticket_rule_require_ticket_applicable_users : free_ticket_rule_requires,
        });

        notification?.success({
            message : 'Updated Ticket Condition',
        })

        setState(state => ({...state, update_loading : false, refresh : (state?.refresh + 1), edit_modal : null, }));
    }

    const deleteFreeTicketRule = async (free_ticket_rule_id) => {
        const deleteFreeTicketRuleResponse = await parkService?.deleteFreeTicketRule(free_ticket_rule_id);
        
        notification?.success({
            message : 'Free Ticket Rule Deleted',
        })

        setState(state => ({...state, refresh : (state?.refresh + 1)}));
    }

    const fetchFreeTicketRules = async () => {
        setState(state => ({...state, loading : true, }));

        const freeTicketRuleResponse = await parkService?.getFreeTicketRule({
            park_id : park?.park_id,
        });

        const { free_ticket_rules, } = freeTicketRuleResponse;

        setState(state => ({...state, loading : false, free_ticket_rules : free_ticket_rules, }));
    }

    useEffect(() => {   
        fetchFreeTicketRules();
    }, [park?.park_id, state?.refresh])

    return (
        <>
            <Card
            styles={{ body : { padding : 12, } }}
            >
                <div style={{ display : 'flex', alignItems : 'center', }}>
                    <div style={{ textAlign : 'start', }}>
                        <div>
                            <span>Ticket Purchase Condition</span>
                        </div>
                        <div>
                            <span style={{ fontSize : 12, color : 'var(--secondary-text-color)', }}>Conditions to get or purchase specific ticket</span>
                        </div>
                    </div>
                    <div style={{ marginLeft : 'auto', }}>
                        <Button
                        icon={<PlusOutlined />}
                        onClick={() => setState(state => ({...state, create_modal : true, modal : (state?.modal + 1), }))}
                        >
                            Ticket Purchase Condition
                        </Button>
                    </div>
                </div>

                <div style={{ marginTop : 12, }}>
                    <Table
                    className='hub-table bordered nested-table'
                    size='small'
                    dataSource={state?.free_ticket_rules}
                    pagination={false}
                    columns={[{
                        title : 'Name',
                        dataIndex : 'name',
                        key : 'name',
                    },{
                        title : 'Ticket Type',
                        dataIndex : 'free_ticket_applicable_users',
                        key : 'free_ticket_applicable_users',
                        render : (free_ticket_applicable_users, row, index) => {
                            const { image, ticket_type } = free_ticket_applicable_users?.park_applicable_ticket_type ?? {};
                            return (
                                <>
                                    <Space>
                                        <div>
                                            <Avatar icon={<AppstoreOutlined />} shape='square' size='small' src={image} />
                                        </div>
                                        <div>
                                            <span>{ticket_type?.name}</span>
                                        </div>
                                    </Space>
                                </>
                            )
                        }
                    },{
                        title : 'Tickets',
                        dataIndex : 'free_ticket_applicable_users',
                        key : 'free_ticket_applicable_users',
                        render : (free_ticket_applicable_users, row, index) => {
                            const { free_quantity, } = row;
                            const { ticket_user_type, } = free_ticket_applicable_users ?? {};
                            return (
                                <>
                                    <Space>
                                        <div>
                                            <span>{free_quantity}</span>
                                        </div>
                                        <div>
                                            <span>{ticket_user_type?.name}</span>
                                        </div>
                                    </Space>
                                </>
                            )
                        }
                    }, {
                        title : 'Condition',
                        dataIndex : 'free_ticket_rule_requires',
                        key : 'free_ticket_rule_requires',
                        render : (free_ticket_rule_requires, row, index) => {
                            const { required_quantity } = row;
                            const required_ticket_user_types = (free_ticket_rule_requires?.map(x => x?.required_ticket_applicable_users?.ticket_user_type));
                            const ticket_user_types = required_ticket_user_types?.map(x => x?.name)?.join(", ");
                            return (
                                <>
                                    <Space>
                                        <div>
                                            <span>Purchase {required_quantity} tickets</span>
                                        </div>
                                        <div>
                                            <span>({ticket_user_types})</span>
                                        </div>
                                    </Space>
                                </>
                            )
                        }
                    },{
                        title : 'Action',
                        dataIndex : 'free_ticket_rule_id',
                        key : 'free_ticket_rule_id',
                        fixed : 'right',
                        width : 100,
                        render : (free_ticket_rule_id, row, index) => {
                            return (
                                <>
                                    <Button.Group>
                                        <Button
                                        size='small'
                                        icon={<EditOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                        onClick={() => setState(state => ({...state, edit_modal : free_ticket_rule_id, modal : (state?.modal + 1), }))}
                                        />
                                        <Popconfirm
                                        onConfirm={() => deleteFreeTicketRule(free_ticket_rule_id)}
                                        title="Delete Ticket Purchase Condition"
                                        // description={name}
                                        overlayStyle={{ width : 250,}}
                                        >
                                            <Button
                                            size='small'
                                            icon={<DeleteOutlined style={{color : 'var(--secondary-text-color)',}} />}
                                            />
                                        </Popconfirm>
                                    </Button.Group>
                                </>
                            )
                        }
                    },]}
                    />
                </div>
            </Card>

            <Modal
            title="New Ticket Purchase Condition"
            open={state?.create_modal}
            onCancel={() => {
                setState(state => ({...state, create_modal : null, modal : (state?.modal + 1), }));
            }}
            onOk={() => save_form.submit()}
            width={800}
            confirmLoading={state?.save_loading}
            >
                <Form
                form={save_form}
                layout='vertical'
                onFinish={saveFreeTicketRule}
                >
                    <AddFreeTicketRuleModal form={save_form} park={park} modal_visible={state?.modal} />
                </Form>
            </Modal>

            <Modal
            title="New Ticket Purchase Condition"
            open={state?.edit_modal}
            onCancel={() => {
                setState(state => ({...state, edit_modal : null, modal : (state?.modal + 1), }));
            }}
            onOk={() => edit_form.submit()}
            width={800}
            confirmLoading={state?.update_loading}
            >
                <Form
                form={edit_form}
                layout='vertical'
                onFinish={updateFreeTicketRule}
                >
                    <EditFreeTicketRuleModal form={edit_form} park={park} modal_visible={state?.modal} free_ticket_rule_id={state?.edit_modal} />
                </Form>
            </Modal>
        </>
    )
}

export default FreeTicketRuleLayout;