import React, { useEffect, useState } from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { Divider, Input, List, Radio, Space, Tabs } from 'antd';
import FilterBullet from '../../../helpers/components/filter_bullet';
import parkService from '../../../services/config/park.service';
import { CaretDownFilled, SearchOutlined } from '@ant-design/icons';
import serviceHelper from '../../../helpers/functions/service.helper';
import ParkConfigDetail from '../ticket_type/park_config_detail';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import ParkProfile from '../profile/park_profile';
import DateConfigCalendar from '../date_config/date_config_calendar';
import EntranceLayout from '../entrance/entrance_layout';
import TicketLayout from '../ticket_v2/ticket_layout';
import AddOnLayout from '../add_on/add_on_layout';
import TicketRuleLayout from '../ticket_rule/ticket_rule_layout';
import TaxLayout from '../tax/tax_layout';
import InvoiceLayout from '../invoice/invoice_layout';

const ParkConfigLayout = ({ default_filter, }) => {
    const { innerWidth } = window;
    const [state, setState] = useState({
        loading : false,
        parks : [],
    })

    const [filter, setFilter] = useState({
        park : default_filter?.park,
        tab : default_filter?.tab ?? 'park',
    });

    const onChange = (key, checkedValues) => {
        setFilter(filter => ({...filter, [key] : checkedValues}))
        changeFilter(key, checkedValues);
    }

    // for changing filter
    const changeFilter = (key, value) => {
        let generate_filter = default_filter;
        generate_filter[key] = value;
        const queryString = serviceHelper?.queryString(generate_filter);
        window.history.replaceState({}, '', `${window.location.pathname}?${queryString}`);
    }

    const initialize = async () => {
        const parkResponse = await parkService?.getParkByAuth();
        const { parks, } = parkResponse;
        setState(state => ({...state, parks : parks, }));
    }

    useEffect(() => {
        initialize();
    }, [])

    return (
        <>
            <div className='mobile-padding-12'>
                <div style={{marginBottom: 24, textAlign:'start'}}>
                    <PageHeaderComponent title={'Park Management'} />
                </div>
                <div className='filter-panel'>
                    <div style={{display:'flex', alignItems:'center', }}>
                        <div style={{textAlign:'start',}}>
                            <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                                <FilterBullet
                                popover={
                                    <>
                                        <div>
                                            <Input prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>} placeholder='Search Parks' />
                                        </div>
                                        
                                        <Radio.Group
                                        value={filter?.park}
                                        onChange={(e) => onChange('park', e.target.value)}
                                        >
                                            <List
                                            style={{ width:280, }}
                                            dataSource={state?.parks}
                                            renderItem={(item, index) => {
                                                const { park_id, name, } = item ?? {};
                                                return (
                                                    <List.Item style={{width:'100%'}} key={index}>
                                                        <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                            <div>
                                                                <span>{name}</span>
                                                            </div>
                                                            <div style={{marginLeft:'auto'}}>
                                                                <Radio
                                                                value={park_id}
                                                                >
                                                                </Radio>
                                                            </div>
                                                        </div>
                                                    </List.Item>
                                                )
                                            }}
                                            />
                                        </Radio.Group>
                                    </>
                                }
                                >
                                    <div>
                                        <Space>
                                            <div>
                                                <span>Park{filter?.park && `: ${state?.parks?.find(x => x.park_id === filter?.park)?.name}` }</span>
                                            </div>
                                            <div>
                                                <CaretDownFilled style={{color:'#a0aec0'}} />
                                            </div>
                                        </Space>
                                        
                                    </div>
                                </FilterBullet>
                            </Space>
                        </div>
                    </div>
                </div>
                <Divider />
                <div>
                    {
                        filter?.park ?
                        (
                            <>
                                <div style={{marginTop : 12, marginBottom: 20,}}>
                                    <Tabs
                                    destroyInactiveTabPane
                                    defaultActiveKey={filter?.tab}
                                    onChange={(value) => changeFilter('tab', value)}
                                    items={[
                                    {
                                        key: 'park',
                                        label: 'Park Detail',
                                        children: (
                                            <>
                                                <EntranceMotion>
                                                    <ParkProfile park_id={filter?.park} />
                                                </EntranceMotion>
                                            </>
                                        ),
                                    },
                                    {
                                        key: 'ticket_type',
                                        label: 'Ticket Type',
                                        children: (
                                            <>
                                                <EntranceMotion>
                                                    <ParkConfigDetail park_id={filter?.park} />
                                                </EntranceMotion>
                                            </>
                                        ),
                                    },
                                    {
                                        key: 'ticket',
                                        label: 'Tickets',
                                        children: (
                                            <>
                                                <EntranceMotion>
                                                    <TicketLayout park_id={filter?.park} />
                                                </EntranceMotion>
                                            </>
                                        ),
                                    },
                                    {
                                        key: 'ticket_rule',
                                        label: 'Ticket Rules',
                                        children: (
                                            <>
                                                <EntranceMotion>
                                                    <TicketRuleLayout park_id={filter?.park} />
                                                </EntranceMotion>
                                            </>
                                        ),
                                    },
                                    {
                                        key: 'add_on',
                                        label: 'Add Ons',
                                        children: (
                                            <>
                                                <EntranceMotion>
                                                    <AddOnLayout park_id={filter?.park} />
                                                </EntranceMotion>
                                            </>
                                        ),
                                    },
                                    {
                                        key: 'tax',
                                        label: 'Taxes',
                                        children: (
                                            <>
                                                <EntranceMotion>
                                                    <TaxLayout park_id={filter?.park} />
                                                </EntranceMotion>
                                            </>
                                        ),
                                    },
                                    {
                                        key: 'date',
                                        label: 'Calendar',
                                        children: (
                                            <>
                                                <EntranceMotion>
                                                    <DateConfigCalendar park_id={filter?.park} />
                                                </EntranceMotion>
                                            </>
                                        ),
                                    },
                                    {
                                        key: 'invoice',
                                        label: 'Invoice',
                                        children: (
                                            <>
                                                <EntranceMotion>
                                                    <InvoiceLayout park_id={filter?.park} />
                                                </EntranceMotion>
                                            </>
                                        ),
                                    },
                                    {
                                        key: 'entrance',
                                        label: 'Entrance',
                                        children: (
                                            <>
                                                <EntranceMotion>
                                                    <EntranceLayout park_id={filter?.park} />
                                                </EntranceMotion>
                                            </>
                                        ),
                                    },
                                    // {
                                    //     key: 'report_email',
                                    //     label: 'Report Email',
                                    //     children: (
                                    //         <>
                                    //             <EntranceMotion>
                                    //                 Report Email
                                    //             </EntranceMotion>
                                    //         </>
                                    //     ),
                                    // },
                                    ]}
                                    className='custom-tabs'
                                    animated
                                    />
                                </div>
                            </>
                        )
                        :
                        (
                            <>
                                <div>
                                    <span>Please select parks</span>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
            
        </>
    );
}

export default ParkConfigLayout;