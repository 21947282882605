import React, { useEffect, useState } from 'react';
import parkService from '../../../services/config/park.service';
import { Alert, Col, Input, InputNumber, Row, Space, Spin, Table, Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';

const GenerateMaTicketUserList = ({ park, ticket_type, form, capacity, }) => {
    const [state, setState] = useState({
        loading : false,
        park_applicable_ticket_users : [],
        ticket_applicable_user_quantity : [],
    })

    const fetchTicketUserList = async () => {
        setState(state => ({...state, loading : true, }));

        // get ticket user & age group
        const park_applicable_ticket_type_id = (ticket_type?.ParkApplicableTicketType?.park_applicable_ticket_type_id);
        const parkApplicableTicketUserResponse = await parkService.getParkApplicableTicketTypeitems({
            park_applicable_ticket_type_id,
            purchase_type_code : 'MA',
        });

        setTimeout(() => {
            setState(state => ({...state, loading : false, park_applicable_ticket_users : parkApplicableTicketUserResponse?.park_applicable_ticket_users, ticket_applicable_user_quantity : []}));
        }, 200);        
    }

    const setQuantity = ({ ticket_user_type_id, ticket_applicable_user_id, quantity, name, price}) => {
        let ticket_applicable_user_quantity = state?.ticket_applicable_user_quantity;
        let existing_item_quantity = state?.ticket_applicable_user_quantity?.find(x => x.ticket_applicable_user_id === ticket_applicable_user_id);
        if(existing_item_quantity){
            const calculated_quantity = quantity;
            existing_item_quantity.quantity = calculated_quantity;
            let changed_quantity = ticket_applicable_user_quantity;
            if(calculated_quantity > 0){
                changed_quantity = ticket_applicable_user_quantity?.map((x) => {
                    return (x?.ticket_applicable_user_id === ticket_applicable_user_id) ? existing_item_quantity : x;
                })
            }else{
                changed_quantity = ticket_applicable_user_quantity?.filter((x) => {
                    return (x?.ticket_applicable_user_id != ticket_applicable_user_id);
                })
            }
            //add quantity
            ticket_applicable_user_quantity = changed_quantity;
        }else{
            ticket_applicable_user_quantity?.push({
                ticket_applicable_user_id, quantity, name, price, ticket_user_type_id,
            });
        }

        setState(state => ({...state, ticket_applicable_user_quantity : ticket_applicable_user_quantity}));
        
        form.setFieldsValue({ ticket_users : ticket_applicable_user_quantity });
    }

    const setPrice = ({ price, name, ticket_user_type_id, ticket_applicable_user_id, }) => {
        let ticket_applicable_user_quantity = state?.ticket_applicable_user_quantity;
        let existing_item_quantity = state?.ticket_applicable_user_quantity?.find(x => x.ticket_applicable_user_id === ticket_applicable_user_id);

        if(existing_item_quantity){
            const calculated_price = price;
            existing_item_quantity.price = calculated_price;
            let changed_price = ticket_applicable_user_quantity;
            changed_price = ticket_applicable_user_quantity?.map((x) => {
                return (x?.ticket_applicable_user_id === ticket_applicable_user_id) ? existing_item_quantity : x;
            })
            //add quantity
            ticket_applicable_user_quantity = changed_price;
        }else{
            ticket_applicable_user_quantity?.push({
                ticket_applicable_user_id, quantity : null, name, price, ticket_user_type_id,
            });
        }

        setState(state => ({...state, ticket_applicable_user_quantity : ticket_applicable_user_quantity}));
        
        form.setFieldsValue({ ticket_users : ticket_applicable_user_quantity });
    }

    useEffect(() => {
        fetchTicketUserList();
    }, [park, ticket_type,]);

    

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined style={{color:'var(--main-color)'}} />}>
                {/* {
                    capacity?.is_capacity &&
                    (
                        <>
                            <div>
                                <Spin spinning={capacity?.loading} indicator={<LoadingOutlined />}>
                                    <Alert
                                    message={(
                                        <>
                                            <Row gutter={[12, 12]} align={'middle'}>
                                                <Col span={12}>
                                                    <div>
                                                        <span style={{fontWeight:500,}}>{`Remaining Tickets : ${capacity?.remaining_capacity}`}</span>
                                                    </div>
                                                </Col>
                                                <Col span={12}>
                                                    <div>
                                                        <Space direction='vertical' style={{width : '100%',}}>
                                                            <div>
                                                                <Tag color='geekblue' style={{border: 'none', width : '100%', textAlign:'center',}}>
                                                                    <span>{`Total capacity : ${capacity?.total_capacity}`}</span>
                                                                </Tag>
                                                            </div>
                                                            <div>
                                                                <Tag color='green' style={{border: 'none', width : '100%', textAlign:'center',}}>
                                                                    <span>{`Purchased tickets : ${capacity?.complete_tickets}`}</span>
                                                                </Tag>
                                                            </div>
                                                        </Space>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                    />
                                </Spin>
                            </div>
                        </>
                    )
                } */}
                
                <div style={{marginTop:12,}}>
                    <Table
                    scroll={{ x : 400, }}
                    className='hub-table bordered'
                    pagination={false}
                    dataSource={state?.park_applicable_ticket_users}
                    columns={[{
                        title : 'Name',
                        dataIndex : 'ticket_user_type',
                        key : 'name',
                        render : (text, row, index) => {
                            const { name, min_age, max_age } = text ?? {};
                            return (
                                <>
                                    <div>
                                        <Space>
                                            <div>
                                                <span>{`${name} 
                                                (${
                                                    `${max_age ? 
                                                    `${min_age} - ${max_age}` :
                                                    `${min_age} and above`
                                                }`
                                                })
                                                `}</span>
                                            </div>
                                        </Space>
                                    </div>
                                </>
                            )
                        }
                    },
                    {
                        title : (
                            <>
                                <Space>
                                    <div>
                                        <span>Price</span>
                                    </div>
                                    <div>
                                        <Tag color='blue' style={{border : 'none',}}>
                                            {park?.currency?.code}
                                        </Tag>
                                    </div>
                                </Space>
                            </>
                        ),
                        dataIndex : 'ticket_user_type',
                        key : 'price',
                        // width: 150,
                        // fixed: 'right',
                        render : (text, row, index) => {
                            const { name, ticket_user_type_id,} = text ?? {}
                            const { ticket_applicable_user_id, price } = row ?? {};
                            const price_item = state?.ticket_applicable_user_quantity?.find(x => x.ticket_applicable_user_id === ticket_applicable_user_id);
                            const price_amount = (price_item?.price ?? price);
                            return (
                                <>
                                    <InputNumber
                                    precision={2}
                                    value={price_amount}
                                    onChange={(e) => setPrice({ price : e, name, ticket_user_type_id, ticket_applicable_user_id, })}
                                    style={{width : '100%',}}
                                    />
                                </>
                            )
                        }
                    },
                    {
                        title : 'Quantity',
                        dataIndex : 'ticket_user_type',
                        key : 'quantity',
                        width: 150,
                        fixed: 'right',
                        render : (text, row, index) => {
                            const { name, ticket_user_type_id,} = text ?? {}
                            const { ticket_applicable_user_id, price } = row ?? {};
                            const quantity_item = state?.ticket_applicable_user_quantity?.find(x => x.ticket_applicable_user_id === ticket_applicable_user_id);
                            // const amount_price = 
                            return (
                                <>
                                    <InputNumber
                                    value={quantity_item?.quantity}
                                    onChange={(e) => setQuantity({ price, name, ticket_user_type_id, ticket_applicable_user_id, quantity : e, })}
                                    style={{width : '100%',}}
                                    />
                                </>
                            )
                        }
                    }]}
                    />
                </div>
            </Spin>
        </>
    );
}

export default GenerateMaTicketUserList;