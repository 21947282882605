import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getEntrance(query){
    const queryString = serviceHelper.queryString(query);
    const entranceResponse = await axios.get(`config/entrance?${queryString}`, { withCredentials : true });
    return entranceResponse?.data;
}

async function getEntranceByPark(query){
    const queryString = serviceHelper.queryString(query);
    const entranceResponse = await axios.get(`config/entrance/park?${queryString}`, { withCredentials : true });
    return entranceResponse?.data;
}

async function getEntranceTypes(query){
    const queryString = serviceHelper?.queryString(query);
    const entranceTypeResponse = await axios.get(`config/entrance/type?${queryString}`, { withCredentials : true, });
    return entranceTypeResponse?.data;
}

async function getEntranceSettings(query){
    const queryString = serviceHelper?.queryString(query);
    const entranceSettingRespones = await axios.get(`config/entrance/setting?${queryString}`, { withCredentials : true, });
    return entranceSettingRespones?.data;
}

async function saveEntrance(params){
    const saveEntranceResponse = await axios.post(`config/entrance/save`, params, { withCredentials : true, });
    return saveEntranceResponse?.data;
}

async function updateEntrance(entrance_id, params){
    const updateEntranceResponse = await axios.post(`config/entrance/${entrance_id}/update`, params, { withCredentials : true, });
    return updateEntranceResponse?.data;
}

async function deleteEntrance(entrance_id){
    const deleteEntrance = await axios.delete(`config/entrance/${entrance_id}/delete`, { withCredentials : true, });
    return deleteEntrance?.data;
}

export default {
    getEntrance,
    getEntranceByPark,
    getEntranceTypes,
    getEntranceSettings,
    saveEntrance,
    updateEntrance,
    deleteEntrance,
}