import { Badge, Card, List, Space } from 'antd';
import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import numberHelper from '../../../../helpers/functions/number.helper';

const BreakdownAddOnType = ({add_on_types, park,}) => {
    const { innerWidth } = window;
    const [state, setState] = useState({
        chart_data : null,
    })

    const options = {
        spacing : 4,
        plugins: {
            legend: {
                // position : 'right',
                display: false,
            }
        }
    }

    const backgroundColor = [
        '#0555E1',
        '#94B5F2',
        '#D3DFFA',
        '#D1E0F8',
        '#F2F5FF',

        // second
        '#0555E1',
        '#94B5F2',
        '#D3DFFA',
        '#D1E0F8',
        '#F2F5FF',
        
    ];


    const initializePurchaseTypeData = async () => {
        const sorted_add_on_types = add_on_types?.sort((a, b) => b.sales_amount - a.sales_amount);
        const data = {
            labels: sorted_add_on_types?.map(x => x?.name),
            datasets: [
                {
                label: `${park?.currency?.code}`,
                data: sorted_add_on_types?.map(x => x.sales_amount),
                backgroundColor: backgroundColor,
                borderColor: [
                    'transparent'
                    // 'rgba(54, 162, 235, 1)',
                    // 'rgba(54, 162, 235, 1)',
                    // 'rgba(255, 206, 86, 1)',
                    // 'rgba(75, 192, 192, 1)',
                    // 'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
                },
            ],
        }

        return data;
    }

    useEffect(() => {
        initializePurchaseTypeData().then(data => {
            setState(state => ({...state, chart_data : data}));
        })
    }, [add_on_types])
    return (
        <>
            <Card>
                <div style={{display:'flex', justifyContent :'start', alignContent:'center',}}>
                    <div 
                    style={{height: (innerWidth <= 768 ? 180 : 150)}}
                    >
                        <Doughnut
                        data={state?.chart_data ?? {
                            labels : [],
                            datasets : [],
                        }}
                        options={options}
                        />
                    </div>

                    <div style={{marginLeft:20, width:'100%'}}>
                    <List
                        size='small'
                        dataSource={add_on_types}
                        renderItem={(add_on_type, index) => {
                            const color = backgroundColor[index] ?? 'grey';
                            const { name, sales_amount, count } = add_on_type ?? { };
                            return (
                                <List.Item key={index}>
                                    <div style={{width:'100%'}}>
                                        <div style={{display:'flex', flexWrap:'wrap', gap:24}}>
                                            <div>
                                                <Space style={{textAlign:'start', }}>
                                                    <Badge color={color} />
                                                    <div>
                                                        <span>{name}</span>
                                                    </div>
                                                </Space>
                                            </div>
                                            <div style={{marginLeft:'auto',}}>
                                                    <div style={{textAlign:'end',}}>
                                                        <span style={{color:'var(--main-color)',fontSize:12,}}>{`${numberHelper?.commaNumber(count, 0)}`}</span>
                                                    </div>
                                                    <div>
                                                        <span style={{color:'var(--secondary-text-color)', fontSize:12,}}>
                                                            {`${park?.currency?.symbol}${numberHelper?.commaNumber(sales_amount)}`}
                                                        </span>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </List.Item>
                            )
                        }}
                        />
                    </div>
                </div>
                
            </Card>
        </>
    );
}

export default BreakdownAddOnType;