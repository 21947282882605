import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getStaffListing(query){
    const queryString = serviceHelper.queryString(query);
    const staffResponse = await axios.get(`config/staff/listing?${queryString}`, { withCredentials : true });
    return staffResponse?.data;
}

async function getStaff(staff_id){
    const staffResponse = await axios.get(`config/staff/${staff_id}/get`, { withCredentials : true, });
    return staffResponse?.data;
}

async function getStaffAssociate(staff_id, query){
    const queryString = serviceHelper.queryString(query);
    const staffAssociateResponse = await axios.get(`config/staff/${staff_id}/associate/get?${queryString}`, { withCredentials : true, });
    return staffAssociateResponse?.data;
}

async function updateStaff(staff_id, params){
    const updateStaffResponse = await axios.post(`config/staff/${staff_id}/update`, params, { withCredentials : true, });
    return updateStaffResponse?.data;
}

async function updateStaffAssociate(staff_associate_id, params){
    const updateStaffAssociateResponse = await axios.post(`config/staff/staff_associate/${staff_associate_id}/update`, params, { withCredentials : true, });
    return updateStaffAssociateResponse?.data;
}

async function uploadStaffImage(staff_id, image){
    const formData = new FormData();
    formData.append('image', image);

    const uploadStaffImageResponse = await axios.post(`config/staff/${staff_id}/upload`, formData, 
    { withCredentials : true, headers : { "Content-Type": "multipart/form-data" } });
    return uploadStaffImageResponse?.data;
}

async function uploadStaffAssociateImage(staff_associate_id, image){
    const formData = new FormData();
    formData.append('image', image);

    const uploadStaffImageResponse = await axios.post(`config/staff/staff_associate/${staff_associate_id}/upload`, formData, 
    { withCredentials : true, headers : { "Content-Type": "multipart/form-data" } });
    return uploadStaffImageResponse?.data;
}

async function saveStaff(params){
    const { image_url, name, identification_number, relationship, is_active, parks = [], } = params ?? {};
    const formData = new FormData();
    formData.append('name', name);
    identification_number && formData.append('identification_number', identification_number);
    relationship && formData.append('relationship', relationship);
    formData.append('is_active', is_active);
    image_url && formData.append('image', image_url);
    // append parks
    parks.forEach((park, index) => {
        formData.append(`parks[${index}]`, park);
    });
    const saveStaffResponse = await axios.post(`config/staff/save`, formData,
    { withCredentials : true, headers : { "Content-Type": "multipart/form-data" }});
    return saveStaffResponse?.data;
}

async function saveStaffAssociate(staff_id, params){
    const { image_url, name, identification_number, relationship, is_active, parks = [], } = params ?? {};
    const formData = new FormData();
    formData.append('name', name);
    identification_number && formData.append('identification_number', identification_number);
    relationship && formData.append('relationship', relationship);
    formData.append('is_active', is_active ?? true);
    image_url && formData.append('image', image_url);
    // append parks
    parks.forEach((park, index) => {
        formData.append(`parks[${index}]`, park);
    });

    const saveStaffAssociateResponse = await axios.post(`config/staff/${staff_id}/staff_associate/save`, formData,
    { withCredentials : true, headers : { "Content-Type": "multipart/form-data" }});
    return saveStaffAssociateResponse?.data;
}

async function deleteStaff(staff_id){
    const deleteStaffResponse = await axios.delete(`config/staff/${staff_id}/delete`, { withCredentials : true, });
    return deleteStaffResponse?.data;
}

async function deleteStaffAssociate(staff_associate_id){
    const deleteStaffAssociateResponse = await axios.delete(`config/staff/staff_associate/${staff_associate_id}/delete`, { withCredentials : true, });
    return deleteStaffAssociateResponse?.data;
}

export default {
    getStaffListing,
    getStaff,
    getStaffAssociate,
    updateStaff,
    updateStaffAssociate,
    uploadStaffImage,
    uploadStaffAssociateImage,
    saveStaffAssociate,
    saveStaff,
    deleteStaff,
    deleteStaffAssociate,
}