import React from 'react';
import SaleReport from '../report/sale_report';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement);
const SaleReportLayout = ({ default_filter }) => {
    return (
        <>
            <SaleReport default_filter={default_filter} />
        </>
    );
}

export default SaleReportLayout;