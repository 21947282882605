import React, { useEffect, useState } from 'react'
import RequiredTicketRuleLayout from './required_ticket_rule_layout';
import parkService from '../../../services/config/park.service';
import FreeTicketRuleLayout from './free_ticket_rule_layout';
import { Space } from 'antd';

const TicketRuleLayout = ({ park_id, }) => {
    const [state, setState] = useState({
        loading : false,
        park : null,
    });

    const fetchPark = async () => {
        setState(state => ({...state, loading : true, }));
        const parkResponse = await parkService?.getPark({ park_id : park_id, });
        const { parks, } = parkResponse;
        setState(state => ({...state,  park : parks, loading : false, }));
    }

    useEffect(() => {
        fetchPark();
    }, [park_id])
    
    return (
        <>
            <Space size={20} direction='vertical' style={{ width : '100%', }}>
                <div>
                    <RequiredTicketRuleLayout park={state?.park} />
                </div>
                <div>
                    <FreeTicketRuleLayout park={state?.park} />
                </div>
            </Space>
        </>
    )
}

export default TicketRuleLayout;