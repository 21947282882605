import React, { useEffect, useState, } from 'react';
import promotionService from '../../../services/config/promotion.service';
import moment from 'moment';
import { Col, DatePicker, Divider, Row, Space, Spin, Tag } from 'antd';
import GrowthChartCount from './growth_chart_count';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import GrowthChartSale from './growth_chart_sale';
import FilterBullet from '../../../helpers/components/filter_bullet';
import { CaretDownFilled, LoadingOutlined } from '@ant-design/icons';
import PromoTicketList from './promo_ticket_list';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import dayjs from 'dayjs';
import serviceHelper from '../../../helpers/functions/service.helper';

const { RangePicker } = DatePicker;
const DashboardLayout = ({ promotion_code_id, default_filter = {}, }) => {
    const [state, setState] = useState({
        loading : false,
        total_date_breakdown_sales : null,
        total_sales : null,

        // promo code
        promotion_code : null,
    });

    const [filter, setFilter] = useState({
        start_date : default_filter?.start_date ?? moment()?.subtract(6, 'days')?.format("YYYY-MM-DD") , // if no default filter, get a week before,
        end_date : default_filter?.end_date ?? moment()?.format("YYYY-MM-DD"),
    })

    const changeDate = (date) => {
        if(date){
            const start_date = dayjs(date[0])?.format("YYYY-MM-DD");
            const end_date = dayjs(date[1])?.format("YYYY-MM-DD");

            setFilter(filter => ({...filter, start_date : start_date, end_date : end_date}));
            changeFilter('start_date', start_date);
            changeFilter('end_date', end_date);
        }else{
            setFilter(filter => ({...filter, start_date : null, end_date : null}));
            changeFilter('start_date', null);
            changeFilter('end_date', null);
        }
    }

    // for changing filter
    const changeFilter = (key, value) => {
        let generate_filter = default_filter;
        generate_filter[key] = value;
        const queryString = serviceHelper?.queryString(generate_filter);
        window.history.replaceState({}, '', `${window.location.pathname}?${queryString}`);
    }

    const fetchGenerateReport = async (promotion_code) => {
        const { start_date, end_date, } = filter;
        const reportResponse = await promotionService?.generateReport({
            start_date : start_date,
            end_date : end_date,
            promotion_code_id : promotion_code_id,
        });

        const { total_date_breakdown_sales, total_sales, } = reportResponse;
        setState(state => ({...state, loading : false, total_date_breakdown_sales : total_date_breakdown_sales, total_sales : total_sales, promotion_code : promotion_code, }));
    }

    const fetchPromotionCode = async () => {
        const promotionCodeResponse = await promotionService?.getPromoCode({
            promotion_code_id,
        });

        const { count, rows : promotion_codes } = promotionCodeResponse?.promotion_codes;
        const promotion_code = promotion_codes?.length > 0 ? promotion_codes[0] : null;

        return promotion_code;
        
    }

    useEffect(() => {
        setState(state => ({...state, loading : true,}));
        fetchPromotionCode().then((promotion_code) => {
            fetchGenerateReport(promotion_code).then(() => {
                setState(state => ({...state, loading : false,}));
            });
        })
    }, [filter])
    return (
        <>
            <div className='mobile-padding-24'>
                <div>
                    <PageHeaderComponent
                    title={"Dashboard"} 
                    textAlign={'start'} />
                    <div style={{textAlign:'start', marginTop:8,}}>
                        <Space align='center'>
                            <div>
                                <Tag color='orange' style={{border:'none', fontWeight:'normal'}}>
                                    {state?.promotion_code?.promo_code}
                                </Tag>
                            </div>
                            <div>
                                <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{state?.promotion_code?.parks?.name}</span>
                            </div>
                        </Space>
                    </div>
                </div>
                <Divider style={{marginTop:8, marginBottom:12,}} />
                <div className='filter-panel' style={{textAlign:'start',}}>
                    <Space>
                        <FilterBullet
                        popover={
                            <>
                                <div>
                                    <RangePicker
                                    onChange={(e) => changeDate(e)} format={"YYYY-MM-DD"} 
                                    />
                                </div>
                            </>
                        }
                        >
                            <div>
                                <Space>
                                    <div>
                                        <span>
                                            Date
                                            <span style={{fontSize:12, fontWeight:500, color:'var(--secondary-text-color)'}}>
                                                {filter?.start_date && filter?.end_date && `: ${filter?.start_date}-${filter?.end_date}`}
                                            </span>
                                        </span>
                                    </div>
                                    <div>
                                        <CaretDownFilled style={{color:'#a0aec0'}} />
                                    </div>
                                </Space>
                            </div>
                        </FilterBullet>
                    </Space>
                </div>
                <div style={{marginTop:24,}}>
                    <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                        <Row gutter={[20, 20]}>
                            <Col xs={24} md={12}>
                                <EntranceMotion>
                                    <GrowthChartCount total_date_breakdown_sales={state?.total_date_breakdown_sales} total_sales={state?.total_sales} />
                                </EntranceMotion>
                            </Col>
                            <Col xs={24} md={12}>
                                <EntranceMotion delay={0.1}>
                                    <GrowthChartSale total_date_breakdown_sales={state?.total_date_breakdown_sales} total_sales={state?.total_sales} park={state?.promotion_code?.parks} />
                                </EntranceMotion>
                            </Col>
                        </Row>
                    </Spin>
                </div>

                <div style={{marginTop:24,}}>
                    <PromoTicketList filter={filter} promotion_code_id={promotion_code_id} park={state?.promotion_code?.parks}/>
                </div>
            </div>
        </>
    );
}

export default DashboardLayout;