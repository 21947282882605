import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function fetchEntrances(query){
    const queryString = serviceHelper.queryString(query);
    const entranceResponse = await axios.get(`config/entrance?${queryString}`, { withCredentials : true });
    return entranceResponse?.data;
}

async function generateTransactionReports(query){
    const queryString = serviceHelper.queryString(query);
    const transactionReportResponse = await axios.get(`fc/entrance/report?${queryString}`, { withCredentials : true });
    return transactionReportResponse?.data;
}

async function generateVisitorReports(query){
    const queryString = serviceHelper?.queryString(query);
    const visitorReportResponse = await axios.get(`fc/visitor/report?${queryString}`, { withCredentials : true, });
    return visitorReportResponse?.data;
}

export default {
    generateTransactionReports,
    fetchEntrances,
    generateVisitorReports,
}