import React, { useState } from 'react';
import PageHeaderComponent from '../../../../helpers/components/page_header.component';
import ConfigTicketTypeList from './config_ticket_type_list';
import { Button, Form, Input, Modal, Space, notification } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import globalHelper from '../../../../helpers/functions/global.helper';
import AddTicketTypeModal from './add_ticket_type_modal';
import ticketTypeService from '../../../../services/config/ticket_type.service';
import EditTicketTypeModal from './edit_ticket_type_modal';

const ConfigTicketTypeLayout = () => {
    const [save_form] = Form.useForm();
    const [update_form] = Form.useForm();
    const [state, setState] = useState({
        create_modal : false,
        edit_modal : null,

        save_loading : false,
        update_loading : false,
        
        modal : 0,
        refresh : 0,
    });
    const [timer, setTimer] = useState();
    const [filter, setFilter] = useState({
        search : null,
    });

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
            // changeFilter('search', input);
        }, 300);
        setTimer(newTimer);
    }

    const saveTicketType = async () => {
        setState(state => ({...state, save_loading : true, }));
        const { name,} = save_form?.getFieldsValue();

        const saveTicketTypeResponse = await ticketTypeService?.saveTicketType({
            name,
        }).catch(err => setState(state => ({...state, save_loading : false, })));

        notification?.success({
            message : 'New ticket type added',
        })

        setState(state => ({...state, save_loading : false, refresh : (state?.refresh + 1), create_modal : false,}));
    }

    const updateTicketType = async () => {
        setState(state => ({...state, update_loading : true,}));

        const ticket_type_id = state?.edit_modal;
        const { name, } = update_form?.getFieldsValue();
        const updateTicketTypeResponse = await ticketTypeService?.updateTicketType(ticket_type_id, {
            name,
        }).catch(err => setState(state => ({...state, update_loading : false, })));

        notification?.success({
            message : 'Ticket type updated',
        })

        setState(state => ({...state, update_loading : false, refresh : (state?.refresh + 1), edit_modal : null, }));
    }

    const deleteTicketType = async (ticket_type_id) => {
        const deleteTicketTypeResponse = await ticketTypeService?.deleteTicketType(ticket_type_id);
        notification?.success({
            message : 'Ticket type Deleted',
        })

        setState(state => ({...state, refresh : (state?.refresh + 1)}));
    }

    const openEditModal = (ticket_type_id) => {
        setState(state => ({...state, edit_modal : ticket_type_id, }))
    }

    return (
        <>
            <div className='mobile-padding-12'>
                <div style={{marginBottom: 24, textAlign:'start'}}>
                    <PageHeaderComponent title={'Ticket Types'} />
                </div>
                <div className='filter-panel'>
                    <div>
                        <Input
                        onChange={(e) => doneTyping(e.target.value)}
                        name="search"
                        prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                        placeholder='Search Ticket Types'
                        style={{minWidth: '100%',}}
                        allowClear
                        />
                    </div>

                    <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                        <div>
                            <div className='filter-panel' style={{textAlign:'start'}}>
                                <Space direction={globalHelper?.isMobileScreen() ? 'vertical' : 'horizontal'}>
                                </Space>
                            </div>
                        </div>

                        <div style={{marginLeft:'auto'}}>
                            <Button
                            type='primary'
                            onClick={() => setState(state => ({...state, create_modal : true,}))}
                            >
                                New Ticket Type
                            </Button>
                        </div>
                    </div>
                </div>

                <div style={{ marginTop : 24, }}>
                    <ConfigTicketTypeList filter={filter} refresh={state?.refresh} openEditModal={openEditModal} deleteTicketType={deleteTicketType} />
                </div>
            </div>

            <Modal
            title="New Ticket Type"
            open={state?.create_modal}
            onCancel={() => {
                setState(state => ({...state, create_modal : false, modal : (state?.modal + 1)}));
                // save_form?.resetFields();
            }}
            onOk={() => save_form.submit()}
            width={800}
            // style={{ top : 20, }}
            confirmLoading={state?.save_loading}
            >
                <Form
                form={save_form}
                onFinish={saveTicketType}
                layout='vertical'
                >
                    <AddTicketTypeModal form={save_form}/>
                </Form>
            </Modal>

            <Modal
            title="Edit Add On"
            open={state?.edit_modal}
            onCancel={() => {
                setState(state => ({...state, edit_modal : null, modal : (state?.modal + 1)}));
                // save_form?.resetFields();
            }}
            onOk={() => update_form.submit()}
            width={800}
            // style={{ top : 20, }}
            confirmLoading={state?.update_loading}
            >
                <Form
                form={update_form}
                onFinish={updateTicketType}
                layout='vertical'
                >
                    <EditTicketTypeModal form={update_form} ticket_type_id={state?.edit_modal} modal={state?.modal} />
                </Form>
            </Modal>
        </>
    );
}

export default ConfigTicketTypeLayout;