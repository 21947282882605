import { CloseOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Badge, Button, Form, Image, Input, Select, Spin, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import addOnService from '../../../../services/config/add_on.service';

const { TextArea } = Input;
const EditAddOnModal = ({ form, add_on_id, modal, }) => {
    const [state, setState] = useState({
        loading : false,
        image : null,
        add_on : null,
        add_on_types : [],
    });
    
    const acceptable_file_format = ['image/jpeg', 'image/png'];
    const beforeUpload = async (file) => {
        const is_image = acceptable_file_format?.find(x => x == file?.type);

        if(is_image){
            const uploaded_file = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    file.base64 = reader.result.split(',')[1];
                    resolve(file);
                };
                reader.onerror = (error) => reject(error);
            });
    
            const base64Image = `data:image/jpeg;base64,${uploaded_file?.base64}`;
            setState(state => ({...state, image : base64Image}));
        }else{
            // errorModal("Please upload in image format");
        }
        return false;
    }

    const fetchAddOn = async () => {
        const addOnResponse = await addOnService?.fetchAddOn({
            add_on_id : add_on_id,
        })

        const { add_ons } = addOnResponse;
        const add_on = add_ons?.[0];
        return add_on;
    }

    const fetchAddOnTypes = async () => {
        const addOnTypeResponse = await addOnService?.fetchAddOnType();

        const { add_on_types } = addOnTypeResponse;
        return add_on_types;
    }

    const initialize = async () => {
        setState(state => ({...state, loading : true,}));

        const add_on = await fetchAddOn();
        const add_on_types = await fetchAddOnTypes();

        setState(state => ({...state, loading : false, add_on : add_on, add_on_types : add_on_types, image : add_on?.image, }));
        
        if(add_on){
            const { name, description, add_on_type_id, } = add_on;
            form.setFieldsValue({ name, description, add_on_type_id, });
        }
    }

    useEffect(() => {
        initialize();
    }, [add_on_id, modal])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <Form.Item
                label={"Image"}
                style={{ textAlign : 'start', marginBottom : 0, }}
                >
                    <Badge offset={[-8, 4]} count={(
                        <Button
                        // onClick={removeCompanyLogo}
                        icon={<CloseOutlined />}
                        size='small'>
                        </Button>
                    )}>
                        <Form.Item name={'image'}>
                            <Upload
                            name="company_logo"
                            listType='picture-card'
                            beforeUpload={beforeUpload}
                            showUploadList={false}
                            style={{width : '100%',}}
                            >
                                {
                                    state?.image ?
                                    (
                                        <>
                                            <Image preview={false} src={state?.image} />
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <div>
                                                <div>
                                                    <PlusOutlined />
                                                </div>
                                                <div>Upload</div>
                                            </div>
                                        </>
                                    )
                                }
                            </Upload>
                        </Form.Item>
                    </Badge>
                </Form.Item>

                <Form.Item label={"Name"} name={'name'}>
                    <Input />
                </Form.Item>

                <Form.Item label={"Description"} name={'description'}>
                    <TextArea rows={4} style={{ resize : 'none', }} />
                </Form.Item>

                <Form.Item label={"Add On Type"} name={'add_on_type_id'}>
                    <Select
                    placeholder={"Select Add On Type"}
                    allowClear
                    options={state?.add_on_types?.map(x => ({ label : x?.name, value : x?.add_on_type_id, }))}
                    />
                </Form.Item>
            </Spin>
        </>
    );
}

export default EditAddOnModal;