import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function fetchCollections(query){
    const collectionResponse = await axios.get(`fc/collection`, { withCredentials : true, });
    return collectionResponse?.data
}

async function fetchCollectionFaces(collection_id, query){
    const queryString = serviceHelper.queryString(query);
    const faceResposne = await axios.get(`fc/collection/${collection_id}/face?${queryString}`, { withCredentials : true, });
    return faceResposne?.data;
}

async function deleteCollection(collection_id){
    const deleteCollectionResponse = await axios.delete(`fc/collection/${collection_id}/delete`, { withCredentials : true, });
    return deleteCollectionResponse?.data;
}

async function deleteFace(collection_id, face_id){
    const deleteFaceResponse = await axios.delete(`fc/collection/${collection_id}/face/${face_id}/delete`, { withCredentials : true, });

    return deleteFaceResponse?.data;
}

export default {
    fetchCollections,
    fetchCollectionFaces,
    deleteCollection,
    deleteFace,
}