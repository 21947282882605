import React, { useEffect, useState } from 'react';
import AddOnSetup from './add_on_setup';
import parkService from '../../../services/config/park.service';
import ParkAddOn from './park_add_on';
import { Divider, Space } from 'antd';

const AddOnLayout = ({ park_id, }) => {
    const [state, setState] = useState({
        loading : false,
        park : null,
    });

    const fetchPark = async () => {
        setState(state => ({...state, loading : true, }));
        const parkResponse = await parkService?.getPark({ park_id : park_id, });
        const { parks, } = parkResponse;
        setState(state => ({...state,  park : parks, loading : false, }));
    }

    useEffect(() => {
        fetchPark();
    }, [park_id])
    return (
        <>
            <Space style={{ width : '100%', }} direction='vertical' size={20}>
                <div>
                    <ParkAddOn park={state?.park} />
                </div>
                {/* <Divider /> */}
                <div>
                    <AddOnSetup park={state?.park} />
                </div>
            </Space>
        </>
    );
}

export default AddOnLayout;