import React, { useEffect, useState } from 'react';
import parkService from '../../../services/config/park.service';
import { Badge, Button, Col, Divider, Form, Image, Input, InputNumber, Row, Select, Spin, Tag, Upload } from 'antd';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { CloseOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const EditTicketTypeModal = ({ park_applicable_ticket_type_id, form, selected_ticket_types, all_ticket_types, }) => {
    const [state, setState] = useState({
        loading : false,
        remove_loading : false,
        park_applicable_ticket_type : null,

        image : null,

        refresh : 0,
    })

    const acceptable_file_format = ['image/jpeg', 'image/png'];
    const beforeUpload = async (file) => {
        const is_image = acceptable_file_format?.find(x => x == file?.type);

        if(is_image){
            const uploaded_file = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    file.base64 = reader.result.split(',')[1];
                    resolve(file);
                };
                reader.onerror = (error) => reject(error);
            });
    
            const base64Image = `data:image/jpeg;base64,${uploaded_file?.base64}`
            setState(state => ({...state, image : base64Image, }));
        }else{
            // errorModal("Please upload in image format");
        }
        return false;
    }

    const removeParkApplicableTicketTypeImage = async () => {
        setState(state => ({...state, remove_loading : true,}));
        const removeParkApplicableTicketTypeImageResponse = await parkService?.removeParkApplicableTicketTypeImage(park_applicable_ticket_type_id);
        setState(state => ({...state, remove_loading : false, refresh : (state?.refresh + 1), }));
    }

    const fetchParkApplicableTicketType = async () => {
        setState(state => ({...state, loading : true,}))
        const parkApplicableTicketTypeResponse = await parkService?.getParkApplicableTicketTypes({
            park_applicable_ticket_type_id : park_applicable_ticket_type_id,
        })
        const { park_applicable_ticket_types } = parkApplicableTicketTypeResponse;
        const park_applicable_ticket_type = park_applicable_ticket_types?.length > 0 ? park_applicable_ticket_types[0] : null;
        const { descriptions, min_purchase, max_purchase, min_paying_guest, advance_day, change_date_advance_day, date_range, image, } = park_applicable_ticket_type;
        
        // indicate to form
        form.setFieldsValue({ descriptions, min_purchase, max_purchase, min_paying_guest, advance_day, change_date_advance_day, date_range, image, })
        setState(state => ({...state, park_applicable_ticket_type : park_applicable_ticket_type, loading : false, image : image, }));
    }

    useEffect(() => {
        fetchParkApplicableTicketType();
    }, [park_applicable_ticket_type_id, state?.refresh])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <div>
                    <Divider orientation='left' orientationMargin="0"><PageHeaderComponent title={<span style={{fontSize:14,}}>Ticket Type</span>} textAlign={'start'} /></Divider>
                    <div>
                        <Form.Item label={'Image'} >
                            <Badge
                            count={(
                                <Button loading={state?.remove_loading} onClick={removeParkApplicableTicketTypeImage} size='small' icon={<CloseOutlined />}></Button>
                            )}
                            styles={{ root : { width : '100%', } }}
                            >
                                <Form.Item name='image'>
                                    <Upload
                                    className='upload-ticket-type-image'
                                    name="avatar"
                                    listType='picture-card'
                                    beforeUpload={beforeUpload}
                                    showUploadList={false}
                                    style={{width : '100%',}}
                                    >
                                        <div style={{display : 'flex', flexDirection : 'column', }}>
                                            {
                                                state?.image ?
                                                (
                                                    <>
                                                        <div>
                                                            <Image preview={false} src={state?.image} alt='avatar' style={{maxHeight : 102,}} />
                                                        </div>
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <div>
                                                            <div>
                                                                <PlusOutlined />
                                                            </div>
                                                            <div>Upload</div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </Upload>
                                </Form.Item>
                            </Badge>
                        </Form.Item>
                        <Form.Item label={'Ticket Type'} style={{marginBottom : 0,}}>
                            <Tag color={'var(--secondary-button-color)'} style={{ border : 'none', color : 'var(--secondary-text-color)', fontWeight: 500, }}>
                                {state?.park_applicable_ticket_type?.ticket_type?.name}
                            </Tag>
                        </Form.Item>
                        <Form.Item name={"descriptions"} label={'Descriptions'} style={{marginTop: 12,}}>
                            <TextArea placeholder='Enter descriptions' rows={4} style={{ resize : 'none', }} />
                        </Form.Item>
                    </div>
                </div>
                
                <div>
                    <Divider orientation='left' orientationMargin="0"><PageHeaderComponent title={<span style={{fontSize:14,}}>Ticket Rules</span>} textAlign={'start'} /></Divider>
                    <div>
                        <Row gutter={[12, 12]}>
                            <Col span={8}>
                                <Form.Item label={'Minimum Ticket'} name={'min_purchase'}>
                                    <InputNumber min={0} precision={0} style={{width : '100%',}} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={'Maximum Ticket'} name={'max_purchase'}>
                                    <InputNumber min={0} precision={0} style={{width : '100%',}} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={'Minimum Payable Ticket'} name={'min_paying_guest'}>
                                    <InputNumber min={0} precision={0} style={{width : '100%',}} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
                
                <div>
                    <Divider orientation='left' orientationMargin="0"><PageHeaderComponent title={<span style={{fontSize:14,}}>Day Rules</span>} textAlign={'start'} /></Divider>
                    <div>
                        <Row gutter={[12, 12]}>
                            <Col span={8}>
                                <Form.Item extra="Advance day to purchase ticket" label={'Advance Days'} name={'advance_day'}>
                                    <InputNumber min={0} precision={0} style={{width : '100%',}} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item extra="Advance day allow to change date"  label={'Change Date Advance Day'} name={'change_date_advance_day'}>
                                    <InputNumber min={0} precision={0} style={{width : '100%',}} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={'Overnight'} name={'date_range'}>
                                    <InputNumber min={0} precision={0} style={{width : '100%',}} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
        </>
    );
}

export default EditTicketTypeModal;