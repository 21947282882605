import { AppstoreOutlined, LoadingOutlined } from '@ant-design/icons';
import { Avatar, Card, Checkbox, Col, Form, Input, InputNumber, List, Row, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import parkService from '../../../services/config/park.service';

const EditRequiredTicketRuleModal = ({ form, park, required_ticket_rule_id, modal_visible, }) => {
    const [state, setState] = useState({
        loading : false,
        required_ticket_rule : null,
        ticket_applicable_users : [],
    })

    const fetchRequiredTicketRule = async () => {
        setState(state => ({...state, loading : true,}));

        // get required ticket rule
        const requiredTicketRuleResponse = await parkService?.getRequiredTicketRule({
            required_ticket_rule_id,
        });

        const { required_ticket_rules, } = requiredTicketRuleResponse;
        const required_ticket_rule = required_ticket_rules?.[0];

        // fetch applicable users
        const park_applicable_ticket_type = required_ticket_rule?.park_applicable_ticket_type;
        const fetchParkApplicableTicketTypeResponse = await parkService?.getParkApplicableTicketTypeitems({
            park_applicable_ticket_type_id : park_applicable_ticket_type?.park_applicable_ticket_type_id,
            is_check_purchase_type : false,
        });
        const { park_applicable_ticket_users, } = fetchParkApplicableTicketTypeResponse;

        setState(state => ({...state, loading : false, required_ticket_rule : required_ticket_rule, ticket_applicable_users : park_applicable_ticket_users, }));

        // assign data
        if(required_ticket_rule){
            const { name, min_quantity, required_type, ticket_applicable_users, } = required_ticket_rule;
            form.setFieldsValue({
                name,
                min_quantity,
                required_type,
                ticket_applicable_users : ticket_applicable_users?.map(x => x?.ticket_applicable_user_id),
            })
        }
    }

    useEffect(() => {
        fetchRequiredTicketRule();
    }, [required_ticket_rule_id, modal_visible]);

    return (
        <>
            <Form.Item label={'Name'} name={'name'}>
                <Input />
            </Form.Item>
           

            <div>
                <Row gutter={[12, 12]}>
                    <Col xs={24} md={12}>
                        <Form.Item label={'Ticket Type'} name={'park_applicable_ticket_type_id'}>
                            <Space>
                                <div>
                                    <Avatar icon={<AppstoreOutlined />} shape='square' size='small' src={state?.required_ticket_rule?.park_applicable_ticket_type?.image} />
                                </div>
                                <div>
                                    <span>{state?.required_ticket_rule?.park_applicable_ticket_type?.ticket_type?.name}</span>
                                </div>
                            </Space>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item label={'Minimum Quantity'} name={'min_quantity'}>
                            <InputNumber min={0} style={{ width : '100%', }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item hidden label={'Type'} name={'required_type'}>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
            </div>

            <Form.Item 
            label={(
                <>
                    <div>
                        <div>
                            <span>Ticket Group</span>
                        </div>
                        <div>
                            <span style={{ color : 'var(--secondary-text-color)', fontSize : 12, }}>At least one of these is required to proceed with the purchase</span>
                        </div>
                    </div>
                </>
            )}
            name={'ticket_applicable_users'}
            >
                <Checkbox.Group
                style={{ width : '100%', }}
                defaultValue={[4]}
                >
                    <Card
                    style={{ width : '100%', }}
                    styles={{ body : { padding : 0, } }}
                    >
                        <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                            <List
                            style={{ width : '100%', }}
                            itemLayout='horizontal'
                            dataSource={state?.ticket_applicable_users}
                            renderItem={(item, index) => {
                                const { ticket_applicable_user_id, ticket_user_type, } = item;
                                return (
                                    <List.Item
                                    style={{ paddingInline: 24, }}
                                    key={index}
                                    actions={[<Checkbox value={item?.ticket_applicable_user_id} />]}
                                    >
                                        {ticket_user_type?.name}
                                    </List.Item>
                                )
                            }}
                            />
                        </Spin>
                    </Card>
                </Checkbox.Group>
            </Form.Item>
        </>
    )
}

export default EditRequiredTicketRuleModal;