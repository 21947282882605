export const set_auth = (auth,) => {
    return {
        type : 'SET_AUTH',
        auth,
    };
}

export const set_access = (access) => {
    return {
        type : 'SET_ACCESS',
        access
    }
}