import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getPark(query){
    const queryString = serviceHelper.queryString(query);
    const parkResponse = await axios.get(`config/park?${queryString}`, { withCredentials : true });
    return parkResponse?.data;
}

async function getParkByAuth(query){
    const queryString = serviceHelper.queryString(query);
    const parkResponse = await axios.get(`config/park/auth?${queryString}`, { withCredentials : true });
    return parkResponse?.data;
}

async function getParkApplicableTicketTypes(query){
    const queryString = serviceHelper.queryString(query);
    const parkApplicableTicketTypeResponse = await axios.get(`config/park/park_applicable_ticket_type?${queryString}`, { withCredentials : true });
    return parkApplicableTicketTypeResponse?.data;
}

async function getParkApplicableTicketTypeitems(query){
    const queryString = serviceHelper.queryString(query);
    const parkApplicableTicketUserResponse = await axios.get(`config/park/park_applicable_ticket_type_items?${queryString}`, { withCredentials : true });
    return parkApplicableTicketUserResponse?.data;
}

async function getAddOn(query){
    const queryString = serviceHelper.queryString(query);
    const addOnResponse = await axios.get(`config/park/add_on?${queryString}`, { withCredentials : true });
    return addOnResponse?.data;
}

async function checkPeakDays(query){
    const queryString = serviceHelper.queryString(query);
    const peakDayResponse = await axios.get(`config/park/peak_days?${queryString}`, { withCredentials : true });
    return peakDayResponse?.data;
}

async function getCapacity(query){
    const queryString = serviceHelper.queryString(query);
    const capacityResponse = await axios.get(`config/park/capacity?${queryString}`, { withCredentials : true, });
    return capacityResponse?.data;
}

async function getPurchaseTypeCapacity(park_id, query){
    const queryString = serviceHelper.queryString(query);
    const capacityResponse = await axios.get(`config/park/${park_id}/purchase_type_capacity?${queryString}`, { withCredentials : true, });
    return capacityResponse?.data;
}

async function getTicketType(query){
    const queryString = serviceHelper.queryString(query);
    const ticketTypeResponse = await axios.get(`ticket/ticket_type/get?${queryString}`, { withCredentials : true, });
    return ticketTypeResponse?.data;
}

async function getTicketUserType(query){
    const queryString = serviceHelper?.queryString(query);
    const ticketUserTypeResponse = await axios.get(`ticket/ticket_user_type/get?${queryString}`, { withCredentials : true, });
    return ticketUserTypeResponse?.data;
}

async function getCalendarOverview(query){
    const queryString = serviceHelper.queryString(query);
    const calendarOverviewResponse = await axios.get(`config/park/calendar?${queryString}`, { withCredentials : true, });
    return calendarOverviewResponse?.data;
}

// park management
async function saveParkApplicableTicketType(park_id, params){
    const { image, advance_day, change_date_advance_day, date_range, descriptions, min_purchase, max_purchase, min_paying_guest, ticket_type_id, } = params ?? {};
    const formData = new FormData();
    image && formData.append('image', image);
    advance_day && formData.append('advance_day', advance_day);
    change_date_advance_day && formData.append('change_date_advance_day', change_date_advance_day);
    date_range && formData.append('date_range', date_range);
    descriptions && formData.append('descriptions', descriptions);
    min_purchase && formData.append('min_purchase', min_purchase);
    max_purchase && formData.append('max_purchase', max_purchase);
    min_paying_guest && formData.append('min_paying_guest', min_paying_guest);
    ticket_type_id && formData.append('ticket_type_id', ticket_type_id);

    const saveParkApplicableTicketTypeResponse = await axios.post(`config/park/${park_id}/park_applicable_ticket_type/save`, 
    formData, 
    { withCredentials : true, headers : { "Content-Type": "multipart/form-data" }, },);
    return saveParkApplicableTicketTypeResponse?.data;
}

async function updateParkApplicableTicketType(park_applicable_ticket_type_id, params){
    const { image, advance_day, change_date_advance_day, date_range, descriptions, min_purchase, max_purchase, min_paying_guest, } = params ?? {};
    const formData = new FormData();
    image && formData.append('image', image);
    formData.append('advance_day', advance_day ?? '');
    formData.append('change_date_advance_day', change_date_advance_day ?? '');
    formData.append('date_range', date_range ?? '');
    formData.append('descriptions', descriptions ?? '');
    formData.append('min_purchase', min_purchase ?? '');
    formData.append('max_purchase', max_purchase ?? '');
    formData.append('min_paying_guest', min_paying_guest ?? '');

    const updateParkApplicableTicketTypeResponse = await axios.post(`config/park/park_applicable_ticket_type/${park_applicable_ticket_type_id}/update`, 
    formData, 
    { withCredentials : true, headers : { "Content-Type": "multipart/form-data" }, },);
    return updateParkApplicableTicketTypeResponse?.data;
}

async function removeParkApplicableTicketTypeImage(park_applicable_ticket_type_id){
    const removeParkApplicableTicketTypeImageResponse = await axios.delete(`config/park/park_applicable_ticket_type/${park_applicable_ticket_type_id}/image/remove`, { withCredentials : true, });
    return removeParkApplicableTicketTypeImageResponse?.data;
}

async function deleteParkApplicableTicketType(park_applicable_ticket_type_id){
    const deleteParkApplicableTicketTypeResponse = await axios.delete(`config/park/park_applicable_ticket_type/${park_applicable_ticket_type_id}/delete`, { withCredentials : true, });
    return deleteParkApplicableTicketTypeResponse?.data;
}

async function updatePark(park_id, params){
    const { name, address, logo_image, cover_image, currency_id, payment_gateway_id, capacity, date_change_price, is_user_detail_required, description, purchase_type_capacities, } = params;
    const formData = new FormData();
    logo_image && formData.append('logo_image', logo_image);
    cover_image && formData.append('cover_image', cover_image);

    formData.append('name', name ?? '');
    formData.append('address', address ?? '');
    formData.append('description', description ?? '');
    formData.append('currency_id', currency_id ?? '');
    formData.append('payment_gateway_id', payment_gateway_id ?? '');
    formData.append('capacity', capacity ?? '');
    formData.append('date_change_price', date_change_price ?? '');
    formData.append('is_user_detail_required', is_user_detail_required);
    // append purchase type capacities
    purchase_type_capacities.forEach((purchase_type_capacity, index) => {
        const { purchase_type_capacity_id, purchase_type_id, is_applicable, } = purchase_type_capacity;
        formData.append(`purchase_type_capacities[${index}][purchase_type_id]`, purchase_type_id);
        formData.append(`purchase_type_capacities[${index}][is_applicable]`, is_applicable);
    });

    const updateParkResponse = await axios.post(`config/park/${park_id}/detail/update`, formData,
    { withCredentials : true, headers : { "Content-Type": "multipart/form-data" }});

    return updateParkResponse?.data;
}

async function removeParkImage(park_id, type){
    const removeParkImageResponse = await axios.delete(`config/park/${park_id}/image/${type}/delete`, { withCredentials : true, });
    return removeParkImageResponse?.data;
}

// peak day management
async function peakDayList(park_id, query){
    const queryString = serviceHelper.queryString(query);
    const calendarPeakDayResponse = await axios.get(`config/park/${park_id}/peak_day/list?${queryString}`, { withCredentials : true, });
    return calendarPeakDayResponse?.data;
}

async function savePeakDay(park_id, params){
    const savePeakDayResponse = await axios.post(`config/park/${park_id}/peak_day/save`, params, { withCredentials : true, });
    return savePeakDayResponse?.data;
}

async function updatePeakDay(peak_day_id, params){
    const updatePeakDayResponse = await axios.post(`config/park/peak_day/${peak_day_id}/update`, params, { withCredentials : true, });
    return updatePeakDayResponse?.data;
}

async function deletePeakDay(peak_day_id){
    const deletePeakDayResponse = await axios.delete(`config/park/peak_day/${peak_day_id}/delete`, { withCredentials : true, });
    return deletePeakDayResponse?.data;
}

// maintenance day management
async function maintenanceList(park_id, query){
    const queryString = serviceHelper.queryString(query);
    const maintenanceResponse = await axios.get(`config/park/${park_id}/maintenance/list?${queryString}`, { withCredentials : true, });
    return maintenanceResponse?.data;
}

async function saveParkMaintenance(park_id, params){
    const saveParkMaintenanceResponse = await axios.post(`config/park/${park_id}/maintenance/save`, params, { withCredentials : true, });
    return saveParkMaintenanceResponse?.data;
}

async function updateParkMaintenance(park_maintenance_id, params){
    const updateParkMaintenanceResponse = await axios.post(`config/park/maintenance/${park_maintenance_id}/update`, params, { withCredentials : true, });
    return updateParkMaintenanceResponse?.data;
}

async function deleteParkMaintenance(park_maintenance_id){
    const deleteParkMaintenanceResponse = await axios.delete(`config/park/maintenance/${park_maintenance_id}/delete`, { withCredentials : true, });
    return deleteParkMaintenanceResponse?.data;
}

// advance day management
async function advanceDayList(query){
    const queryString = serviceHelper.queryString(query);
    const advanceDayResponse = await axios.get(`config/park/advance_day_discount/list?${queryString}`, { withCredentials : true, });
    return advanceDayResponse?.data;
}

async function saveAdvanceDayDiscount(park_applicable_ticket_type_id, params){
    const saveAdvanceDayDiscountResponse = await axios.post(`config/park/park_applicable_ticket_type/${park_applicable_ticket_type_id}/advance_day_discount/save`, params, { withCredentials : true, });
    return saveAdvanceDayDiscountResponse?.data;
}

async function updateAdvanceDayDiscount(advance_day_discount_id, params){
    const updateAdvanceDayDiscountResponse = await axios.post(`config/park/park_applicable_ticket_type/advance_day_discount/${advance_day_discount_id}/update`, params, { withCredentials : true, });
    return updateAdvanceDayDiscountResponse?.data;
}

async function deleteAdvanceDayDiscount(advance_day_discount_id){
    const deleteAdvanceDayDiscountResponse = await axios.delete(`config/park/park_applicable_ticket_type/advance_day_discount/${advance_day_discount_id}/delete`, { withCredentials : true, });
    return deleteAdvanceDayDiscountResponse?.data;
}

// this will organize both peak and non peak day discounts by park applicable ticket type
// also getting the discount percentage and value
async function peakDayDiscountList(query){
    const queryString = serviceHelper.queryString(query);
    const peakDayResponse = await axios.get(`config/park/peak_day_discount/list?${queryString}`, { withCredentials : true, });
    return peakDayResponse?.data;
}

async function saveUpdatePeakDayDiscount(park_applicable_ticket_type_id, params){
    const saveUpdatePeakDayDiscountResponse = await axios.post(`config/park/park_applicable_ticket_type/${park_applicable_ticket_type_id}/peak_day_discount/upsert`, params, { withCredentials : true, });
    return saveUpdatePeakDayDiscountResponse?.data;
}

// ticket applicable users
async function getTicketApplicableUser(query){
    const queryString = serviceHelper.queryString(query);
    const ticketApplicableUserResponse = await axios.get(`config/park/ticket_applicable_user/list?${queryString}`, { withCredentials : true, });
    return ticketApplicableUserResponse?.data;
}

async function saveTicketApplicableUser(park_applicable_ticket_type_id, params){
    const saveTicketApplicableUserResponse = await axios.post(`config/park/park_applicable_ticket_type/${park_applicable_ticket_type_id}/ticket_applicable_user/save`, params, { withCredentials : true, });
    return saveTicketApplicableUserResponse?.data;
}

async function updateTicketApplicableUser(ticket_applicable_user_id, params){
    const updateTicketApplicableUserResponse = await axios.post(`config/park/ticket_applicable_user/${ticket_applicable_user_id}/update`, params, { withCredentials : true, });
    return updateTicketApplicableUserResponse?.data;
}

async function deleteTicketApplicableUser(ticket_applicable_user_id){
    const deleteTicketApplicableUserResponse = await axios.delete(`config/park/ticket_applicable_user/${ticket_applicable_user_id}/delete`, { withCredentials : true, });
    return deleteTicketApplicableUserResponse?.data;
}

// add ons configuration
async function addOnList(query){
    const queryString = serviceHelper.queryString(query);
    const addOnListResponse = await axios.get(`config/park/add_on/list?${queryString}`, { withCredentials : true, });
    return addOnListResponse?.data;
}

async function saveAddOnSetup(park_applicable_ticket_type_id, params){
    const saveAddOnSetupResponse = await axios.post(`config/park/park_applicable_ticket_type/${park_applicable_ticket_type_id}/add_on_setup/save`, params, { withCredentials : true,  });
    return saveAddOnSetupResponse?.data;
}

async function updateAddOnSetup(add_on_setup_id, params){
    const updateAddOnSetupResponse = await axios.post(`config/park/add_on_setup/${add_on_setup_id}/update`, params, { withCredentials : true, });
    return updateAddOnSetupResponse?.data;
}

async function deleteAddOnSetup(add_on_setup_id){
    const deleteAddOnSetupResponse = await axios.delete(`config/park/add_on_setup/${add_on_setup_id}/delete`, { withCredentials : true, });
    return deleteAddOnSetupResponse?.data;
}

// add on for park, v1 is on slot configuration
async function getParkAddOn(park_id, query){
    const queryString = serviceHelper.queryString(query);
    const parkAddOnResponse = await axios.get(`config/park/${park_id}/park_add_on/list?${queryString}`, { withCredentials : true, });
    return parkAddOnResponse?.data;
}

async function saveParkAddOn(params){
    const saveParkAddOnResponse = await axios.post(`config/park/park_add_on/save`, params, { withCredentials : true, });
    return saveParkAddOnResponse?.data;
}

async function updateParkAddOn(park_add_on_id, params){
    const updateParkAddOnResponse = await axios.post(`config/park/park_add_on/${park_add_on_id}/update`, params, { withCredentials : true, });
    return updateParkAddOnResponse?.data;
}

async function deleteParkAddOn(park_add_on_id){
    const deleteParkAddOnResponse = await axios.delete(`config/park/park_add_on/${park_add_on_id}/delete`, { withCredentials : true, });
    return deleteParkAddOnResponse?.data;
}

// ticket rules
async function getRequiredTicketRule(query){
    const queryString = serviceHelper.queryString(query);
    const requiredTicketRuleResponse = await axios.get(`config/park/required_ticket_rule/list?${queryString}`, { withCredentials : true, });
    return requiredTicketRuleResponse?.data;
}

async function saveRequiredTicketRule(params){
    const saveRequiredTicketRuleResponse = await axios.post(`config/park/required_ticket_rule/save`, params, { withCredentials : true, });
    return saveRequiredTicketRuleResponse?.data;
}

async function updateRequiredTicketRule(required_ticket_rule_id, params){
    const updateRequiredTicketRuleResponse = await axios.post(`config/park/required_ticket_rule/${required_ticket_rule_id}/update`, params, { withCredentials : true, });
    return updateRequiredTicketRuleResponse?.data;
}

async function deleteRequiredTicketRule(required_ticket_rule_id){
    const deleteRequiredTicketRuleResponse = await axios.delete(`config/park/required_ticket_rule/${required_ticket_rule_id}/delete`, { withCredentials : true, });
    return deleteRequiredTicketRuleResponse?.data;
}

// free ticket rules
async function getFreeTicketRule(query){
    const queryString = serviceHelper.queryString(query);
    const freeTicketRuleResponse = await axios.get(`config/park/free_ticket_rule/list?${queryString}`, { withCredentials : true, });
    return freeTicketRuleResponse?.data;
}

async function saveFreeTicketRule(params){
    const saveFreeTicketRuleResponse = await axios.post(`config/park/free_ticket_rule/save`, params, { withCredentials : true, });
    return saveFreeTicketRuleResponse?.data;
}

async function updateFreeTicketRule(free_ticket_rule_id, params){
    const updateFreeTicketRuleResponse = await axios.post(`config/park/free_ticket_rule/${free_ticket_rule_id}/update`, params, { withCredentials : true, });
    return updateFreeTicketRuleResponse?.data;
}

async function deleteFreeTicketRule(free_ticket_rule_id){
    const deleteFreeTicketRuleResponse = await axios.delete(`config/park/free_ticket_rule/${free_ticket_rule_id}/delete`, { withCredentials : true, });
    return deleteFreeTicketRuleResponse?.data;
}

// park taxes
async function getParkTax(query){
    const queryString = serviceHelper.queryString(query);
    const parkTaxResponse = await axios.get(`config/park/tax/list?${queryString}`, { withCredentials : true, });
    return parkTaxResponse?.data;
}

async function saveParkTax(params){
    const saveParkTaxResponse = await axios.post(`config/park/tax/save`, params, { withCredentials : true, });
    return saveParkTaxResponse?.data;
}

async function updateParkTax(park_tax_id, params){
    const updateParkTaxResponse = await axios.post(`config/park/tax/${park_tax_id}/update`, params, { withCredentials : true, });
    return updateParkTaxResponse?.data;
}

async function deleteParkTax(park_tax_id){
    const deleteParkTaxResposne = await axios.delete(`config/park/tax/${park_tax_id}/delete`, { withCredentials : true, });
    return deleteParkTaxResposne?.data;
}

// invoices
async function getParkInvoice(park_id){
    const parkInvoiceResponse = await axios.get(`config/park/${park_id}/invoice/get`, { withCredentials : true, });
    return parkInvoiceResponse?.data;
}

async function upsertParkInvoice(park_id, params){
    const { company_name, company_address, registration_number, term_and_condition, image, } = params;
    const formData = new FormData();
    image && formData.append('image', image);
    formData.append('company_name', company_name ?? '');
    formData.append('company_address', company_address ?? '');
    formData.append('registration_number', registration_number ?? '');
    formData.append('term_and_condition', (term_and_condition) ?? '');
    const upsertParkInvoiceResponse = await axios.post(`config/park/${park_id}/invoice/insert_update`, params, { withCredentials : true, headers : { "Content-Type": "multipart/form-data" }});
    return upsertParkInvoiceResponse?.data;
}

async function removeInvoiceCompanyLogo(invoice_id){
    const removeInvoiceCompanyLogoResponse = await axios.post(`config/park/invoice/${invoice_id}/logo/remove`, {}, { withCredentials : true, });
    return removeInvoiceCompanyLogoResponse?.data;
}

export default {
    getPark,
    getParkByAuth,
    getParkApplicableTicketTypes,
    removeParkApplicableTicketTypeImage,
    deleteParkApplicableTicketType,
    getParkApplicableTicketTypeitems,
    getAddOn,
    checkPeakDays,
    getCapacity,
    getPurchaseTypeCapacity,
    getTicketType,
    getTicketUserType,
    getCalendarOverview,
    saveParkApplicableTicketType,
    updateParkApplicableTicketType,
    updatePark,
    removeParkImage,
    peakDayList,
    savePeakDay,
    updatePeakDay,
    deletePeakDay,
    maintenanceList,
    saveParkMaintenance,
    updateParkMaintenance,
    deleteParkMaintenance,
    advanceDayList,
    saveAdvanceDayDiscount,
    updateAdvanceDayDiscount,
    deleteAdvanceDayDiscount,
    peakDayDiscountList,
    saveUpdatePeakDayDiscount,
    getTicketApplicableUser,
    saveTicketApplicableUser,
    updateTicketApplicableUser,
    deleteTicketApplicableUser,
    addOnList,
    saveAddOnSetup,
    updateAddOnSetup,
    deleteAddOnSetup,
    getParkAddOn,
    saveParkAddOn,
    updateParkAddOn,
    deleteParkAddOn,
    getRequiredTicketRule,
    saveRequiredTicketRule,
    updateRequiredTicketRule,
    deleteRequiredTicketRule,
    getFreeTicketRule,
    saveFreeTicketRule,
    updateFreeTicketRule,
    deleteFreeTicketRule,
    getParkTax,
    saveParkTax,
    updateParkTax,
    deleteParkTax,
    getParkInvoice,
    upsertParkInvoice,
    removeInvoiceCompanyLogo,
}