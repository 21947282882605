import React, { useEffect, useState } from 'react';
import entranceService from '../../../../services/operation/entrance.service';
import { Avatar, Image, Space, Spin, Table, Tag } from 'antd';
import moment from 'moment';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import serviceHelper from '../../../../helpers/functions/service.helper';

const EntranceTable = ({filter, transaction_type, default_filter, setErrors = () => {}}) => {
    const display_size = 10;
    const [state, setState] = useState({
        loading : false,
        entrance_transactions : [],
        entrance_count : 0,

        //page
        page : (isNaN(parseInt(default_filter?.page) - 1)) ? 0 : (parseInt(default_filter?.page) - 1),
    })

    const fetchEntranceTransactions = async () => {
        const { search, entrance, inout, start_date, end_date, } = filter;
        setState(state => ({...state, loading : true}));
        const entranceTransactionResponse = await entranceService.generateTransactionReports({
            search : search,
            entrance_ids : entrance?.join(','),
            ...(inout != null && ({ is_in : inout?.map(x => x === true ? 1 : 0)?.join(',') })),
            page : state?.page,
            size : display_size,
            start_date: start_date,
            end_date : end_date,

            transaction_type : transaction_type,
        });

        const { entrance_transactions, errors } = entranceTransactionResponse;

        setTimeout(() => {
            setState(state => ({...state, loading : false, entrance_transactions : entrance_transactions?.rows, entrance_count : entrance_transactions?.count}));
            setErrors(errors);
        }, 0);
    }

    // for changing filter
    const changeFilter = (key, value) => {
        let generate_filter = default_filter;
        generate_filter[key] = value;
        const queryString = serviceHelper?.queryString(generate_filter);
        window.history.replaceState({}, '', `${window.location.pathname}?${queryString}`);
    }

    useEffect(() => {
        fetchEntranceTransactions();
    }, [filter, state?.page ])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <Table
                scroll={{ x : 400, }}
                className='hub-table bordered'
                dataSource={state?.entrance_transactions}
                pagination={{ 
                    size : display_size,
                    showSizeChanger: false,
                    total : state?.entrance_count,
                    current : (state?.page + 1),
                    onChange : (page) => {
                        setState(state => ({...state, page : (page - 1)}))
                        changeFilter('page', page);
                        // dispatch(set_list_page(page - 1));
                    }
                }}
                columns={[
                {
                    ...(transaction_type == 'ticket' ? ({
                        title : 'Tickets',
                        dataIndex : 'ticket_user',
                        key : 'ticket_user',
                        width : '20%',
                        render: (text, row, index) => {
                            const { ticket_user_no, ticket } = text ?? {};
                            return (
                                <>
                                    <div>
                                        <span>{ticket_user_no}</span>
                                    </div>
                                </>
                            )
                        }
                    })
                    :
                    ({
                        title : 'Pass',
                        dataIndex : 'pass',
                        key : 'pass',
                        width : '20%',
                        render: (text, row, index) => {
                            const { name, pass_code } = text ?? {};
                            return (
                                <>
                                    <div>
                                        <div>
                                            <span>{name}</span>
                                        </div>
                                        <div>
                                            <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{pass_code}</span>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    })
                    )
                },{
                    title : 'Entrance',
                    dataIndex : 'entrance',
                    key : 'entrance',
                    render: (text, row, index) => {
                        const { name, entrance_no, entrance_type, park } = text ?? {};
                        return (
                            <>
                                <div>
                                    <div>
                                        <span>{name}</span>
                                    </div>

                                    <div>
                                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{park?.name}</span>
                                    </div>
                                </div>
                                
                            </>
                        )
                    }
                },{
                    title : 'In/Out',
                    dataIndex : 'is_in',
                    key : 'is_in',
                    render: (text, row, index) => {
                        const is_in = text;
                        return (
                            <>
                                {
                                    <Tag color={text ? 'green' : 'blue'} style={{border:'none', minWidth:80, textAlign:'center',}}>
                                        <span>{text ? 'In' : 'Out'}</span>
                                    </Tag>
                                }
                                
                            </>
                        )
                    }
                },{
                    title : 'Time',
                    dataIndex : 'time_log',
                    key : 'time_log',
                    render: (text, row, index) => {
                        const time_log = moment(text)?.format("YYYY-MM-DD hh:mm:ssa");
                        return (
                            <>
                                <span>{(time_log)}</span>
                                
                            </>
                        )
                    }
                },{
                    title : 'Face',
                    dataIndex : 'image_url',
                    key : 'image_url',
                    render : (text, row, index) => {
                        const { ticket_user, account, pass, } = row ?? {};
                        let image;
                        switch(transaction_type){
                            case 'account' : 
                                image = account?.face_image;
                                break;
                            case 'ticket' :
                                image = ticket_user?.image_url;
                                break;
                            default :
                                // if is not ticket, then consider as pass
                                image = pass?.url_image;
                                break;
                        }
                        return (
                            <>
                                <Space>
                                    <div>
                                        <div>
                                            <span style={{fontWeight:500, color:'var(--secondary-text-color)'}}>Registered</span>
                                        </div>
                                        <div style={{border:'1px solid var(--main-color)', borderRadius:6, width:'fit-content'}}>
                                            {
                                                image ?
                                                (
                                                    <>
                                                        <Image style={{padding:4, width : 80, height : 80, objectFit : 'cover',}} shape='square' size={80} src={image} icon={<UserOutlined />} />
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <Avatar style={{padding:4,}} shape='square' size={80} src={image} icon={<UserOutlined />} />
                                                    </>
                                                )
                                            }
                                            
                                        </div>
                                    </div>

                                    <div>
                                        <div>
                                            <span style={{fontWeight:500, color:'var(--secondary-text-color)'}}>Scanned</span>
                                        </div>
                                        <div style={{border:'1px solid var(--main-color)', borderRadius:6, width:'fit-content'}}>
                                            {
                                                text ?
                                                (
                                                    <>
                                                        <Image style={{padding:4, width : 80, height : 80, objectFit : 'cover',}} shape='square' size={80} src={text} icon={<UserOutlined />} />
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <Avatar style={{padding:4,}} shape='square' size={80} src={text} icon={<UserOutlined />} />
                                                    </>
                                                )
                                            }
                                            
                                        </div>
                                    </div>
                                </Space>
                            </>
                        )
                    }
                }]}
                />
            </Spin>
        </>
    );
}

export default EntranceTable;