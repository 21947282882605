import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({component: Component, ...rest}) => {
    const authRedux = useSelector(state => state.auth);
    return (
       
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <>
            <Route key={Math.floor(Math.random() * 100000)} {...rest} render={props => (
                authRedux?.auth ?
                    <Component {...props}/>
                : <Redirect to="/login" />
            )} />
        </>
    );
};

export default PrivateRoute;