import { DeleteOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Popconfirm, Space, Spin, Table, Tag, notification } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import globalHelper from '../../../helpers/functions/global.helper';
import customPassService from '../../../services/config/custom_pass.service';
import serviceHelper from '../../../helpers/functions/service.helper';

const CustomPassList = ({ filter, }) => {
    const [state, setState] = useState({
        loading : false,
        custom_passes : [],
        count : 0,
        refresh : 0,

        // pagination
        display_size : 10,
        page : filter?.page ?? 1,

        // delete
        delete_loading : false,
    })

    const paginateChange = (key, value) => {
        setState(state => ({...state, [key] : value}));
        let generate_filter = filter;
        generate_filter[key] = value;
        const queryString = serviceHelper?.queryString(generate_filter);
        window.history.replaceState({}, '', `${window.location.pathname}?${queryString}`);
    }

    const fetchCustomPasses = async () => {
        setState(state => ({...state, loading : true, }));

        const { search, parks, status, } = filter;
        const customPassResponse = await customPassService?.getCustomPassListing({
            // pagination
            page : (state?.page - 1),
            size : state?.display_size,

            search : search,
            parks : parks,
            
            is_active : status,
        });
        const { passes } = customPassResponse;
        setState(state => ({...state, loading : false, custom_passes : passes?.rows, count : passes?.count }));
    }

    const deleteCustomPass = async (custom_pass_id, name) => {
        setState(state => ({...state, delete_loading : true, }));
        const deleteCustomPassResponse = await customPassService.deleteCustomPass(custom_pass_id);

        notification.success({
            message : 'Delete successful',
            description : name,
        })

        setState(state => ({...state, delete_loading : false, refresh : (state?.refresh + 1)}));
    }

    useEffect(() => {
        fetchCustomPasses();
    }, [filter, state?.page, state?.refresh])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <Table
                    dataSource={state?.custom_passes}
                    scroll={{x : 400,}}
                    pagination={{ 
                        size : state?.display_size,
                        showSizeChanger: false,
                        total : state?.count,
                        current : parseInt(state?.page),
                        onChange : (page) => paginateChange('page', page),
                    }}
                    className='hub-table bordered'
                    columns={[{
                        title : 'Custom Pass',
                        dataIndex : 'name',
                        key : 'name',
                        width : '15%',
                        render : (name, row, index) => {
                            const generated_color = name ? globalHelper?.generateFixedColorFromString(name) : 'var(--secondary-text-color)';
                            const { url_image, pass_code, } = row;
                            return (
                                <>
                                    <Space>
                                        <div>
                                            <Avatar src={url_image} style={{ backgroundColor: generated_color }} icon={<UserOutlined />} />
                                        </div>
                                        <div>
                                            <div>
                                                <span>{name}</span>
                                            </div>
                                            <div>
                                                <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{pass_code}</span>
                                            </div>
                                        </div>
                                    </Space>
                                </>
                            )
                        }
                    },{
                        title : 'Parks',
                        dataIndex : 'parks',
                        key : 'parks',
                        render : (parks, row, index) => {
                            return (
                                <>
                                    <Space wrap key={index}>
                                    {
                                        parks?.map((park, index) => {
                                            const { name } = park ?? {};
                                            return (
                                                <Tag style={{background : 'var(--secondary-button-color)', color : 'var(--secondary-text-color)', border:'none',}}>
                                                    {name}
                                                </Tag>
                                            )
                                        })
                                    }
                                    </Space>
                                </>
                            )
                        }
                    },{
                        title : 'Creation Date',
                        dataIndex : 'created_at',
                        key : 'created_at',
                        render : (created_at, row, index) => {
                            return (
                                <>
                                    {moment(created_at)?.format("YYYY-MM-DD HH:mm:ssa")}
                                </>
                            )
                        }
                    },{
                        title : 'Status',
                        dataIndex : 'is_active',
                        key : 'is_active',
                        render : (is_active, row, index) => {
                            return (
                                <>
                                    <Tag color={is_active ? 'green' : 'orange'} style={{border: 'none',}}>
                                        {is_active ? 'Active' : 'Inactive'}
                                    </Tag>
                                </>
                            )
                        }
                    },{
                        title : 'Action',
                        dataIndex : 'custom_pass',
                        key : 'custom_pass',
                        width : '10%',
                        render : (custom_pass, row, index) => {
                            const { name, } = row;
                            const { custom_pass_id, } = custom_pass;
                            return (
                                <>
                                    <Button.Group>
                                        <Link to={`/custom/pass/detail/${custom_pass_id}`}>
                                            <Button style={{borderTopRightRadius : 0, borderBottomRightRadius : 0,}}>
                                                View
                                            </Button>
                                        </Link>
                                        <Popconfirm
                                        onConfirm={() => deleteCustomPass(custom_pass_id, name)}
                                        title={`Are you confirm to delete ${name}`}
                                        okButtonProps={{ loading : state?.delete_loading, }}
                                        >
                                            <Button icon={<DeleteOutlined style={{color:'var(--secondary-text-color)'}}/>} />
                                        </Popconfirm>
                                    </Button.Group>
                                </>
                            )
                        }
                    },]}
                />
            </Spin>
        </>
    );
}

export default CustomPassList;