import React, { useState } from 'react';
import MaintenanceDateList from './maintenance_date_list';
import { Button, Form, Modal, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AddMaintenanceDayModal from './add_maintenance_day_modal';
import dayjs from 'dayjs';
import parkService from '../../../services/config/park.service';
import EditMaintenanceDayModal from './edit_maintenance_day_modal';

const MaintenanceDateLayout = ({ park_id, }) => {
    const [save_form] = Form.useForm();
    const [edit_form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        
        create_modal : false,
        edit_modal : null,

        save_loading : false,
        update_loading : false,

        modal : 0,
        refresh : 0,
    });

    const saveMaintenanceDay = async () => {
        setState(state => ({...state, save_loading : true, }));
        
        const { name, maintenance_frequency_id, date, start_date : wk_start_date, end_date : wk_end_date, is_weekly, } = save_form?.getFieldsValue();
        let start_date;
        let end_date;
        if(is_weekly){
            start_date = dayjs(wk_start_date)?.format("YYYY-MM-DD");
            end_date = wk_end_date ? dayjs(wk_end_date)?.format("YYYY-MM-DD") : null;
        }else{
            start_date = dayjs(date?.[0])?.format("YYYY-MM-DD");
            end_date = date?.[1] ? dayjs(date?.[1])?.format("YYYY-MM-DD") : null;
        }

        const saveParkMaintenanceResponse = await parkService?.saveParkMaintenance(park_id, {
            name,
            maintenance_frequency_id,
            start_date : start_date,
            end_date : end_date,
        })
        .then((result) => {
            notification?.success({
                message : 'Added Park Maintenance'
            })

            save_form?.resetFields();
            setState(state => ({...state, save_loading : false,  modal : (state?.modal + 1), refresh : (state?.refresh + 1), create_modal : false, }));
        })
        .catch(err => setState(state => ({...state, save_loading : false,})));
    }

    const updateParkMaintenance = async () => {
        setState(state => ({...state, update_loading : true, }));
        
        const park_maintenance_id = state?.edit_modal;
        const { name, maintenance_frequency_id, date, start_date : wk_start_date, end_date : wk_end_date, is_weekly, } = edit_form?.getFieldsValue();
        let start_date;
        let end_date;
        if(is_weekly){
            start_date = dayjs(wk_start_date)?.format("YYYY-MM-DD");
            end_date = wk_end_date ? dayjs(wk_end_date)?.format("YYYY-MM-DD") : null;
        }else{
            start_date = dayjs(date?.[0])?.format("YYYY-MM-DD");
            end_date = date?.[1] ? dayjs(date?.[1])?.format("YYYY-MM-DD") : null;
        }

        const updateParkMaintenanceResponse = await parkService?.updateParkMaintenance(park_maintenance_id, {
            name,
            maintenance_frequency_id,
            start_date : start_date,
            end_date : end_date,
        })
        .then((result) => {
            notification?.success({
                message : 'Updated Park Maintenance'
            })

            edit_form?.resetFields();
            setState(state => ({...state, update_loading : false,  modal : (state?.modal + 1), refresh : (state?.refresh + 1), edit_modal : null, }));
        })
        .catch(err => setState(state => ({...state, update_loading : false,})));
    }

    const deleteParkMaintenance = async (park_maintenance_id) => {
        const deleteParkMaintenanceResponse = await parkService?.deleteParkMaintenance(park_maintenance_id);
        
        notification?.success({
            message : 'Park Maintenance Deleted',
        })

        setState(state => ({...state, refresh : (state?.refresh + 1)}));
    }

    return (
        <>
            <div>
                <div style={{ display : 'flex', alignItems : 'center', }}>
                    <div style={{ textAlign : 'start', }}>
                        <div>
                            <span>Maintenance Days</span>
                        </div>
                    </div>
                    <div style={{ marginLeft : 'auto', }}>
                        <Button
                        icon={<PlusOutlined />}
                        onClick={() => setState(state => ({...state, create_modal : true, modal : (state?.modal + 1), }))}
                        >
                            Maintenance Days
                        </Button>
                    </div>
                </div>
                <div style={{ marginTop : 24, }}>
                    <MaintenanceDateList
                    park_id={park_id}
                    refresh={state?.refresh}
                    openEditModal={(park_maintenance_id) => setState(state => ({...state, edit_modal : park_maintenance_id, }))}
                    deleteParkMaintenance={deleteParkMaintenance}
                    />
                </div>
            </div>

            <Modal
            title="New Maintenance Days"
            open={state?.create_modal}
            onCancel={() => {
                setState(state => ({...state, create_modal : null, modal : (state?.modal + 1), }));
            }}
            onOk={() => save_form.submit()}
            width={800}
            confirmLoading={state?.save_loading}
            >
                <Form
                form={save_form}
                layout='vertical'
                onFinish={saveMaintenanceDay}
                >
                    <AddMaintenanceDayModal form={save_form} />
                </Form>
            </Modal>

            <Modal
            title="Edit Maintenance Days"
            open={state?.edit_modal}
            onCancel={() => {
                setState(state => ({...state, edit_modal : null, modal : (state?.modal + 1), }));
            }}
            onOk={() => edit_form.submit()}
            width={800}
            confirmLoading={state?.update_loading}
            >
                <Form
                form={edit_form}
                layout='vertical'
                onFinish={updateParkMaintenance}
                >
                    <EditMaintenanceDayModal form={edit_form} park_id={park_id} park_maintenance_id={state?.edit_modal} modal_visible={state?.modal} />
                </Form>
            </Modal>
        </>
    );
}

export default MaintenanceDateLayout;