import React, { useState } from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import ParkConfigLayout from './components/park_config_layout';
import { useLocation } from 'react-router-dom';
import './park.css';

const ParkConfigIndex = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [filter, setFilter] = useState({
        park : isNaN(parseInt(queryParams.get("park"))) ? null : parseInt(queryParams.get("park")),
        tab : queryParams?.get("tab"),
    })

    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <ParkConfigLayout default_filter={filter} />
            </ContainerComponent>
        </>
    );
}

export default ParkConfigIndex;