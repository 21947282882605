import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import numberHelper from '../../../../helpers/functions/number.helper';

const TicketTypeTable = ({ ticket_types, park, }) => {

    const [state, setState] = useState({
        ticket_type_datas : [],
        columns : [],
    })
    const initializeTable = () => {
        let ticket_type_table = {};
        let columns = [];

        //re-arrange the sequence
        ticket_types = ticket_types?.sort((a, b) => a.ticket_type_id - b.ticket_type_id);
        for(const ticket_type of ticket_types){
            const { name, sales_amount, ticket_user_count, } = ticket_type;
            ticket_type_table[name] = {
                ticket_user_count : ticket_user_count,
                sales_amount : sales_amount,
            };

            columns.push({
                title : name,
                dataIndex : name,
                key : name,
                render: (text, row, index) => {
                    const { ticket_user_count, sales_amount } = text ?? {};
                    return (
                        <>
                            <div>
                                <span style={{color:'var(--main-color)'}}>{numberHelper?.commaNumber(ticket_user_count, 0)}</span>
                            </div>
                            <div>
                                <span>{`${park?.currency?.code}${numberHelper?.commaNumber(sales_amount)}`}</span>
                            </div>
                        </>
                    )
                }
            })
        }


        setState(state => ({...state, ticket_type_datas : ticket_type_table, columns : columns}))

    }

    useEffect(() => {
        initializeTable();
    }, [ticket_types])

    return (
        <>
            <Table
            scroll={{ x : 400, }}
            size='small'
            pagination={false}
            className='hub-table bordered'
            dataSource={[state?.ticket_type_datas]}
            columns={state?.columns}
            />
        </>
    );
}

export default TicketTypeTable;