import React from 'react';
import ContainerComponent from '../../../helpers/components/container.component';
import TicketUserTypeLayout from './components/config_ticket_user_type_layout';

const ConfigTicketUserType = () => {
    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <TicketUserTypeLayout />
            </ContainerComponent>
        </>
    );
}

export default ConfigTicketUserType;