import { Checkbox, List, Pagination, Spin } from 'antd';
import React, { useEffect, useState, } from 'react';
import ticketService from '../../../services/ticket/ticket.service';
import { LoadingOutlined } from '@ant-design/icons';

// this is special because ticket list is huge
const IntegrationTicketUserFilter = ({ ticket_id, ticket_user_no, }) => {
    const [state, setState] = useState({
        loading : false,
        ticket_users : [],
        count : null,

        // pagination
        size : 10,
        page : 1,
    })
    const fetchTicketUsers = async () => {
        setState(state => ({...state, loading : true, }))
        // get ticket users
        const ticketUserResponse = await ticketService?.getTicketUser({
            ticket_id,
            search_ticket_user_no : ticket_user_no,
            page : (state?.page - 1), size : state?.size,
        });

        const { ticket_users } = ticketUserResponse;
        setState(state => ({...state, loading : false, ticket_users : ticket_users?.rows, count : ticket_users?.count, }))
    }
    useEffect(() => {
        if(ticket_id){
            fetchTicketUsers();
        }
    }, [ticket_id, ticket_user_no, state?.page])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined style={{color:'var(--main-color)'}} />}>
                <div>
                    <List
                    style={{ width:280, }}
                    dataSource={state?.ticket_users}
                    renderItem={(item, index) => {
                        const { ticket_user_id, ticket_user_no, } = item ?? {};
                        return (
                            <List.Item style={{width:'100%'}} key={index}>
                                <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                    <div>
                                        <span>{ticket_user_no}</span>
                                    </div>
                                    <div style={{marginLeft:'auto'}}>
                                        <Checkbox
                                        value={ticket_user_id}
                                        />
                                    </div>
                                </div>
                            </List.Item>
                        )
                    }}
                    />
                </div>
                {/* pagination */}
                <div style={{ margin: '24px 0'}}>
                    <Pagination 
                    showSizeChanger={false} 
                    pageSize={10} 
                    onChange={(page) => setState(state => ({...state, page : page, }))}
                    current={state?.page}
                    total={state?.count} 
                    />
                </div>
            </Spin>
        </>
    );
}

export default IntegrationTicketUserFilter;