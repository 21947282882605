import React, { useEffect, useState } from 'react';
import parkService from '../../../services/config/park.service';
import { Avatar, Button, Divider, Form, InputNumber, Select, Space } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const AddParkAddOnModal = ({ form, park_add_ons }) => {
    const [state, setState] = useState({
        loading : false,
        add_ons : [],
    });

    const fetchAddOn = async () => {
        const addOnResponse = await parkService?.addOnList();
        const { add_ons } = addOnResponse;
        return add_ons;
    }

    const initialize = async () => {
        setState(state => ({...state, loading : true, }));
        const add_ons = await fetchAddOn();

        setState(state => ({...state, loading : false, add_ons, }));
    }

    useEffect(() => {
        initialize();
    }, [])

    return (
        <>
            <div>
                <Form.Item label={"Add On"} name={"add_on_id"}>
                    <Select
                    showSearch
                    optionFilterProp="label2"
                    options={state?.add_ons?.map(x => ({ label : (
                        <>
                            <div>
                                <Space wrap align='center'>
                                    {
                                        x?.image && (
                                            <>
                                                <div>
                                                    <Avatar shape='square' src={x?.image} />
                                                </div>
                                            </>
                                        )
                                    }
                                    
                                    <div>
                                        <span>{x?.name}</span>
                                    </div>
                                </Space>
                            </div>
                        </>
                    ),
                    disabled : park_add_ons?.find(park_add_on => park_add_on?.add_on_id === x?.add_on_id),
                    label2 : x?.name,
                    value : x?.add_on_id,
                    // min_age : x?.min_age,
                    // max_age : x?.max_age,
                    // descriptions : x?.descriptions,
                    }))}
                    dropdownRender={(menu) => (
                        <>
                            {menu}
                            <div>
                                <Divider style={{ marginBlock : 8, }} />
                                <div style={{ padding : 8, }}>
                                    <Link to="/config/add_on">
                                        <Button icon={<PlusCircleOutlined />}>Add On</Button>
                                    </Link>
                                </div>
                            </div>
                        </>
                    )}
                    optionLabelProp='label2'
                    />
                </Form.Item>

                <Form.Item name={'slot'} label={'Slot Per Day'} style={{ margin : 0, }}>
                    <InputNumber min={0} placeholder='Slot' precision={0} style={{ width : '100%', }} />
                </Form.Item>
            </div>
        </>
    );
}

export default AddParkAddOnModal;