import { Button, ColorPicker, Popconfirm, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import ticketUserTypeService from '../../../../services/config/ticket_user_type.service';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const ConfigTicketUserTypeList = ({ filter, refresh, openEditModal = () => {}, deleteTicketUserType = () => {} }) => {
    const [state, setState] = useState({
        loading : false,
        ticket_user_types : [],
    })

    const fetchTicketUserTypes = async () => {
        setState(state => ({...state, loading : true, }));
        const { search, } = filter;
        const ticketUserTypeResponse = await ticketUserTypeService?.fetchTicketUserType({
            search,
        });

        const { ticket_user_types } = ticketUserTypeResponse;

        setState(state => ({...state, loading : false, ticket_user_types : ticket_user_types, }));
    }

    useEffect(() => {
        fetchTicketUserTypes();
    }, [filter, refresh])

    return (
        <>
            <Table
            scroll={{ x : 400, }}
            className='hub-table bordered'
            dataSource={state?.ticket_user_types}
            columns={[{
                title : 'Name',
                dataIndex : 'name',
                key : 'name',
                render : (name, row, index) => {
                    const { descriptions } = row;
                    return (
                        <>
                            <div>
                                <div>
                                    <span>{name}</span>
                                </div>
                                <div>
                                    <span style={{ fontSize : 12, color : 'var(--secondary-text-color)', }}>{descriptions}</span>
                                </div>
                            </div>
                        </>
                    )
                }
            },{
                title : 'Age',
                dataIndex : 'age',
                key : 'age',
                render : (age, row, index) => {
                    const { min_age, max_age, } = row;
                    return (
                        <>
                            <div>
                                <span>
                                {min_age != null ?
                                (
                                    max_age ?
                                    (
                                        `${min_age} - ${max_age}`
                                    )
                                    :
                                    (
                                        `${min_age} - 100+`
                                    )
                                ) : ""}
                                </span>
                            </div>
                        </>
                    )
                }
            },{
                title : 'Color',
                dataIndex : 'color',
                key : 'color',
                render : (color, row, index) => {
                    return (
                        <>
                            <div>
                                {
                                    color ? (
                                        <>
                                            <ColorPicker value={color} disabled/>
                                        </>
                                    )
                                    :
                                    (
                                        <span style={{ fontSize : 12, color : 'var(--secondary-text-color)', }}>No color configured</span>
                                    )
                                }
                                
                            </div>
                        </>
                    )
                }
            },{
                title : 'Action',
                dataIndex : 'ticket_user_type_id',
                key : 'ticket_user_type_id',
                width : '10%',
                render : (ticket_user_type_id, row, index) => {
                    return (
                        <>
                            <Button.Group>
                                <Button
                                onClick={() => openEditModal(ticket_user_type_id)}
                                icon={<EditOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                />

                                <Popconfirm
                                onConfirm={() => deleteTicketUserType(ticket_user_type_id)}
                                title="Delete Ticket Type"
                                overlayStyle={{ width : 250,}}
                                >
                                    <Button
                                    icon={<DeleteOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                    />
                                </Popconfirm>
                            </Button.Group>
                        </>
                    )
                }
            },]}
            />
        </>
    );
}

export default ConfigTicketUserTypeList;