import { CloseOutlined, EditOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Spin, Tooltip, Upload } from 'antd';
import React, { useState, useEffect } from 'react';
import globalHelper from '../../../helpers/functions/global.helper';
import Compressor from 'compressorjs';
import staffService from '../../../services/config/staff.service';

const DetailAssociateImage = ({ staff_associate, refreshStaff, is_upload = true, form = null, }) => {
    const [state, setState] = useState({
        loading : false,
        file : null,
    })
    const acceptable_file_format = ['image/jpeg', 'image/png'];

    const handleChange = async info => {
        // update here
        setState(state => ({...state, loading : true, }))
        const { file } = info;

        // compress image
        let compressed_file = null;
        if(file){
            const compressor = new Promise((resolve, reject) => 
                new Compressor(file, {
                    quality : 0.8,
                    height : 800,
                    width : 600,
                    mimeType : 'image/jpeg',
                    success : resolve,
                    error : reject,
                    // convertSize: 500000,
                })
            )
            compressed_file = await compressor;
        }

        if(is_upload){
            // upload image to server
            const uploadResponse = await staffService?.uploadStaffAssociateImage(staff_associate?.staff_associate_id, compressed_file);
            refreshStaff();
        }
        form && form.setFieldsValue({ image_url : compressed_file, })
        const base64Image = await renderFile(file);
        setState(state => ({...state, loading : false, file : base64Image, }))
    }

    const removeImage = async () => {
        setState(state => ({...state, loading : true, }));
        if(is_upload){
            // upload image to server
            const uploadResponse = await staffService?.uploadStaffAssociateImage(staff_associate?.staff_associate_id, null);
            refreshStaff();
        }
        form && form.setFieldsValue({ image_url : null, })
        setState(state => ({...state, loading : false, file : null,}));
    }

    const beforeUpload = async (file) => {
        const base64Image = await renderFile(file);
        setState(state => ({...state, file : base64Image}));

        return false;
    }

    const renderFile = async (file) => {
        const is_image = acceptable_file_format?.find(x => x == file?.type);
        let base64Image;
        if(is_image){
            const uploaded_file = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    file.base64 = reader.result.split(',')[1];
                    resolve(file);
                };
                reader.onerror = (error) => reject(error);
            });
    
            base64Image = `data:image/jpeg;base64,${uploaded_file?.base64}`
        }else{
            // errorModal("Please upload in image format");
        }
        return base64Image;
    }

    const initializeImage = () => {
        const file = staff_associate?.pass?.url_image;
        setState(state => ({...state, file : file,}));
    }

    useEffect(() => {
        initializeImage();
    }, [staff_associate])

    return (
        <>
            <Badge
            offset={[-10, 100]}
            count={
                <Button.Group>
                    <Upload
                    beforeUpload={beforeUpload}
                    maxCount={1}
                    onChange={handleChange}
                    showUploadList={false}
                    >
                        <Button
                        size='small'
                        shape='circle'
                        icon={<EditOutlined style={{color:'var(--secondary-text-color)',}} />}
                        style={{borderBottomRightRadius : state?.file ? 0 : '50%', borderTopRightRadius: state?.file ? 0 : '50%',}}
                        />
                    </Upload>
                    {
                        state?.file &&
                        (
                            <Button
                            onClick={removeImage}
                            size='small'
                            shape='circle'
                            disabled={!state?.file}
                            icon={<CloseOutlined style={{color:'var(--secondary-text-color)',}} />}
                            // style={{background:'var(--secondary-button-color)',}}
                            />
                        )
                    }
                </Button.Group>
            }>
                <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                    <Avatar
                    size={120}
                    style={{
                        background : staff_associate?.pass?.name ? globalHelper?.generateFixedColorFromString(staff_associate?.pass?.pass_code) : 'var(--secondary-text-color)'
                    }}
                    src={state?.file}
                    icon={<UserOutlined />}
                    />
                </Spin>
            </Badge>
        </>
    );
}

export default DetailAssociateImage;