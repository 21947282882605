import React, { useEffect, useState } from 'react';
import ticketService from '../../../services/ticket/ticket.service';
import { Col, Form, Input, Row } from 'antd';
import integrationService from '../../../services/integration/integration.service';
import IntegrationPazzport from '../../integration/pazzport';

const FieldDynamicItem = ({ ticket_user, form, fields, ticket_user_fields }) => {
    return (
        <>
            <div style={{marginTop:12,}}>
                <Row>
                {
                    fields?.map((field, index) => {
                        const { ticket_user_field_id, key, label, descriptions, placeholder, park_api_type, } = field;
                        return (
                            <Col span={12} key={index}>
                                <Form.Item label={label} name={key}>
                                    {
                                        park_api_type ?
                                        (
                                            <>
                                                {
                                                    park_api_type?.condition === "FETCH_PAZZPORT" ?
                                                    (
                                                        <>
                                                            <IntegrationPazzport ticket_user={ticket_user} field={field} form={form} ticket_user_field={ticket_user_fields?.find(x => x.park_api_type_id === park_api_type?.park_api_type_id)} />
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                        {/* If there is any custom integration */}
                                                        </>
                                                    )
                                                }
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <Input />
                                            </>
                                        )
                                    }
                                </Form.Item>
                            </Col>
                        )
                    })
                }
                </Row>
            </div>
        </>
    );
}

export default FieldDynamicItem;