import { Avatar, Button, Form, Modal, Popconfirm, Space, Table, Tag, notification } from 'antd';
import React, { useState } from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import globalHelper from '../../../helpers/functions/global.helper';
import { DeleteOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import DetailAssociateEdit from './detail_associate_edit';
import staffService from '../../../services/config/staff.service';
import DetailAssociateNew from './detail_associate_new';

const DetailAssociate = ({ staff, refreshStaff, }) => {
    const [form] = Form.useForm();
    const [new_form] = Form.useForm();
    const { staff_associates } = staff ?? {};
    const [state, setState] = useState({
        save_loading : false,
        delete_loading : false,
        edit_modal : null,
        new_modal : false,
    })

    const updateAssociate = async () => {
        setState(state => ({...state, save_loading : true, }));
        const staff_associate_id = state?.edit_modal;
        const { name, identification_number, relationship, is_active, parks } = form.getFieldsValue();
        const updateStaffAssociateResponse = await staffService?.updateStaffAssociate(staff_associate_id, {
            name, identification_number, relationship, is_active, parks,
        });

        setState(state => ({...state, save_loading : false, edit_modal : null, }));
        // form.resetFields();
        refreshStaff();
    }

    const saveAssociate = async () => {
        setState(state => ({...state, save_loading : true, }));
        const { image_url, name, identification_number, relationship, is_active, parks } = new_form?.getFieldsValue();
        const saveStaffAssociateResponse = await staffService?.saveStaffAssociate(staff?.staff_id, {
            image_url, name, identification_number, relationship, is_active, parks,
        });

        const { save_pass } = saveStaffAssociateResponse;
        setState(state => ({...state, save_loading : false, new_modal : false, }));

        notification.success({
            message : 'Added Staff Associate',
            description : save_pass?.pass_code
        })
        new_form.resetFields();
        refreshStaff();
    }

    const deleteAssociate = async (staff_associate_id, pass) => {
        setState(state => ({...state, delete_loading : true, }));
        const deleteAssociateResponse = await staffService?.deleteStaffAssociate(staff_associate_id);
        notification.success({
            message : 'Delete successful',
            description : pass?.name,
        })
        setState(state => ({...state, delete_loading : false, }));
        refreshStaff();
    }

    return (
        <>
            <div>
                <div>
                    <div style={{display:'flex', alignItems: 'center', }}>
                        <div>
                            <PageHeaderComponent title={<span style={{fontSize:16,}}>Family Members</span>} textAlign={'start'} />
                        </div>
                        <div style={{marginLeft:'auto'}}>
                            <Button onClick={() => setState(state => ({...state, new_modal : true, }))}>
                                Add new member
                            </Button>
                        </div>
                    </div>
                    
                </div>
                <div style={{marginTop:12,}}>
                    <Table
                    dataSource={staff_associates}
                    scroll={{x : 400,}}
                    className='hub-table bordered'
                    columns={[{
                        title : 'Name',
                        dataIndex : 'pass',
                        key : 'pass',
                        width : '20%',
                        render : (pass, row, index) => {
                            const { name, url_image, pass_code, } = pass ?? {};
                            const generated_color = pass_code && globalHelper?.generateFixedColorFromString(pass_code);
                            return (
                                <>
                                    <Space>
                                        <div>
                                            <Avatar src={url_image} style={{ backgroundColor: generated_color }} icon={<UserOutlined />} />
                                        </div>
                                        <div>
                                            <div>
                                                <span>{name}</span>
                                            </div>
                                            <div>
                                                <span style={{fontSize:12, color : 'var(--secondary-text-color)',}}>{pass_code}</span>
                                            </div>
                                        </div>
                                    </Space>
                                </>
                            )
                        }
                    },{
                        title : 'Identification Number',
                        dataIndex : 'pass',
                        key : 'pass',
                        render : (pass, row, index) => {
                            const { identification_number } = pass ?? {};
                            return (
                                <>
                                    <div>
                                        <span>{identification_number}</span>
                                    </div>
                                </>
                            )
                        }
                    },{
                        title : 'Parks',
                        dataIndex : 'pass',
                        key : 'pass',
                        width : 200,
                        render : (pass, row, index) => {
                            const { parks } = pass ?? {};
                            return (
                                <>
                                    <div>
                                        <Space wrap>
                                        {
                                            parks?.map((park, index) => {
                                                const { name } = park ?? {};
                                                return (
                                                    <Tag style={{background : 'var(--secondary-button-color)', color : 'var(--secondary-text-color)', border:'none',}}>
                                                        {name}
                                                    </Tag>
                                                )
                                            })
                                        }
                                        </Space>
                                    </div>
                                </>
                            )
                        }
                    },{
                        title : 'Relationship',
                        dataIndex : 'relationship',
                        key : 'relationship',
                    },{
                        title : 'Status',
                        dataIndex : 'pass',
                        key : 'pass',
                        render : (pass, row, index) => {
                            const { is_active, } = pass;
                            return (
                                <>
                                    <div>
                                        <Tag color={is_active ? 'green' : 'orange'} style={{border: 'none',}}>
                                            {is_active ? 'Active' : 'Inactive'}
                                        </Tag>
                                    </div>
                                </>
                            )
                        }
                    },{
                        title : 'Action',
                        dataIndex : 'staff_associate_id',
                        key : 'staff_associate_id',
                        width : '10%',
                        // fixed : 'right',
                        render : (staff_associate_id, row, index) => {
                            const { pass, } = row;
                            return (
                                <>
                                    <Button.Group>
                                        <Button icon={<EditOutlined style={{color:'var(--secondary-text-color)',}} />} onClick={() => setState(state => ({...state, edit_modal : staff_associate_id}))} />
                                        
                                        <Popconfirm
                                        onConfirm={() => deleteAssociate(staff_associate_id, pass)}
                                        title={`Are you confirm to delete ${pass?.name}`}
                                        okButtonProps={{ loading : state?.delete_loading, }}
                                        >
                                            <Button icon={<DeleteOutlined style={{color:'var(--secondary-text-color)',}} />} />
                                        </Popconfirm>
                                    </Button.Group>
                                </>
                            )
                        }
                    },]}
                    />
                </div>
            </div>

            <Modal
            confirmLoading={state?.save_loading}
            open={state?.edit_modal}
            title="Edit Family Members"
            onCancel={() => setState(state => ({...state, edit_modal : null, }))}
            width={800}
            onOk={() => form.submit()}
            >
                <Form
                form={form}
                layout='vertical'
                onFinish={updateAssociate}
                >
                    <DetailAssociateEdit refreshStaff={refreshStaff} staff_associate_id={state?.edit_modal} staff_id={staff?.staff_id} form={form} />
                </Form>
            </Modal>
            
            <Modal
            confirmLoading={state?.save_loading}
            open={state?.new_modal}
            title="New Family Members"
            width={800}
            onCancel={() => setState(state => ({...state, new_modal : false, }))}
            onOk={() => new_form.submit()}
            >
                <Form
                form={new_form}
                layout='vertical'
                onFinish={saveAssociate}
                >
                    <DetailAssociateNew refreshStaff={refreshStaff} staff_id={staff?.staff_id} form={new_form}/>
                </Form>
            </Modal>
        </>
    );
}

export default DetailAssociate;