import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getParkIntegrations(park_id, query){
    const queryString = serviceHelper.queryString(query);
    const entranceResponse = await axios.get(`integration/api_type/park/${park_id}?${queryString}`, { withCredentials : true });
    return entranceResponse?.data;
}

export default {
    getParkIntegrations,
}