import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getDashboard(query){
    const queryString = serviceHelper.queryString(query);
    const dashboardResponse = await axios.get(`dashboard/get?${queryString}`, { withCredentials : true });
    return dashboardResponse?.data;
}


export default {
    getDashboard,
}