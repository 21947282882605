import React, { useEffect, useMemo, useState } from 'react';
import parkService from '../../../services/config/park.service';
import { Avatar, Button, Card, Col, Form, Modal, Popconfirm, Row, Space, Spin, Tag, notification } from 'antd';
import { AppstoreOutlined, DeleteOutlined, EditOutlined, LoadingOutlined, PlusOutlined, TagFilled } from '@ant-design/icons';
import numberHelper from '../../../helpers/functions/number.helper';
import AddTicketGroupModal from './add_ticket_group_modal';
import otherService from '../../../services/config/other.service';
import EditTicketGroupModal from './edit_ticket_group_modal';
import AdvanceDayDiscount from './advance_day_discount';
import PeakDayDiscount from './peak_day_discount';

const TicketLayout = ({ park_id, }) => {
    const [save_form] = Form.useForm();
    const [edit_form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        park : null,
        ticket_applicable_users : [],
        advance_day_discount : [],

        create_modal_park_applicable_ticket_type : null,
        update_modal_ticket_applicable_user : null,

        save_loading : false,
        update_loading : false,
        
        modal : 0,
        refresh : 0,
    })

    const configured_ticket_user_types = useMemo(() => {
        const { park_applicable_ticket_type_id } = state?.create_modal_park_applicable_ticket_type?.ParkApplicableTicketType ?? {};
        const configured_ticket_applicable_users = state?.ticket_applicable_users?.filter(x => x?.park_applicable_ticket_type_id === park_applicable_ticket_type_id);
        return configured_ticket_applicable_users;
    }, [state?.create_modal_park_applicable_ticket_type]);

    const get_ticket_type_by_ticket_applicable_user = useMemo(() => {
        const ticket_applicable_user = (state?.ticket_applicable_users?.find(x => x?.ticket_applicable_user_id === state?.update_modal_ticket_applicable_user));
        const ticket_type = state?.park?.ticket_types?.find(x => x?.ParkApplicableTicketType?.park_applicable_ticket_type_id === ticket_applicable_user?.park_applicable_ticket_type_id);
        return ticket_type;
    }, [state?.update_modal_ticket_applicable_user])

    const saveTicketApplicableUser = async () => {
        setState(state => ({...state, save_loading : true, }));
        const { park_applicable_ticket_type_id } = state?.create_modal_park_applicable_ticket_type?.ParkApplicableTicketType ?? {};
        const { descriptions, is_fixed_price, min_purchase, price, purchase_type_ticket_applicable_users, ticket_user_type_id, } = save_form?.getFieldsValue();
        const saveTicketApplicableUserResponse = await parkService?.saveTicketApplicableUser(park_applicable_ticket_type_id, {
            descriptions, is_fixed_price, min_purchase, price, ticket_user_type_id,
            purchase_types : purchase_type_ticket_applicable_users,
        }).catch(err => {
            setState(state => ({...state, save_loading : false,}));
        });

        notification?.success({
            message : 'Ticket Added',
        })

        setState(state => ({...state, save_loading : false, create_modal_park_applicable_ticket_type : null, refresh : (state?.refresh + 1),}));
        save_form?.resetFields();
    }

    const openEditModal = (ticket_applicable_user_id) => {
        setState(state => ({...state, update_modal_ticket_applicable_user : ticket_applicable_user_id, }));
    }

    const updateTicketApplicableUser = async () => {
        setState(state => ({...state, update_loading : true, }));
        const ticket_applicable_user_id = state?.update_modal_ticket_applicable_user;
        const { descriptions, is_fixed_price, min_purchase, price, purchase_type_ticket_applicable_users : purchase_type_ids, } = edit_form?.getFieldsValue();

        // get all purchase types
        const purchaseTypeResponse = await otherService?.getPurchaseTypes();
        const { purchase_types } = purchaseTypeResponse;
        const purchase_type_ticket_applicable_users = purchase_types?.map(x => {
            const is_purchaseable = purchase_type_ids?.find(purchase_type_id => purchase_type_id === x?.purchase_type_id) ? true : false;
            return {
                purchase_type_id : x?.purchase_type_id,
                is_purchaseable,
            }
        })
        const updateTicketApplicableUserResponse = await parkService?.updateTicketApplicableUser(ticket_applicable_user_id, {
            descriptions, is_fixed_price, min_purchase, price, purchase_type_ticket_applicable_users,
        });

        const { update_ticket_applicable_user } = updateTicketApplicableUserResponse;
        if(update_ticket_applicable_user){
            notification?.success({
                message : 'Ticket Updated',
            })
        }
        setState(state => ({...state, update_loading : false, update_modal_ticket_applicable_user : false, refresh : (state?.refresh + 1),}));
        // edit_form?.resetFields();
    }

    const deleteTicketApplicableUser = async (ticket_applicable_user_id) => {
        const deleteTicketApplicableUserResponse = await parkService?.deleteTicketApplicableUser(ticket_applicable_user_id);

        notification?.success({
            message : 'Ticket Deleted',
        })
        setState(state => ({...state, refresh : (state?.refresh + 1)}));
    }

    const fetchPark = async () => {
        const parkResponse = await parkService?.getPark({ park_id : park_id, });
        const { parks, } = parkResponse;

        return parks;
    }

    const fetchTicketApplicableUsers = async () => {
        const ticketApplicableUserResponse = await parkService?.getTicketApplicableUser({
            park_id : park_id,
        });

        const { ticket_applicable_users } = ticketApplicableUserResponse;
        return ticket_applicable_users;
    }

    const fetchAdvanceDays = async () => {
        const advanceDayResponse = await parkService?.advanceDayList({
            park_id,
        });
        const { advance_day_discount, } = advanceDayResponse;
        return advance_day_discount;
    }

    const refreshPage = () => {
        setState(state => ({...state, refresh : (state?.refresh + 1)}));
    }
    
    const initialize = async () => {
        setState(state => ({...state, loading : true, }));
        const park = await fetchPark();
        const ticket_applicable_users = await fetchTicketApplicableUsers();
        const advance_day_discount = await fetchAdvanceDays();

        setState(state => ({...state,  park : park, ticket_applicable_users : ticket_applicable_users, advance_day_discount : advance_day_discount, loading : false, }));
    }

    useEffect(() => {
        initialize();
    }, [park_id, state?.refresh])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <Space size={20} direction='vertical' style={{ width : '100%', }}>
                {
                    state?.park?.ticket_types?.map((ticket_type, index) => {
                        const { park_applicable_ticket_type_id, image } = ticket_type?.ParkApplicableTicketType ?? {};
                        const ticket_applicable_users = (state?.ticket_applicable_users?.filter(x => x?.park_applicable_ticket_type_id === park_applicable_ticket_type_id));
                        const advance_day_discount = (state?.advance_day_discount?.filter(x => x?.park_applicable_ticket_type_id === park_applicable_ticket_type_id));
                        return (
                            <div key={index}>
                                <Card
                                className='card-shadow'
                                styles={{ body : { padding : 12, } }}
                                style={{ border: 'none', }}
                                >
                                    <div>
                                        <div style={{ textAlign : 'start', }}>
                                            <Space size={20}>
                                                <Space>
                                                    <div>
                                                        <Avatar icon={<AppstoreOutlined />} shape='square' size='small' src={image} />
                                                    </div>
                                                    <div>
                                                        <span>{ticket_type?.name}</span>
                                                    </div>
                                                </Space>
                                            </Space>
                                        </div>
                                        <div style={{ marginTop: 12, }}>
                                            <Row gutter={[12, 12]}>
                                                {
                                                    ticket_applicable_users?.map((ticket_applicable_user, index) => {
                                                        const { ticket_user_type, descriptions, purchase_types, price, is_fixed_price, ticket_applicable_user_id, } = ticket_applicable_user ?? {};
                                                        return (
                                                            <Col span={6} xs={24} md={8} xl={6} key={index}>
                                                                <Card
                                                                styles={{ body : { padding : 12, } }}
                                                                style={{ height : '100%', }}
                                                                >
                                                                    <div style={{ textAlign : 'start', }}>
                                                                        <div style={{ display : 'flex', alignContent : 'center', }}>
                                                                            <div>
                                                                                <span style={{ fontSize : 14, fontWeight : 500, color : 'var(--secondary-text-color)', }}>{ticket_user_type?.name}</span>
                                                                            </div>
                                                                            <div style={{ marginLeft : 'auto' }}>
                                                                                <Space size={4}>
                                                                                    <Button onClick={() => openEditModal(ticket_applicable_user_id)} icon={<EditOutlined style={{ color : 'var(--secondary-text-color)', }} />} size='small' />
                                                                                    <Popconfirm
                                                                                    onConfirm={() => deleteTicketApplicableUser(ticket_applicable_user_id)}
                                                                                    title="Delete Ticket"
                                                                                    description={`${ticket_user_type?.name}`}
                                                                                    overlayStyle={{ width : 250,}}
                                                                                    >
                                                                                        <Button icon={<DeleteOutlined style={{ color : 'var(--secondary-text-color)', }} />} size='small' />
                                                                                    </Popconfirm>
                                                                                </Space>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <Space style={{ fontSize : 18, }}>
                                                                                <div>
                                                                                    <span>{`RM${numberHelper?.commaNumber(price)}`}</span>
                                                                                </div>
                                                                            </Space>
                                                                        </div>
                                                                        <div style={{ minHeight : 40, }}>
                                                                            <span style={{ fontSize : 12, color : 'var(--secondary-text-color)', }}>{descriptions}</span>
                                                                        </div>

                                                                        <div style={{ marginTop : 4, }}>
                                                                            <Space wrap size={4}>
                                                                                {
                                                                                    purchase_types?.filter(x => x?.PurchaseTypeTicketApplicableUser?.is_purchaseable)?.map((purchase_type, index) => {
                                                                                        const { name, } = purchase_type;
                                                                                        return (
                                                                                            <Tag color={'var(--secondary-button-color)'} style={{ color : 'var(--secondary-text-color)', border : 'none', }}>
                                                                                                {name}
                                                                                            </Tag>
                                                                                        )
                                                                                    })
                                                                                }

                                                                                {
                                                                                    is_fixed_price &&
                                                                                    (
                                                                                        <>
                                                                                            <Tag color={'green'} style={{ border : 'none', }}>
                                                                                                {`Fixed Price`}
                                                                                            </Tag>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </Space>
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </Col>
                                                        )
                                                    })
                                                }

                                                {/* Add new ticket */}
                                                <Col span={6} xs={24} md={8} xl={6}>
                                                    <Card
                                                    style={{background : 'var(--secondary-button-color)', height : '100%', cursor : 'pointer', border : '1px dashed var(--secondary-text-color)', }}
                                                    // bodyStyle={{ minHeight : 200, height : '100%', display : 'flex', justifyContent:'center', alignItems:'center', }}
                                                    styles={{ body : { minHeight : 140, height : '100%', display : 'flex', justifyContent:'center', alignItems:'center', } }}
                                                    onClick={() => setState(state => ({...state, create_modal_park_applicable_ticket_type : ticket_type, }))}
                                                    >
                                                        <div style={{textAlign : 'center', color : 'var(--secondary-text-color)',  }}>
                                                            <div>
                                                                <PlusOutlined />
                                                            </div>
                                                            <div>
                                                                <span style={{ fontSize:12, fontWeight:500, }}>
                                                                Add New Ticket
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div style={{ marginTop : 12, }}>
                                            <AdvanceDayDiscount
                                            park_id={state?.park?.park_id}
                                            ticket_type={ticket_type}
                                            advance_day_discount={advance_day_discount}
                                            ticket_applicable_users={ticket_applicable_users}
                                            refreshPage={refreshPage}
                                            />
                                        </div>
                                        <div style={{ marginTop : 12, }}>
                                            <PeakDayDiscount
                                            park_id={state?.park?.park_id}
                                            park_applicable_ticket_type_id={park_applicable_ticket_type_id}
                                            ticket_type={ticket_type}
                                            ticket_applicable_users={ticket_applicable_users}
                                            refreshPage={refreshPage}
                                            />
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        )
                    })
                }
                </Space>
            </Spin>

            <Modal
            title="New Ticket"
            open={state?.create_modal_park_applicable_ticket_type}
            onCancel={() => {
                setState(state => ({...state, create_modal_park_applicable_ticket_type : null, modal : (state?.modal + 1)}));
                save_form?.resetFields();
            }}
            onOk={() => save_form.submit()}
            width={800}
            style={{ top : 20, }}
            confirmLoading={state?.save_loading}
            >
                <Form
                form={save_form}
                onFinish={saveTicketApplicableUser}
                layout='vertical'
                >
                    <AddTicketGroupModal form={save_form} ticket_type={state?.create_modal_park_applicable_ticket_type} park_applicable_ticket_users={configured_ticket_user_types} modal_visible={state?.modal} />
                </Form>
            </Modal>

            <Modal
            title="Update Ticket"
            open={state?.update_modal_ticket_applicable_user}
            onCancel={() => {
                setState(state => ({...state, update_modal_ticket_applicable_user : null, modal : (state?.modal + 1) }));
            }}
            onOk={() => edit_form?.submit()}
            width={800}
            style={{ top : 20, }}
            confirmLoading={state?.update_loading}
            >
                <Form
                layout='vertical'
                form={edit_form}
                onFinish={updateTicketApplicableUser}
                >
                    <EditTicketGroupModal form={edit_form} ticket_type={get_ticket_type_by_ticket_applicable_user} ticket_applicable_user_id={state?.update_modal_ticket_applicable_user} modal_visible={state?.modal} />
                </Form>
            </Modal>
        </>
    );
}

export default TicketLayout;