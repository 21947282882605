import { Alert, Empty, Table, Tag } from 'antd';
import moment from 'moment';
import React from 'react';

const IntegrationLogList = ({ integration_logs }) => {
    return (
        <>
            {
                integration_logs?.length > 0 ?
                (
                    <>
                        <Table 
                        scroll={{x : 400,}}
                        dataSource={integration_logs}
                        className='hub-table bordered'
                        columns={[{
                            title : 'Attempt',
                            dataIndex : 'attempt',
                            key : 'attempt',
                            width : '10%',
                            render : (attempt, row, index) => {
                                return (
                                    <>
                                        <div
                                        style={{
                                            background : 'var(--secondary-button-color)',
                                            borderRadius:'50%',
                                            width : 30,
                                            height : 30,
                                            display: 'flex',
                                            justifyContent : 'center',
                                            alignItems : 'center',
                                        }}>
                                            <span style={{color:'var(--secondary-text-color)', fontWeight:500,}}>{attempt}</span>
                                        </div>
                                    </>
                                )
                            }
                        },{
                            title : 'Name',
                            dataIndex : 'name',
                            key : 'name',
                            width : '35%',
                            render : (name, row, index) => {
                                const { park_api_type } = row ?? {};
                                return (
                                    <>
                                        <div>
                                            <div>
                                                <span>{name}</span>
                                            </div>
                                            <div>
                                                <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{park_api_type?.name}</span>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        },{
                            title : 'API Integrated',
                            dataIndex : 'park_api_type',
                            key : 'park_api_type',
                            width : '15%',
                            render : (park_api_type, row, index) => {
                                return (
                                    <>
                                        <div>
                                            <span>
                                                {park_api_type?.condition}
                                            </span>
                                        </div>
                                    </>
                                )
                            }
                        },{
                            title : 'Request',
                            dataIndex : 'request',
                            key : 'request',
                            width : '15%',
                            render : (request, row, index) => {
                                const { status } = row ?? {};
                                return (
                                    <>
                                        <div>
                                            {
                                                request ?
                                                (
                                                    <>
                                                        <Alert type={'info'} message={
                                                            <>
                                                                <div style={{maxWidth:200,}}>
                                                                    {request}
                                                                </div>
                                                            </>
                                                        } />
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>No request is logged</span>
                                                    </>
                                                )
                                            }
                                            
                                        </div>
                                    </>
                                )
                            }
                        },{
                            title : 'Response',
                            dataIndex : 'response',
                            key : 'response',
                            width : '15%',
                            render : (response, row, index) => {
                                const { status, is_retry, is_error, } = row ?? {};
                                return (
                                    <>
                                        <div>
                                            <Alert type={status == 1 ? 'success' : 'error'} message={(
                                                <>
                                                    <div style={{maxWidth:200,}}>
                                                        {response}
                                                    </div>
                                                </>
                                            )} />

                                            {
                                                (status == 2 && is_error) &&
                                                (
                                                    <>
                                                        <div>
                                                            <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{is_retry ? 'Retried' : 'Pending'}</span>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            
                                        </div>
                                    </>
                                )
                            }
                        },{
                            title : 'Integrated On',
                            dataIndex : 'created_at',
                            key : 'created_at',
                            render : (created_at, row, index) => {
                                return (
                                    <>
                                        <div>
                                            <span>{moment(created_at)?.format("YYYY-MM-DD hh:mm:ssa")}</span>
                                        </div>
                                    </>
                                )
                            }
                        },
                        ]}
                        />
                    </>
                )
                :
                (
                    <>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </>
                )
            }
        </>
    );
}

export default IntegrationLogList;