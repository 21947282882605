import { Button, Col, Divider, Form, Input, Row, Select, Space, Switch, Tag, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import parkService from '../../../services/config/park.service';
import staffService from '../../../services/config/staff.service';

const DetailPersonalInfo = ({ staff, refreshStaff, edit_mode = false, }) => {
    const [form] = Form.useForm();
    const [field, setField] = useState({
        is_active : false,
    });
    const [state, setState] = useState({
        loading : false,
        edit_mode : edit_mode,
        save_loading : false,
    });
    const [select, setSelect] = useState({
        parks : [],
    })

    const updateStaff = async () => {
        setState(state => ({...state, save_loading : true, }));
        const { staff_id } = staff ?? {};
        const { name, identification_number, is_active, parks, } = form.getFieldsValue();
        const updateStaffResponse = await staffService?.updateStaff(staff_id, {
            name, identification_number, is_active, parks,
        });

        setState(state => ({...state, save_loading : false, edit_mode : false,}));
        notification.success({
            message : 'Staff updated',
            description : staff?.pass?.pass_code
        })
        refreshStaff();
    }

    const initializeForm = () => {
        const { pass, } = staff ?? {};
        const { parks, is_active, } = pass;
        const mapped_parks = parks?.map(x => x.park_id);
        form.setFieldsValue({
            name : pass?.name,
            identification_number : pass?.identification_number,
            is_active : is_active,
            parks : mapped_parks,
        })

        // set isactive
        setField(field => ({...field, is_active : is_active}));
    }

    const onFieldChange = (key, value) => {
        setField(field => ({...field, [key] : value, }));
        form.setFieldsValue({ [key] : value });
    }

    const initializeSelection = async () => {
        const getParkResponse = await parkService?.getParkByAuth();

        const { parks } = getParkResponse;
        setSelect(select => ({...select, parks : parks}));
    }

    const initialize = async () => {
        setState(state => ({...state, loading : true, }));
        await initializeSelection();
        await initializeForm();

        setState(state => ({...state, loading : false, }));
    }

    const tagRender = ({ label, value, closable, onClose }) => {
        const onPreventMouseDown = (event) => {
          event.preventDefault();
          event.stopPropagation();
        };
        return (
          <Tag
            color={'var(--secondary-button-color)'}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ color : 'var(--secondary-text-color)'}}
            closeIcon={<CloseOutlined style={{color:'var(--secondary-text-color)'}} />}
          >
            {label}
          </Tag>
        );
    };

    useEffect(() => {
        staff && initialize();
    }, [staff])

    return (
        <>
            <div>
                <div style={{textAlign:'start', border:'2px solid #cbd5e0', borderRadius:8, background:'#f7fafc', padding : 24, margin:'12px 0'}}>
                    <Form
                    form={form}
                    layout='vertical'
                    onFinish={updateStaff}
                    >
                        <div>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <div>
                                    <PageHeaderComponent title={<span style={{fontSize:16,}}>Personal Information</span>} textAlign={'start'} />
                                </div>
                                <div style={{marginLeft:'auto', }}>
                                    <Space>
                                        {
                                            state?.edit_mode ?
                                            (
                                                <>
                                                    <Button loading={state?.save_loading} onClick={() => form.submit()} size='small' icon={<CheckOutlined style={{color:'var(--secondary-text-color)'}} />} />
                                                    <Button onClick={() => setState(state => ({...state, edit_mode : false, }))} size='small' icon={<CloseOutlined style={{color:'var(--secondary-text-color)'}} />} />
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <Button onClick={() => setState(state => ({...state, edit_mode : true, }))} size='small' icon={<EditOutlined style={{color:'var(--secondary-text-color)'}} />} />
                                                </>
                                            )
                                        }
                                    </Space>
                                </div>
                            </div>
                        </div>
                        <div style={{marginTop:12,}}>
                            <div>
                                <Row gutter={[20, 0]}>
                                    <Col xs={24} sm={12} span={12}>
                                        <Form.Item name={'name'} label={'Name'}>
                                            {
                                                state?.edit_mode ?
                                                (
                                                    <Input />
                                                )
                                                :
                                                (
                                                    <>
                                                        <div style={{textAlign:'start',}}>
                                                            <span style={{color:'var(--secondary-text-color)'}}>{staff?.pass?.name}</span>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} span={12}>
                                        <Form.Item name={'identification_number'} label={'Identification Number'}>
                                            {
                                                state?.edit_mode ?
                                                (
                                                    <Input />
                                                )
                                                :
                                                (
                                                    <>
                                                        <div style={{textAlign:'start',}}>
                                                            <span style={{color:'var(--secondary-text-color)'}}>{staff?.pass?.identification_number}</span>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} span={12}>
                                        <Form.Item name={'is_active'} label={'Status'} style={{textAlign:'start',}}>
                                            {
                                                state?.edit_mode ?
                                                (
                                                    <Space size={12}>
                                                        <Switch onChange={(e) => onFieldChange('is_active', e)} checked={field?.is_active}/>
                                                        <Tag color={field?.is_active ? 'green' : 'orange'} style={{border:'none',}}>
                                                            {field?.is_active ? 'Active' : 'Inactive'}
                                                        </Tag>
                                                    </Space>
                                                )
                                                :
                                                (
                                                    <>
                                                        <div style={{textAlign:'start',}}>
                                                            <Tag color={field?.is_active ? 'green' : 'orange'} style={{border:'none',}}>
                                                                {field?.is_active ? 'Active' : 'Inactive'}
                                                            </Tag>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                            <Divider />
                            <div>
                                <div>
                                    <PageHeaderComponent title={<span style={{fontSize:16,}}>Park Access</span>} textAlign={'start'} />
                                </div>
                                <div style={{marginTop:12,}}>
                                    <Row gutter={[20, 0]}>
                                        <Col xs={24} sm={12} span={12}>
                                            <Form.Item name="parks" label="Parks" style={{textAlign:'start',}}>
                                            {
                                                state?.edit_mode ?
                                                (
                                                    <Select
                                                    tagRender={tagRender}
                                                    mode='multiple'
                                                    options={select?.parks?.map((park, index) => {
                                                        const { name, park_id } = park;
                                                        return {
                                                            label : name,
                                                            value : park_id,
                                                        }
                                                    })}
                                                    />
                                                )
                                                :
                                                (
                                                    <Space wrap>
                                                        {
                                                            staff?.pass?.parks?.length > 0 ?
                                                            (
                                                                <>
                                                                    {
                                                                        staff?.pass?.parks?.map((park, index) => {
                                                                            const { park_id, name } = park ?? {};
                                                                            return (
                                                                                <div key={index}>
                                                                                    <Tag color='var(--secondary-button-color)' style={{border:'none', color:'var(--secondary-text-color)'}}>
                                                                                        {name}
                                                                                    </Tag>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            )
                                                            :
                                                            (
                                                                <>
                                                                    <span style={{fontSize:12, color : 'var(--secondary-text-color)',}}>No parks is assigned</span>
                                                                </>
                                                            )
                                                        }
                                                        
                                                    </Space>
                                                )
                                            }
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default DetailPersonalInfo;