import React from 'react';
import EntranceReport from '../report/entrance/entrance_report';

const OperationReportLayout = ({ default_filter, }) => {
    return (
        <>
            <EntranceReport default_filter={default_filter} />
        </>
    );
}

export default OperationReportLayout;