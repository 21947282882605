import { Avatar, Button, Popconfirm, Space, Spin, Table, Tag, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import adminService from '../../../services/admin/admin.service';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { refresh_account_list, set_edit_account_modal } from '../../../actions/account.action';
import { useHistory } from 'react-router-dom';

const EmployeeList = ({filter}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const accountRedux = useSelector(state => state.account);
    const [state, setState] = useState({
        loading : false,
        accounts : [],
        delete_loading : false,
    });

    const fetchAccount = async () => {
        const { search, roles, parks } = filter;
        setState(state => ({...state, loading : true,}))
        const accountResponse = await adminService.getAccount({
            search,
            role_ids : roles?.join(","),
            park_ids : parks?.join(","),
        });

        setTimeout(() => {
            setState(state => ({...state, accounts : accountResponse?.accounts, loading : false,}))
        }, 200);
    }

    const deleteAccount = async (account_id) => {
        setState(state => ({...state, delete_loading : true, }));
        const deleteAccountResponse = await adminService?.deleteAccount(account_id);
        const { delete_account } = deleteAccountResponse;
        notification?.success({
            message : 'Account deleted',
        })

        setState(state => ({...state, delete_loading : false,}));
        dispatch(refresh_account_list());
    }

    useEffect(() => {
        fetchAccount();
    }, [filter, accountRedux?.list_refresh])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <Table
                scroll={{ x : 400, }}
                className='hub-table bordered'
                dataSource={state?.accounts}
                columns={[{
                    title : 'Username',
                    dataIndex : 'username',
                    key : 'username',
                    render : (text, row, index) => {
                        const { face_image, full_name } = row;
                        return (
                            <>
                                <Space>
                                    <div>
                                        <div>
                                            <span>{text}</span>
                                        </div>
                                        <div>
                                            <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{full_name}</span>
                                        </div>
                                    </div>
                                </Space>
                            </>
                        )
                    }
                },{
                    title : 'Email',
                    dataIndex : 'email',
                    key : 'email',
                },{
                    title : 'Role',
                    dataIndex : 'role',
                    key : 'role',
                    render : (text, row, index) => {
                        const { name, is_dev } = text ?? {};
                        return (
                            <div>
                                <span>{name}</span>
                            </div>
                        )
                    }
                },{
                    title : 'Park',
                    dataIndex : 'parks',
                    key : 'parks',
                    render : (text, row, index) => {
                        const parks = text;
                        return (
                            <div>
                                <Space wrap>
                                {
                                    parks?.map((park, index) => {
                                        const { name : park_name } = park ?? {};
                                        return (
                                            <Tag key={index} style={{ border : 'none', background : 'var(--secondary-button-color)', }}>
                                                {park_name}
                                            </Tag>
                                        )
                                    })
                                }
                                </Space>
                            </div>
                        )
                    }
                },{
                    title : 'Status',
                    dataIndex : 'is_active',
                    key : 'is_active',
                    render : (is_active, row, index) => {
                        return (
                            <>
                                <Tag
                                color={is_active ? 'green' : 'orange'}
                                style={{ border : 'none', }}
                                >
                                    {is_active ? 'Active' : 'Inactive'}
                                </Tag>
                            </>
                        )
                    },
                },{
                    title : 'Action',
                    dataIndex : 'action',
                    key : 'action',
                    render : (text, row, index) => {
                        const { username, is_dev, account_id } = row ?? {};
                        return (
                            <div>
                                <Button.Group>
                                    <Button onClick={() => history.push(`/account/detail/${account_id}`)}>
                                        View
                                    </Button>
                                    <Popconfirm
                                    onConfirm={() => deleteAccount(account_id)}
                                    title="Are you sure to delete account?"
                                    description={username}
                                    overlayStyle={{ width : 250,}}
                                    okButtonProps={{ loading : state?.delete_loading, }}
                                    >
                                        <Button icon={<DeleteOutlined style={{color : 'var(--secondary-text-color)',}} />} />
                                    </Popconfirm>
                                </Button.Group>
                               
                            </div>
                        )
                    }
                }]}
                />
            </Spin>
        </>
    );
}

export default EmployeeList;