import React, { useEffect, useState, } from 'react';
import dashboardService from '../../../services/dashboard/dashboard.service';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Card } from 'antd';
import DashboardType from './dashboard_type';
import { LoadingOutlined } from '@ant-design/icons';
import EntranceMotion from '../../../helpers/components/entrance_motion';

const HomeDashboard = () => {
    const [state, setState] = useState({
        loading : false,
        dashboards : [],
    })
    const fetchDashboard = async () => {
        setState(state => ({...state, loading : true, }));

        const dashboardResponse = await dashboardService?.getDashboard();
        const { dashboards } = dashboardResponse;

        setState(state => ({...state, loading : false, dashboards : dashboards, }));
        
    }

    useEffect(() => {
        fetchDashboard();
    }, [])

    return (
        <>
            {
                state?.loading ?
                (
                    <>
                        <LoadingOutlined />
                    </>
                )
                :
                (
                    <>
                        <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 2}}>
                            <Masonry gutter={20}>
                                {
                                    state?.dashboards?.map((dashboard, index) => {
                                        return (
                                            <div key={index}>
                                                <EntranceMotion delay={(index * 0.1)}>
                                                    <DashboardType dashboard={dashboard} />
                                                </EntranceMotion>
                                            </div>
                                        )
                                    })
                                }
                            </Masonry>
                        </ResponsiveMasonry>
                    </>
                )
            }
        </>
    );
}

export default HomeDashboard;