import React from 'react';
import { useParams } from 'react-router-dom';
import PromoDetailLayout from './components/promo_detail_layout';
import ContainerComponent from '../../helpers/components/container.component';

const PromoDetail = () => {
    const { promotion_code_id } = useParams();
    return (
        <>
            <div>
                <ContainerComponent span={22}>
                    <PromoDetailLayout promotion_code_id={promotion_code_id} />
                </ContainerComponent>
            </div>
        </>
    );
}

export default PromoDetail;