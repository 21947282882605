import React from 'react';
import { useParams } from 'react-router-dom';
import FieldLayout from './components/field_layout';
import ContainerComponent from '../../helpers/components/container.component';

const TicketFieldEdit = () => {
    const { ticket_user_id } = useParams();
    return (
        <>
            <ContainerComponent span={16} top={20} bottom={20}>
                <FieldLayout ticket_user_no={ticket_user_id} />
            </ContainerComponent>
        </>
    );
}

export default TicketFieldEdit;