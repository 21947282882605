import { CaretLeftFilled, CaretRightFilled, LoadingOutlined, } from '@ant-design/icons';
import { Button, Card, Space, Spin, } from 'antd';
import React, { useEffect, useState } from 'react';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import parkService from '../../../services/config/park.service';
import StepNoItem from '../../../helpers/components/step_no_item';
import PeakDayLayout from './peak_day_layout';
import MaintenanceDateLayout from './maintenance_date_layout';

const localizer = momentLocalizer(moment);
const DateConfigCalendar = ({ park_id, }) => {
    const [state, setState] = useState({
        loading : false,
        month : (moment()?.month() + 1), // need plus 1 by default because month start from 0
        year : moment()?.year(),

        peak_days : [],
    })

    const fetchPeakDays = async () => {
        setState(state => ({...state, loading : true, }));
        const calendarPeakDayResponse = await parkService?.getCalendarOverview({
            park_id : park_id,
            year : state?.year,
            month : state?.month,
        });

        const { calendar_peak_days, } = calendarPeakDayResponse;

        const compiled_peak_days = [];
        let current_peak_day = null;
        const compiled_maintenance_days = [];
        let current_maintenance_day = null;
        let index = 0;
        for(const day of calendar_peak_days){
            // peak days compiled
            if (day.is_peak_days) {
                if (!current_peak_day) {
                    current_peak_day = {
                        title : (<span style={{ fontSize:12, }}>Peak days</span>),
                        start: new Date(moment(day.visit_date)?.startOf('days')), 
                        end: new Date(moment(day.visit_date)?.endOf('days')), 
                        is_peak_day: true,
                        background : '#fff7e6',
                        color : '#fa8c16',
                    };
                } else {
                    current_peak_day.end = new Date(moment(day.visit_date)?.endOf('days'));
                }
            } else {
                if (current_peak_day) {
                    compiled_peak_days.push(current_peak_day);
                    current_peak_day = null;
                }
            }
            // If it's the last day and it's a peak day, add the current peak day
            if (index === calendar_peak_days.length - 1 && current_peak_day) {
                compiled_peak_days.push(current_peak_day);
            }


            // maintenance date compiled
            if (day?.is_maintenances) {
                if (!current_maintenance_day) {
                    current_maintenance_day = {
                        title : (<span style={{ fontSize:12, }}>Maintenance days</span>),
                        start: new Date(moment(day.visit_date)?.startOf('days')), 
                        end: new Date(moment(day.visit_date)?.endOf('days')), 
                        is_maintenances : true,
                        background : '#f9f0ff',
                        color : '#722ed1',
                    };
                } else {
                    current_maintenance_day.end = new Date(moment(day.visit_date)?.endOf('days'));
                }
            } else {
                if (current_maintenance_day) {
                    compiled_maintenance_days.push(current_maintenance_day);
                    current_maintenance_day = null;
                }
            }
            // If it's the last day and it's a peak day, add the current peak day
            if (index === calendar_peak_days.length - 1 && current_maintenance_day) {
                compiled_maintenance_days.push(current_maintenance_day);
            }

            index++;
        }

        const combined_days = [...compiled_peak_days, ...compiled_maintenance_days];

        setState(state => ({...state, loading : false, peak_days : combined_days, }));
    }

    useEffect(() => {
        fetchPeakDays();
    }, [state?.year, state?.month, park_id,])
    
    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <div className='calendar-wrapper'>
                    <Calendar
                    localizer={localizer}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 600, width : '100%', }}
                    defaultView={Views.MONTH}
                    views={{ month : true, week: false, day : false, agenda : false, }}
                    events={state?.peak_days}
                    onNavigate={(date,) => {
                        const month = (moment(date)?.month() + 1);
                        const year = moment(date)?.year();
                        setState(state => ({...state, month : month, year : year, }));
                    }}
                    eventPropGetter={(event, start, end, isSelected) => {
                        const { background, color, } = event;
                        return {
                            style : {
                                background : background,
                                color : color,
                                borderLeft : `6px solid ${color}`,
                                // minHeight : 30,
                            }
                        }
                    }}
                    components={{
                        toolbar : (props) => {
                            const { onNavigate, date, } = props;
                            const date_item = moment(date);
                            const month_name = date_item?.format("MMMM");
                            const year_name = date_item?.format("YYYY");
                            return (
                                <>
                                    <div style={{textAlign:'start', paddingBottom : 12,}}>
                                        <Space>
                                            <Button
                                            onClick={() => {
                                                // go to previous month
                                                onNavigate('PREV');
                                            }}
                                            type='text'
                                            icon={<CaretLeftFilled style={{color : 'var(--secondary-text-color)'}} />}
                                            />
                                            <Button
                                            onClick={() => {
                                                // go to next month
                                                onNavigate('NEXT');
                                            }}
                                            type='text'
                                            icon={<CaretRightFilled style={{color : 'var(--secondary-text-color)'}} />}
                                            />
                                            <div>
                                                <span style={{fontSize:16, fontWeight:500,}}>{`${month_name} ${year_name}`}</span>
                                            </div>
                                        </Space>
                                    </div>
                                </>
                            )
                        },
                    }}
                    />
                </div>

                <div style={{ marginTop : 24, }}>
                    <Card styles={{ body : { padding : 12,} }}>
                        <div>
                            {/* peak days */}
                            <PeakDayLayout park_id={park_id} />
                        </div>
                    </Card>
                </div>

                <div style={{ marginTop : 12, }}>
                    <Card styles={{ body : { padding : 12 },}}>
                        <div>
                            {/* maintenance date */}
                            <MaintenanceDateLayout park_id={park_id} />
                        </div>
                    </Card>
                </div>
            </Spin>
        </>
    );
}

export default DateConfigCalendar;