import React from 'react';
import { Divider, } from 'antd';
import PromoTicketTypeAvailability from './promo_ticket_type_availability';
import PromoDateAvailability from './promo_date_availability';
import PromoAddOnAvailability from './promo_add_on_availability';

const PromoDetailConfig = ({ promotion_code }) => {
    return (
        <>
            <div>
                <Divider />
                <div>
                    <div>
                        <PromoTicketTypeAvailability promotion_code={promotion_code} />
                    </div>
                    <Divider />
                    <div>
                        <PromoAddOnAvailability promotion_code={promotion_code} />
                    </div>
                    <Divider />
                    <div>
                        <PromoDateAvailability promotion_code={promotion_code} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default PromoDetailConfig;