import React from 'react';
import PromoLayout from './components/promo_layout';
import ContainerComponent from '../../helpers/components/container.component';

const PromoIndex = () => {
    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <PromoLayout />
            </ContainerComponent>
        </>
    );
}

export default PromoIndex;