import { Button, Card, Col, Form, InputNumber, Modal, Popconfirm, Popover, Row, Space, Spin, Table, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import parkService from '../../../services/config/park.service';
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import AddParkAddOnModal from './add_park_add_on_modal';

const ParkAddOn = ({ park, }) => {
    const [save_form] = Form.useForm();
    const [edit_form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        save_loading : false,
        update_loading : false,
        park_add_ons : [],

        edit_modal : false,
        create_modal : null,

        refresh : 0,
    });

    const fetchParkAddOns = async () => {
        setState(state => ({...state, loading : true,}))
        const parkAddOnResponse = await parkService?.getParkAddOn(park?.park_id, {

        });
        const { park_add_ons } = parkAddOnResponse;
        setState(state => ({...state, loading : false, park_add_ons,}))
    }

    const saveParkAddOn = async () => {
        setState(state => ({...state, save_loading : true,}));
        const { park_id } = park;
        const { add_on_id, slot, } = save_form?.getFieldsValue();

        const saveParkAddOnResponse = await parkService?.saveParkAddOn({
            park_id, add_on_id, slot,
        }).catch(err => ({...state, save_loading : false,}));

        notification?.success({
            message : 'Added Park Add On',
        })

        setState(state => ({...state, save_loading : false, refresh : (state?.refresh + 1), create_modal : false,}));
    }

    const updateParkAddOn = async () => {
        setState(state => ({...state, update_loading : true,}));
        const park_add_on_id = state?.edit_modal;
        const { slot, } = edit_form?.getFieldsValue();

        const updateParkAddOnResponse = await parkService?.updateParkAddOn(park_add_on_id, {
            slot,
        }).catch(err => ({...state, update_loading : false,}));

        notification?.success({
            message : 'Updated Park Add On',
        })
        setState(state => ({...state, refresh : (state?.refresh + 1), update_loading : false, edit_modal : null,}))
    }

    const deleteParkAddOn = async (park_add_on_id) => {
        const deleteParkAddOnResponse = await parkService?.deleteParkAddOn(park_add_on_id);

        notification?.success({
            message : 'Park Add On Deleted',
        })
        setState(state => ({...state, refresh : (state?.refresh + 1)}));
    }

    useEffect(() => {
        park && fetchParkAddOns();
    }, [park?.park_id, state?.refresh])

    return (
        <>
            <div>
                <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                    <Row gutter={[12, 12]}>
                    {
                        state?.park_add_ons?.map((park_add_on, index) => {
                            const { add_on, slot, park_add_on_id, } = park_add_on ?? {};
                            return (
                                <Col span={6} xs={24} md={8} xl={6} key={index}>
                                    <Card
                                    styles={{ body : { padding : 12, } }}
                                    style={{ height : '100%', }}
                                    >
                                        <div style={{ textAlign : 'start', }}>
                                            <div style={{ display : 'flex', alignContent : 'center', }}>
                                                <div>
                                                    <span style={{ fontSize : 14, fontWeight : 500, color : 'var(--secondary-text-color)', }}>{add_on?.name}</span>
                                                </div>
                                                <div style={{ marginLeft : 'auto' }}>
                                                    <Space size={4}>
                                                        <Popover
                                                        open={(state?.edit_modal === park_add_on_id)}
                                                        content={(
                                                            <>
                                                                <Form
                                                                form={edit_form}
                                                                layout='vertical'
                                                                onFinish={updateParkAddOn}
                                                                >
                                                                    <Space size={20}>
                                                                        <div>
                                                                            <Form.Item name={'slot'} style={{ margin : 0, }}>
                                                                                <InputNumber min={0} placeholder='slot' precision={0} style={{ minWidth : 120, }} />
                                                                            </Form.Item>
                                                                        </div>
                                                                        <div>
                                                                            <Space size={4}>
                                                                                <Button loading={state?.update_loading} onClick={() => edit_form?.submit()} size='small' icon={<CheckOutlined style={{ color : 'var(--secondary-text-color)', }} />} />
                                                                                <Button onClick={() => {
                                                                                    setState(state => ({...state, edit_modal : null,}));
                                                                                    edit_form?.resetFields();
                                                                                }}
                                                                                size='small'
                                                                                icon={<CloseOutlined style={{ color : 'var(--secondary-text-color)', }} />}
                                                                                />
                                                                            </Space>
                                                                        </div>
                                                                    </Space>
                                                                </Form>
                                                            </>
                                                        )}
                                                        onOpenChange={(open) => {
                                                            setState(state => ({...state, edit_modal : (open ? park_add_on_id : null), }));
                                                            if(!open){
                                                                edit_form?.resetFields();
                                                            }
                                                        }}
                                                        trigger={['click']}
                                                        placement='bottomLeft'
                                                        overlayInnerStyle={{padding : 12, maxHeight : 400, overflowY : 'auto', }}
                                                        >
                                                            <Button icon={<EditOutlined style={{ color : 'var(--secondary-text-color)', }} />} size='small' />
                                                        </Popover>
                                                        <Popconfirm
                                                        onConfirm={() => deleteParkAddOn(park_add_on_id)}
                                                        title="Delete Park Add On"
                                                        description={`${add_on?.name}`}
                                                        overlayStyle={{ width : 250,}}
                                                        >
                                                            <Button icon={<DeleteOutlined style={{ color : 'var(--secondary-text-color)', }} />} size='small' />
                                                        </Popconfirm>
                                                    </Space>
                                                </div>
                                            </div>
                                            <div>
                                                <Space size={4} align='center'>
                                                    <span style={{ fontSize : 24, }}>{slot}</span>
                                                    <span style={{ color : 'var(--secondary-text-color)', }}>slot per day</span>
                                                </Space>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            )
                        })
                    }

                        <Col span={6} xs={24} md={8} xl={6}>
                            <Card
                            style={{background : 'var(--secondary-button-color)', height : '100%', cursor : 'pointer', border : '1px dashed var(--secondary-text-color)', }}
                            // bodyStyle={{ minHeight : 200, height : '100%', display : 'flex', justifyContent:'center', alignItems:'center', }}
                            styles={{ body : { minHeight : 80, height : '100%', display : 'flex', justifyContent:'center', alignItems:'center', } }}
                            onClick={() => setState(state => ({...state, create_modal : true, }))}
                            >
                                <div style={{textAlign : 'center', color : 'var(--secondary-text-color)',  }}>
                                    <div>
                                        <PlusOutlined />
                                    </div>
                                    <div>
                                        <span style={{ fontSize:12, fontWeight:500, }}>
                                        Add Park Add On
                                        </span>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Spin>
            </div>

            <Modal
            title="New Park Add On"
            open={state?.create_modal}
            onCancel={() => {
                setState(state => ({...state, create_modal : false, modal : (state?.modal + 1)}));
                // save_form?.resetFields();
            }}
            onOk={() => save_form.submit()}
            // width={800}
            // style={{ top : 20, }}
            confirmLoading={state?.save_loading}
            >
                <Form
                form={save_form}
                onFinish={saveParkAddOn}
                layout='vertical'
                >
                    <AddParkAddOnModal
                    form={save_form}
                    park_add_ons={state?.park_add_ons}
                    />
                </Form>
            </Modal>
        </>
    );
}

export default ParkAddOn;