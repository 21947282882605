import { CaretDownFilled, CaretUpFilled, } from '@ant-design/icons';
import { Empty, Space, Table, Tag } from 'antd';
import React, { useState } from 'react';
import TicketDetailTicketTypeUserList from './ticket_detail_ticket_type_user_list';
import numberHelper from '../../../helpers/functions/number.helper';

const TicketDetailUserList = ({ticket_users, park}) => {
    const { code, symbol } = park?.currency ?? {};
    const [expanded_row_keys, setExpandedRowKeys] = useState([]);
    const onTableRowExpand = (expanded, record) => {
        const keys = [];
        if(expanded){
            const id = record?.ticket_user_type?.ticket_user_type_id;
            keys.push(id); // I have set my record.id as row key. Check the documentation for more details.
        }

        setExpandedRowKeys(keys);
    }

    return (
        <>
            {
                ticket_users?.length > 0 ?
                (
                    <>
                        <Table
                        scroll={{ x : 400, }}
                        rowKey={(r) => (r?.ticket_user_type?.ticket_user_type_id)}
                        expandable={{
                            expandedRowKeys : expanded_row_keys,
                            expandedRowRender : (record) => (
                                <TicketDetailTicketTypeUserList park={park} ticket_id={record?.ticket_id} ticket_user_type_id={record?.ticket_user_type?.ticket_user_type_id} />
                            ),
                            expandIcon: ({ expanded, onExpand, record }) =>
                            expanded ? (
                            <CaretUpFilled onClick={e => onExpand(record, e)} />
                            ) : (
                            <CaretDownFilled onClick={e => onExpand(record, e)} />
                            ),
                            onExpand : onTableRowExpand
                        }}
                        className='hub-table bordered'
                        dataSource={ticket_users}
                        pagination={false}
                        columns={[{
                            title : 'Tickets',
                            dataIndex : 'ticket_user_type',
                            key : 'ticket_user_type',
                            render : (text, row, index) => {
                                const { name, } = text ?? {};
                                return (
                                    <>
                                        {name}
                                    </>
                                )
                            }
                        },{
                            title : 'Quantity',
                            dataIndex : 'count',
                            key : 'count',
                        },{
                            title : (
                                <>
                                    <Space>
                                        <div>
                                            <span>Unit Price</span>
                                        </div>
                                        <div>
                                            <Tag color='blue' style={{border : 'none', }}>
                                                {code}
                                            </Tag>
                                        </div>
                                    </Space>
                                </>
                            ),
                            dataIndex : 'price',
                            key : 'price',
                            render : (text, row, index) => {
                                const unit_price = numberHelper?.commaNumber((isNaN(parseFloat(text)) ? 0 : parseFloat(text)), 2);
                                const { tax_amount, } = row;
                                return (
                                    <>
                                        <div>
                                            <div>
                                                <span>{`${symbol}${unit_price}`}</span>
                                            </div>
                                            <div>
                                                <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{`${symbol}${numberHelper?.commaNumber((tax_amount ?? 0), 2)}`}</span>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        },{
                            title : (
                                <>
                                    <Space>
                                        <div>
                                            <span>Total Price</span>
                                        </div>
                                        <div>
                                            <Tag color='blue' style={{border : 'none', }}>
                                                {code}
                                            </Tag>
                                        </div>
                                    </Space>
                                </>
                            ),
                            dataIndex : 'price',
                            key : 'price',
                            fixed : 'right',
                            render : (text, row, index) => {
                                const unit_price = (isNaN(parseFloat(text)) ? 0 : parseFloat(text));
                                const { count : quantity, tax_amount, } = row;
                                const total_price = numberHelper?.commaNumber((unit_price * quantity), 2);
                                const total_tax_amount = numberHelper?.commaNumber((tax_amount * quantity), 2);
                                return (
                                    <>
                                        <div>
                                            <div>
                                                <span>{`${symbol}${total_price}`}</span>
                                            </div>
                                            <div>
                                                <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{`${symbol}${total_tax_amount}`}</span>
                                            </div>
                                        
                                        </div>
                                    </>
                                )
                            }
                        }]}
                        />
                    </>
                )
                :
                (
                    <>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </>
                )
            }
        </>
    );
}

export default TicketDetailUserList;