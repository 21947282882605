import { Space, Spin, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import moment from 'moment';
import promotionService from '../../../services/config/promotion.service';
import serviceHelper from '../../../helpers/functions/service.helper';
import { LoadingOutlined } from '@ant-design/icons';

const PromoTicketList = ({ promotion_code_id, filter, park, }) => {
    const [state, setState] = useState({
        loading : false,
        tickets : [],

        page : 1,
        display_size : 10,
        count : 0,
    });

    const fetchSaleListing = async () => {
        setState(state => ({...state, loading : true, }));
        const { start_date, end_date, } = filter;
        const ticketListingResponse = await promotionService?.ticketListing({
            promotion_code_id,

            // paging
            page : (state?.page - 1),
            size : (state?.display_size),
            start_date,
            end_date,
        });

        const { tickets } = ticketListingResponse;
        setState(state => ({...state, loading : false, tickets : tickets?.rows, count : tickets?.count, }));
    }

    const paginateChange = (key, value) => {
        // set state
        setState(state => ({...state, [key] : value}));

        // let generate_filter = filter ?? {};
        // generate_filter[key] = value;
        // const queryString = serviceHelper?.queryString(generate_filter);
        // window.history.replaceState({}, '', `${window.location.pathname}?${queryString}`);
    }

    useEffect(() => {
        fetchSaleListing();
    }, [filter, state?.page])

    return (
        <>
            <div>
                <div>
                    <PageHeaderComponent title={(<><span style={{fontSize:16,}}>Sales Detail</span></>)} textAlign={'start'} />
                </div>
                <div style={{marginTop:12,}}>
                    <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                        <Table
                        dataSource={state?.tickets}
                        scroll={{ x : 400, }}
                        pagination={{
                            size : state?.display_size,
                            // current : (ticketRedux?.count > state?.display_size) ? state?.page : 1, 
                            current : state?.page ?? 1,
                            total : state?.count,
                            showSizeChanger: false,
                            onChange : (page) => paginateChange('page', page)
                        }}
                        className='hub-table bordered'
                        columns={[{
                            title : 'Transaction No',
                                dataIndex : 'masked_unique_no',
                                key : 'masked_unique_no',
                                render : (running_number, row, index) => {
                                    return (
                                        <>
                                        {running_number}
                                        </>
                                    )
                                }
                        },{
                            title : 'Park',
                            dataIndex : 'park',
                            key : 'park',
                            render : (park, row, index) => {
                                const { name, } = park;
                                const { ticket_type, } = row;
                                return (
                                    <>
                                        <div>
                                            <div>
                                                <span>{name}</span>
                                            </div>
                                            <div>
                                                <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{ticket_type?.name}</span>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        },
                        {
                            title : (
                                <>
                                    <Space>
                                        <div>
                                            <span>Sales Amount</span>
                                        </div>
                                        <div>
                                            <Tag color='blue' style={{ border:'none', }}>
                                                {park?.currency?.code}
                                            </Tag>
                                        </div>
                                    </Space>
                                </>
                            ),
                            dataIndex : 'payment_amount',
                            key : 'payment_amount',
                            render : (payment_amount, row, index) => {
                                const { payment_date } = row;
                                return (
                                    <>
                                        <div>
                                            <div>
                                                <span>{`${park?.currency?.symbol}${payment_amount}`}</span>
                                            </div>
                                            <div>
                                                <Tag color='green' style={{border:'none',}}>
                                                    <span>{`Paid: ${moment(payment_date)?.format("YYYY-MM-DD hh:mm:ssa")}`}</span>
                                                </Tag>
                                            </div>
                                            {/* <div>
                                                <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{email}</span>
                                            </div> */}
                                        </div>
                                    </>
                                )
                            }
                        },{
                            title : 'Ticket Count',
                            dataIndex : 'ticket_user_count',
                            key : 'ticket_user_count',
                            render : (ticket_user_count, row, index) => {
                                return (
                                    <>
                                        <div>
                                            <div
                                            style={{
                                                background : 'var(--secondary-button-color)',
                                                borderRadius:'50%',
                                                width : 30,
                                                height : 30,
                                                display: 'flex',
                                                justifyContent : 'center',
                                                alignItems : 'center',
                                            }}>
                                                <span style={{color:'var(--secondary-text-color)', fontWeight:500,}}>{ticket_user_count}</span>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        },]}
                        />
                    </Spin>
                </div>
            </div>
        </>
    );
}

export default PromoTicketList;