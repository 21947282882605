import { Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';

const SummaryTable = ({ ticket_types, expected_visitors }) => {
    const [state, setState] = useState({
        ticket_type_datas : [],
        columns : [],
    })

    const initializeTable = () => {
        let ticket_type_table = {};
        let columns = [];
        for(const ticket_type of ticket_types){
            const { name, checked_in_ticket_type_visitors, expected_ticket_type_visitors, } = ticket_type;
            ticket_type_table[name] = {
                checked_in_ticket_type_visitors,
                expected_ticket_type_visitors,
            };

            columns.push({
                title : name,
                dataIndex : name,
                key : name,
                render: (text, row, index) => {
                    const { checked_in_ticket_type_visitors, expected_ticket_type_visitors, } = text ?? {};
                    const percentage = (expected_ticket_type_visitors / expected_visitors) * 100;
                    return (
                        <>
                            <div>
                                <div>
                                    <Space style={{color:'var(--main-color)'}}>
                                        <div>
                                            <span>{expected_ticket_type_visitors}</span>
                                        </div>
                                        <div>
                                            -
                                        </div>
                                        <div>
                                            <span style={{fontWeight:500,}}>{isNaN(percentage) ? 0 : percentage?.toFixed(2)}%</span>
                                        </div>

                                    </Space>
                                    
                                    
                                </div>
                                <div>
                                    <span style={{fontSize:12,}}>{checked_in_ticket_type_visitors}</span>
                                </div>

                            </div>
                        </>
                    )
                }
            })
        }
        setState(state => ({...state, ticket_type_datas : ticket_type_table, columns : columns}))
    }

    useEffect(() => {
        initializeTable();
    }, [ticket_types])

    return (
        <>
            <Table
            scroll={{ x : 400, }}
            size='small'
            pagination={false}
            className='hub-table bordered'
            dataSource={[state?.ticket_type_datas]}
            columns={state?.columns}
            />
        </>
    );
}

export default SummaryTable;