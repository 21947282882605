import { Alert, Form, Input } from 'antd';
import React, { useState } from 'react';
import affiliateService from '../../../services/config/affiliate.service';
import { CheckCircleFilled, CloseCircleFilled, LoadingOutlined } from '@ant-design/icons';
import EntranceMotion from '../../../helpers/components/entrance_motion';

const InviteAffiliate = ({ form, disableAffiliate, }) => {
    const [timer, setTimer] = useState(null);
    const [state, setState] = useState({
        loading : false,
        is_existing : false,
        input : null,
    })

    const checkAffiliateExisting = async (input) => {
        setState(state => ({...state, loading : true, }));
        const affiliateResponse = await affiliateService?.fetchAffiliate({
            email : input,
        });

        const is_existing = affiliateResponse?.affiliates?.length > 0;
        disableAffiliate(is_existing);
        setState(state => ({...state, input : input, loading : false, is_existing : is_existing, }));
    }

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(async () => {
            // check existing
            await checkAffiliateExisting(input);
        }, 300);
        setTimer(newTimer);
    }

    return (
        <>
            <Form.Item
            label={"Email Address"}
            name="email"
            extra={
                <div>
                    <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>Enter email address</span>
                </div>
            }
            >
                <Input
                onChange={(e) => doneTyping(e?.target?.value)} 
                suffix={
                    (
                        state?.loading ?
                        (
                            <>
                                <LoadingOutlined />
                            </>
                        )
                        :
                        (
                            <>
                                {
                                    state?.input && 
                                    (state?.is_existing ? <CloseCircleFilled style={{color:'#ff7875'}} /> : <CheckCircleFilled style={{color:'#95de64'}}/>)
                                }
                            </>
                        )
                        
                    )
                } 
                />
            </Form.Item>
            {
                state?.is_existing &&
                (
                    <>
                        <div>
                            <EntranceMotion>
                                <Alert message={`${state?.input} is an existing affiliate, please try another email`} type='error'/>
                            </EntranceMotion>
                        </div>
                    </>
                )
            }
        </>
    );
}

export default InviteAffiliate;