import { Divider, Space, Tag } from 'antd';
import React from 'react';
import numberHelper from '../../../helpers/functions/number.helper';

const TicketTransaction = ({ sub_total, tax_amount, total_amount, promotion_code, park, }) => {
    const discount_amount = (sub_total - (total_amount - tax_amount));
    const { code, symbol, } = park?.currency ?? {};
    return (
        <>
            <div style={{textAlign: 'start'}}>
                <Space direction='vertical'>
                    {
                        promotion_code &&
                        (
                            <>
                                <div>
                                    <Space>
                                        <div style={{ minWidth: 200 }}>
                                            <span className='label' style={{ color: '#595959', fontWeight:500, }}>Promo Code</span>
                                        </div>
                                        <div>
                                            <Tag color='orange' style={{border:'none'}}>{promotion_code?.promo_code}</Tag>
                                        </div>
                                    </Space>
                                </div>
                            </>
                        )
                    }
                    <div>
                        <Space>
                            <div style={{ minWidth: 200 }}>
                                <span className='label' style={{ color: '#595959', fontWeight:500, }}>Sub Total</span>
                            </div>
                            <span className='description' style={{color:'var(--secondary-text-color)',}}>{`${symbol}${numberHelper?.commaNumber(parseFloat(sub_total ?? 0), 2)}`}</span>

                        </Space>
                    </div>
                    <div>
                        <Space>
                            <div style={{ minWidth: 200 }}>
                                <span className='label' style={{ color: '#595959', fontWeight:500, }}>Discount</span>
                            </div>
                            <span className='description' style={{color:'var(--secondary-text-color)',}}>{`${symbol}${numberHelper?.commaNumber(discount_amount, 2)}`}</span>
                        </Space>
                    </div>
                    <div>
                        <Space>
                            <div style={{ minWidth: 200 }}>
                                <span className='label' style={{ color: '#595959', fontWeight:500, }}>Tax Amount</span>
                            </div>
                            <span className='description' style={{color:'var(--secondary-text-color)',}}>{`${symbol}${numberHelper?.commaNumber(parseFloat(tax_amount) ?? 0, 2)}`}</span>
                        </Space>
                    </div>
                    <Divider style={{margin:0,}} />
                    <div>
                        <Space>
                            <div style={{ minWidth: 200 }}>
                                <span className='label' style={{ color: '#595959', fontWeight:500, }}>Total Amount</span>
                            </div>
                            <span className='description' style={{color:'var(--secondary-text-color)', fontWeight:500,}}>{`${symbol}${numberHelper?.commaNumber(parseFloat(total_amount ?? 0))}`}</span>
                        </Space>
                    </div>
        
                </Space>
            </div>
        </>
    );
}

export default TicketTransaction;