import React, { useEffect, useState } from 'react'
import parkService from '../../../services/config/park.service';
import { Avatar, Button, Card, Form, Modal, Popconfirm, Space, Table, notification } from 'antd';
import { AppstoreOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import AddRequiredTicketRuleModal from './add_required_ticket_rule_modal';
import EditRequiredTicketRuleModal from './edit_required_ticket_rule_modal';

const RequiredTicketRuleLayout = ({ park, }) => {
    const [save_form] = Form.useForm();
    const [edit_form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        required_ticket_rules : [],

        create_modal : false,
        edit_modal : null,

        save_loading : false,
        update_loading : false,

        refresh : 0,
        modal : 0,
    })

    const saveRequiredTicketRule = async () => {
        setState(state => ({...state, save_loading : true, }));
        const { name, park_applicable_ticket_type_id, required_type, min_quantity, ticket_applicable_users, } = save_form?.getFieldsValue();
        const saveRequiredTicketRuleResponse = await parkService?.saveRequiredTicketRule({
            name, park_applicable_ticket_type_id, required_type, min_quantity, ticket_applicable_users,
        }).catch(err => {
            setState(state => ({...state, save_loading : false, }));
        })

        notification?.success({
            message : 'Required Ticket Rule Added',
        })

        setState(state => ({...state, save_loading : false, refresh : (state?.refresh + 1), create_modal : false, }));
        save_form?.resetFields();
        save_form?.setFieldsValue({ required_type : 'OR', })
    }

    const updateRequiredTicketRule = async () => {
        setState(state => ({...state, update_loading : true, }));
        const required_ticket_rule_id = state?.edit_modal;
        const { name, required_type, min_quantity, ticket_applicable_users, } = edit_form?.getFieldsValue();

        const updateRequiredTicketRuleResponse = await parkService?.updateRequiredTicketRule(required_ticket_rule_id, {
            name, required_type, min_quantity, ticket_applicable_users,
        });

        notification?.success({
            message : 'Required Ticket Rule Updated',
        })
        
        setState(state => ({...state, update_loading : false, refresh : (state?.refresh + 1), edit_modal : null, }));
    }

    const deleteRequiredTicketRule = async (required_ticket_rule_id) => {
        const deleteRequiredTicketRuleResponse = await parkService?.deleteRequiredTicketRule(required_ticket_rule_id);
        
        notification?.success({
            message : 'Required Ticket Rule Deleted',
        })

        setState(state => ({...state, refresh : (state?.refresh + 1)}));
    }

    const fetchTicketRules = async () => {
        if(park){
            setState(state => ({...state, loading : true, }));
            const requiredTicketRuleResponse = await parkService?.getRequiredTicketRule({
                park_id : park?.park_id,
            });
    
            const { required_ticket_rules } = requiredTicketRuleResponse;
            setState(state => ({...state, laoding : false, required_ticket_rules : required_ticket_rules, }));
        }
    }

    useEffect(() => {
        fetchTicketRules();
    }, [park?.park_id, state?.refresh])

    return (
        <>
            <Card
            styles={{ body : { padding : 12, } }}
            >
                <div style={{ display : 'flex', alignItems : 'center', }}>
                    <div style={{ textAlign : 'start', }}>
                        <div>
                            <span>Required Ticket Rule</span>
                        </div>
                        <div>
                            {/* <span style={{ fontSize : 12, color : 'var(--secondary-text-color)', }}>Either one of this ticket group is required to proceed payment</span> */}
                        </div>
                    </div>
                    <div style={{ marginLeft : 'auto', }}>
                        <Button
                        icon={<PlusOutlined />}
                        onClick={() => setState(state => ({...state, create_modal : true, modal : (state?.modal + 1), }))}
                        >
                            Required Ticket Rule
                        </Button>
                    </div>
                </div>

                <div style={{ marginTop : 12, }}>
                    <Table
                    className='hub-table bordered nested-table'
                    size='small'
                    dataSource={state?.required_ticket_rules}
                    pagination={false}
                    columns={[{
                        title : 'Name',
                        dataIndex : 'name',
                        key : 'name',
                    },{
                        title : 'Ticket Type',
                        dataIndex : 'park_applicable_ticket_type',
                        key : 'park_applicable_ticket_type',
                        render : (park_applicable_ticket_type, row, index) => {
                            const { ticket_type, image, } = park_applicable_ticket_type ?? {};
                            return (
                                <>
                                    <Space>
                                        <div>
                                            <Avatar icon={<AppstoreOutlined />} shape='square' size='small' src={image} />
                                        </div>
                                        <div>
                                            <span>{ticket_type?.name}</span>
                                        </div>
                                    </Space>
                                </>
                            )
                        }
                    },
                    // {
                    //     title : 'Type',
                    //     dataIndex : 'required_type',
                    //     key : 'required_type',
                    // },
                    {
                        title : 'Minimum Quantity',
                        dataIndex : 'min_quantity',
                        key : 'min_quantity',
                    },{
                        title : 'Tickets',
                        dataIndex : 'ticket_applicable_users',
                        key : 'ticket_applicable_users',
                        render : (ticket_applicable_users, row, index) => {
                            const ticket_user_types = ticket_applicable_users?.map(x => x?.ticket_user_type?.name)?.join(", ");
                            return (
                                <>
                                    <span>{ticket_user_types}</span>
                                </>
                            )
                        }
                    },{
                        title : 'Action',
                        dataIndex : 'required_ticket_rule_id',
                        key : 'required_ticket_rule_id',
                        fixed : 'right',
                        width : 100,
                        render : (required_ticket_rule_id, row, index) => {
                            return (
                                <>
                                    <Button.Group>
                                        <Button
                                        size='small'
                                        icon={<EditOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                        onClick={() => setState(state => ({...state, edit_modal : required_ticket_rule_id, modal : (state?.modal + 1), }))}
                                        />
                                        <Popconfirm
                                        onConfirm={() => deleteRequiredTicketRule(required_ticket_rule_id)}
                                        title="Delete Required Ticket Rule"
                                        // description={name}
                                        overlayStyle={{ width : 250,}}
                                        >
                                            <Button
                                            size='small'
                                            icon={<DeleteOutlined style={{color : 'var(--secondary-text-color)',}} />}
                                            />
                                        </Popconfirm>
                                    </Button.Group>
                                </>
                            )
                        }
                    },]}
                    />
                </div>
            </Card>

            <Modal
            title="New Required Ticket Rule"
            open={state?.create_modal}
            onCancel={() => {
                setState(state => ({...state, create_modal : null, modal : (state?.modal + 1), }));
            }}
            onOk={() => save_form.submit()}
            width={800}
            confirmLoading={state?.save_loading}
            >
                <Form
                form={save_form}
                layout='vertical'
                onFinish={saveRequiredTicketRule}
                >
                    <AddRequiredTicketRuleModal form={save_form} park={park} modal_visible={state?.modal} />
                </Form>
            </Modal>

            <Modal
            title="Edit Required Ticket Rule"
            open={state?.edit_modal}
            onCancel={() => {
                setState(state => ({...state, edit_modal : null, modal : (state?.modal + 1), }));
            }}
            onOk={() => edit_form.submit()}
            width={800}
            confirmLoading={state?.update_loading}
            >
                <Form
                form={edit_form}
                layout='vertical'
                onFinish={updateRequiredTicketRule}
                >
                    <EditRequiredTicketRuleModal form={edit_form} park={park} modal_visible={state?.modal} required_ticket_rule_id={state?.edit_modal} />
                </Form>
            </Modal>
        </>
    )
}

export default RequiredTicketRuleLayout;