import { Switch, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const TaxApplicableTicketTable = ({ park_tax, ticket_user_types, default_ticket_types = [], }) => {
    const [state, setState] = useState({
        loading : false,
        columns : [],
        datas : [],
    });

    const generateRows = () => {
        const { ticket_types, park_tax_id, is_applicables, } = park_tax ?? {};
        // map data
        const map_datas = ticket_types?.map(x => {
            const { name, park_applicable_ticket_types, ticket_type_id, } = x;
            const { ticket_user_types } = park_applicable_ticket_types ?? {};


            let applicable = {};
            for(const ticket_user_type of ticket_user_types){
                const { TicketApplicableUser : ticket_applicable_user } = ticket_user_type;

                const is_applicable = is_applicables?.find(x => (x?.ticket_applicable_user_id === ticket_applicable_user?.ticket_applicable_user_id));
                applicable[ticket_user_type?.ticket_user_type_id] = {
                    is_applicable : is_applicable?.is_applicable,
                    ticket_applicable_user_id : ticket_applicable_user?.ticket_applicable_user_id,
                    edit : false,
                };
            }

            return {
                name,
                ticket_type_id,
                applicable,
            }
        }) ?? []
        
        return map_datas;
    }

    const generateColumns = () => {
        let columns = [];

        // map title
        columns?.push({
            title : 'Ticket Type',
            dataIndex : 'name',
            key : 'name',
            width: 150,
            fixed: 'left',
        })

        // map data
        for(const ticket_user_type of ticket_user_types){
            const { name, ticket_user_type_id, } = ticket_user_type;
            const column = {
                title : name,
                dataIndex : 'applicable',
                key : 'applicable',
                width : 150,
                render : (applicable, row, index) => {
                    // from database
                    const { is_applicable, ticket_applicable_user_id, edit, } = applicable[ticket_user_type_id] ?? {};  
                    return (
                        <>
                            {
                                is_applicable != undefined ?
                                (
                                    <>
                                        {
                                            is_applicable ?
                                            (
                                                <>
                                                    <CheckCircleFilled style={{color:'#95de64'}} />
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <CloseCircleFilled style={{color:'#d9d9d9',}} />
                                                </>
                                            )
                                        }
                                    </>
                                )
                                :
                                (
                                    <>
                                        <div>
                                            <span style={{fontSize:12, color:('var(--secondary-text-color)')}}>Not Applicable</span>
                                        </div>
                                    </>
                                )
                            }
                        </>
                    )
                }
            }
            columns?.push(column);
        }

        return columns;
    }

    const initializeTable = () => {
        const columns = generateColumns();
        const rows = generateRows();

        setState(state => ({...state, columns : columns, datas : rows, }));
    }

    useEffect(() => {
        initializeTable();
    }, [park_tax])

    return (
        <>
            <div>
                <Table
                scroll={{x : 600,}}
                pagination={false}
                className='hub-table bordered'
                columns={state?.columns}
                dataSource={state?.datas}
                size='small'
                />
            </div>
        </>
    );
}

export default TaxApplicableTicketTable;