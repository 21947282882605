import { Avatar, Badge, Button, Col, Divider, Form, Input, Row, Select, Space, Spin, Switch, Tag, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import staffService from '../../../services/config/staff.service';
import globalHelper from '../../../helpers/functions/global.helper';
import { CloseOutlined, EditOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons';
import parkService from '../../../services/config/park.service';
import DetailAssociateImage from './detail_associate_image';

const DetailAssociateEdit = ({ staff_id, staff_associate_id, form, refreshStaff, }) => {
    const [field, setField] = useState({
        is_active : false,
    })
    const [state, setState] = useState({
        loading : false,
        staff_associate : null,
    });
    const [select, setSelect] = useState({
        parks : [],
    })

    const onFieldChange = (key, value) => {
        setField(field => ({...field, [key] : value, }));
        form.setFieldsValue({ [key] : value });
    }

    const initializeSelection = async () => {
        const getParkResponse = await parkService?.getParkByAuth();

        const { parks } = getParkResponse;
        setSelect(select => ({...select, parks : parks}));
    }

    const fetchAssociates = async () => {
        setState(state => ({...state, loading : true, }));
        const staffAssociateResponse = await staffService?.getStaffAssociate(staff_id, {
            staff_associate_id : staff_associate_id,
        });

        const { staff_associates } = staffAssociateResponse;
        const staff_associate = staff_associates?.length > 0 ? staff_associates[0] : null;
        const { pass, relationship, } = staff_associate;
        const { is_active, } = pass;
        const mapped_parks = pass?.parks?.map(x => x.park_id);

        setTimeout(() => {
            setState(state => ({...state, loading : false, staff_associate : staff_associate, }));
            form.setFieldsValue({ name : pass?.name, identification_number : pass?.identification_number, relationship : relationship, is_active : is_active, parks : mapped_parks, });
            setField(field => ({...field, is_active : is_active, }));
        }, 500);
    }

    const tagRender = ({ label, value, closable, onClose }) => {
        const onPreventMouseDown = (event) => {
          event.preventDefault();
          event.stopPropagation();
        };
        return (
          <Tag
            color={'var(--secondary-button-color)'}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ color : 'var(--secondary-text-color)'}}
            closeIcon={<CloseOutlined style={{color:'var(--secondary-text-color)'}} />}
          >
            {label}
          </Tag>
        );
    };

    const initialize = async () => {
        await initializeSelection();
        await fetchAssociates();
    }

    useEffect(() =>{
        initialize();
    }, [staff_associate_id])
    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <div>
                    <div>
                        <span style={{fontSize:12, color : 'var(--secondary-text-color)',}}>Family members of {state?.staff_associate?.staff?.pass?.name}</span>
                    </div>
                    <div style={{marginTop:24,}}>
                        <Form.Item name={'image_url'}>
                            <DetailAssociateImage staff_associate={state?.staff_associate} refreshStaff={refreshStaff} />
                        </Form.Item>
                    </div>
                    <div>
                        <div>
                            <Row gutter={[20, 0]}>
                                <Col xs={24} sm={12}>
                                    <Form.Item name={'name'} label={'Name'}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item name={'identification_number'} label={'Identification Number'}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item name={'relationship'} label={'Relationship'}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item name={'is_active'} label={'Status'}>
                                        <Space size={12}>
                                            <Switch onChange={(e) => onFieldChange('is_active', e)} checked={field?.is_active}/>
                                            <Tag color={field?.is_active ? 'green' : 'orange'} style={{border:'none',}}>
                                                {field?.is_active ? 'Active' : 'Inactive'}
                                            </Tag>
                                        </Space>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <Divider />
                        <div>
                            <Row gutter={[20, 0]}>
                                <Col xs={24} sm={12}>
                                    <Form.Item name={'parks'} label={'Parks'}>
                                        <Select
                                        tagRender={tagRender}
                                        mode='multiple'
                                        options={select?.parks?.map((park, index) => {
                                            const { name, park_id } = park;
                                            return {
                                                label : name,
                                                value : park_id,
                                            }
                                        })}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Spin>
        </>
    );
}

export default DetailAssociateEdit;