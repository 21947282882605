import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getAccount(query){
    const queryString = serviceHelper.queryString(query);
    const accountResponse = await axios.get(`admin/account/get?${queryString}`, { withCredentials : true });
    return accountResponse?.data;
}

async function checkUsername(username){
    const checkUsernameResponse = await axios.get(`admin/account/username/${username}/check`, { withCredentials : true, });
    return checkUsernameResponse?.data;
}

async function createAccount(params){
    // as face is no longer required for account
    // then just use json to pass params
    const createAccountResponse = await axios.post(`admin/account/register`, params, { withCredentials : true, });
    return createAccountResponse?.data;
}

async function updateAccount(account_id, params){
    const updateAccountResponse = await axios.post(`admin/account/update/${account_id}`, params, { withCredentials : true, });
    return updateAccountResponse?.data;
}

async function deleteAccount(account_id){
    const deleteAccountResponse = await axios.delete(`admin/account/delete/${account_id}`, { withCredentials : true, });
    return deleteAccountResponse?.data;
}

async function getRole(query){
    const queryString = serviceHelper.queryString(query);
    const roleResponse = await axios.get(`admin/role/get?${queryString}`, { withCredentials : true });
    return roleResponse?.data;
}

// change password
async function checkPassword(password){
    const checkPasswordResponse = await axios.post(`admin/account/password/check`, { password, }, { withCredentials : true, });
    return checkPasswordResponse?.data;
}

async function changePassword(params){
    const changePasswordResponse = await axios.post(`admin/account/password/change`, params, { withCredentials : true, });
    return changePasswordResponse?.data;
}

export default {
    getAccount,
    checkUsername,
    createAccount,
    updateAccount,
    deleteAccount,
    getRole,
    checkPassword,
    changePassword,
}