import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getEstablishment(query){
    const queryString = serviceHelper.queryString(query);
    const establishmentResponse = await axios.get(`config/establishment/list?${queryString}`, { withCredentials : true });
    return establishmentResponse?.data;
}

export default {
    getEstablishment,
}