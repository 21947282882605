import React, { useState } from 'react';
import CustomPassLayout from './components/custom_pass_layout';
import ContainerComponent from '../../helpers/components/container.component';
import { useLocation } from 'react-router-dom';

const CustomPassIndex = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [filter, setFilter] = useState({
        page : (queryParams.get("page")),
        parks : queryParams.get("parks")?.split(',')?.map(Number)?.filter(x => x != 0),
        search : queryParams.get("search"),
    })
    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <CustomPassLayout default_filter={filter} />
            </ContainerComponent>
        </>
    );
}

export default CustomPassIndex;