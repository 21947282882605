import { Avatar, Button, Popconfirm, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import faceService from '../../../services/operation/face.service';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { LoadingOutlined } from '@ant-design/icons';

const CollectionFaceList = ({ collection_id, pass_type, }) => {
    const [state, setState] = useState({
        loading : false,
        faces : [],
    })

    const fetchCollectionFaces = async () => {
        setState(state => ({...state, loading : true,}));
        const faceResponse = await faceService?.fetchCollectionFaces(collection_id, {
            pass_type : pass_type,
        });
        setState(state => ({...state, loading : false, faces : faceResponse?.faces}));
    }

    const deleteFace = async (face_id) => {
    }

    useEffect(() => {
        fetchCollectionFaces();
    }, [])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <Table
                scroll={{ x : 400,}}
                dataSource={state?.faces}
                className='hub-table bordered'
                columns={[{
                    title : 'No',
                    dataIndex : 'collection',
                    key : 'collection',
                    width : 100,
                    render : (text, row, index) => {
                        const no = (index + 1);
                        return (
                            <>
                                <div>
                                    <span>{no}</span>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Face ID',
                    dataIndex : 'face',
                    key : 'face',
                    render : (text, row, index) => {
                        const { face_id } = text ?? {};
                        return (
                            <>
                                <div>
                                    <span>{face_id}</span>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Type',
                    dataIndex : 'type',
                    key : 'type',
                    render : (text, row, index) => {
                        const { ticket_user, account, pass, type, } = row ?? {};
                        let name;
                        if(pass){
                            name = pass?.pass_code;
                        }else{
                            if(type === 'account'){
                                name = account?.username;
                            }else{
                                name = ticket_user?.ticket_user_no;
                            }
                        }
                        
                        return (
                            <>
                                <div>
                                    <div>
                                        <span>{text}</span>
                                    </div>
                                    <div>
                                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>
                                            {name}
                                            {/* { type == 'account' ? account?.username : ticket_user?.ticket_user_no } */}
                                        </span>
                                    </div>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Confidence',
                    dataIndex : 'confidence',
                    key : 'confidence',
                    render : (text, row, index) => {
                        const { face } = row;
                        let num = face?.confidence.toString(); //If it's not already a String
                        num = num.slice(0, (num.indexOf(".")) + 5); //With 3 exposing the hundredths place
                        Number(num); //If you need it back as a Number
                        return (
                            <>
                                <div>
                                    <span>{`${num}%`}</span>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Image',
                    dataIndex : 'type',
                    key : 'type',
                    render : (text, row, index) => {
                        const type = text;
                        const { pass, account, ticket_user, } = row;
                        let image_url;

                        if(pass){
                            image_url = pass?.url_image;
                        }else{
                            if(type == 'account'){
                                image_url = account?.face_image;
                            }else{
                                image_url = ticket_user?.image_url;
                            }
                        }
                        
                        return (
                            <>
                                <div>
                                    <Avatar 
                                    size={'large'}
                                    src={
                                        <LazyLoadImage src={image_url ?? '/banners/empty-user.png'} />
                                    } />
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Action',
                    dataIndex : 'action',
                    key : 'action',
                    render : (text, row, index) => {
                        const { face } = row ?? {};
                        return (
                            <>
                                <div>
                                    <Popconfirm
                                    onConfirm={() => deleteFace(face?.face_id)}
                                    title={face?.face_id}
                                    description={`This action is not reversible, are you sure to delete this face?`}
                                    >
                                        <Button>
                                            Delete
                                        </Button>
                                    </Popconfirm>
                                </div>
                            </>
                        )
                    }
                }]}
                />
            </Spin>
        </>
    );
}

export default CollectionFaceList;