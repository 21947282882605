import { Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import ticketTypeService from '../../../../services/config/ticket_type.service';

const EditTicketTypeModal = ({ form, ticket_type_id, modal, }) => {
    const [state, setState] = useState({
        loading : false,
        ticket_type : null,
    })
    const fetchTicketType = async () => {
        setState(state => ({...state, loading : true, }));
        const ticketTypeResponse = await ticketTypeService?.fetchTicketType({
            ticket_type_id : ticket_type_id,
        });

        const { ticket_types } = ticketTypeResponse;
        const ticket_type = ticket_types?.[0];
        
        form.setFieldsValue({ name : ticket_type?.name, })
        setState(state => ({...state, loading : false, ticket_type : ticket_type, }));
    }

    useEffect(() => {
        fetchTicketType();
    }, [ticket_type_id, modal])
    return (
        <>
            <Form.Item label={'Name'} name={'name'}>
                <Input />
            </Form.Item>
        </>
    );
}

export default EditTicketTypeModal;