import React, { useRef } from 'react';
import Visitors from '../visitors';
import PurchaseTypeTable from './purchase_type_table';
import TicketTypeTable from './ticket_type_table';
import OtherSalesTable from './other_sales_table';
import moment from 'moment';
import { Button, Space } from 'antd';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { PrinterOutlined } from '@ant-design/icons';
import numberHelper from '../../../../helpers/functions/number.helper';

const GridView = ({ filter, total_date_breakdown_sales, total_sales, ticket_types, purchase_types, add_on_types, park, }) => {
    const start_date = moment(filter?.start_date);
    const end_date = moment(filter?.end_date);

    const componentRef = useRef();

    return (
        <>
            <div>
                <div style={{display:'flex'}}>
                    <div style={{marginLeft:'auto'}}>
                        <ReactToPrint 
                        trigger={() => (
                            <Button icon={<PrinterOutlined />}>Print</Button>
                        )}
                        content={() => componentRef.current}
                        pageStyle={`@page {
                            margin: 24px;
                        }`}
                        />
                    </div>
                </div>
                <div ref={componentRef}>
                    <div style={{textAlign:'center'}}>
                        <div>
                            <span style={{fontSize:24, fontWeight:500,}}>{park?.name}</span>
                        </div>

                        <div style={{marginTop:6}}>
                            <Space style={{fontSize:20, color:'var(--secondary-text-color)'}}>
                                <span>Sales Report:</span>
                                
                            </Space>
                        </div>
                        <div style={{marginTop:4,}}>
                            <span style={{fontSize:16, color:'var(--secondary-text-color)'}}>
                                {
                                    filter?.start_date ? (
                                        <>
                                            {
                                                start_date?.isSame(end_date) ? 
                                                start_date?.format('DD MMMM YYYY hh:mm:ss') : 
                                                `${start_date?.format('DD MMMM YYYY HH:mm')} to ${end_date?.format('DD MMMM YYYY HH:mm')}`
                                            }
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            {
                                                moment()?.format('DD MMMM YYYY')
                                            }
                                        </>
                                    )
                                }
                                </span>
                        </div>
                    </div>

                    <div style={{marginTop:12, textAlign:'left', }}>
                        <Space size={32}>
                            <div>
                                {/* <Visitors filter={filter} /> */}
                            </div>
                            {/* <div>
                                <div>
                                    <span style={{fontWeight: 500, color:'#414552', fontSize:14}}>Tax Amount</span>
                                </div>
                                <div style={{marginTop:6}}>
                                    <span style={{fontSize:20}}>{`${park?.currency?.code}${numberHelper?.commaNumber((total_sales?.tax_amount ?? 0), 2)}`}</span>
                                </div>
                            </div> */}
                        </Space>
                       
                    </div>

                    <div style={{marginTop:42, display:'flex', flexDirection:'column', gap:24,}}>
                        <div style={{textAlign:'left',}}>
                            <div>
                                <div>
                                    <span style={{fontWeight:500,}}>Ticket Sales</span>
                                </div>
                                <div>
                                    <span style={{color:'var(--secondary-text-color)', fontSize:12}}>
                                        Exclude Other Sales.
                                    </span>
                                </div>
                            </div>
                            <div style={{marginTop:12,}}>
                                <PurchaseTypeTable purchase_types={purchase_types} total_sales={total_sales} park={park} />
                            </div>
                        </div>

                        <div style={{textAlign:'left',}}>
                            <div>
                                <div>
                                    <span style={{fontWeight:500,}}>Sales Breakdown by Ticket Group</span>
                                </div>
                                <div>
                                    <span style={{color:'var(--secondary-text-color)', fontSize:12}}>
                                        Visitor ticket only, Does not include plot/hotel/food/training room.
                                    </span>
                                </div>
                            </div>
                            <div style={{marginTop:12,}}>
                                <TicketTypeTable ticket_types={ticket_types} park={park}/>
                            </div>
                        </div>

                        <div style={{textAlign:'left',}}>
                            <div>
                                <span style={{fontWeight:500,}}>Other Sales</span>
                            </div>
                            <div style={{marginTop:12,}}>
                                <OtherSalesTable add_on_types={add_on_types} park={park}/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default GridView;