import { Badge, Button, Col, Form, Image, Input, Row, Spin, Upload, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import parkService from '../../../services/config/park.service';
import { CloseOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill-v2.0';

const { TextArea } =  Input;
const ParkInvoiceForm = ({ park, }) => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        park_invoice : null,

        company_logo : null,
        save_loading : false,
        remove_logo_loading : false,

        refresh : 0,
    });
    const [delta, setDelta] = useState();

    const fetchParkInvoice = async () => {
        setState(state => ({...state, loading : true, }));

        const parkInvoiceResponse = await parkService?.getParkInvoice(park?.park_id);
        const { park_invoice } = parkInvoiceResponse;

        setState(state => ({...state, loading : false, park_invoice : park_invoice, company_logo : park_invoice?.company_logo, }));
        if(park_invoice){
            const { company_name, registration_number, company_address, term_and_condition, } = park_invoice;
            if(term_and_condition){
                setDelta(JSON.parse(term_and_condition));
            }
            // set form
            form.setFieldsValue({
                company_name : company_name,
                registration_number : registration_number,
                company_address : company_address,
                ...(term_and_condition && ({ term_and_condition : JSON.parse(term_and_condition),})),
            })
        }
    }

    const upsertParkInvoice = async () => {
        setState(state => ({...state, save_loading : true, }));
        const { company_name, registration_number, company_address, company_logo } = form.getFieldsValue();
        const upsertParkInvoiceResponse = await parkService?.upsertParkInvoice(park?.park_id, {
            company_name,
            registration_number,
            company_address,
            term_and_condition : JSON.stringify(delta),
            image : company_logo?.file,
        }).catch(err => setState(state => ({...state, save_loading : false,})));

        notification?.success({
            message : 'Park Invoice Updated',
        })

        setState(state => ({...state, save_loading : false, refresh : (state?.refresh + 1), }));
    }

    const onDeltaChange = (content, delta, source, editor) => {
        setDelta(editor.getContents());
    }

    const acceptable_file_format = ['image/jpeg', 'image/png'];
    const beforeUpload = async (file) => {
        const is_image = acceptable_file_format?.find(x => x == file?.type);

        if(is_image){
            const uploaded_file = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    file.base64 = reader.result.split(',')[1];
                    resolve(file);
                };
                reader.onerror = (error) => reject(error);
            });
    
            const base64Image = `data:image/jpeg;base64,${uploaded_file?.base64}`;
            setState(state => ({...state, company_logo : base64Image}));
        }else{
            // errorModal("Please upload in image format");
        }
        return false;
    }

    const removeCompanyLogo = async (e) => {
        setState(state => ({...state, remove_logo_loading : true, }));

        const park_invoice_id = (state?.park_invoice?.park_invoice_id)
        const removeInvoiceCompanyLogoResponse = await parkService?.removeInvoiceCompanyLogo(park_invoice_id).catch(err => setState(state => ({...state, remove_logo_loading : false,})));


        setState(state => ({...state, remove_logo_loading : false, refresh : (state?.refresh + 1)}));
    }

    useEffect(() => {
        form.resetFields();
        fetchParkInvoice();
    }, [park?.park_id, state?.refresh])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <Form
                form={form}
                layout='vertical'
                onFinish={upsertParkInvoice}
                >
                    <Row gutter={[12, 12]}>
                        <Col span={24}>
                            <Form.Item
                            label={"Company Logo"}
                            // name={'company_logo'}
                            style={{ textAlign : 'start', }}
                            >
                                <Badge offset={[-8, 4]} count={(
                                    <Button loading={state?.remove_logo_loading} onClick={removeCompanyLogo} icon={<CloseOutlined />} size='small'></Button>
                                )}>
                                    <Form.Item name={'company_logo'} style={{ marginBottom : 0, }}>
                                        <Upload
                                        name="company_logo"
                                        listType='picture-card'
                                        beforeUpload={beforeUpload}
                                        showUploadList={false}
                                        style={{width : '100%',}}
                                        >
                                            {
                                                state?.company_logo ?
                                                (
                                                    <>
                                                        <Image preview={false} src={state?.company_logo} />
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <div>
                                                            <div>
                                                                <PlusOutlined />
                                                            </div>
                                                            <div>Upload</div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </Upload>
                                    </Form.Item>
                                </Badge>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={"company_name"} label={"Company Name"}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name={'registration_number'} label={"Registration Number"}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name={"company_address"} label={"Company Address"}>
                                <TextArea rows={4} style={{ resize : 'none', }} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item name={"term_and_condition"} label={"Term And Condition"}>
                                <ReactQuill
                                // readOnly
                                // theme={null}
                                theme='snow'
                                modules={{
                                    toolbar : true,
                                }}
                                placeholder='Enter descriptions'
                                onChange={onDeltaChange}
                                // {...state?.park_invoice?.term_and_condition && ({ value : JSON.parse(state?.park_invoice?.term_and_condition) })}
                                // value={JSON.parse(state?.park_invoice?.term_and_condition)}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item>
                                <div style={{ textAlign: 'end', }}>
                                    <Button loading={state?.save_loading} onClick={() => form.submit()} type='primary'>Confirm</Button>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </>
    );
}

export default ParkInvoiceForm;