import React, { useEffect, useState, useContext } from 'react';
import ticketService from '../../../services/ticket/ticket.service';
import EditTicketDetail from './edit_ticket_detail';
import { Space, Spin, Tag } from 'antd';
import AccessContext from '../../../helpers/context/access.context';

const TicketVisitDate = ({ ticket_id, visit_date, park_id, }) => {
    const access = useContext(AccessContext);
    const [state, setState] = useState({
        loading : false,
        checked_in : false,
    });

    const fetchCheckedIn = async () => {
        setState(state => ({...state, loading : true,}));
        const checkInResponse = await ticketService?.checkIsCheckedIn(ticket_id);

        const { is_checked_in } = checkInResponse;
        setState(state => ({...state, loading : false, checked_in : is_checked_in}));
    }

    useEffect(() => {
        if(ticket_id){
            fetchCheckedIn();
        }
    }, [ticket_id]);
    
    return (
        <>
            {
                state?.loading ?
                (
                    <>
                        <Spin spinning />
                    </>
                )
                :
                (
                    <>
                        {
                            state?.checked_in ? 
                            (
                                <>
                                    <Space>
                                        <div>
                                            <span>{visit_date}</span>
                                        </div>
                                        <div style={{marginTop:4,}}>
                                            <Tag style={{border : 'none', background:'var(--secondary-button-color)'}} >Checked in</Tag>
                                        </div>
                                    </Space>
                                </>
                            )
                            :
                            (
                                <>
                                    <EditTicketDetail
                                    message_name={'Visit Date'}
                                    type={`date`}
                                    ticket_id={ticket_id}
                                    name={'visit_date'}
                                    value={visit_date}
                                    park_id={park_id}
                                    feature_code={'CHANGE_VISIT_DATE'}
                                    />
                                </>
                            )
                        }
                    </>
                )
            }
            
        </>
    );
}

export default TicketVisitDate;