import { Empty, Space, Table, Tag } from 'antd';
import React from 'react';
import numberHelper from '../../../helpers/functions/number.helper';

const TicketDetailAddOnList = ({add_ons, park,}) => {
    const { code, symbol } = park?.currency ?? {};
    return (
        <>
            {
                add_ons?.length > 0 ?
                (
                    <>
                        <Table
                        scroll={{ x : 400, }}
                        className='hub-table bordered'
                        dataSource={add_ons}
                        pagination={false}
                        columns={[{
                            title : 'Add On',
                            dataIndex : 'name',
                            key : 'name',
                        },{
                            title : 'Quantity',
                            dataIndex : 'TicketAddOn',
                            key : 'TicketAddOn',
                            render : (text, row, index) => {
                                const { quantity } = text ?? {};
                                return (
                                    <>
                                        {quantity}
                                    </>
                                )
                            }
                        },{
                            title : (
                                <>
                                    <Space>
                                        <div>
                                            <span>Unit Price</span>
                                        </div>
                                        <div>
                                            <Tag color='blue' style={{border : 'none', }}>
                                                {code}
                                            </Tag>
                                        </div>
                                    </Space>
                                </>
                            ),
                            dataIndex : 'TicketAddOn',
                            key : 'TicketAddOn',
                            render : (text, row, index) => {
                                const { price, tax_amount, } = text ?? {}
                                const unit_price = numberHelper?.commaNumber((isNaN(parseFloat(price)) ? 0 : parseFloat(price)));
                                return (
                                    <>
                                        <div>
                                            <div>
                                                <span>{`${symbol}${unit_price}`}</span>
                                            </div>
                                            <div>
                                                <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{`${symbol}${numberHelper?.commaNumber((tax_amount ?? 0), 2)}`}</span>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        },{
                            title : (
                                <>
                                    <Space>
                                        <div>
                                            <span>Total Price</span>
                                        </div>
                                        <div>
                                            <Tag color='blue' style={{border : 'none', }}>
                                                {code}
                                            </Tag>
                                        </div>
                                    </Space>
                                </>
                            ),
                            dataIndex : 'TicketAddOn',
                            key : 'TicketAddOn',
                            fixed : 'right',
                            render : (text, row, index) => {
                                const { price, quantity, tax_amount, } = text ?? {}
                                const unit_price = (isNaN(parseFloat(price)) ? 0 : parseFloat(price));
                                const total_price = numberHelper?.commaNumber(((unit_price * quantity)), 2);
                                const total_tax_amount = numberHelper?.commaNumber((tax_amount * quantity), 2);
                                return (
                                    <>
                                        <div>
                                            <div>
                                                <span>{`${symbol}${total_price}`}</span>
                                            </div>
                                            <div>
                                                <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{`${symbol}${total_tax_amount}`}</span>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        }]}
                        />
                    </>
                )
                :
                (
                    <>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </>
                )
            }
        </>
    );
}

export default TicketDetailAddOnList;