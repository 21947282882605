import { Alert, Button, Col, Divider, Empty, Form, Input, Modal, Row, Select, Space, Table, Tag, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import StepNoItem from '../../../helpers/components/step_no_item';
import parkService from '../../../services/config/park.service';
import GenerateMaTicketDate from './generate_ma_ticket_date';
import GenerateMaTicketUserList from './generate_ma_ticket_user_list';
import dayjs from 'dayjs';
import ticketService from '../../../services/ticket/ticket.service';
import { useHistory } from 'react-router-dom';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import AddOnList from './add_on_list';
import IconWrapper from '../../../helpers/components/icon_wrapper';
import numberHelper from '../../../helpers/functions/number.helper';
import GenerateParkTransaction from '../components/generate_park_transaction';
import PreferenceList from './preference_list';

const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;
const GenerateMaTicketLayout = () => {
    const history = useHistory();
    const [form] = Form.useForm();

    const [state, setState] = useState({
        loading : true,
        parks : [],

        //filter
        selected_park_id : null,
        selected_ticket_type_id : null,
        selected_date : Date?.now(),

        //loading
        save_loading : false,
    });
    const [capacity, setCapacity] = useState({
        loading : false,
        remaining_capacity : null,
        complete_tickets : null,
        is_capacity : false,
        total_capacity : null,
    })

    const fetchDetail = async () => {
        setState(state => ({...state, loading : true}));
        const parkResponse = await parkService?.getParkByAuth();
        const { parks } = parkResponse;
        setState(state => ({...state, loading : false, parks : parks}));
    }

    const changePark = (e) => {
        setState(state => ({...state, selected_park_id : e, selected_ticket_type_id : null}));
        form.setFieldsValue({ticket_type : null});
    }

    const changeDate = (ds) => {
        const selected_date = dayjs(ds).format('YYYY-MM-DD');
        setState(state => ({...state, selected_date : selected_date}));
    }

    const checkCapacityBeforeConfirmPurchase = async () => {
        const { remaining_capacity, } = capacity;
        const { ticket_users, visit_date, } = form.getFieldsValue();

        // check is exceed purchase count
        const purchase_count = ticket_users?.reduce((a, b) => (a + b?.quantity), 0);
        const is_exceed_capacity = capacity?.is_capacity && (purchase_count > capacity?.remaining_capacity);
        if(is_exceed_capacity){
            notification?.warning({
                message : 'Ticket Sold Out',
                description : `Only ${remaining_capacity} tickets remain for ${dayjs(visit_date)?.format("YYYY-MM-DD")}.`
            })
        }else{
            confirmPurchase();
        }
    }

    const confirmPurchase = async () => {
        const { ticket_users, add_ons, name, email, preference, } = form.getFieldsValue();
        const park = state?.parks?.find(x => x.park_id === state?.selected_park_id);
        const ticket_type = park?.ticket_types?.find(x => x.ticket_type_id == state?.selected_ticket_type_id);

        // calculate tax
        const calculateResponse = await ticketService?.calculateOpenPricing({
            park_id : park?.park_id,
            ticket_type_id : ticket_type?.ticket_type_id,
            ticket_users,
            add_ons,
        });
        const { sub_total, tax_amount, total_amount, taxes, ticket_amount : total_ticket_price, add_on_amount : total_add_on_price, } = calculateResponse;
        // const total_price = (total_ticket_price + total_add_on_price) + tax_amount;
        const total_price = total_amount;

        confirm({
            title : `Generate Manager Ticket?`,
            okButtonProps : {
                type : 'primary',
                // disabled : is_exceed_capacity,
            },
            content : (
                <>
                    <div>
                        <div>
                            <Space>
                                <div>
                                    <Tag color='blue' style={{border : 'none',}}>
                                        {park?.currency?.code}
                                    </Tag>
                                </div>
                                <div>
                                    <span style={{ fontSize: 24, fontWeight: 500 }}>{`${park?.currency?.symbol}${numberHelper?.commaNumber(total_price)}`}</span>
                                </div>
                            </Space>
                        </div>
                        <Divider style={{margin: 4,}} />

                        <div style={{marginTop:12,}}>
                            <div>
                                <Space>
                                    <IconWrapper icon={'ticket.svg'} />
                                    <div>
                                        <span style={{ fontWeight: 500 }}>Transactions</span>
                                    </div>
                                </Space>
                            </div>
                            <div style={{marginTop:8,}}>
                                <Row>
                                    <Col xs={24} md={8}>
                                        <div>
                                            <div>
                                                <span style={{color:'#595959', fontWeight:500,}}>
                                                    Park
                                                </span>
                                            </div>
                                            <div>
                                                <span style={{color:'var(--secondary-text-color)'}}>
                                                    {/* {state?.parks?.find(x => x.park_id == state?.selected_park_id)?.name} */}
                                                    {park?.name}
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <div>
                                            <div>
                                                <span style={{color:'#595959', fontWeight:500,}}>
                                                    Ticket Type
                                                </span>
                                            </div>
                                            <div>
                                                <span style={{color:'var(--secondary-text-color)'}}>
                                                    {/* {state?.parks?.find(x => x.park_id === state?.selected_park_id)?.ticket_types?.find(x => x.ticket_type_id == state?.selected_ticket_type_id)?.name} */}
                                                    {ticket_type?.name}
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <div>
                                            <div>
                                                <span style={{color:'#595959', fontWeight:500,}}>
                                                    Visit Date
                                                </span>
                                            </div>
                                            <div>
                                                <span style={{color:'var(--secondary-text-color)'}}>
                                                    {dayjs(state?.selected_date ?? Date?.now())?.format("YYYY-MM-DD")}
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <div>
                                            <div>
                                                <span style={{color:'#595959', fontWeight:500,}}>
                                                    Visit Time
                                                </span>
                                            </div>
                                            <div>
                                                <span style={{color:'var(--secondary-text-color)'}}>
                                                    {preference?.name}
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div style={{marginTop:12,}}>
                            <div>
                                <Space>
                                    <IconWrapper icon={'profile.svg'} />
                                    <div>
                                        <span style={{ fontWeight: 500 }}>Purchaser Info</span>
                                    </div>
                                </Space>
                            </div>
                            <div style={{marginTop:8,}}>
                                <Row>
                                    <Col xs={24} md={8}>
                                        <div>
                                            <div>
                                                <span style={{color:'#595959', fontWeight:500,}}>
                                                    Name
                                                </span>
                                            </div>
                                            <div>
                                                <span style={{color:'var(--secondary-text-color)'}}>
                                                    {name ?? "Anonymous"}
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <div>
                                            <div>
                                                <span style={{color:'#595959', fontWeight:500,}}>
                                                    Email
                                                </span>
                                            </div>
                                            <div>
                                                <span style={{color:'var(--secondary-text-color)'}}>
                                                    {email ?? 'No Email Entered'}
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                    
                                </Row>
                            </div>
                        </div>

                        <Divider  />
                        <div>
                            <div>
                                <span style={{ fontWeight: 500 }}>Tickets</span>
                            </div>
                            <div style={{marginTop:12,}}>
                                <Table
                                size='small'
                                scroll={{ x : 400, }}
                                pagination={false}
                                className='hub-table bordered'
                                dataSource={ticket_users}
                                columns={[{
                                    title : 'Name',
                                    dataIndex : 'name',
                                    key : 'name',
                                },{
                                    title : 'Quantity',
                                    dataIndex : 'quantity',
                                    key : 'quantity',
                                },{
                                    title : (
                                        <>
                                            <Space>
                                                <div>
                                                    <span>Price</span>
                                                </div>
                                                <div>
                                                    <Tag color='blue' style={{border : 'none',}}>
                                                        {park?.currency?.code}
                                                    </Tag>
                                                </div>
                                            </Space>
                                        </>
                                    ),
                                    dataIndex : 'price',
                                    key : 'price',
                                    render : (text, row, index) => {
                                        const price = isNaN(parseFloat(text)) ? 0 : parseFloat(text);
                                        
                                        return (
                                            <>
                                                <div>
                                                    <span>{`${park?.currency?.symbol}${numberHelper?.commaNumber(price)}`}</span>
                                                </div>
                                            </>
                                        )
                                    }
                                },{
                                    title : (
                                        <>
                                            <Space>
                                                <div>
                                                    <span>Total Price</span>
                                                </div>
                                                <div>
                                                    <Tag color='blue' style={{border : 'none',}}>
                                                        {park?.currency?.code}
                                                    </Tag>
                                                </div>
                                            </Space>
                                        </>
                                    ),
                                    dataIndex : 'price',
                                    key : 'total_price',
                                    // fixed:'right',
                                    align:'right',
                                    render : (text, row, index) => {
                                        const total_price = text * row?.quantity;
                                        return (
                                            <>
                                                <div>
                                                    <span>{`${park?.currency?.symbol}${numberHelper?.commaNumber(total_price)}`}</span>
                                                </div>
                                            </>
                                        )
                                    }
                                }]}
                                footer={() => {
                                    return (
                                        <>
                                            <div style={{display:'flex'}}>
                                                <div style={{marginLeft:'auto'}}>
                                                    <Space>
                                                        <div>
                                                            <Tag color='blue' style={{border : 'none',}}>
                                                                {park?.currency?.code}
                                                            </Tag>
                                                        </div>
                                                        <div>
                                                            <span>{`${park?.currency?.symbol}${numberHelper?.commaNumber(total_ticket_price)}`}</span>
                                                        </div>
                                                    </Space>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }}
                                />
                            </div>
                        </div>
                        <div style={{marginTop:24,}}>
                            <div>
                                <span style={{ fontWeight: 500 }}>Add Ons</span>
                            </div>
                            <div style={{marginTop:12,}}>
                            <Table
                            size='small'
                            scroll={{ x : 400, }}
                            pagination={false}
                            className='hub-table bordered'
                            dataSource={add_ons}
                            columns={[{
                                title : 'Name',
                                dataIndex : 'name',
                                key : 'name',
                            },{
                                title : 'Quantity',
                                dataIndex : 'quantity',
                                key : 'quantity',
                            },{
                                title : (
                                    <>
                                        <Space>
                                            <div>
                                                <span>Price</span>
                                            </div>
                                            <div>
                                                <Tag color='blue' style={{border : 'none',}}>
                                                    {park?.currency?.code}
                                                </Tag>
                                            </div>
                                        </Space>
                                    </>
                                ),
                                dataIndex : 'price',
                                key : 'price',
                                render : (text, row, index) => {
                                    const price = isNaN(parseFloat(text)) ? 0 : parseFloat(text);
                                    return (
                                        <>
                                            <div>
                                                <span>{`${park?.currency?.symbol}${numberHelper?.commaNumber(price)}`}</span>
                                            </div>
                                        </>
                                    )
                                }
                            },{
                                title : (
                                    <>
                                        <Space>
                                            <div>
                                                <span>Total Price</span>
                                            </div>
                                            <div>
                                                <Tag color='blue' style={{border : 'none',}}>
                                                    {park?.currency?.code}
                                                </Tag>
                                            </div>
                                        </Space>
                                    </>
                                ),
                                dataIndex : 'price',
                                key : 'total_price',
                                // fixed:'right',
                                align:'right',
                                render : (text, row, index) => {
                                    const total_price = text * row?.quantity;
                                    return (
                                        <>
                                            <div>
                                                <span>{`${park?.currency?.symbol}${numberHelper?.commaNumber(total_price)}`}</span>
                                            </div>
                                        </>
                                    )
                                }
                            }]}
                            footer={() => {
                                return (
                                    <>
                                        <div style={{display:'flex'}}>
                                            <div style={{marginLeft:'auto'}}>
                                                <Space>
                                                    <div>
                                                        <Tag color='blue' style={{border : 'none',}}>
                                                            {park?.currency?.code}
                                                        </Tag>
                                                    </div>
                                                    <div>
                                                        <span>{`${park?.currency?.symbol}${numberHelper?.commaNumber(total_add_on_price)}`}</span> 
                                                    </div>
                                                </Space>
                                                
                                            </div>
                                        </div>
                                    </>
                                )
                            }}
                            />
                            </div>
                        </div>
                        <Divider />
                        <div>
                            <div>
                                <span style={{ fontWeight: 500 }}>Amount</span>
                            </div>
                            <div style={{marginTop:12,}}>
                                <Row>
                                    <Col span={12}>
                                        <GenerateParkTransaction park={park} sub_total={sub_total} total_amount={total_amount} taxes={taxes}/>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </>
            ),
            onOk(){
                return new Promise(async (resolve, reject) => {
                    const { generated_ma_ticket, error, } = await purchaseManagerTickets();
                    generated_ma_ticket && resolve();
                    error?.length && reject();
                }).catch(err => {
                    console.error(err);
                })
            },
            style: {top : 20,},
            width : 800,
        });
    }

    const purchaseManagerTickets = async () => {
        const { park, ticket_type, ticket_users, add_ons, remarks, visit_date, email, name, preference, } = form.getFieldsValue();
        // get tax amount
        const calculateResponse = await ticketService?.calculateOpenPricing({
            park_id : state?.selected_park_id,
            ticket_type_id : state?.selected_ticket_type_id,
            ticket_users,
            add_ons,
        });
        const { tax_amount, total_amount, } = calculateResponse;

        const final_price = total_amount;
        const generateMATicketResponse = await ticketService.generateTicket({
            park_id : park,
            ticket_type_id : ticket_type,
            ticket_users,
            add_ons,
            email,
            remarks,
            purchase_type_code : 'MA',
            name,
            visit_date : dayjs(visit_date)?.format("YYYY-MM-DD"),
            final_price,
            preference_id : preference?.preference_id,
        }).catch(err => {
            // resetFields();
        });

        const { ticket : generated_ma_ticket, error, } = generateMATicketResponse;

        if(error?.length > 0){
            const err = error[0];
            notification?.warning({
                message : err?.message,
            })
        }else{
            notification.success({
                message : 'Successful created Managers Tickets',
                description : (
                    <>
                        <a style={{color:'var(--main-color)'}} onClick={() => history.push(`/ticket/detail/${generated_ma_ticket?.ticket_id}`)}>
                            {`${generated_ma_ticket?.unique_no}${generated_ma_ticket?.salt}`}
                        </a>
                    </>
                ),
                duration: 10,
                placement : 'bottomLeft'
            })
    
            resetFields();
        }

        return { generated_ma_ticket, error, };
    }

    const fetchCapacity = async () => {
        // get capacity
        setCapacity(capacity => ({...capacity, loading : true,}))
        const { visit_date } = form.getFieldsValue();
        const capacityResponse = await parkService?.getCapacity({
            visit_date : dayjs(visit_date)?.format("YYYY-MM-DD") ?? dayjs()?.format("YYYY-MM-DD"),
            park_id : state?.selected_park_id,
            purchase_type_code : 'MA'
        });
        const { is_capacity, remaining_capacity, complete_tickets, capacity : total_capacity, } = capacityResponse;
        setCapacity(capacity => ({...capacity, loading : false, remaining_capacity, complete_tickets, is_capacity, total_capacity, }))
    }

    const resetFields = () => {
        form.resetFields();
        setState(state => ({...state, selected_park_id : null, selected_ticket_type_id : null, selected_date : null,}));
    }

    useEffect(() => {
        fetchDetail();
    }, [])

    useEffect(() => {
        // get capacity
        if(state?.selected_park_id){
            fetchCapacity();
        }
    }, [form.getFieldValue('visit_date'), state?.selected_park_id,])

    return (
        <>
            <div className='mobile-padding-12'>
                <div style={{marginBottom: 24, textAlign:'start'}}>
                    <PageHeaderComponent isBack title={'Generate Manager Tickets'} />
                </div>
                <Form
                onFinish={checkCapacityBeforeConfirmPurchase}
                form={form}
                layout={'vertical'}
                >
                    <EntranceMotion>
                        <div style={{textAlign:'start', border:'2px solid #cbd5e0', borderRadius:8, background:'#f7fafc', padding : 24, margin:'12px 0'}}>
                            <Row gutter={[12, 12]}>
                                <Col span={12}>
                                    <div>
                                        <Form.Item
                                        name="park" 
                                        label={
                                            <StepNoItem no={1}
                                            text={<span style={{fontSize:14, fontWeight:500, color:'var(--secondary-text-color)'}}>Park</span>} 
                                            />
                                        }
                                        >
                                            <Select
                                            value={state?.selected_park_id}
                                            placeholder={'Select Park'}
                                            onChange={changePark}
                                            >
                                                {
                                                    state?.parks?.map((park, index) => {
                                                        return (
                                                            <Option key={index} value={park?.park_id}>
                                                                {park?.name}
                                                            </Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                    name={'ticket_type'}
                                    label={
                                        <StepNoItem no={2}
                                        text={<span style={{fontSize:14, fontWeight:500, color:'var(--secondary-text-color)'}}>Ticket Group</span>} 
                                        />
                                    }
                                    >
                                        <Select
                                        // defaultValue={state?.selected_ticket_type_id}
                                        value={state?.selected_ticket_type_id}
                                        disabled={!state?.selected_park_id}
                                        placeholder={'Select Ticket Group'}
                                        onChange={(e) => setState(state => ({...state, selected_ticket_type_id : e}))}
                                        >
                                            {
                                                state?.parks?.find(x => x.park_id === state?.selected_park_id)?.ticket_types?.map((ticket_type, index) => {
                                                    return (
                                                        <Option key={index} value={ticket_type?.ticket_type_id}>
                                                            {ticket_type?.name}
                                                        </Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                            
                                </Col>
                            </Row>
                        </div>
                    </EntranceMotion>

                    <EntranceMotion delay={0.1}>
                        <div style={{marginTop:24, textAlign:'start'}}>
                            <div>
                                <Form.Item
                                name={'visit_date'}
                                label={
                                    <StepNoItem no={3}
                                    text={<span style={{fontSize:14, fontWeight:500, color:'var(--secondary-text-color)'}}>Date</span>} 
                                    />
                                }
                                >
                                    <GenerateMaTicketDate
                                    onChange={changeDate}
                                    park={state?.parks?.find(x => x.park_id === state?.selected_park_id)} 
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </EntranceMotion>

                    <EntranceMotion delay={0.2}>
                        <div style={{marginTop:24,}}>
                            <Form.Item
                            name={'preference'}
                            label={
                                <StepNoItem no={4}
                                text={<span style={{fontSize:14, fontWeight:500, color:'var(--secondary-text-color)'}}>Visit Time</span>} 
                                />
                            }
                            >
                                {
                                    state?.selected_park_id && state?.selected_ticket_type_id ?
                                    (
                                        <>
                                            <PreferenceList
                                            form={form} 
                                            park={state?.parks?.find(x => x.park_id === state?.selected_park_id)} 
                                            ticket_type={(state?.parks?.find(x => x.park_id === state?.selected_park_id)?.ticket_types?.find(x => x.ticket_type_id === state?.selected_ticket_type_id))}
                                            />
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`Please select park and ticket group`}/>
                                        </>
                                    )
                                }
                            </Form.Item>
                        </div>
                    </EntranceMotion>

                    <EntranceMotion delay={0.3}>
                        <div style={{marginTop:24,}}>
                            <Form.Item
                            name={'ticket_users'}
                            label={
                                <StepNoItem no={5}
                                text={<span style={{fontSize:14, fontWeight:500, color:'var(--secondary-text-color)'}}>Tickets</span>} 
                                />
                            }
                            >
                                {
                                    state?.selected_park_id && state?.selected_ticket_type_id ?
                                    (
                                        <>
                                            <GenerateMaTicketUserList 
                                            form={form} 
                                            park={state?.parks?.find(x => x.park_id === state?.selected_park_id)} 
                                            ticket_type={(state?.parks?.find(x => x.park_id === state?.selected_park_id)?.ticket_types?.find(x => x.ticket_type_id === state?.selected_ticket_type_id))}
                                            capacity={capacity}
                                            />
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`Please select park and ticket group`}/>
                                        </>
                                    )
                                }
                            </Form.Item>
                        </div>
                    </EntranceMotion>

                    <EntranceMotion delay={0.3}>
                        <div style={{marginTop:24,}}>
                            <Form.Item
                            name={'add_ons'}
                            label={
                                <StepNoItem no={6}
                                text={<span style={{fontSize:14, fontWeight:500, color:'var(--secondary-text-color)'}}>Add Ons</span>} 
                                />
                            }
                            >
                                {
                                    state?.selected_park_id && state?.selected_ticket_type_id ?
                                    (
                                        <>
                                            <AddOnList
                                            form={form} 
                                            park={state?.parks?.find(x => x.park_id === state?.selected_park_id)} 
                                            ticket_type={(state?.parks?.find(x => x.park_id === state?.selected_park_id)?.ticket_types?.find(x => x.ticket_type_id === state?.selected_ticket_type_id))}
                                            />
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`Please select park and ticket group`}/>
                                        </>
                                    )
                                }
                            </Form.Item>
                        </div>
                    </EntranceMotion>

                    <EntranceMotion delay={0.3}>
                        <div style={{marginTop:24,}}>
                            <div style={{textAlign:'start'}}>
                                <StepNoItem no={7}
                                text={<span style={{fontSize:14, fontWeight:500, color:'var(--secondary-text-color)'}}>Contact Information</span>} 
                                />
                            </div>
                            <div style={{marginTop:12,}}>
                                <Form.Item
                                name={'name'}
                                label={"Name"}
                                >
                                    <Input placeholder='Enter name' />
                                </Form.Item>
                                <Form.Item
                                name={'email'}
                                label={"Email Address"}
                                >
                                    <Input placeholder='Enter email address' />
                                </Form.Item>
                            </div>
                        </div>
                    </EntranceMotion>

                    <EntranceMotion delay={0.3}>
                        <div style={{marginTop:24,}}>
                            <Form.Item
                            name={'remarks'}
                            label={
                                <StepNoItem no={8}
                                text={<span style={{fontSize:14, fontWeight:500, color:'var(--secondary-text-color)'}}>Remarks</span>} 
                                />
                            }
                            >
                            <TextArea placeholder='Enter your remarks' rows={4} style={{resize : 'none'}} />
                            </Form.Item>
                        </div>
                    </EntranceMotion>

                    <div style={{marginTop:24, display:'flex'}}>
                            <div style={{marginLeft:'auto'}}>
                                <Form.Item>
                                    <Space>
                                        <Button onClick={() => history?.goBack()}>
                                            <span>Cancel</span>
                                        </Button>
                                        <Button disabled={(!state?.selected_park_id && !state?.selected_ticket_type_id)} htmlType="submit" type="primary" style={{fontWeight:500, padding:'0 24px'}}>
                                            <span>Checkout</span>
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </div>
                        </div>
                </Form>
            </div>
        </>
    );
}

export default GenerateMaTicketLayout;