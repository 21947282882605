import { Table } from 'antd';
import React, { useEffect } from 'react';

const AddOnTable = ({ add_ons, }) => {
    return (
        <>
            <Table
            scroll={{ x : 400, }}
            size='small'
            pagination={false}
            className='hub-table bordered'
            dataSource={add_ons}
            columns={[{
                title : 'Type',
                dataIndex : 'add_on_type',
                key : 'add_on_type',
                render: (add_on_type, row, index) => {
                    const { name, } = add_on_type ?? {};
                    return (
                        <>
                            <div>
                                <span>{name}</span>
                            </div>
                        </>
                    )
                }
            },{
                title : 'Name',
                dataIndex : 'name',
                key : 'name',
            },{
                title : 'Quantity',
                dataIndex : 'ticket_add_ons',
                key : 'ticket_add_ons',
                render: (ticket_add_ons, row, index) => {
                    const quantity = ticket_add_ons?.reduce((a, b) => (a + b?.quantity), 0)
                    return (
                        <>
                            <div>
                                <span>{quantity}</span>
                            </div>
                        </>
                    )
                }
            }]}
            />
        </>
    );
}

export default AddOnTable;