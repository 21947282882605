import { ArrowDownOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Empty, Space, Table, Tag } from 'antd';
import React from 'react';

const TicketDetailDateChangeList = ({ date_changes, park, }) => {
    return (
        <>
            {
                date_changes?.length > 0 ?
                (
                    <>
                        <Table
                        scroll={{ x : 400, }}   
                        className='hub-table bordered'
                        dataSource={date_changes}
                        pagination={false}
                        columns={[{
                            title : 'Running Number',
                            dataIndex : 'running_number',
                            key : 'running_number',
                        },{
                            title : 'Date',
                            dataIndex : 'initial_date',
                            key : 'initial_date',
                            render : (text, row, index) => {
                                const { initial_date, new_date } = row;
                                return (
                                    <>
                                        <div>
                                            <Space size={2} wrap direction='vertical' align='center'>
                                                <Tag style={{border : 'none',}}>
                                                    <span style={{color : 'var(--secondary-text-color)',}}>
                                                    {initial_date}
                                                    </span>
                                                </Tag>
                                                <ArrowDownOutlined style={{ color : 'var(--secondary-text-color)', }}/>
                                                <Tag style={{border : 'none',}}>
                                                    <span style={{color : 'var(--main-color)',}}>
                                                    {new_date}
                                                    </span>
                                                </Tag>
                                            </Space>
                                        </div>
                                    </>
                                )
                            }
                        },
                        {
                            title : 'Visit Time',
                            dataIndex : 'initial_preference_id',
                            key : 'initial_preference_id',
                            render : (text, row, index) => {
                                const { initial_preference, new_preference, } = row;
                                return (
                                    <>
                                        {
                                            (initial_preference && new_preference) ?
                                            (
                                                <>
                                                    <div>
                                                        <Space size={2} wrap direction='vertical' align='center'>
                                                            <Tag style={{border : 'none',}}>
                                                                <span style={{color : 'var(--secondary-text-color)',}}>
                                                                    {`${initial_preference?.preference_group ? `${initial_preference?.preference_group?.name} -` : ''} ${initial_preference?.name}`}
                                                                </span>
                                                            </Tag>
                                                            <ArrowDownOutlined style={{ color : 'var(--secondary-text-color)', }}/>
                                                            <Tag style={{border : 'none',}}>
                                                                <span style={{color : 'var(--main-color)',}}>
                                                                    {`${new_preference?.preference_group ? `${new_preference?.preference_group?.name} -` : ''} ${new_preference?.name}`}
                                                                </span>
                                                            </Tag>
                                                        </Space>
                                                    </div>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <div>
                                                        <span style={{ fontSize : 12, }}>No preference</span>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }
                        },
                        {
                            title : (
                                <>
                                    <Space>
                                        <div>
                                            <span>Payment Amount</span>
                                        </div>
                                        <div>
                                            <Tag color='blue' style={{border : 'none', }}>
                                                {park?.currency?.code}
                                            </Tag>
                                        </div>
                                    </Space>
                                </>
                            ),
                            dataIndex : 'payment_amount',
                            key : 'payment_amount',
                            fixed : 'right',
                            render : (text, row, index) => {
                                const { payment_status } = row;
                                const { code, symbol } = park?.currency;
                                return (
                                    <>
                                        <Space>
                                            <div>
                                                <span>{`${symbol}${text}`}</span>
                                            </div>
                                            <div>
                                                <Tag color={payment_status?.is_payment_complete ? 'green' : 'orange'}>{payment_status?.name}</Tag>
                                            </div>
                                        </Space>
                                    </>
                                )
                            }
                        },]}
                        />
                    </>
                )
                :
                (
                    <>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </>
                )
            }
        </>
    );
}

export default TicketDetailDateChangeList;