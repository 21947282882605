import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function fetchAddOn(query){
    const queryString = serviceHelper.queryString(query);
    const addOnResponse = await axios.get(`config/add_on/get?${queryString}`, { withCredentials : true });
    return addOnResponse?.data;
}

async function fetchAddOnType(query){
    const queryString = serviceHelper.queryString(query);
    const addOnTypeResponse = await axios.get(`config/add_on/type/get?${queryString}`, { withCredentials : true });
    return addOnTypeResponse?.data;
}

async function saveAddOn(params){
    const { image, name, description, add_on_type_id, } = params ?? {};
    const formData = new FormData();
    formData.append('name', name);
    image && formData.append('image', image);
    description && formData.append("description", description);
    add_on_type_id && formData.append('add_on_type_id', add_on_type_id);

    const saveAddOnResponse = await axios.post(`config/add_on/save`, formData, { withCredentials : true, headers : { "Content-Type": "multipart/form-data" }});
    return saveAddOnResponse?.data;
}

async function updateAddOn(add_on_id, params){
    const { image, name, description, add_on_type_id, } = params ?? {};
    const formData = new FormData();
    formData.append('name', name);
    image && formData.append('image', image);
    description && formData.append("description", description);
    add_on_type_id && formData.append('add_on_type_id', add_on_type_id);

    const updateAddOnResponse = await axios.post(`config/add_on/${add_on_id}/update`, formData, { withCredentials : true, headers : { "Content-Type": "multipart/form-data" }});
    return updateAddOnResponse?.data;
}

async function deleteAddOn(add_on_id){
    const deleteAddOnResponse = await axios.delete(`config/add_on/${add_on_id}/delete`, { withCredentials : true, });
    return deleteAddOnResponse?.data;
}

export default {
    fetchAddOn,
    fetchAddOnType,
    saveAddOn,
    updateAddOn,
    deleteAddOn,
}