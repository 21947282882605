import { Button, Modal, Space, Spin, Table, Tag } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import invoiceService from '../../../services/ticket/invoice.service';
import serviceHelper from '../../../helpers/functions/service.helper';
import { DownloadOutlined, EyeOutlined, FilePdfOutlined, LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import ViewInvoice from './view_invoice';
import moment from 'moment';

const InvoiceList = ({ filter, default_filter, selectRows = () => {} }) => {
    const [state, setState] = useState({
        loading : false,
        invoices : [],

        // pagination
        page : 0,
        count : 0,

        // view invoice
        modal : null,
        download_loading : false,

        selected_rows : [],
        is_select_all : false,
    })

    const invoice_view_running_number = useMemo(() => {
        return state?.invoices?.find(x => x?.invoice_id === state?.modal)?.ticket?.running_number;
    }, [state?.modal])

    const paginateChange = (key, value) => {
        // set state
        setState(state => ({...state, [key] : value,}));

        let generate_filter = default_filter;
        generate_filter[key] = value;
        const queryString = serviceHelper?.queryString(generate_filter);
        window.history.replaceState({}, '', `${window.location.pathname}?${queryString}`);
    }

    // const downloadInvoice = async ({ invoice_id, running_number, }) => {
    //     setState(state => ({...state, download_loading : invoice_id, }));
        
    //     const invoice_name = `tax-invoice-${running_number}`;
    //     const downloadInvoiceResponse = await invoiceService?.downloadInvoice(invoice_id)
    //     .then((response) => {
    //         const url = window.URL.createObjectURL(new Blob([response]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', `${invoice_name}.pdf`); //or any other extension
    //         document.body.appendChild(link);
    //         link.click();
    //     })
    //     .catch(err => setState(state => ({...state, download_loading : null, })))
    //     .finally(() => setState(state => ({...state, download_loading : null, })));
    // }

    const fetchInvoices = async () => {
        setState(state => ({...state, loading : true, }));
        const { parks, start_date, end_date, search, } = filter;

        const invoicesResponse = await invoiceService?.invoiceList({
            page : (state?.page - 1) > 0 ? (state?.page - 1) : 0,
            size : 10,
            order : 'invoice_id',
            order_position : 'DESC',
            park_ids : parks,
            start_date : start_date,
            end_date : end_date,
            search : search,
        })
        const { invoices } = invoicesResponse;
       
        const selected_rows = state?.is_select_all ? [...state?.selected_rows, ...(invoices?.rows?.map(x => x?.invoice_id))] : [...state?.selected_rows];
        setState(state => ({...state, loading : false, invoices : invoices?.rows, count : invoices?.count, selected_rows : selected_rows, }));
    }
    
    useEffect(() => {
        fetchInvoices();
    }, [filter, state?.page])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <Table
                rowSelection={{
                    type : 'checkbox',
                    onChange: (selectedRowKeys, selectedRows, info) => {
                        const is_all = info?.type === 'all' && selectedRows?.length > 0;
                        setState(state => ({...state, selected_rows : selectedRows?.map(x => x?.invoice_id), is_select_all : is_all, }));
                        selectRows(selectedRows, info);
                    },
                    selectedRowKeys : state?.selected_rows,
                }}
                pagination={{
                    // size : state?.count,
                    current : state?.count > 10 ? (state?.page) : 1,
                    total : state?.count,
                    showSizeChanger: false,
                    onChange : (page) => paginateChange('page', page)
                }}
                rowKey={'invoice_id'}
                className='hub-table bordered'
                dataSource={state?.invoices}
                columns={[{
                    title : 'Invoice No',
                    dataIndex : 'ticket',
                    key : 'ticket',
                    render : (ticket, row, index) => {
                        const { running_number, } = ticket;
                        return (
                            <>
                                <span>{`${running_number}`}</span>
                            </>
                        )
                    }
                },{
                    title : 'Transaction',
                    dataIndex : 'ticket',
                    key : 'ticket',
                    render : (ticket, row, index) => {
                        const { unique_no, salt, park, payment_date, ticket_id, } = ticket;
                        return (
                            <>
                                <div>
                                    <div>
                                        <Link to={`/ticket/detail/${ticket_id}`}>
                                            <span>{`${unique_no}${salt ?? ''}`}</span>
                                        </Link>
                                    </div>
                                    <div>
                                        <span>{park?.name}</span>
                                    </div>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Payment Date',
                    dataIndex : 'ticket',
                    key : 'ticket',
                    render : (ticket, row, index) => {
                        return (
                            <>
                                <span>{moment(ticket?.payment_date)?.format("YYYY-MM-DD hh:mm:ssa")}</span>
                            </>
                        )
                    }
                },{
                    title : 'Invoice File',
                    dataIndex : 'invoice_file',
                    key : 'invoice_file',
                    fixed : 'right',
                    width : '25%',
                    render : (invoice_file, row, index) => {
                        const { ticket, invoice_id, } = row;
                        return (
                            <>
                                <Button onClick={() => setState(state => ({...state, modal : invoice_id, }))} icon={<FilePdfOutlined style={{ color : '#1677ff', }} />}>
                                    <span style={{ color : 'var(--secondary-text-color)', }}>{`tax-invoice-${ticket?.running_number}.pdf`}</span>
                                </Button>  
                                {/* <Tag onClick={() => setState(state => ({...state, modal : invoice_id, }))} bordered={false} icon={<FilePdfOutlined style={{ color : '#1677ff', }} />}>
                                    <span style={{ color : 'var(--secondary-text-color)', }}>{`tax-invoice-${ticket?.running_number}.pdf`}</span>
                                </Tag> */}
                            </>
                        )
                    }
                },
                // {
                //     title : 'Action',
                //     dataIndex : 'invoice_id',
                //     key : 'invoice_id',
                //     render : (invoice_id, row, index) => {
                //         const { ticket } = row;
                //         return (
                //             <>
                //                 <Button.Group>
                //                     <Button onClick={() => setState(state => ({...state, modal : invoice_id, }))} size='small' icon={<EyeOutlined style={{ color : 'var(--secondary-text-color)', }} />} />
                //                     <Button loading={(state?.download_loading === invoice_id)} onClick={() => downloadInvoice({ invoice_id, running_number : ticket?.running_number, })} size='small' icon={<DownloadOutlined style={{ color : 'var(--secondary-text-color)', }} />} />
                //                 </Button.Group>
                //             </>
                //         )
                //     }
                // },
                ]}
                />
            </Spin>

            <Modal
            open={state?.modal}
            onCancel={() => setState(state => ({...state, modal : null, }))}
            width={800}
            title={invoice_view_running_number}
            footer={null}
            style={{ top : 20, }}
            >
                <ViewInvoice invoice_id={state?.modal} running_number={invoice_view_running_number} />
            </Modal>
        </>
    );
}

export default InvoiceList;