import React, { useEffect, useState } from 'react';
import otherService from '../../../services/config/other.service';
import { Card, Col, Row } from 'antd';

const GenerateTicketModal = ({parentState, setParentState}) => {
    const [state, setState] = useState({
        loading : false,
        purchase_types : [],
    });
    
    const fetchPurchaseTypes = async () => {
        setState(state => ({...state, loading : true}));
        const purchaseTypeResponse = await otherService.getPurchaseTypes({
            is_online : false,
            is_role_based : true,
        });

        setState(state => ({...state, loading : false, purchase_types : purchaseTypeResponse?.purchase_types}));
    }

    const selectPurchaseType = (purchase_type_id) => {
        setParentState(parentState => ({...parentState, selected_purchase_type : purchase_type_id}));
    }

    useEffect(() => {
        fetchPurchaseTypes();
    }, []);

    return (
        <>
            <div>
                {
                    state?.purchase_types?.length > 0 ?
                    (
                        <>
                            <div>
                                <span style={{color:'var(--secondary-text-color)'}}>Choose what purchase types of ticket that you want to generate</span>
                            </div>
                            <div style={{marginTop:24,}}>
                                <Row gutter={[20, 20]}>
                                {
                                    state?.purchase_types?.map((purchase_type, index) => {
                                        const { code, name, is_open_price, is_online, purchase_type_id, } = purchase_type ?? {};
                                        const is_selected = parentState?.selected_purchase_type?.purchase_type_id === purchase_type_id;
                                        return (
                                            <Col md={12} xl={12} span={12}>
                                                <Card 
                                                onClick={() => selectPurchaseType(purchase_type)}
                                                className={`${is_selected ? 'card-shadow' : ''}`}
                                                style={{
                                                    cursor : 'pointer',
                                                    borderWidth:2,
                                                    ...(is_selected? ({ borderColor : 'var(--main-color)',}) : ({ borderColor : '#E9EAEF' })) 
                                                }}
                                                >
                                                    <div style={{textAlign:'center',}}>
                                                        <span style={{
                                                            ...(!is_selected && ({color:'var(--secondary-text-color)'}))
                                                        }}
                                                        >
                                                            {name}
                                                        </span>
                                                    </div>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                }
                                </Row>
                            </div>
                        </>
                    )
                    :
                    (
                        <>
                            <div style={{textAlign:'center',}}>
                                <span style={{color:'var(--secondary-text-color)'}}>No Purchase Types Found</span>
                            </div>
                        </>
                    )
                }
                
            </div>
        </>
    );
}

export default GenerateTicketModal;