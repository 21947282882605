import { AppstoreOutlined, LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Avatar, Button, Checkbox, Col, Divider, Form, Input, InputNumber, Row, Select, Space, Spin, Switch } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import parkService from '../../../services/config/park.service';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import otherService from '../../../services/config/other.service';
import { Link } from 'react-router-dom';

const { TextArea } = Input;
const AddTicketGroupModal = ({ form, ticket_type, park_applicable_ticket_users, modal_visible, }) => {
    const [state, setState] = useState({
        loading : false,
        ticket_user_types : [],
        purchase_types : [],
    });

    const fetchTicketUserTypes = async () => {
        const ticketUserTypeResponse = await parkService?.getTicketUserType();
        const { ticket_user_types } = ticketUserTypeResponse;
       
        return ticket_user_types;
    }

    const fetchPurchaseTypes = async () => {
        const purchaseTypeResponse = await otherService?.getPurchaseTypes();
        const { purchase_types, } = purchaseTypeResponse;
        return purchase_types;
    }

    const initialize = async () => {
        const ticket_user_types = await fetchTicketUserTypes();
        const purchase_types = await fetchPurchaseTypes();

        setState(state => ({...state, ticket_user_types : ticket_user_types, purchase_types : purchase_types, }));
        form.setFieldsValue({ purchase_type_ticket_applicable_users : purchase_types?.map(x => x?.purchase_type_id), is_fixed_price : false, });
    }

    useEffect(() => {
        initialize();
    }, [ticket_type?.ticket_type_id, modal_visible])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <div>
                    <Divider orientation='left' orientationMargin="0"><PageHeaderComponent title={<span style={{fontSize:14,}}>Ticket Detail</span>} textAlign={'start'} /></Divider>
                    <div>
                        <Form.Item label={"Ticket"} name={"ticket_user_type_id"}>
                            <Select
                            showSearch
                            optionFilterProp="label2"
                            options={state?.ticket_user_types?.map(x => ({ label : (
                                <>
                                    <div style={{ display : 'flex', alignItems : 'center', width : '100%', }}>
                                        <div>
                                            <div>
                                                <span>{x?.name}</span>
                                            </div>
                                            {/* <div>
                                                <span style={{ color : 'var(--secondary-text-color)', fontSize : 12, }}>{x?.descriptions}</span>
                                            </div> */}
                                        </div>
                                        <div style={{ marginLeft : 'auto', }}>
                                            <span style={{ color : 'var(--secondary-text-color)', fontSize : 12, }}>
                                                {x?.min_age != null ?
                                                (
                                                    x?.max_age ?
                                                    (
                                                        `${x?.min_age} - ${x?.max_age}`
                                                    )
                                                    :
                                                    (
                                                        `${x?.min_age} - 100+`
                                                    )
                                                ) : ""}
                                            </span>
                                        </div>
                                    </div>
                                </>
                            ),
                            disabled : park_applicable_ticket_users?.find(park_applicable_ticket_user => park_applicable_ticket_user?.ticket_user_type_id === x?.ticket_user_type_id),
                            label2 : x?.name,
                            value : x?.ticket_user_type_id,
                            min_age : x?.min_age,
                            max_age : x?.max_age,
                            descriptions : x?.descriptions,
                            }))}
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <div>
                                        <Divider style={{ marginBlock : 8, }} />
                                        <div style={{ padding : 8, }}>
                                            <Link to="/config/ticket_user_type">
                                                <Button icon={<PlusCircleOutlined />}>Ticket</Button>
                                            </Link>
                                        </div>
                                    </div>
                                </>
                            )}
                            optionLabelProp='label2'
                            />
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item label="Ticket Type">
                            <Space>
                                <div>
                                    <Avatar icon={<AppstoreOutlined />} shape='square' size='small' src={ticket_type?.ParkApplicableTicketType?.image} />
                                </div>
                                <div>
                                    <span>{ticket_type?.name}</span>
                                </div>
                            </Space>
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item label={"Description"} name={"descriptions"}>
                            <TextArea placeholder='Enter descriptions' rows={4} style={{ resize : 'none', }} />
                        </Form.Item>
                    </div>
                </div>
                <div>
                    <Divider orientation='left' orientationMargin="0"><PageHeaderComponent title={<span style={{fontSize:14,}}>Purchase Detail</span>} textAlign={'start'} /></Divider>
                    <div>
                        <Row gutter={12}>
                            <Col span={12} xs={24} md={12}>
                                <Form.Item label={"Price"} name={"price"}>
                                    <InputNumber min={0} addonBefore={"MYR"} style={{ width : '100%', }} precision={2} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={"Purchase Types"} name={"purchase_type_ticket_applicable_users"}>
                                    <Checkbox.Group options={state?.purchase_types?.map((purchase_type, index) => {
                                        const { purchase_type_id, name, } = purchase_type ?? {};
                                        return {
                                            label : name,
                                            value : purchase_type_id,
                                        }
                                    })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12} xs={24} md={12}>
                                <Form.Item label={"Minimum Purchase"} name={"min_purchase"}>
                                    <InputNumber min={0} style={{ width : '100%', }} precision={0} />
                                </Form.Item>
                            </Col>
                            <Col span={12} xs={24} md={12}>
                                <Form.Item extra={(
                                    <span style={{fontSize:12, color : 'var(--secondary-text-color)',}}>If this is switch on, advance and peak day price discount will be ignored</span>
                                )} label={"Is Fixed Price"} name={"is_fixed_price"}>
                                    <Switch />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>

            {/* for school groups purposes */}
            {/* <Form.Item label={(
                <>
                    <div>
                        <div>
                            <span>Free Tickets</span>
                        </div>
                        <div>
                            <span style={{fontSize:12, color : 'var(--secondary-text-color)',}}>Customers eligble to get free tickets by purchasing this ticket group</span>
                        </div>
                    </div>
                </>
            )} name={"free_tickets"} extra={""}>
            </Form.Item> */}

            {/* for at least one adult OR one kid */}
            {/* <Form.Item label={"Required Tickets"} name={"required_tickets"}>
            </Form.Item> */}
            
            
        </>
    );
}

export default AddTicketGroupModal;