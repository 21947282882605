import React, { useEffect, useState } from 'react';
import ticketService from '../../../services/ticket/ticket.service';
import DateCalendar from './date_calendar';
import { Alert, Avatar, Button, Col, Divider, Empty, Form, Input, Row, Select, Skeleton, Space, Spin, Tag, notification } from 'antd';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import FieldDynamicItem from './field_dynamic_item';
import { useDispatch, useSelector } from 'react-redux';
import integrationService from '../../../services/integration/integration.service';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import kidzoftService from '../../../services/integration/kidzoft/kidzoft.service';
import { Link } from 'react-router-dom';
import { refresh_ticket_detail } from '../../../actions/ticket.action';
// import integrationService from '../../../services/integration/integration.service';

const FieldLayout = ({ ticket_user_no, }) => {
    const dispatch = useDispatch();
    const ticketRedux = useSelector(state => (state.ticket));
    const history = useHistory();
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        ticket_user : null,
        ticket : null,
        is_checked_in : false,
        save_loading : false,
        integrations : [],
        ticket_user_fields : [],
    })
    const [option, setOption] = useState({
        age : [],
    });
    const [fields, setField] = useState([]);

    const getTicketUser = async () => {
        setState(state => ({...state, loading : true, }));
        const ticketUserResponse = await ticketService.getTicketUser({
            ticket_user_no,
        });

        const { ticket_users } = ticketUserResponse;
        const ticket_user = ticket_users?.rows?.length > 0 ? ticket_users?.rows[0] : null;
        const is_checked_in = ticket_user?.entrance_transactions?.length > 0 ? true : false;
        const { name, gender, age, ticket_user_type, ticket_user_fields, } = ticket_user;
        //set option
        const max_age = (ticket_user_type?.max_age ?? 100);
        const min_age = (ticket_user_type?.min_age ?? 0);
        let age_options = [];
        for(let i = min_age; i <= max_age; i++){
            age_options?.push({ value : i, label : i });
        }
        setOption(option => ({...option, age : age_options}));

        const dynamic_fields = {};
        // Iterate through the array and destructure the values
        for (const field of ticket_user_fields) {
            const { key, FieldResponse : field_response } = field;
            dynamic_fields[key] = field_response?.response;
        }

        form.setFieldsValue({
            name, gender, age,
            ...dynamic_fields,
        })

        // get fields
        const get_fields = await fetchFields();
        const get_integrations = await fetchIntegrations(ticket_user?.ticket?.park_id);
        setState(state => ({...state, 
            loading : false,
            ticket_user : ticket_user,
            ticket : ticket_user?.ticket,
            is_checked_in : is_checked_in,
            integrations : get_integrations,
            ticket_user_fields : ticket_user_fields,
        }));
        setField(get_fields);
    }

    const fetchFields = async () => {
        const fieldResponse = await ticketService?.getTicketField(ticket_user_no);
        const { ticket_user_fields } = fieldResponse;
        return ticket_user_fields;
    }

    const fetchIntegrations = async (park_id) => {
        const integrationResponse = await integrationService?.getParkIntegrations(park_id);
        const { integrations } = integrationResponse;
        return integrations;
    }

    const updateTicketUser = async () => {
        setState(state => ({...state, save_loading : true, }));
        // Create an object to store the destructured values
        const dynamic_fields = {};
        // Iterate through the array and destructure the values
        for (const field of fields) {
            const { key } = field;
            dynamic_fields[key] = form.getFieldsValue()[key];
        }

        form.setFieldsValue({...dynamic_fields});
        const { age, gender, name, } = form.getFieldsValue();
        const updateTicketUserResponse = await ticketService.updateTicketUser(ticket_user_no, {
            name, age, gender,
            ...dynamic_fields,
        }, fields).catch(err => {
            setState(state => ({...state, save_loading : false,}));
        }).then(async (result) => {
            // link pazzport
            const results = await integrationUpdate(state?.integrations);
        });

        // success update
        notification?.success({
            message : `Ticket ${ticket_user_no} Updated`,
        });

        setState(state => ({...state, save_loading : false, }));
        // redux refresh
        dispatch(refresh_ticket_detail());
    }

    const integrationUpdate = async (integrations) => {
        let results = [];
        for(const integration of integrations){
            let result;
            const { condition, api_type, } = integration;
            if(condition === 'LINK_PAZZPORT'){ // link bracelet
                switch(api_type?.fn_code){
                    case 'KIDZOFT_ACT' :
                        // link pazzport
                        // first, get FETCH_PAZZPORT api type
                        const get_pazzport_condition = integrations?.find(x => x.condition === 'FETCH_PAZZPORT');
                        // 2nd, get the response of the field of FETCH_PAZZPORT
                        const ticket_user_field = state?.ticket_user_fields?.find(x => x.park_api_type_id === get_pazzport_condition?.park_api_type_id);
                        const { response : integrated_pazzport } = (ticket_user_field?.FieldResponse) ?? {};
                        // then get FETCH_PAZZPORT key of field, which is pazzport
                        const field_key = fields?.find(x => x.park_api_type_id === get_pazzport_condition?.park_api_type_id);
                        const field_pazzport = form.getFieldsValue()[field_key?.key];

                        // lastly, check whether is response key
                        if(!integrated_pazzport && field_pazzport){
                            const ticket_user_id = (state?.ticket_user?.ticket_user_id);
                            const linkPazzportResponse = await kidzoftService?.linkPazzport({
                                ticket_user_id, 
                            });
                            result = linkPazzportResponse;
                            const { linkPazzportIntegration } = linkPazzportResponse;
                            const { status, name, response } = linkPazzportIntegration ?? {};
                            if(status === 1){
                                // success
                                notification?.success({
                                    message : `Pazzport Linked`,
                                    description : (
                                        <>
                                            <div>
                                                <Alert type='success' message={response} />
                                                <div>
                                                    <div>
                                                        <a style={{color:'var(--main-color)'}} onClick={() => history.push(`/ticket/detail/${state?.ticket_user?.ticket_id}`)}>
                                                            <span>{state?.ticket?.unique_no}</span>
                                                        </a>
                                                    </div>
                                                    <div>
                                                        <span>{`Please check log for integration status`}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ),
                                });
                            }else{
                                notification?.error({
                                    message : name,
                                    description : (
                                        <>
                                            <div>
                                                <Alert type='error' message={response} />
                                                <div>
                                                    <div>
                                                        <a style={{color:'var(--main-color)'}} onClick={() => history.push(`/ticket/detail/${state?.ticket_user?.ticket_id}`)}>
                                                            <span>{state?.ticket?.unique_no}</span>
                                                        </a>
                                                    </div>
                                                    <div>
                                                        <span>{`Please check log for integration status`}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ),
                                });
                            }
                        }
                        break;
                    default :
                        result = null;
                        break;
                }
            }
            results.push(result);
        }

        return results;
    }

    const initialize = async () => {
        const ticket_user = await getTicketUser();
    }

    useEffect(() => {
        initialize();
    }, [ticketRedux?.refresh])
    return (
        <>
            <div className='mobile-padding-12'>
                <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                    <div>
                        <div style={{display:'flex', alignItems:'center',}}>
                            <Space style={{justifyContent:'start', width:'100%'}} size={12}>
                                <div>
                                    <DateCalendar date={state?.ticket_user?.open_date} is_open_date={state?.ticket?.is_open_date} />
                                </div>
                                <div style={{textAlign : 'left'}}>
                                    <div>
                                        <PageHeaderComponent title={state?.ticket?.park?.name} />
                                    </div>
                                    <div>
                                        <span style={{color:'#6f7287', fontWeight:500}}>
                                            <Space wrap size={2}>
                                                <span>Ticket No:</span>
                                                <span>{state?.ticket_user?.ticket_user_no}</span>
                                            </Space>
                                        </span>
                                    </div>
                                    <div>
                                        <span style={{color:'#6f7287', fontWeight:500}}>
                                            <Space wrap size={2}>
                                                <span>Ticket Type:</span>
                                                <span>
                                                    {`${state?.ticket_user?.ticket_user_type?.name} 
                                                    (${
                                                        `Age ${state?.ticket_user?.ticket_user_type?.max_age ? 
                                                            `${state?.ticket_user?.ticket_user_type?.min_age} - ${state?.ticket_user?.ticket_user_type?.max_age}` :
                                                            `${state?.ticket_user?.ticket_user_type?.min_age} and above`
                                                    }`
                                                    })
                                                    `}
                                                </span>
                                            </Space> 
                                        </span>
                                    </div>
                                </div>
                            </Space>
                            <div style={{marginLeft:'auto'}}>
                                <Space>
                                    <div>
                                        <Avatar size={'large'} src={state?.ticket_user?.image_url} icon={<UserOutlined />} />
                                    </div>
                                    <div>
                                    {
                                        state?.is_checked_in &&
                                        (
                                            <>
                                                <Tag color='var(--secondary-button-color)' style={{ border : 'none', color : 'var(--secondary-text-color)', }}>
                                                    Checked In
                                                </Tag>
                                            </>
                                        )
                                    }
                                    </div>
                                </Space>
                            </div>
                        </div>
                        <Divider />

                        {
                            (state?.ticket_user?.entrance_transactions?.length > 0) ?
                            (
                                <>
                                    <Form
                                    onFinish={updateTicketUser}
                                    id="personal-detail-form"
                                    layout='vertical'
                                    form={form}
                                    >
                                        {/* User Detail Field */}  
                                        <div>
                                            <div>
                                                <PageHeaderComponent title={<span style={{fontSize:16,}}>Personal Detail</span>} textAlign={'start'} />
                                            </div>
                                            
                                            <div style={{marginTop:12,}}>
                                                {
                                                    (state?.ticket?.park?.is_user_detail_required) ?
                                                    (
                                                        <>
                                                            <Row gutter={[12, 12]}>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                    name={'name'}
                                                                    required={false}
                                                                    className='ticket_user-form-item'
                                                                    label={`Name`}
                                                                    >
                                                                        <Input placeholder='John Doe' />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                    name={'age'}
                                                                    required={false}
                                                                    className='ticket_user-form-item'
                                                                    label={`Age`}
                                                                    >
                                                                        <Select
                                                                        placeholder={`${state?.ticket_user?.ticket_user_type?.min_age} - ${state?.ticket_user?.ticket_user_type?.max_age ?? "100+"}`}
                                                                        options={option?.age}
                                                                        >
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                    name={'gender'}
                                                                    className='ticket_user-form-item'
                                                                    label={`Gender`}
                                                                    >
                                                                        <Select
                                                                        placeholder="Male"
                                                                        options={[
                                                                            {value : 'M', label : 'Male'},
                                                                            {value : 'F', label : 'Female'}
                                                                        ]}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`User detail is not required for ${state?.ticket?.park?.name}`} />
                                                        </>
                                                    )
                                                }
                                                
                                            </div>
                                        </div>
                                        <Divider />
                                        {/* Dynamic Field */}
                                        <div>
                                            <div>
                                                <PageHeaderComponent title={<span style={{fontSize:16,}}>Additional Field</span>} textAlign={'start'} />
                                            </div>
                                            {
                                                fields?.length > 0 ?
                                                (
                                                    <>
                                                        <FieldDynamicItem ticket_user={state?.ticket_user} form={form} fields={fields} ticket_user_fields={state?.ticket_user_fields} />
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`There is no additional field for ${state?.ticket?.park?.name}`} />
                                                    </>
                                                )
                                            }
                                        
                                        </div>
                                        <div style={{marginTop:12,}}>
                                            <div style={{display : 'flex', alignItems:'center'}}>
                                                <div style={{marginLeft:'auto',}}>
                                                    <Space>
                                                        <Button onClick={() => history.goBack()}>Back</Button>
                                                        <Button
                                                        onClick={() => form.submit()}
                                                        disabled={!ticketRedux?.is_editable}
                                                        type='primary'
                                                        loading={state?.save_loading}
                                                        >
                                                            Save
                                                        </Button>
                                                    </Space>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </>
                            )
                            :
                            (
                                <>
                                    <div>
                                        <span style={{fontSize:12, color : 'var(--secondary-text-color)',}}>
                                            This ticket is not checked in, please edit detail on <a target='_blank' href={`${state?.ticket?.park?.base_url}ticket/user/${ticket_user_no}`}>ticket page</a>.
                                        </span>
                                    </div>
                                    <div style={{marginTop:12,}}>
                                        <Button onClick={() => history.goBack()}>Back</Button>
                                    </div>
                                </>
                            )
                        }
                        
                    </div>
                </Spin>
            </div>
        </>
    );
}

export default FieldLayout;