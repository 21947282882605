import React, { useEffect, useState } from 'react';
import CollectionFaceList from './collection_face_list';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import otherService from '../../../services/config/other.service';
import { Tabs } from 'antd';

const DetailLayout = ({ collection_id }) => {
    const [state, setState] = useState({
        loading : false,
        tab_items : [],
        selected_tab : 'ticket',
    })
    const fetchPassType = async () => {
        setState(state => ({...state, loading : true, }));
        const passTypeResponse = await otherService?.getPassType();

        const { pass_types } = passTypeResponse;
        let tab_items = [...pass_types, {
            name : 'Ticket',
            prefix : 'ticket',
        },{
            name : 'Account',
            prefix : 'account',
        }];
        setState(state => ({...state, loading : false, tab_items : tab_items, }));
    }

    useEffect(() => {
        fetchPassType();
    }, [])

    return (
        <>
            <div className='mobile-padding-12'>
                <div style={{textAlign:'start',}}>
                    <PageHeaderComponent title={collection_id} isBack />
                </div>
                <div style={{marginTop:24,}}>
                    <Tabs
                    activeKey={state?.selected_tab}
                    onChange={(e) => setState(state => ({...state, selected_tab : e}))}
                    items={state?.tab_items?.map((pass_type, index) => {
                        const { prefix, name } = pass_type;
                        return {
                            key : prefix,
                            label : name,
                            children : <CollectionFaceList collection_id={collection_id} pass_type={prefix} />,
                        }
                    })}
                    />
                </div>
            </div>
        </>
    );
}

export default DetailLayout;