import React, { useState } from 'react';
import ContainerComponent from '../../helpers/components/container.component';
import InvoiceLayout from './components/invoice_layout';
import './invoice.css';
import { useLocation } from 'react-router-dom';

const InvoiceIndex = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [filter, setFilter] = useState({
        page : queryParams.get("page"),
        parks : queryParams.get("parks")?.split(',')?.map(Number)?.filter(x => x != 0),
        start_date : queryParams.get("start_date"),
        end_date : queryParams.get("end_date"),
    })

    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <InvoiceLayout default_filter={filter} />
            </ContainerComponent>
        </>
    );
}

export default InvoiceIndex;