import React, { useEffect, useState } from 'react';
import ticketService from '../../../services/ticket/ticket.service';
import { Avatar, Button, Dropdown, List, Pagination, Space, Spin } from 'antd';
import { CaretDownFilled, EditOutlined, EllipsisOutlined, LinkOutlined, LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const TicketDetailTicketTypeUserList = ({ ticket_id, ticket_user_type_id, park }) => {
    const [state, setState] = useState({
        loading : false,
        ticket_users : [],
        count : null,
        page : 1,
    })
    const fetchTicketUsers = async () => {
        setState(state => ({...state, loading : true,}));
        const ticketUserResponse = await ticketService?.getTicketUser({
            ticket_id, ticket_user_type_id, page : (state?.page - 1), size : 10,
        });

        const { ticket_users } = ticketUserResponse;
        setState(state => ({...state, loading : false, ticket_users : ticket_users?.rows, count : ticket_users?.count }));
    }
    
    useEffect(() => {
        fetchTicketUsers();
    }, [state?.page])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <List 
                dataSource={state?.ticket_users}
                renderItem={(item, index) => {
                    const { base_url } = park;
                    const { ticket_user_no, image_url, } = item ?? {};
                    const face_image = image_url ?? "/banners/empty-user.png";
                    return (
                        <List.Item style={{width:'100%'}} key={index}>
                            <div style={{display:'flex', width:'100%'}}>
                                <div>
                                    <Space>
                                        <div>
                                            <Avatar src={face_image}/>
                                        </div>
                                        <div>
                                            <span>{ticket_user_no}</span>
                                        </div>
                                    </Space>
                                </div>

                                <div style={{marginLeft:'auto'}}>
                                    <div>
                                    <Dropdown
                                    trigger={['click']}
                                    placement='bottomRight'
                                    menu={{ items : [{
                                        key : '1',
                                        icon : <LinkOutlined />,
                                        label : (
                                            <>
                                                <a target='_blank' href={`${base_url}ticket/user/${ticket_user_no}`}>
                                                    Ticket
                                                </a>
                                            </>
                                        ),
                                    },{
                                        key : '2',
                                        icon : <LinkOutlined />,
                                        label : (
                                            <>
                                                <Link to={`/ticket/user/field/${ticket_user_no}`}>
                                                    <span>Edit Detail</span>
                                                </Link>
                                            </>
                                        ),
                                    }]}}
                                    >
                                        <Button icon={<CaretDownFilled style={{color : 'var(--secondary-text-color)'}} />} >Action</Button>
                                    </Dropdown>
                                        {/* <a target='_blank' href={`${base_url}ticket/user/${ticket_user_no}`}>
                                            <Button icon={} />
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                        </List.Item>
                    )
                }}
                />

                {/* pagination */}
                <div style={{marginTop:24}}>
                    <Pagination 
                    showSizeChanger={false} 
                    pageSize={10} 
                    onChange={(page) => setState(state => ({...state, page : page, }))}
                    current={state?.page}
                    total={state?.count} 
                    />
                </div>
            </Spin>
        </>
    );
}

export default TicketDetailTicketTypeUserList;