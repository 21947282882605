import React, { useEffect, useState } from 'react';
import affiliateService from '../../../services/config/affiliate.service';
import { Avatar, Button, Dropdown, Form, Modal, Popconfirm, Space, Spin, Table, Tag, Timeline, notification } from 'antd';
import moment from 'moment';
import { CheckCircleFilled, ClockCircleFilled, DeleteOutlined, EditOutlined, EllipsisOutlined, LoadingOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import globalHelper from '../../../helpers/functions/global.helper';
import AffiliateEdit from './affiliate_edit';

const AffiliateList = ({ filter, refresh, }) => {
    const [form] = Form.useForm();
    const [timer, setTimer] = useState(null);
    const [state, setState] = useState({
        loading : false,
        affiliates : [],

        open_modal_affiliate_id : null,
        refresh : 0,

        // delete
        action_loading : false,
    })

    const fetchAffiliate = async () => {
        setState(state => ({...state, loading : true,}));
        const { search, } = filter;
        const affiliateResponse = await affiliateService?.fetchAffiliate({
            search,
        });

        const { affiliates } = affiliateResponse;
        setState(state => ({...state, loading : false, affiliates : affiliates, }));
    }

    const updateAffiliate = async (affiliate_account_id) => {
        const { email, is_active } = form?.getFieldsValue();
        const updateAffiliateResponse = await affiliateService?.updateAffiliateAccount(affiliate_account_id, {
            email, is_active,
        })

        // notification
        notification?.success({
            message : 'Success updated affiliate',
            description : `${email}`
        })
        setState(state => ({...state, refresh : (state?.refresh + 1), open_modal_affiliate_id : null,}));
    }

    const resendEmail = async (affiliate_account_id, email) => {
        const resendEmailResponse = await affiliateService?.resendInvitationEmail(affiliate_account_id);    

        // notification
        notification?.success({
            message : 'Success resend email',
            description : `${email}`
        })
    }

    const deleteAffiliate = async (affiliate_account_id, email) => {
        setState(state => ({...state, action_loading : true, }));
        const deleteAffiliateResponse = await affiliateService?.deleteAffiliateAccount(affiliate_account_id);

        // notification
        notification?.success({
            message : 'Success delete affiliate',
            description : `${email}`
        })

        setState(state => ({...state, action_loading : false, refresh : (state?.refresh + 1) }));
    }

    useEffect(() => {
        fetchAffiliate();
    }, [filter, refresh, state?.refresh])
    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <Table 
                scroll={{ x : 400, }}
                className='hub-table bordered'
                dataSource={state?.affiliates}
                columns={[{
                    title : 'Affiliate',
                    dataIndex : 'username',
                    key : 'username',
                    render : (text, row, index) => {
                        const { face_image, full_name, email } = row;
                        const generated_color = globalHelper?.generateFixedColorFromString(email);
                        return (
                            <>
                                <Space>
                                    <div>
                                        <Avatar style={{ backgroundColor: generated_color }} icon={<UserOutlined />} />
                                    </div>
                                    <div>
                                        <div>
                                            <span>{text}</span>
                                        </div>
                                        <div>
                                            <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{full_name}</span>
                                        </div>
                                    </div>
                                </Space>
                            </>
                        )
                    }
                },{
                    title : 'Email',
                    dataIndex : 'email',
                    key : 'email',
                    render : (email, row, index) => {
                        return (
                            <>
                                <div>
                                    <span>{email}</span>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Status',
                    dataIndex : 'is_active',
                    key : 'is_active',
                    render : (is_active, row, index) => {
                        const activation = is_active ? 'Active' : 'Deactivated';
                        const color = is_active ? 'green' : 'orange';
                        return (
                            <>
                                <Tag color={color} style={{border:'none', borderRadius:6,}}>
                                    {activation}
                                </Tag>
                            </>
                        )
                    }
                },{
                    title : 'Activity',
                    dataIndex : 'created_at',
                    key : 'created_at',
                    render : (created_at, row, index) => {
                        const { registered_at } = row;
                        const created_date = moment(created_at)?.format("YYYY-MM-DD hh:mm:ssa");
                        const registered_date = moment(registered_at)?.format("YYYY-MM-DD hh:mm:ssa");
                        return (
                            <>
                                <div>
                                    <div>
                                        <span style={{color:'var(--secondary-text-color)', fontSize:12, }}>Created on {created_date}</span>
                                    </div>
                                    <div>
                                        <div>
                                        {
                                            registered_at ?
                                            (
                                                <>
                                                    <Space>
                                                        <div>
                                                            <span style={{color:'var(--secondary-text-color)', fontSize:12, }}>Registered on {moment(registered_at)?.format("YYYY-MM-DD hh:mm:ssa")}</span>
                                                        </div>
                                                        <div>
                                                            <CheckCircleFilled style={{color:'#95de64'}}/>
                                                        </div>
                                                    </Space>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <Space>
                                                        <div>
                                                            <span style={{color:'var(--secondary-text-color)', fontSize:12, }}>Pending Registration</span>
                                                        </div>
                                                        <div>
                                                            <ClockCircleFilled style={{color:'#ffc069'}}/>
                                                        </div>
                                                    </Space>
                                                </>
                                            )
                                        }
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                },{
                    title : 'Action',
                    dataIndex : 'affiliate_account_id',
                    key : 'affiliate_account_id',
                    width : '10%',
                    render : (affiliate_account_id, row, index) => {
                        const { email, promotion_count } = row;
                        return (
                            <>
                                <Button.Group>
                                    <Dropdown
                                    placement='bottomRight'
                                    menu={{ items : [{
                                        key : '1',
                                        icon : <EditOutlined />,
                                        label : (
                                            <>
                                                Edit
                                            </>
                                        ),
                                        onClick(){
                                            setState(state => ({...state, open_modal_affiliate_id : affiliate_account_id,}));
                                        }
                                    },{
                                        key : '2',
                                        icon : <MailOutlined />,
                                        label : (
                                            <>
                                                Resend Email
                                            </>
                                        ),
                                        onClick : async () => {
                                            const send_email = await resendEmail(affiliate_account_id, email);
                                        }
                                    },]}}
                                    >
                                        <Button
                                        // size='small'
                                        icon={<EllipsisOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                        // onClick={() => openEditModal(account_id)}
                                        />
                                    </Dropdown>

                                    <Popconfirm
                                    onConfirm={() => deleteAffiliate(affiliate_account_id, email)}
                                    title="Delete Affiliate"
                                    description={email}
                                    overlayStyle={{ width : 250,}}
                                    okButtonProps={{ disabled : promotion_count > 0 }}
                                    >
                                        <Button
                                        disabled={promotion_count > 0}
                                        icon={<DeleteOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                        />
                                    </Popconfirm>
                                </Button.Group>
                            </>
                        )
                    }
                },]}
                />
            </Spin>

            <Modal
            title="Edit Affiliate"
            open={state?.open_modal_affiliate_id}
            onCancel={() => setState(state => ({...state, open_modal_affiliate_id : null,}))}
            onOk={() => form.submit()}
            >
                <Form
                layout='vertical'
                form={form}
                onFinish={() => updateAffiliate(state?.open_modal_affiliate_id)}
                >
                    <AffiliateEdit form={form} affiliate_account_id={state?.open_modal_affiliate_id}/>
                </Form>
            </Modal>
        </>
    );
}

export default AffiliateList;