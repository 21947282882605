import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ContainerComponent from '../../helpers/components/container.component';
import DashboardLayout from './dashboards/dashboard_layout';
import { useLocation } from 'react-router-dom';

const PromoDashboardIndex = () => {
    const { promotion_code_id } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [filter, setFilter] = useState({
        start_date : queryParams.get("start_date"),
        end_date : queryParams.get("end_date"),
    })
    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <DashboardLayout promotion_code_id={promotion_code_id} default_filter={filter} />
            </ContainerComponent>
        </>
    );
}

export default PromoDashboardIndex;