import { Space } from 'antd';
import React from 'react';

const StepNoItem = ({no, text}) => {
    return (
        <>
            <Space>
                <div className='step-no'>
                    <span className='ticket-label'>{no}</span>
                </div>
                <div className='step-label'>
                    <span className='ticket-label'>{text}</span>
                </div>
            </Space>
        
        </>
    );
}

export default StepNoItem;