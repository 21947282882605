import { Avatar, Col, Divider, Form, Input, InputNumber, Row, Select, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import parkService from '../../../services/config/park.service';
import { AppstoreOutlined, LoadingOutlined } from '@ant-design/icons';

const EditFreeTicketRuleModal = ({ form, park, free_ticket_rule_id, modal_visible, }) => {
    const [state, setState] = useState({
        loading : false,
        free_ticket_rule : null,
        ticket_applicable_users : [],

        selected_free_ticket_applicable_user_id : null,
        park_applicable_ticket_type : null,
    })

    const fetchFreeTicketRules = async () => {
        setState(state => ({...state, loading : true, }));
        const freeTicketRuleResponse = await parkService?.getFreeTicketRule({
            free_ticket_rule_id : free_ticket_rule_id,
        })
        const { free_ticket_rules } = freeTicketRuleResponse;
        const free_ticket_rule = free_ticket_rules?.[0];

        // fetch applicable users
        const park_applicable_ticket_type_id = (free_ticket_rule?.free_ticket_applicable_users?.park_applicable_ticket_type_id);
        const fetchParkApplicableTicketTypeResponse = await parkService?.getParkApplicableTicketTypeitems({
            park_applicable_ticket_type_id : park_applicable_ticket_type_id,
            is_check_purchase_type : false,
        });
        const { park_applicable_ticket_users, } = fetchParkApplicableTicketTypeResponse;

        const { name, free_quantity, required_quantity, free_ticket_applicable_user_id, free_ticket_rule_requires = [], free_ticket_applicable_users, } = free_ticket_rule;

        setState(state => ({
            ...state,
            loading : false,
            free_ticket_rule : free_ticket_rule,
            ticket_applicable_users : park_applicable_ticket_users,
            selected_free_ticket_applicable_user_id : free_ticket_applicable_user_id,
            park_applicable_ticket_type : free_ticket_applicable_users?.park_applicable_ticket_type,
        }));

        // assign to form
        if(free_ticket_rule){
            form.setFieldsValue({
                name,
                free_quantity,
                required_quantity,
                free_ticket_applicable_user : free_ticket_applicable_user_id,
                free_ticket_rule_requires : free_ticket_rule_requires?.map(x => x?.ticket_applicable_user_id),
            })
        }
    }

    useEffect(() => {
        fetchFreeTicketRules();
    }, [free_ticket_rule_id, modal_visible])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <Form.Item label={'Name'} name={'name'}>
                    <Input />
                </Form.Item>
                <Form.Item label={'Ticket Type'} name={'park_applicable_ticket_type_id'}>
                    <Space>
                        <div>
                            <Avatar icon={<AppstoreOutlined />} shape='square' size='small' src={state?.park_applicable_ticket_type?.image} />
                        </div>
                        <div>
                            <span>{state?.park_applicable_ticket_type?.ticket_type?.name}</span>
                        </div>
                    </Space>
                </Form.Item>

                <Divider style={{ margin : 0, marginBottom : 12, }} />
                <div>
                    <PageHeaderComponent title={<span style={{ fontSize : 14, }}>Ticket</span>} />
                    <Row gutter={[12, 12]}>
                        <Col xs={24} md={12}>
                            <Form.Item label={'Entitled Quantity'} name={'free_quantity'}>
                                <InputNumber min={0} style={{ width : '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item label={'Entitled Ticket'} name={'free_ticket_applicable_user'}>
                                <Select
                                onChange={(ticket_applicable_user_id) => setState(state => ({...state, selected_free_ticket_applicable_user_id : ticket_applicable_user_id, }))}
                                options={state?.ticket_applicable_users?.map(x => ({ value : x?.ticket_applicable_user_id, label : x?.ticket_user_type?.name, }))}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>

                <Divider style={{ margin : 0, marginBottom : 12, }} />
                <div>
                    <PageHeaderComponent title={<span style={{ fontSize : 14, }}>Condition For Ticket</span>} />
                    <Row gutter={[12, 12]}>
                        <Col xs={24} md={12}>
                            <Form.Item label={'Required Quantity'} name={'required_quantity'}>
                                <InputNumber min={0} style={{ width : '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item label={'Required Ticket'} name={'free_ticket_rule_requires'}>
                                <Select
                                mode='multiple'
                                options={state?.ticket_applicable_users?.map(x => ({ value : x?.ticket_applicable_user_id, label : x?.ticket_user_type?.name, disabled : (x?.ticket_applicable_user_id == state?.selected_free_ticket_applicable_user_id), }))}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Spin>
        </>
    )
}

export default EditFreeTicketRuleModal;